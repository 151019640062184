import { LinkPoolProposalFormState } from 'js/models'
import store from 'js/store'
import { escapeHtmlGo } from 'js/utils'
import { RPCParams } from 'tradehub-api-js'

export const requiredFields = ['poolID', 'marketName']

export function linkPoolProposalValue(): RPCParams.LinkPoolProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as LinkPoolProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, poolID, marketName } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    msg: {
      pool_id: poolID,
      market: marketName,
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
