import { clear, setPosition } from 'js/actions/position'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import {
  Effect,
  put,
  spawn,
  takeLatest,
} from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Position extends Saga {
  private readonly id: string
  constructor(id: string) {
    super()
    this.id = id
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.fetchPosition], [this, this.watchSetNetwork]].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchPosition(): any {
    const position_id = this.id
    yield runSagaTask(TaskNames.Position.Detail, function* () {
      const sdk = yield* getInitializedSDK()
  
      const position = (yield sdk.api.getPosition({
        position_id,
      })) as RestModels.Position
      yield put(setPosition(position))
    });
  }
}