import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { Location, Pathname } from 'history'
import { Paths } from 'js/constants'
import ReactGA from 'react-ga'
import { match, matchPath } from 'react-router'
// import { useLocation } from 'react-router-dom'
import { SagaIterator } from 'redux-saga'
import { call, Effect, select, spawn, takeLatest } from 'redux-saga/effects'
import { parseNet } from '../models/Network'
import Account from './Account'
import Block from './Block'
import Blocks from './Blocks'
import Dashboard from './Dashboard'
import Events from './Events'
import Governance from './Governance'
import Leaderboard from './Leaderboard'
import LeaderboardHistory from './LeaderboardHistory'
import LiquidityPools from './LiquidityPools'
import Position from './Position'
import Market from './Market'
import Markets from './Markets'
import Nodes from './Nodes'
import Order from './Order'
import Saga from './Saga'
import Token from './Token'
import Tokens from './Tokens'
import Transaction from './Transaction'
import Transactions from './Transactions'
import Validator from './Validator'
import Validators from './Validators'

export default class Router extends Saga {
  private saga?: Saga
  private location: Pathname

  constructor() {
    super()
    this.location = ''
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.watchLocationChange]].map(spawn)
    // return [spawn([this, this.watchLocationChange])]
  }

  private *watchLocationChange() {
    yield takeLatest(LOCATION_CHANGE, this.handleLocationChange.bind(this))
  }

  private *handleLocationChange(action: LocationChangeAction): SagaIterator {
    const location: Location = action.payload.location
    this.location = location.pathname

    const params = new URLSearchParams(window.location.search)
    if (params.get('net') === null) {
      const network: any = yield select((state) => state.app.network)
      const net = parseNet(network)

      params.set('net', net)
      window.history.replaceState('', '', `${location.pathname}?${params}`)
    }

    // TODO: remove to bypass sagas not working
    // if (this.location === location.pathname) {
    //   return
    // }

    ReactGA.ga('send', 'pageview', location.pathname, { transport: 'beacon' })

    for (const { path, sagaCreator } of [
      {
        path: Paths.Account,
        sagaCreator: (m: match<any>): Saga => {
          return new Account(m.params.address, params.get('msg_type'))
        },
      },
      {
        path: Paths.Blocks,
        sagaCreator: (m: match<any>): Saga => new Blocks(),
      },
      {
        path: Paths.Nodes,
        sagaCreator: (m: match<any>): Saga => new Nodes(),
      },
      {
        path: Paths.Block,
        sagaCreator: (m: match<any>): Saga => new Block(m.params.height),
      },
      {
        path: Paths.Transaction,
        sagaCreator: (m: match<any>): Saga => {
          return new Transaction(m.params.hash)
        },
      },
      {
        path: Paths.Transactions,
        sagaCreator: (m: match<any>): Saga => new Transactions(),
      },
      {
        path: Paths.Token,
        sagaCreator: (m: match<any>): Saga => {
          return new Token(m.params.symbol)
        },
      },
      {
        path: Paths.Tokens,
        sagaCreator: (m: match<any>): Saga => new Tokens(),
      },
      {
        path: Paths.Events,
        sagaCreator: (m: match<any>): Saga => new Events(),
      },
      {
        path: Paths.Markets,
        sagaCreator: (m: match<any>): Saga => new Markets(),
      },
      {
        path: Paths.Market,
        sagaCreator: (m: match<any>): Saga => new Market(m.params.market),
      },
      {
        path: Paths.Order,
        sagaCreator: (m: match<any>): Saga => new Order(m.params.id),
      },
      {
        path: Paths.Position,
        sagaCreator: (m: match<any>): Saga => new Position(m.params.id)
      },
      {
        path: Paths.Validators,
        sagaCreator: (m: match<any>): Saga => new Validators(),
      },
      {
        path: Paths.Validator,
        sagaCreator: (m: match<any>): Saga => new Validator(m.params.address),
      },
      {
        path: Paths.Governance,
        sagaCreator: (m: match<any>): Saga => new Governance(),
      },
      {
        path: Paths.Pools,
        sagaCreator: (m: match<any>): Saga => new LiquidityPools(),
      },
      {
        path: Paths.Leaderboard,
        sagaCreator: (m: match<any>): Saga => new Leaderboard(),
      },
      {
        path: Paths.LeaderboardHistory,
        sagaCreator: (m: match<any>): Saga => new LeaderboardHistory(),
      },
      {
        path: Paths.Dashboard,
        sagaCreator: (m: match<any>): Saga => new Dashboard(),
      },
    ]) {
      const m: match<any> | null = matchPath(location.pathname, { path })
      if (m) {
        yield call([this, this.startSaga], sagaCreator(m))
        return
      }
    }
  }

  private *startSaga(saga: Saga): SagaIterator {
    if (this.saga) {
      yield* this.saga.stop()
    }
    yield* saga.start()
    this.saga = saga
  }
}
