import { makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import clsx from 'clsx'
import { useRedux } from 'js/hooks'
import React from 'react'

const Tokens = importIcons((require as any).context('assets/tokens', false))

function importIcons(r: any) {
  const icons: any = {}
  r.keys().forEach((key: string) => {
    const res = key.match(/^\.\/(.*)\..*?$/)
    if (res) {
      const symbol: string = res[1]
      icons[symbol] = r(key)
    }
  })
  return icons
}

const tokenNameMap: {
  [index: string]: string
} = {
  BSC: 'BNB',
  BTCB: 'BTC',
  IUSD: 'USD',
  nNEO: 'NEO',
}

export interface TokenIconProps extends SvgIconProps {
  denom?: string
  hideUnknown?: boolean
}

const TokenIcon: React.FunctionComponent<TokenIconProps> = (
  props: TokenIconProps,
) => {
  const { denom, className, hideUnknown, ...rest } = props
  const tokenClient = useRedux((state) => state.core.sdk?.token)
  const classes = useStyles()

  let tokenName = tokenClient?.getTokenName(denom ?? '') ?? denom?.toUpperCase() ?? '';
  if (tokenNameMap[tokenName]) {
    tokenName = tokenNameMap[tokenName]
  }
  let defaultIcon: React.ReactNode = <AttachMoney />

  if (hideUnknown) {
    defaultIcon = null
  }

  const Icon: string | undefined = Tokens[tokenName]

  return Icon ? (
    <img className={clsx(classes.svg, className)} src={Icon} alt={tokenName} />
  ) : (
    <SvgIcon
      {...rest}
      className={clsx(classes.svg, className)}
    >
      {defaultIcon}
    </SvgIcon>
  )
}

const useStyles = makeStyles({
  svg: {
    fontSize: 'inherit',
    width: '2em',
    height: '2em',
  },
})

export default TokenIcon
