import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, delay, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setBlockTime, setDistributionParams, setSlashingParams } from '../actions/validators'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Validators extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.pollAvgBlockTime]),
      call([this, this.fetchSlashingParameters]),
      call([this, this.fetchDistributionParameters]),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchSlashingParameters(): any {
    yield runSagaTask(TaskNames.Validators.Slashing, function* () {
      const sdk = yield* getInitializedSDK()

      const params = (yield sdk.api.getSlashingParams()) as RestTypes.GetSlashingParamsResponse
      yield put(setSlashingParams(params))
    });
  }
  private *fetchDistributionParameters(): any {
    yield runSagaTask(TaskNames.Validators.Distribution, function* () {
      const sdk = yield* getInitializedSDK()

      const params = (yield sdk.api.getDistributionParams()) as RestTypes.GetDistributionParamsResponse
      yield put(setDistributionParams(params))
    });
  }

  private * pollAvgBlockTime(): any {
    while (true) {
      yield runSagaTask(TaskNames.Validators.BlockTime, function* () {
        const sdk = yield* getInitializedSDK()

        const blockTime = (yield sdk.api.getAverageBlocktime()) as string
        yield put(setBlockTime(blockTime))
      });
      yield delay(11015)
    }
  }
}
