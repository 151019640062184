import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiListItem']>> => ({
  root: {
    fontSize: 13,
    paddingTop: 4,
    paddingBottom: 4,
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
})
