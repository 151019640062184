import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { Fragment, ReactElement } from 'react'

interface Props {
  checked: boolean
  label: string
  onChange: any
}

const CheckboxFilter: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { checked, label, onChange } = props

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            color="primary"
          />
        }
        label={label}
      />
    </Fragment>
  )
}

export default CheckboxFilter
