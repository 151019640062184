import { BIG_ZERO, parseNumber } from 'js/utils'
import { fieldTypes } from '../../GenericProposal/Helpers/types'
import { getErrorMessages, getInputConstraints } from '../../Helpers/InputConstraints'

export const requiredFields = [
  'name',
  'symbol',
  'denom',
  'decimals',
  'nativeDecimals',
  'blockchain',
  'chainId',
  'assetId',
  'isCollateral',
  'delegatedSupply',
]

const basicConfig = {
  symbol: {
    id: 'symbol',
    type: fieldTypes.text,
    constraints: {
      validPattern: true,
    },
  },
  decimals: {
    id: 'decimals',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 18,
    },
  },
  nativeDecimals: {
    id: 'nativeDecimals',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 18,
    },
  },
  chainId: {
    id: 'chainId',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
}

export function createTokenValidator(formState: any) {
  const errors = getErrorMessages(formState, getInputConstraints(basicConfig))

  const decimals = parseNumber(formState.decimals, BIG_ZERO)!
  const nativeDecimals = parseNumber(formState.nativeDecimals, BIG_ZERO)!
  if (nativeDecimals.isGreaterThan(decimals)) {
    errors.push('Native decimals cannot be larger than token decimals')
  }

  return errors
}
