import { Tab, Tabs } from '@material-ui/core'
import React, { ReactElement } from 'react'

interface Props {
  handleChange: any
  items: TabItem[]
  tabClass?: string
  value: number | string
}

interface TabItem {
  label: string
  key?: string
}

const TabsButtons: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { handleChange, items, tabClass, value } = props

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="on"
    >
      {
        items.map((itm, id) =>
          <Tab
            className={tabClass}
            key={id}
            value={itm.key || id}
            label={itm.label ? itm.label : `Tab ${id}`}
          />,
        )
      }
    </Tabs>
  )
}

export default TabsButtons
