import { makeStyles, Table, TableBody, TableContainer, Theme, Typography } from '@material-ui/core'
import { ProposalRow, Section } from 'js/components'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  title: string
  models: RestModels.GovProposal[]
}

const SectionTable: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, models } = props
  const classes = useStyles()

  return (
    <Section title={title}>
      {models.length === 0 && (
        <Typography>No proposals found</Typography>
      )}

      {models.length > 0 && (
        <TableContainer>
          <Table className={classes.proposalsTable}>
            <TableBody>
              {models.map((proposal: RestModels.GovProposal, index: number) => (
                <ProposalRow proposal={proposal} key={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  proposalsTable: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(3),
      '&:first-child': {
        paddingLeft: theme.spacing(3),
      },
      '&:last-child': {
        paddingRight: theme.spacing(3),
      },
    },
    '& .MuiTableRow-root:not(:last-child) .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}))

export default SectionTable
