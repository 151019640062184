import { makeStyles, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import BigNumber from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import { CellLink, ListTable } from 'js/components'
import { capitalizeFirstLetter } from 'js/helpers'
import { shorten } from 'js/helpers/utils'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  trades: RestModels.Trade[]
  type?: string
  takerOrMaker: string
}

const RelatedTrades: React.FunctionComponent<Props> = (props): ReactElement<Props> => {
  const classes = useStyles()
  const { trades, type, takerOrMaker } = props
  return (
    <>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Side</TableCell>
            {type && (
              <TableCell>Type</TableCell>
            )}
            <TableCell align="right">Price</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Counter Order ID</TableCell>
            <TableCell>Counter Party Address</TableCell>
            <TableCell align="right">Match at</TableCell>
            <TableCell>Height</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{trades.map((trade: RestModels.Trade) => renderTrade(trade, type, takerOrMaker, classes))}</TableBody>
      </ListTable>
    </>
  )
}

function renderTrade(trade: RestModels.Trade, type: string | undefined, takerOrMaker: string, classes: ClassNameMap): ReactNode {
  const {
    block_created_at,
    block_height,
    taker_side,
    taker_id,
    maker_id,
    maker_address,
    taker_address,
    quantity,
    price,
  } = trade
  const time: Dayjs = dayjs(block_created_at)
  const priceBN: BigNumber = new BigNumber(price)
  const quantityBN: BigNumber = new BigNumber(quantity)
  const orderId = takerOrMaker === "taker" ? taker_id : maker_id
  const counterId = takerOrMaker === "taker" ? maker_id : taker_id
  const counterAddress = takerOrMaker === "taker" ? maker_address : taker_address

  return (
    <TableRow key={orderId + quantity + price + block_created_at + taker_id + maker_id} hover>
      <TableCell>
        <CellLink to={`/order/${orderId}`}>
          <span className={classes.longString}>
            {shorten(orderId)}
          </span>
        </CellLink>
      </TableCell>
      <TableCell>
        {capitalizeFirstLetter(taker_side)}
      </TableCell>
      {
        type && (
          <TableCell>
            {capitalizeFirstLetter(type)}
          </TableCell>
        )
      }
      <TableCell align="right">
        {priceBN.toString()}
      </TableCell>
      <TableCell>
        {quantityBN.toString()}
      </TableCell>
      <TableCell>
        <CellLink to={`/order/${counterId}`}>
          <span className={classes.longString}>
            {shorten(counterId)}
          </span>
        </CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/account/${counterAddress}`}>
          <span className={classes.longString}>
            {shorten(counterAddress)}
          </span>
        </CellLink>
      </TableCell>
      <TableCell align="right">
        <span className={classes.longString}>
          {time.format('YYYY-MM-DD HH:mm:ss')}
        </span>
      </TableCell>
      <TableCell>
        <CellLink to={`/order/${maker_id}`}>{block_height}</CellLink>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  longString: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 1,
    overflow: 'hidden',
    display: 'inline-block'

  },
}))
export default RelatedTrades
