import { CreateOracleProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { RPCParams } from "tradehub-api-js"
import { fieldTypes } from "../Helpers/types"

export const createOracleFormRequiredFields = [
  'oracleId',
  'oracleDescription',
  'minTurnoutPercentage',
  'maxResultAge',
  'securityType',
  'resultStrategy',
  'resolution',
  'spec',
]

export function createOracleConfig() {
  return {
    oracleId: {
      id: 'oracleId',
      label: 'Oracle ID',
      type: fieldTypes.text,
      tooltip: 'Unique identifier for oracle',
      placeholder: 'Eg. DETH',
      constraints: {
        validPattern: true,
      },
    },
    oracleDescription: {
      id: 'oracleDescription',
      label: 'Oracle Description',
      type: fieldTypes.text,
      tooltip: 'Description of oracle',
      constraints: {
        validPattern: true,
      },
    },
    minTurnoutPercentage: {
      id: 'minTurnoutPercentage',
      label: 'Min Turnout Percentage',
      type: fieldTypes.number,
      tooltip: 'Minimum percentage for a oracle vote to be accepted as an oracle result',
      constraints: {
        greaterThan: 0,
        lessThanOrEqualTo: 100,
        onlyInteger: true,
      },
    },
    maxResultAge: {
      id: 'maxResultAge',
      label: 'Max Result Age',
      type: fieldTypes.number,
      tooltip: 'Maximum age an oracle vote will last',
      constraints: {
        greaterThan: 0,
        onlyInteger: true,
      },
    },
    securityType: {
      id: 'securityType',
      label: 'Security Type',
      type: fieldTypes.select,
      options: ["SecuredByValidators"],
      tooltip: 'Set to "SecuredByValidators"',
    },
    resultStrategy: {
      id: 'resultStrategy',
      label: 'Result Strategy',
      type: fieldTypes.select,
      options: ["exact", "median"],
      tooltip: 'Strategy to pick data for oracle result, "exact" or "median"',
    },
    resolution: {
      id: 'resolution',
      label: 'Resolution',
      type: fieldTypes.number,
      tooltip: 'Rate at which oracle votes should be submitted',
      constraints: {
        greaterThan: 0,
        onlyInteger: true,
      },
    },
    spec: {
      id: 'spec',
      label: 'Spec',
      type: fieldTypes.text,
      tooltip: 'Specification for oracle service to generate oracle votes',
      constraints: {
        validJSON: true,
      },
    },
  }
}

export function createOracleProposalValue(): RPCParams.CreateOracleProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as CreateOracleProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, oracleId, oracleDescription, minTurnoutPercentage, maxResultAge,
    securityType, resultStrategy, resolution, spec } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    oracle: {
      id: oracleId,
      description: oracleDescription,
      min_turnout_percentage: parseNumber(minTurnoutPercentage, BIG_ZERO)!.toString(),
      max_result_age: parseNumber(maxResultAge, BIG_ZERO)!.toString(),
      security_type: securityType,
      result_strategy: resultStrategy,
      resolution: parseNumber(resolution, BIG_ZERO)!.toString(),
      spec,
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
