import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CellLink, DataLoadSegment, ListTable, PaginationByIndex, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { shorten } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import { BIG_ZERO, parseNumber } from 'js/utils'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { bnOrZero, SimpleMap } from 'js/utils'

export interface TradesProps {
  filterStatus: boolean
  trades?: RestTypes.ResultsMinMax<RestModels.Trade>
  title: string
  action: any
  markets: SimpleMap<RestModels.Market>
  loadName: string[]
}

interface Props extends TradesProps { }

const Trades: React.FunctionComponent<Props> = ({
  filterStatus = false,
  trades,
  title,
  action,
  markets,
  loadName,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.App.Markets, ...loadName)

  return (
    <Section title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Market</TableCell>
              <TableCell>Side</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell>Order ID (Maker)</TableCell>
              <TableCell>Maker Acount</TableCell>
              <TableCell>Order ID (Taker)</TableCell>
              <TableCell>Taker Account</TableCell>
              <TableCell align="right">Matched At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{trades?.data.map(renderTrade)}</TableBody>
        </ListTable>

        {!!trades?.data.length && (
          <Box marginTop={3}>
            <PaginationByIndex
              response={trades}
              action={action}
              useBlockHeight={false}
            />
          </Box>
        )}
        {!trades?.data.length && (
          <TableEmptyState itemName="trades" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTrade(trade: RestModels.Trade, index: number): ReactNode {
    const priceBN = bnOrZero(trade.price)
    const quantityBN = bnOrZero(trade.quantity)
    const lotDp: number = parseNumber(markets?.[trade.market]?.lot_size, BIG_ZERO)!.dp()
    const tickDp: number = parseNumber(markets?.[trade.market]?.tick_size, BIG_ZERO)!.dp()
    return (
      <TableRow
        key={`${trade.maker_address}${index}trade`}
        hover
      >
        <TableCell>
          <CellLink to={`/market/${trade.market}`}>{trade.market}</CellLink>
        </TableCell>
        <TableCell>{trade.taker_side}</TableCell>
        <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
        <TableCell align="right">{quantityBN.toFormat(lotDp)}</TableCell>
        <TableCell>
          <CellLink to={`/order/${trade.maker_id}`}>{shorten(trade.maker_id)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${trade.maker_address}`}>
            {trade.maker_username === '' ? shorten(trade.maker_address) : trade.maker_username}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/order/${trade.taker_id}`}>{shorten(trade.taker_id)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${trade.taker_address}`}>
            {trade.taker_username === '' ? shorten(trade.taker_address) : trade.taker_username}
          </CellLink>
        </TableCell>
        <TableCell align="right">
          <CellLink to={`/block/${trade.block_height}`}>{trade.block_height}</CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default Trades
