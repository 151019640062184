import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CellLink, DataLoadSegment, ListTable, PaginationByIndex, Section, TableEmptyState } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import { bnOrZero, SimpleMap } from 'js/utils'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { OrderStatusLabel } from './ParsedLabel'

export interface OrdersProps {
  filterStatus: boolean
  orders?: RestTypes.ResultsMinMax<RestModels.Order>
  title: string
  action: any
  markets: SimpleMap<RestModels.Market>
  loadName: string[]
  loadCondition?: boolean
  sectionClass?: string
}

const Orders: React.FunctionComponent<OrdersProps> = ({
  filterStatus = false,
  orders,
  title,
  action,
  markets,
  loadName,
  loadCondition = true,
  sectionClass,
}: OrdersProps): ReactElement<OrdersProps> => {
  const [loading] = useTaskSubscriber(...loadName)

  return (
    <Section className={sectionClass} title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={loading && loadCondition}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell>Side</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Filled</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{orders?.data.map(renderOrder)}</TableBody>
        </ListTable>
        {!!orders?.data?.length && (
          <Box marginTop={3}>
            <PaginationByIndex
              response={orders}
              action={action}
              filters={{}}
              useBlockHeight={false}
            />
          </Box>
        )}
        {!orders?.data?.length && (
          <TableEmptyState itemName={title.toLowerCase()} />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderOrder(order: RestModels.Order): ReactNode {
    const {
      address,
      username,
      market: marketName,
      order_type,
      side,
      price,
      quantity,
      filled,
      order_status,
      block_height,
      order_id,
    } = order
    const market = markets[marketName]

    const priceBN = bnOrZero(price)
    const quantityBN = bnOrZero(quantity)
    const filledBN = bnOrZero(filled)
    const lotDp = bnOrZero(market?.lot_size).dp()
    const tickDp = bnOrZero(market?.tick_size).dp()

    return (
      <TableRow key={order_id} hover>
        <TableCell>
          <CellLink to={`/order/${order_id}`}>{shorten(order_id)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {username === '' ? shorten(address) : username}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${marketName}`}>{marketName}</CellLink>
        </TableCell>
        <TableCell>{order_type}</TableCell>
        <TableCell>{side}</TableCell>
        <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
        <TableCell align="right">{quantityBN.toFormat(lotDp)}</TableCell>
        <TableCell align="right">{filledBN.toFormat(lotDp)}</TableCell>
        <TableCell>
          <OrderStatusLabel>{order_status}</OrderStatusLabel>
        </TableCell>
        <TableCell align="right">
          <CellLink to={`/block/${block_height}`}>{block_height}</CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default Orders
