import { Backdrop, Box, Link, makeStyles, MenuItem, MenuList, Paper, Theme } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import clsx from 'clsx'
import { setMenuDropClose, setMenuDropItem, setNetDropClose, setWalletDropClose } from 'js/actions/app'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { MenuRouteItem } from '../headerConfig'

interface Props {
  item: MenuRouteItem
}

const HeaderMenuItem: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { item } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const location = useLocation()

  const menuDrop = useRedux((state) => state.app.menuDrop)

  const onOpenMenu = (menuItem: string) => {
    dispatch(setNetDropClose())
    dispatch(setWalletDropClose())
    dispatch(setMenuDropItem(menuItem))
  }
  const onCloseMenu = () => dispatch(setMenuDropClose())
  const renderLinkItem = (item: MenuRouteItem) => {
    if (item.link) {
      return (
        <Link
          underline="none"
          component={NavLink}
          to={item.link}
          className={classes.menuItem}
          activeClassName={classes.activeMenuItem}
          variant="h3"
          exact={item.matchExact}
        >
          {item.label}
        </Link>
      )
    }
    if (item.href) {
      return (
        <Link
          underline="none"
          href={item.href}
          className={classes.menuItem}
          variant="h3"
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.label}
        </Link>
      )
    }
  }

  return (
    <>
      {!item.items && renderLinkItem(item)}
      {item.items && (
        <Box position="relative" height="100%">
          <Link
            underline="none"
            className={clsx(classes.menuItem, {
              [classes.activeMenuItem]: item.linkArr?.includes(location.pathname),
            })}
            variant="h3"
            onClick={() => onOpenMenu(item.label)}
          >
            {item.label}
            {
              menuDrop === item.label
                ? (
                  <ArrowDropUp className={classes.dropdownIcon} />
                ) : (
                  <ArrowDropDown className={classes.dropdownIcon} />
                )
            }
          </Link>
          {
            menuDrop === item.label && (
              <React.Fragment>
                <Backdrop className={classes.backdrop} invisible open={menuDrop === item.label} onClick={onCloseMenu} />
                <Paper className={classes.linkDropdown}>
                  <MenuList className={classes.menuList}>
                    {item.items.map((item, index) => (
                      <MenuItem
                        className={classes.dropdownMenuItem}
                        key={index}
                        onClick={onCloseMenu}
                      >
                        {renderLinkItem(item)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </React.Fragment>
            )
          }
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backdrop: {
  },
  dropdownMenuItem: {
    fontWeight: 'normal',
    padding: 0,
    minWidth: '10rem',
    '& a.MuiLink-root': {
      padding: theme.spacing(2, 3),
      width: "100%",
    },
  },
  dropdownIcon: {
    verticalAlign: 'middle',
    // marginBottom: theme.spacing(0.5),
  },
  linkDropdown: {
    left: 0,
    top: '3.25rem',
    position: 'absolute',
  },
  menuList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  menuItem: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'normal',
    padding: theme.spacing(2),
    textDecoration: 'none',
    width: "100%",
  },
  activeMenuItem: {
    minWidth: 'unset',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
}))

export default HeaderMenuItem
