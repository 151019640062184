import dayjs from 'dayjs'
import { SetRewardCurveProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from "js/utils"
import { RPCParams } from "tradehub-api-js"
import { fieldTypes } from "../Helpers/types"

export const setRewardCurveFormRequiredFields = [
  'startTime',
  'initialRewardBps',
  'reductionMultiplierBps',
  'reductionIntervalSeconds',
  'reductions',
  'finalRewardBps',
]

export function setRewardCurveConfig() {
  return {
    startTime: {
      id: 'startTime',
      label: 'Start Time',
      type: fieldTypes.datetime,
      constraints: {
        inFuture: true,
      },
    },
    initialRewardBps: {
      id: 'initialRewardBps',
      label: 'Initial Reward Bps',
      type: fieldTypes.number,
      tooltip: 'Initial rewards/fees to be distributed in basis points',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
    reductionMultiplierBps: {
      id: 'reductionMultiplierBps',
      label: 'Reduction Multiplier Bps',
      type: fieldTypes.number,
      tooltip: 'Rewards/fees to be reduced in basis points on each interval',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
    reductionIntervalSeconds: {
      id: 'reductionIntervalSeconds',
      label: 'Reduction Interval Seconds',
      type: fieldTypes.number,
      tooltip: 'Interval period in seconds',
      constraints: {
        greaterThanOrEqualTo: 60,
        onlyInteger: true,
      },
    },
    reductions: {
      id: 'reductions',
      label: 'Reductions',
      type: fieldTypes.number,
      tooltip: 'Number of intervals that will apply reduce the allocation',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
    finalRewardBps: {
      id: 'finalRewardBps',
      label: 'Final Reward Bps',
      type: fieldTypes.number,
      tooltip: 'Minimum rewards/fees to be distributed in basis points',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
  }
}

export function setRewardCurveProposalValue(): RPCParams.SetRewardCurveProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as SetRewardCurveProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, startTime, initialRewardBps, reductionMultiplierBps,
    reductionIntervalSeconds, reductions, finalRewardBps } = formState!

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    msg: {
      start_time: dayjs(startTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      initial_reward_bps: parseNumber(initialRewardBps, BIG_ZERO)!.toNumber(),
      reduction_multiplier_bps: parseNumber(reductionMultiplierBps, BIG_ZERO)!.toNumber(),
      reduction_interval_seconds: parseNumber(reductionIntervalSeconds, BIG_ZERO)!.toString(),
      reductions: parseNumber(reductions, BIG_ZERO)!.toNumber(),
      final_reward_bps: parseNumber(finalRewardBps, BIG_ZERO)!.toNumber(),
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
