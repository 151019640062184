import BigNumber from 'bignumber.js'
import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum LiquidityPoolsActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_LIQUIDITY_POOLS = 'SET_LIQUIDITY_POOLS',
  SET_WEEKLY_POOL_REWARDS = 'SET_WEEKLY_POOL_REWARDS',
  SET_COMMITMENT_CURVE = 'SET_COMMITMENT_CURVE',
  SET_REWARD_CURVE = 'SET_REWARD_CURVE',
}

export type LiquidityPoolsAction =
  | ClearAction
  | SetLiquidityPoolsAction
  | SetWeeklyPoolRewardsAction
  | SetCommitmentCurveAction
  | SetRewardCurveAction

export interface ClearAction extends Action<LiquidityPoolsActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: LiquidityPoolsActionType.CLEAR,
  }
}

export interface SetLiquidityPoolsAction extends Action<LiquidityPoolsActionType.SET_LIQUIDITY_POOLS> {
  pools: RestModels.LiquidityPool[]
}

export function setLiquidityPools(pools: RestModels.LiquidityPool[]): SetLiquidityPoolsAction {
  return {
    type: LiquidityPoolsActionType.SET_LIQUIDITY_POOLS,
    pools,
  }
}

export interface SetWeeklyPoolRewardsAction extends Action<LiquidityPoolsActionType.SET_WEEKLY_POOL_REWARDS> {
  rewards: BigNumber
}
export function setWeeklyPoolRewards(rewards: BigNumber): SetWeeklyPoolRewardsAction {
  return {
    type: LiquidityPoolsActionType.SET_WEEKLY_POOL_REWARDS,
    rewards,
  }
}
export interface SetCommitmentCurveAction extends Action<LiquidityPoolsActionType.SET_COMMITMENT_CURVE> {
  commitmentCurve: RestModels.CommitmentCurve
}
export function setCommitmentCurve(commitmentCurve: RestModels.CommitmentCurve): SetCommitmentCurveAction {
  return {
    type: LiquidityPoolsActionType.SET_COMMITMENT_CURVE,
    commitmentCurve,
  }
}

export interface SetRewardCurveAction extends Action<LiquidityPoolsActionType.SET_REWARD_CURVE> {
  rewardCurve: RestModels.RewardCurve
}
export function setRewardCurve(rewardCurve: RestModels.RewardCurve): SetRewardCurveAction {
  return {
    type: LiquidityPoolsActionType.SET_REWARD_CURVE,
    rewardCurve,
  }
}
