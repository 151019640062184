import { makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CellLink, ProfileAvatarIcon } from 'js/components'
import { Constants, SignedBlocksCount } from 'js/constants'
import React, { ReactElement, useEffect, useState } from 'react'
import { RestModels } from 'tradehub-api-js'
import { bnOrZero } from 'js/utils'

type Props = {
  delegatorList: RestModels.ValidatorDelegation[]
  index: number
  info: any
  totalShares: BigNumber
  validator?: RestModels.Validator
}

const ValidatorRow: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const classes = useStyles()
  const { delegatorList = [], index, info, totalShares, validator } = props
  const delegatorSharesBN = bnOrZero(validator?.DelegatorShares)
  const delegatorsNum = bnOrZero(delegatorList.length)
  const commissionBN = bnOrZero(validator?.Commission.commission_rates.rate).shiftedBy(2)
  const sharePercent = delegatorSharesBN.div(totalShares)
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)
  let selfBond: BigNumber = new BigNumber(0)
  const currencyShift = -Constants.Decimals.SWTH
  // tslint:disable: prefer-for-of
  // tslint:disable: no-increment-decrement
  for (let i = 0; i < delegatorList.length; i++) {
    if (delegatorList[i]?.delegator_address === validator?.WalletAddress) {
      selfBond = new BigNumber(delegatorList[i]?.shares).shiftedBy(currencyShift)
    }
  }

  const description = validator?.Description
  useEffect(() => {
    if (description?.identity === '') {
      setImage('')
    } else {
      setLoading(true)
      fetch(
        `https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${description?.identity}&fields=basics,pictures`,
      )
        .then((r: any) => (r ? r.json() : null))
        .then((json: any) => {
          if (json && json?.them && json?.them[0]) {
            const user = json?.them[0]
            const newImg = user?.pictures?.primary?.url
            setImage(newImg)
          }
        })
    }
  }, [description])

  const uptime = (info: any, cAdd: string) => {
    const missedBlocks = new BigNumber(bnOrZero(info[cAdd]?.missed_blocks_counter).toNumber())
    if (typeof missedBlocks === 'undefined') {
      return '0%'
    }
    if (missedBlocks.comparedTo(SignedBlocksCount) === 1) {
      return 100
    }
    return SignedBlocksCount.minus(missedBlocks).div(SignedBlocksCount).times(100).toFormat(2)
  }

  return (
    <TableRow key={validator?.OperatorAddress} hover>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <div className={classes.nameCell}>
          <ProfileAvatarIcon
            avatarClass={classes.avatar}
            initialClass={classes.initial}
            srcUrl={image}
            loading={loading}
            name={description?.moniker ?? ''}
            setLoading={setLoading}
            spinnerSize="0.6rem"
          />
          <CellLink to={`/validator/${validator?.OperatorAddress}`}>
            {description?.moniker}
          </CellLink>
        </div>
      </TableCell>
      <TableCell align="right" className={classes.numCell}>
        <div>{delegatorSharesBN.toFormat(0) ? `${delegatorSharesBN.toFormat(0)} SWTH` : '-'}</div>
        <div>({sharePercent.times(100).toFormat(2) ? `${sharePercent.times(100).toFormat(2)}%` : '-'})</div>
      </TableCell>
      <TableCell align="right" className={classes.numCell}>
        <div>{selfBond.toFormat(0) ? `${selfBond.toFormat(0)} SWTH` : '-'}</div>
        <div>
          ({selfBond.div(delegatorSharesBN).times(100)
            ? `${selfBond.div(delegatorSharesBN).times(100).toFormat(2)}%`
            : '-'})
        </div>
      </TableCell>
      <TableCell align="right">
        {delegatorsNum.toFormat(0)}
      </TableCell>
      <TableCell align="right">{commissionBN.toFormat(2)}%</TableCell>
      <TableCell align="right">
        <div>{uptime(info, validator?.ConsAddress ?? '')}%</div>
        <div>({info[validator?.ConsAddress ?? '']?.missed_blocks_counter || 0})</div>
      </TableCell>
      <TableCell align="right" className={classes.bondStatus}>
        {validator?.BondStatus}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: '1.2rem',
    height: '1.2rem',
    marginRight: theme.spacing(1),
  },
  bondStatus: {
    textTransform: 'capitalize',
  },
  initial: {
    fontSize: '0.6rem',
  },
  nameCell: {
    display: 'flex',
    minWidth: '12rem',
  },
  numCell: {
    minWidth: '11.2rem',
  },
}))

export default ValidatorRow
