import React, { useState } from 'react'

import ArrowUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import { Box } from '@material-ui/core'

interface Props {
  expanded?: boolean
  show: boolean
  children: any
}

const Expandable: React.FC<Props> = props => {
  const [isOpen, setIsOpen] = useState(props.expanded)

  return (
    <React.Fragment>
      {
        props.show ? (
          <>
            <Box onClick={() => setIsOpen(!isOpen)} display='flex' alignItems='center'>
              {isOpen ? 'Hide...' : 'Show More...'}
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Box>
            {isOpen ? props.children : null}
            {React.Children.count(props.children) === 0 && isOpen ? 'The list is empty!' : null}
          </>
        ) : props.children
      }
    </React.Fragment>
  )
}

export default Expandable