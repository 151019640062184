import { Box, BoxProps, FormControl, FormControlLabel, FormLabel, Grid, Input, InputLabel, makeStyles, Radio, RadioGroup, TextField, Theme } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { TooltipHint } from 'js/components'
import { TokenSelectInput } from 'js/components/form'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { CreateMarketProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CreateMarketProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import CreateMarketProposalFuturesForm from './CreateMarketProposalFuturesForm'
import { createFuturesMarketRequiredFields, createSpotMarketRequiredFields } from './Helpers/FormConstraints'

interface Props extends BoxProps {
}

const CreateMarketProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || CreateMarketProposalInitialFormState) as CreateMarketProposalFormState

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: CreateMarketProposalFormState) => {
    const requiredFields = formState.marketType === 'spot'
      ? createSpotMarketRequiredFields
      : createFuturesMarketRequiredFields
    const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof CreateMarketProposalFormState, inputValue: string): string => {
    const name = formKey === 'name' ? inputValue : formState.name

    return `Create ${name} market`
  }

  const handleFormChange = (key: keyof CreateMarketProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      let newFormState = {
        ...formState,
        [key]: value,
        title: isTitleInvolved ? autoGenerateTitle(key, value) : formState.title,
      }

      if (key === 'marketType') {
        if (newFormState.marketType === 'spot') {
          newFormState = {
            ...newFormState,
            riskStepSize: CreateMarketProposalInitialFormState.riskStepSize,
            initialMarginBase: CreateMarketProposalInitialFormState.initialMarginBase,
            initialMarginStep: CreateMarketProposalInitialFormState.initialMarginStep,
            maintenanceMarginRatio: CreateMarketProposalInitialFormState.maintenanceMarginRatio,
            maxLiquidationOrderTicket: CreateMarketProposalInitialFormState.maxLiquidationOrderTicket,
            maxLiquidationOrderDuration: CreateMarketProposalInitialFormState.maxLiquidationOrderDuration,
            impactSize: CreateMarketProposalInitialFormState.impactSize,
            markPriceBand: CreateMarketProposalInitialFormState.markPriceBand,
            lastPriceProtectedBand: CreateMarketProposalInitialFormState.lastPriceProtectedBand,
            indexOracleId: CreateMarketProposalInitialFormState.indexOracleId,
            expiryTime: CreateMarketProposalInitialFormState.expiryTime,
          }
        }
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  const handleExpiryChange = (date: MaterialUiPickersDate) => {
    if (!date) return
    dispatch(setSubmitProposalFormState({
      ...formState,
      expiryTime: date.toISOString(),
    }))
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            component="fieldset"
            fullWidth
          >
            <FormLabel component="legend">Market Type</FormLabel>
            <RadioGroup
              row
              value={formState.marketType}
              onChange={handleFormChange('marketType')}
            >
              <FormControlLabel
                value={'spot'}
                control={<Radio size="small" color='primary' />}
                label="Spot Market"
              />
              <FormControlLabel
                value={'futures'}
                control={<Radio size="small" color='primary' />}
                label="Futures Market"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Market Name
              <TooltipHint title={'Market name must be unique'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.name}
              onChange={handleFormChange('name', true)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Display Name
              <TooltipHint title={'Mutable name of market'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.displayName}
              onChange={handleFormChange('displayName')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TokenSelectInput value={formState.base} onChange={handleFormChange('base')} label={(
            <>
              Base
              <TooltipHint title={'Base token denom for market'} />
            </>
          )} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TokenSelectInput value={formState.quote} onChange={handleFormChange('quote')} label={(
            <>
              Quote
              <TooltipHint title={'Quote token denom for market'} />
            </>
          )} />
        </Grid>

        {formState.marketType === 'futures' && (
          <Grid item xs={12}>
            <InputLabel shrink>
              Expiry Time
            </InputLabel>
            <FormControl className={classes.formControl}>
              <KeyboardDateTimePicker
                value={formState.expiryTime}
                onChange={handleExpiryChange}
                DialogProps={{
                  className: classes.datetimePicker,
                }}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            type="number"
            rows={2}
            label="Market Description"
            InputLabelProps={{ shrink: true }}
            value={formState.marketDescription}
            onChange={handleFormChange('marketDescription')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Lot Size
              <TooltipHint title={'Smallest divisible unit for quantity of an order'} />
            </InputLabel>
            <Input
              fullWidth
              type="number"
              value={formState.lotSize}
              onChange={handleFormChange('lotSize')}
              placeholder='1'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Min Order Quantity (multiple of Lot Size)"
            InputLabelProps={{ shrink: true }}
            value={formState.minQuantity}
            onChange={handleFormChange('minQuantity')}
            placeholder='10'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Tick Size
              <TooltipHint title={'Smallest divisible unit for price of an order'} />
            </InputLabel>
            <Input
              fullWidth
              type="number"
              value={formState.tickSize}
              onChange={handleFormChange('tickSize')}
              placeholder='0.1'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Taker Fee %
              <TooltipHint title={'Taker fee for market'} />
            </InputLabel>
            <Input
              fullWidth
              type="number"
              value={formState.takerFee}
              onChange={handleFormChange('takerFee')}
              placeholder='1.0%'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Maker Fee %
              <TooltipHint title={'Maker fee for market'} />
            </InputLabel>
            <Input
              fullWidth
              type="number"
              value={formState.makerFee}
              onChange={handleFormChange('makerFee')}
              placeholder='-0.5%'
            />
          </FormControl>
        </Grid>
      </Grid>
      {formState.marketType === 'futures' && (
        <CreateMarketProposalFuturesForm formState={formState} />
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  datetimePicker: {
    '& .MuiPickersModal-dialogRootWider': {
      maxWidth: '20rem',
    },
    '& .MuiPickersBasePicker-pickerView': {
      maxWidth: 'unset',
    },
    '& .MuiPickerDTToolbar-toolbar': {
      '& .MuiPickersToolbarText-toolbarTxt': {
        '&.MuiTypography-subtitle1': {
          ...theme.typography.subtitle1,
          fontSize: '1rem',
          maxHeight: '1.85rem',
        },
        '&.MuiTypography-h4': {
          ...theme.typography.h4,
          fontSize: '2rem',
          lineHeight: 1.17,
          letterSpacing: '0.00735rem',
          fontWeight: 600,
        },
        '&.MuiTypography-h3': {
          ...theme.typography.h3,
          fontSize: '3rem',
          lineHeight: 1.04,
          letterSpacing: '0rem',
        },
      },
    },
    '& .MuiPickerDTTabs-tabs': {
      border: '1px solid transparent',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '& .MuiPickersYearSelection-container': {
      '& .MuiPickersYear-root': {
        fontSize: '1.25rem',
        height: '2.375rem',
        '&.MuiPickersYear-yearSelected': {
          fontWeight: 600,
          fontSize: '1.6rem',
        },
      },
    },
    '& .MuiDialogActions-root': {
      '& >button': {
        padding: theme.spacing(1, 1.75),
        minWidth: 'unset',
        textTransform: 'initial',
      },
    },
  },
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 120,
  },
}))

export default CreateMarketProposalMainForm
