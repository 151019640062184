export const switcheo = {
  default: {
    primary: '#063C4F',
    accent: '#E2FCA4',
    gray: '#DEDEDE',
    white: '#FFFFFF',
  },
  primary: {
    500: '#073C50',
    400: '#517684',
    300: '#9DADB2',
    200: '#ECF5E7',
    100: '#E5ECEF',
  },
  mono: {
    400: '#666666',
    300: '#DEDEDE',
    200: '#E8E8E8',
    100: '#F7F7F7',
    50: '#F8F8F8',
    // accent colours here
    A50: '#F8FAFD', // background for sections in modals (eg swth deposits)
    A100: '#EEF0F5', // disabled button colour
    A200: '#E7EDF9', // used in dropdown hovers
    A300: '#EFF3FB', // used in 'you will receive' sections in modals
    A400: '#F2F5FA', // text button hover colour
    A500: '#E3E8F7', // outline
    A600: '#DFE5F0', // scrollbar
    A700: '#D7E0F6', // unused
  },
  green: {
    400: '#338F2B',
    300: '#57B022',
    200: '#65aa88',
    100: '#84B35B',
  },
  accent: '#29CCC4',
  success: '#439C0F',
  danger: '#DD3C39',
  warning: '#D78100',

  black: '#242626',
  red: '#C94444',
  yellow: '#E6B300',
  beige: '#FAEDD9',

  hover: 'rgba(0, 0, 0, 0.08)',
  twitterHover: '#1784c8',
  counterTradeHover: '#a62523',
  copyTradeHover: '#24731d',

  logMsg: '#FAFAFA',
  twitter: '#1B95E0',
  copyTrade: '#338F2B',
  counterTrade: '#DD3C39',
  paginationButtonBorder: 'rgba(6, 60, 79, 0.5)',

  loss: '#DD3C3A'
}
