import { Typography } from '@material-ui/core'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useAssetSymbol, useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import Information from './Information'
import Richlist from './Richlist'

interface Props { }

const TokenComponent: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const assetSymbol = useAssetSymbol()
  const { token, rich } = useRedux((state) => state.token)
  const [loading] = useTaskSubscriber(TaskNames.Token.Detail)

  const getTokenName = () => {
    if (!token?.name || !token?.symbol) return ''
    if (token.name.includes('/') && token.symbol.includes('-')) {
      const symbolArr = token.symbol.match(/^([a-z\d.-]+)-(\d+)-([a-z\d.-]+)-(\d+)-lp\d+$/i)
      if (!symbolArr || symbolArr.length === 0) return ''
      symbolArr.shift()
      const symbolA = assetSymbol(symbolArr[0] ?? '')
      const symbolB = assetSymbol(symbolArr[2] ?? '')
      return `${symbolArr[1] ?? ''}% ${symbolA} / ${symbolArr[3] ?? ''}% ${symbolB}`
    }
    return token.name
  }

  return (
    <Page title={getTokenName()}>
      <DataLoadSegment loading={loading}>
        {token?.name && (
          <Fragment>
            <Information token={token} />
            <Richlist rich={rich} />
          </Fragment>
        )}
        {!token?.name && (
          <NotFoundState title="No Token Found">
            <Typography variant="body1">
              We can’t find any token with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Tokens}>
                Tokens
              </CellLink>
              &nbsp;page to view existing tokens.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

export default TokenComponent
