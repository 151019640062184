import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
import { ListTableRow } from '../../Staking/Validators/components'

interface Props {
  params: RestModels.RewardCurve
}

const RewardCurve: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { params } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Pools.RewardCurve)
  const cellFormat = (key: keyof RestModels.RewardCurve) => {
    switch (key) {
      case 'start_time':
        return dayjs(params[key]).format('YYYY-MM-DD HH:mm:ss')
      default:
        return new BigNumber(params[key]).toString()
    }
  }
  // rewardBps = initialBps * ((10000 - reduction) / 10000) ^ reductions
  const basisPoints = new BigNumber(10000)
  const initial = new BigNumber(params.initial_reward)
  const reduction = new BigNumber(params.reduction)
  const floor = new BigNumber(params.final_reward)
  const reductionsMade = new BigNumber(params.reductions_made)
  const currentRewardBeforeFloor = initial.times(((basisPoints.minus(reduction)).div(basisPoints)).pow(reductionsMade))
  const currentReward = BigNumber.maximum(currentRewardBeforeFloor, floor)
  const startTime = dayjs(params.start_time)
  const currentRewardHuman = currentReward.shiftedBy(-2)
  let currentRewardString = '-'

  if (!currentRewardHuman.isNaN() && !startTime.isAfter(dayjs()) && !initial.isZero()) {
    currentRewardString = currentReward.toFixed(0)
  }
  let currentRewardHumanString = ''
  if (!currentRewardHuman.isNaN() && !startTime.isAfter(dayjs()) && !initial.isZero()) {
    currentRewardHumanString = `(${currentRewardHuman.toFixed(2)}%)`
  }
  return (
    <Section title="Reward Curve" className={classes.section}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableBody>
            <ListTableRow
              headerClass={classes.headerClass}
              key="current_reward"
              keyVal="current_reward"
              valueClass={classes.valueClass}
            >
              <div className={classes.stringContainer}>
                <div>{currentRewardString}</div>
                <div className={classes.sharePercent}>{currentRewardHumanString}</div>
              </div>
            </ListTableRow>
            {
              (Object.keys(params) as (keyof RestModels.RewardCurve)[]).map((each) => {
                if (each === 'interval') {
                  return (
                    <ListTableRow
                      headerClass={classes.headerClass}
                      key={each}
                      keyVal={each}
                      valueClass={classes.valueClass}
                    >
                      <div className={classes.stringContainer}>
                        <div>{cellFormat(each)}</div>
                        <div className={classes.sharePercent}>seconds</div>
                      </div>
                    </ListTableRow>
                  )
                }
                if (each === 'initial_reward' || each === 'reduction' ||  each === 'final_reward') {
                  const humanValue = new BigNumber(cellFormat(each)).shiftedBy(-2)
                  const humanValueString = `(${humanValue.toFormat()}%)`
                  return (
                    <ListTableRow
                      headerClass={classes.headerClass}
                      key={each}
                      keyVal={each}
                      valueClass={classes.valueClass}
                    >
                      <div className={classes.stringContainer}>
                        <div>{cellFormat(each)}</div>
                        <div className={classes.sharePercent}>{humanValueString}</div>
                      </div>
                    </ListTableRow>
                  )
                }
                return (
                  <ListTableRow
                    headerClass={classes.headerClass}
                    key={each}
                    keyVal={each}
                    valueClass={classes.valueClass}
                  >
                    {cellFormat(each)}
                  </ListTableRow>
                )
              })
            }
          </TableBody>
        </ListTable>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  sharePercent: {
    color: theme.palette.grey[500],
    marginLeft: 6,
  },
  stringContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default RewardCurve
