import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableEmptyState, TooltipHint } from 'js/components'
import { BlockChainPaths, TaskNames } from 'js/constants'
import { shorten } from 'js/helpers/utils'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels, TradeHubSDK } from 'tradehub-api-js'

export interface RewardsProps {
  externalTransfers: RestModels.Transfer[]
  sectionClass?: string
}

interface Props extends RewardsProps { }

const ExternalTransfers: React.FunctionComponent<Props> = ({
  sectionClass,
  externalTransfers,
}: Props): ReactElement<Props> => {
  const sdk = useRedux((state) => state.core.sdk)

  const [loading] = useTaskSubscriber(TaskNames.Account.ExternalTransfers)

  return (
    <Section className={sectionClass} title="External Transfers">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Blockchain</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Contract</TableCell>
              <TableCell>Transaction</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{externalTransfers.map(renderTransfer)}</TableBody>
        </ListTable>
        {(!externalTransfers || externalTransfers.length <= 0) && (
          <TableEmptyState itemName="external transfers" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransfer(t: RestModels.Transfer, index: number): ReactNode {
    const {
      timestamp,
      transfer_type,
      block_height,
      status,
      denom,
      amount,
      contract_hash,
      transaction_hash,
      blockchain,
    } = t

    const decimals = sdk?.token.getDecimals(denom) ?? 0
    let url = ''
    let heightUrl = ''
    let contractUrl = ''
    let heightChain = 'TradeHub'
    const paths = sdk?.network === TradeHubSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet;
    switch (blockchain) {
      case 'neo':
        url = paths.neo.replace(':path', `/transaction/${transaction_hash}`)
        heightUrl = paths.neo.replace(':path', `/block/${block_height}/page/1`)
        const h = contract_hash?.match(/[a-fA-F0-9]{2}/g)?.reverse().join('') ?? ''
        contractUrl = `https://explorer.o3.network/contracts/0x${h}`
        break
      case 'eth':
        url = paths.eth.replace(':path', `/tx/${transaction_hash}`)
        heightUrl = paths.eth.replace(':path', `/block/${block_height}`)
        contractUrl = paths.eth.replace(':path', `/address/${contract_hash}`)
        break
      case 'zil':
        url = paths.zil.replace(':path', `/tx/${transaction_hash}`)
        heightUrl = paths.zil.replace(':path', `/block/${block_height}`)
        contractUrl = paths.zil.replace(':path', `/address/${contract_hash}`)
        break
      case 'bsc':
        url = paths.bsc.replace(':path', `/tx/${transaction_hash}`)
        heightUrl = paths.bsc.replace(':path', `/block/${block_height}`)
        contractUrl = paths.eth.replace(':path', `/address/${contract_hash}`)
        break
      default:
        break
    }
    switch (blockchain) {
      case 'neo':
        if (status === 'success')
          heightChain = 'neo'
        break
      default:
        if (transaction_hash)
          heightChain = blockchain
        break
    }
    const time: Dayjs = dayjs.unix(timestamp)
    const amountFormatted: string = new BigNumber(amount).toFixed(decimals)

    return (
      <TableRow key={denom + index.toString()} hover>
        <TableCell>{time.format('YYYY-MM-DD HH:mm:ss')}</TableCell>
        <TableCell>{transfer_type}</TableCell>
        <TableCell>{blockchain}</TableCell>
        <TableCell>
          <TooltipHint title={heightChain} placement="right">
            <span>
              <CellLink href={heightUrl}>{block_height}</CellLink>
            </span>
          </TooltipHint>
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          <CellLink href={contractUrl}>{shorten(contract_hash)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink href={url}>{shorten(transaction_hash)}</CellLink>
        </TableCell>
        <AmountDenomCell amount={amountFormatted} denom={denom} />
      </TableRow>
    )
  }
}

export default ExternalTransfers
