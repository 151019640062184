import { makeStyles, Theme } from '@material-ui/core'
import { updateFilters } from 'js/actions/transactions'
import { Page, Transactions } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import GasFees from './GasFees'
import TransactionFilter, { FilterOption } from './TransactionFilter'

interface Props { }

const activeTasks: string[] = [
  TaskNames.Transactions.List,
  // TODO: Enable when the endpoint is fixed
  // TaskNames.Transactions.Types,
  TaskNames.Transactions.Filter,
]

const TransactionList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { transactions, types, gasFees } = useRedux((state) => state.transactions)
  const [filters, setFilters] = useState<{ [index: string]: boolean }>()
  const dispatch = useDispatch()
  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
    }
  }

  const onFiltersChange = (types: string[]) => {
    const newFilters: { [index: string]: boolean } = {}
    types.forEach((type) => type !== undefined && (newFilters[type] = true))
    setFilters(newFilters)
    dispatch(updateFilters({ filters: newFilters }))
  }

  return (
    <Page
      title="Transactions"
      rightMenu={
        <TransactionFilter
          className={classes.filter}
          onFilter={onFiltersChange}
          filters={filters}
          options={types.map(mapTypeToOption)}
        />
      }
    >
      <Transactions
        transactions={transactions}
        filters={filters}
        filterStatus
        action={updateFilters}
        loadName={activeTasks}
      />
      <GasFees
        fees={gasFees}
        title="Transaction Gas Fees"
        loadName={activeTasks}
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default TransactionList
