import { Divider, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core'
import { useRedux } from 'js/hooks'
import { Network } from 'js/models/Network'
import { actions } from 'js/store'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { SearchInput } from '../components'
import { MobileItem, mobilemenuItems } from '../headerConfig'

interface Props {
  network: Network
  toggleDrawer: (forceClose?: boolean) => (event: any) => void
}

const DrawerMenuList: React.FunctionComponent<Props> = ({
  network,
  toggleDrawer,
}: Props): ReactElement<Props> => {
  const dispatch = useDispatch()
  const history = useHistory()
  const sdk = useRedux(state => state.core.sdk)

  const classes = useStyles()

  const logout = () => {
    sdk?.disconnect()

    dispatch(actions.Core.updateSDK(null))
    history.push('/login')
  }

  return (
    <div
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer(true)}
    >
      <List className={classes.listPad}>
        <SearchInput className={classes.searchInput} network={network} />
        <Divider className={classes.menuDivider} />
        {mobilemenuItems.map((item: MobileItem, index: number) => (
          <React.Fragment key={index}>
            <ListItem
              component={NavLink}
              to={item?.link}
              className={classes.menuListItem}
              button
            >
              <ListItemText primary={item?.label} />
            </ListItem>
            <Divider className={classes.menuDivider} />
          </React.Fragment>
        ))}
        {sdk?.wallet ? (
          <ListItem
            className={classes.menuListItem}
            button
            onClick={logout}
          >
            <ListItemText primary='Logout' />
          </ListItem>
        ) : (
          <ListItem
            component={NavLink}
            to={'/login'}
            className={classes.menuListItem}
            button
          >
            <ListItemText primary='Login' />
          </ListItem>
        )}
      </List>
    </div>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  searchInput: {
    margin: theme.spacing(0, 1.5),
    height: '4.25rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  menuListItem: {
    '&.MuiListItem-root .MuiTypography-body1': {
      fontSize: '2.5rem',
      lineHeight: '3.5rem',
      letterSpacing: '-0.125rem',
      fontWeight: 'bold',
    },
  },
  menuDivider: {
    backgroundColor: switcheo.primary[500],
    margin: theme.spacing(0, 1.5),
  },
  listPad: {
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0),
    },
  },
}))

export default DrawerMenuList
