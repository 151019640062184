import { Box, BoxProps, Divider, makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { BIG_ZERO } from 'js/utils'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
import VoteStatistic from './VoteStatistic'

type Props = BoxProps & {
  proposal: RestModels.GovProposal
}

const VoteStatisticGroup: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, proposal, ...rest } = props
  const classes = useStyles(props)

  const voteTally = useRedux((state) => state.governance.liveVoteTallies)[proposal.id]

  const resultYesHuman = (voteTally?.yes || BIG_ZERO).shiftedBy(-8)
  const resultNoHuman = (voteTally?.no || BIG_ZERO).shiftedBy(-8)
  const resultVetoHuman = (voteTally?.no_with_veto || BIG_ZERO).shiftedBy(-8)
  const resultAbstainHuman = (voteTally?.abstain || BIG_ZERO).shiftedBy(-8)
  const totalVotedHuman = resultYesHuman
    .plus(resultNoHuman)
    .plus(resultVetoHuman)
    .plus(resultAbstainHuman)

  const getLabel = (resultHuman: BigNumber) => {
    if (totalVotedHuman.lte(0)) {
      return "0"
    }

    return resultHuman
      .div(totalVotedHuman)
      .shiftedBy(2)
      .decimalPlaces(3)
      .toFormat()
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.container}>
        <VoteStatistic label="Yes" success>
          {getLabel(resultYesHuman)}%
        </VoteStatistic>
        <Divider orientation="vertical" flexItem />
        <VoteStatistic label="No" error>
          {getLabel(resultNoHuman)}%
        </VoteStatistic>
        <Divider orientation="vertical" flexItem />
        <VoteStatistic label="No (Veto)" warning>
          {getLabel(resultVetoHuman)}%
        </VoteStatistic>
        <Divider orientation="vertical" flexItem />
        <VoteStatistic label="Abstain" disabled>
          {getLabel(resultAbstainHuman)}%
        </VoteStatistic>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiDivider-root': {
      backgroundColor: switcheo.primary[300],
      opacity: 0.49,
      margin: theme.spacing(0.5, 0),
    },
  },
}))

export default VoteStatisticGroup
