import BigNumber from 'bignumber.js'
import { Constants, TaskNames } from 'js/constants'
import { bnOrZero, SimpleMap } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { call, delay, Effect, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setBlocks, setDelegations, setProfile, setSelfbondAmount, setValidator, ValidatorActionType } from '../actions/validator'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

interface BlocksOptions {
  address: string
}

export default class Validators extends Saga {
  private readonly address: string
  constructor(address: string) {
    super()

    this.address = address
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchProfile]),
      call([this, this.fetchValidator]),
      call([this, this.fetchDelegations]),
      call([this, this.fetchBlocks]),
      spawn([this, this.watchSetNetwork]),
      spawn([this, this.watchBlocksFilters]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchProfile(): any {
    try {
      const sdk = yield* getInitializedSDK()
      const profile = (yield sdk.api.getProfile({ account: this.address })) as RestModels.Profile
      yield put(setProfile(profile))
    } catch (err) {
      console.error(err);
    }
  }

  private *fetchValidator(): any {
    const validatorAddress = this.address
    yield runSagaTask(TaskNames.Validator.Detail, function* () {
      let validators = (yield select((state) => state.app.validatorsMap)) as SimpleMap<RestModels.Validator>
      const sdk = yield* getInitializedSDK()

      while (!Object.values(validators).length) {
        yield delay(1001)
        validators = (yield select((state) => state.app.validatorsMap)) as SimpleMap<RestModels.Validator>
      }

      const validator = validators[validatorAddress]

      if (validator) {
        const response = (yield sdk.api.getValidatorDelegations({
          validator: validator.OperatorAddress
        })) as RestTypes.ListValidatorDelegationsResponse
        let selfBond = new BigNumber(0)
        const currencyShift = -Constants.Decimals.SWTH

        const delegations = response.result
        for (const delegation of delegations) {
          if (delegation.validator_address === validator.OperatorAddress) {
            selfBond = bnOrZero(delegation.shares).shiftedBy(currencyShift)
          }
        }

        yield put(setValidator(validator))
        yield put(setSelfbondAmount(selfBond))
      }
    })
  }
  private *fetchDelegations(): any {
    const validatorAddress = this.address
    yield runSagaTask(TaskNames.Validator.Delegations, function* () {
      let validators = (yield select((state) => state.app.validatorsMap)) as SimpleMap<RestModels.Validator>
      const sdk = yield* getInitializedSDK()

      while (!Object.values(validators).length) {
        yield delay(1001)
        validators = (yield select((state) => state.app.validatorsMap)) as SimpleMap<RestModels.Validator>
      }

      const validator = validators[validatorAddress]

      if (validator) {
        const response = (yield sdk.api.getValidatorDelegations({
          validator: validator.OperatorAddress
        })) as RestTypes.ListValidatorDelegationsResponse

        response.result.sort((lhs, rhs) => {
          if (rhs.shares === "0") return -1;
          return bnOrZero(rhs.shares).comparedTo(lhs.shares)
        })

        yield put(setDelegations(response.result))
      }
    })
  }

  private *fetchBlocks(): any {
    const validator = this.address
    yield runSagaTask(TaskNames.Validator.Blocks, function* () {
      const sdk = yield* getInitializedSDK()
      let operators: any = yield select((state) => state.app.operatorsMap)
      
      while (!Object.values(operators).length) {
        yield delay(1001)
        operators = yield select((state) => state.app.operatorsMap)
      }

      const blocks = (yield sdk.api.getBlocksPaged({
        proposer: operators[validator],
        limit: 10,
      })) as RestTypes.ResultsMinMax<RestModels.Block>
      yield put(setBlocks(blocks))
    })
  }

  private *watchBlocksFilters(): any {
    yield takeLatest(
      ValidatorActionType.UPDATE_BLOCKS_FILTERS,
      this.handleBlocksFilter,
      { address: this.address },
    )
  }
  private *handleBlocksFilter(options: BlocksOptions, action: any): Generator {
    const validator = this.address
    yield runSagaTask(TaskNames.Validator.BlocksFilter, function* () {
      const sdk = yield* getInitializedSDK()
      let operators: any = yield select((state) => state.app.operatorsMap)
      
      while (!Object.values(operators).length) {
        yield delay(1001)
        operators = yield select((state) => state.app.operatorsMap)
      }

      const blocks = (yield sdk.api.getBlocksPaged({
        ...action.options.pagination,
        limit: 10,
        proposer: operators[validator],
      })) as RestTypes.ResultsMinMax<RestModels.Block>
      if(action.options.pagination.order_by === "asc"){
        blocks.data.reverse();
      }
      yield put(setBlocks(blocks))
    })
  }
}
