import { TableRow } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { TableCellInfo, TableSectionCell } from 'js/components'
import { FullTokenBalance } from 'js/models/Balance'
import React, { ReactElement } from 'react'

interface Props {
  addInfo: any
  model: FullTokenBalance
}

const CirculateRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { addInfo, model } = props
  const { swthSupply, totalBonded } = addInfo

  let availableBN: BigNumber = new BigNumber(model?.available)
  const orderBN: BigNumber = new BigNumber(model?.order)
  const positionBN: BigNumber = new BigNumber(model?.position)
  let totalBN: BigNumber = availableBN.plus(orderBN).plus(positionBN)
  let stakedBN: BigNumber = new BigNumber(0)
  if (model?.denom === 'swth') {
    stakedBN = totalBonded
    totalBN = swthSupply
    availableBN = totalBN.minus(totalBonded)
  }

  const CirculateCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: model?.denom,
      to: `/token/${model?.denom}`,
    },
    {
      content: availableBN.toFormat(2),
    },
    {
      content: orderBN.toFormat(2),
    },
    {
      content: positionBN.toFormat(2),
    },
    {
      content: stakedBN.toFormat(2),
    },
    {
      content: totalBN.toFormat(2),
    }
  ]

  return (
    <TableRow key={model?.denom} hover>
      {CirculateCells.map((cell: TableCellInfo, index: number) => (
        <TableSectionCell {...cell} key={index} />
      ))}
    </TableRow>
  )
}

export default CirculateRow
