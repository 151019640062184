import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { BlocksActionType, clear, setBlocks } from '../actions/blocks'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Blocks extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchBlocks],
      [this, this.watchSetNetwork],
      [this, this.watchFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchBlocks(): any {
    yield runSagaTask(TaskNames.Blocks.List, function* () {
      const sdk = yield* getInitializedSDK()

      const blocks = (yield sdk.api.getBlocksPaged({
        limit: 10,
      })) as RestTypes.ResultsMinMax<RestModels.Block>
      yield put(setBlocks(blocks))
    })
  }

  private *watchFilters(): any {
    yield takeLatest(BlocksActionType.UPDATE_FILTERS, this.handleFilters)
  }

  private *handleFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Blocks.Filter, function* () {
      const sdk = yield* getInitializedSDK()

      const blocks = (yield sdk.api.getBlocksPaged({
        limit: 10,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Block>
      if(action.options.pagination.order_by === "asc"){
        blocks.data.reverse();
      }
      yield put(setBlocks(blocks))
    })
  }
}
