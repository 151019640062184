export const ProposalStatuses = { DepositPeriod: 'DepositPeriod', VotingPeriod: 'VotingPeriod', Passed: 'Passed', Rejected: 'Rejected' }

export type SubmitProposalFormState =
  | CreateTokenProposalFormState
  | CreateMarketProposalFormState
  | UpdateMarketProposalFormState
  | LinkPoolProposalFormState
  | UnlinkPoolProposalFormState
  | SetPoolRewardWeightsProposalFormState
  | CreateOracleProposalFormState
  | SetRewardCurveProposalFormState
  | SetCommitmentCurveProposalFormState
  | SetSettlementPriceProposalFormState
  | CommunityPoolSpendProposalFormState
  | ParameterChangeProposalFormState
  | SoftwareUpgradeProposalFormState
  | TextProposalFormState

export interface RootProposalFormState {
  title: string
  description: string
}

export interface CreateTokenProposalFormState extends RootProposalFormState {
  name: string
  symbol: string
  denom: string
  decimals: string
  nativeDecimals: string
  blockchain: string
  chainId: string
  assetId: string
  isCollateral: string
  lockProxyHash: string
  delegatedSupply: string
}

export interface CreateOracleProposalFormState extends RootProposalFormState {
  oracleId: string
  oracleDescription: string
  minTurnoutPercentage: string
  maxResultAge: string
  securityType: string
  resultStrategy: string
  resolution: string
  spec: string
}

export interface CreateMarketProposalFormState extends RootProposalFormState {
  name: string
  displayName: string
  marketType: 'spot' | 'futures'
  marketDescription: string
  base: string
  quote: string
  lotSize: string
  tickSize: string
  minQuantity: string
  riskStepSize: string
  initialMarginBase: string
  initialMarginStep: string
  maintenanceMarginRatio: string
  maxLiquidationOrderTicket: string
  maxLiquidationOrderDuration: string
  impactSize: string
  markPriceBand: string
  lastPriceProtectedBand: string
  indexOracleId: string
  expiryTime: string
  takerFee: string
  makerFee: string
}

export interface UpdateMarketProposalFormState extends RootProposalFormState {
  name: string
  displayName: string
  marketType: 'spot' | 'futures'
  marketDescription: string
  minQuantity: string
  takerFee: string
  makerFee: string

  riskStepSize: string
  initialMarginBase: string
  initialMarginStep: string
  maintenanceMarginRatio: string
  maxLiquidationOrderTicket: string
  maxLiquidationOrderDuration: string
  impactSize: string
  markPriceBand: string
  lastPriceProtectedBand: string
}

export interface LinkPoolProposalFormState extends RootProposalFormState {
  poolID: string
  marketName: string
}

export interface UnlinkPoolProposalFormState extends RootProposalFormState {
  poolID: string
}

export interface PoolWeightObject {
  poolID: string
  weight: string
}

interface PoolWeightObjectList extends Array<PoolWeightObject> { }

export interface SetPoolRewardWeightsProposalFormState extends RootProposalFormState {
  weights: PoolWeightObjectList
}

export interface SetRewardCurveProposalFormState extends RootProposalFormState {
  startTime: string
  initialRewardBps: string
  reductionMultiplierBps: string
  reductionIntervalSeconds: string
  reductions: string
  finalRewardBps: string
}

export interface SetCommitmentCurveProposalFormState extends RootProposalFormState {
  maxDuration: string
  maxRewardMultiplier: string
}

export interface SetSettlementPriceProposalFormState extends RootProposalFormState {
  market: string
  settlementPrice: string
}

export interface CommunityPoolSpendProposalFormState extends RootProposalFormState {
  denom: string,
  amount: string,
  recipient: string,
}

export interface ParameterChangeProposalFormState extends RootProposalFormState {
  topic: string
  parameter: string
  value: string
}

export interface SoftwareUpgradeProposalFormState extends RootProposalFormState {
  name: string
  time: string
  height: string
  info: string
}

export interface TextProposalFormState extends RootProposalFormState { }
