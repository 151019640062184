import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { bnOrZero, SimpleMap } from 'js/utils'
import { default as React, ReactElement, ReactNode } from 'react'
import { RestModels } from 'tradehub-api-js'

export interface RewardsProps {
  rewards?: RestModels.DelegationRewards
  title: string
  validatorsMap: SimpleMap<RestModels.Validator>
}

interface Props extends RewardsProps { }

interface DelegationReward {
  amount: RestModels.TokenAmount
  validator: string
}

const Rewards: React.FunctionComponent<Props> = ({
  rewards,
  title,
  validatorsMap,
}: Props): ReactElement<Props> | null => {
  const [loading] = useTaskSubscriber(TaskNames.Account.Rewards)
  const sdk = useRedux((state) => state.core.sdk)

  const delegationRewards = rewards?.rewards?.reduce((result, reward) => {
    const amounts = reward.reward?.map((amount) => ({
      amount,
      validator: reward.validator_address,
    })) ?? []
    return [...result, ...amounts]
  }, [] as DelegationReward[]) ?? []

  return (
    <Section title={title}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Staked On</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{delegationRewards.map(renderReward)}</TableBody>
        </ListTable>
        {!delegationRewards.length && (
          <TableEmptyState itemName="rewards" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderReward(reward: DelegationReward, index: number): ReactNode {
    const decimals = sdk?.token.getDecimals(reward.amount.denom) ?? 0;
    const amountBN = bnOrZero(reward.amount.amount).shiftedBy(-decimals).toFormat(decimals);
    const validatorAddress = reward.validator

    return (
      <TableRow key={reward.amount.denom + index.toString()} hover>
        <TableCell>
          <CellLink to={`/validator/${validatorAddress}`}>
            {validatorAddress in validatorsMap
              ? validatorsMap[validatorAddress].Description.moniker
              : validatorAddress}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={amountBN} denom={reward.amount.denom} />
      </TableRow>
    )
  }
}

export default Rewards
