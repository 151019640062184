import BigNumber from 'bignumber.js'
import { Record, RecordOf } from 'immutable'
import { SimpleMap } from 'js/utils'
import { RestModels } from 'tradehub-api-js'
import { AppAction, AppActionType } from '../actions/app'
import { Network, parseNetwork } from '../models/Network'

export type MenuDropValue = string | null

interface Props {
  network: Network
  delegatorsMap: SimpleMap<RestModels.ValidatorDelegation[]>
  validatorsMap: SimpleMap<RestModels.Validator>
  consensusMap: any
  operatorsMap: any
  info: object
  totalBonded: BigNumber
  totalStaked: BigNumber
  markets: SimpleMap<RestModels.Market>
  geckoPrices: any
  neoPrice: any
  oracles: SimpleMap<RestModels.Oracle>
  swthSupply: BigNumber
  tokensMap: SimpleMap<RestModels.Token>
  mode: string
  total: BigNumber
  latestBlock?: any
  readonly isSigningModalShown: boolean
  readonly signError?: string
  netDrop: boolean
  walletDrop: boolean
  menuDrop: MenuDropValue
}

const params = new URLSearchParams(window.location.search)
const network = parseNetwork(params.get('net'))

export const defaultGeckoPrice: any = { usd: 0, btc: 0, eth: 0, sgd: 0 }
export const defaultNeoPrice: any = { usd: 0 }

export const makeState: Record.Factory<Props> = Record<Props>({
  network,
  delegatorsMap: {},
  validatorsMap: {},
  consensusMap: {},
  operatorsMap: {},
  info: {},
  totalBonded: new BigNumber(0),
  totalStaked: new BigNumber(0),
  markets: {},
  geckoPrices: {
    switcheo: defaultGeckoPrice,
    ethereum: defaultGeckoPrice,
    bitcoin: defaultGeckoPrice,
    dai: defaultGeckoPrice,
  },
  neoPrice: defaultNeoPrice,
  oracles: {},
  swthSupply: new BigNumber(0),
  tokensMap: {},
  mode: 'bright',
  total: new BigNumber(0),
  latestBlock: undefined,
  isSigningModalShown: false,
  signError: undefined,
  netDrop: false,
  walletDrop: false,
  menuDrop: null
})
export type AppState = RecordOf<Props>

function appReducer(
  state: AppState = makeState(),
  action: AppAction,
): AppState {
  switch (action.type) {
    case AppActionType.SET_NETWORK:
      const network: Network = action.network
      return state.merge({ network })
    case AppActionType.SET_DELEGATORS_MAP:
      return state.updateIn(['delegatorsMap'], (): {} => {
        return action.delegators
      })
    case AppActionType.SET_VALIDATORS_MAP:
      return state.updateIn(['validatorsMap'], () => action.validators)
    case AppActionType.SET_CONSENSUS_MAP:
      return state.updateIn(['consensusMap'], (): [] => {
        return action.consensus
      })
    case AppActionType.SET_OPERATORS_MAP:
      return state.updateIn(['operatorsMap'], (): [] => {
        return action.operators
      })
    case AppActionType.SET_SIGNING_INFO:
      return state.updateIn(['info'], (): [] => {
        return action.info
      })
    case AppActionType.SET_TOTAL_BONDED:
      return state.updateIn(['totalBonded'], (): [] => {
        return action.totalBonded
      })
    case AppActionType.SET_TOTAL_STAKED:
      return state.updateIn(['totalStaked'], (): [] => {
        return action.totalStaked
      })
    case AppActionType.SET_MARKETS:
      return state.updateIn(['markets'], (): SimpleMap<RestModels.Market> => {
        return action.markets
      })
    case AppActionType.SET_GECKO_PRICES:
      return state.updateIn(['geckoPrices'], (): {} => {
        return action.geckoPrices
      })
    case AppActionType.SET_NEO_PRICE:
      return state.updateIn(['neoPrice'], (): {} => {
        return action.neoPrice
      })
    case AppActionType.SET_ORACLE_RESULTS:
      return state.updateIn(['oracles'], () => action.oracles)
    case AppActionType.SET_SWTH_SUPPLY:
      return state.updateIn(['swthSupply'], (): any => {
        return action.swthSupply
      })
    case AppActionType.SET_LATEST_BLOCK:
      return state.updateIn(['latestBlock'], (): any => {
        return action.latestBlock
      })
    case AppActionType.SET_TOKENS_MAP:
      return state.updateIn(['tokensMap'], () => action.tokens)
    case AppActionType.SET_MODE:
      localStorage.setItem(AppActionType.SET_MODE, action.mode)
      return state.set('mode', action.mode)
    case AppActionType.SET_TOTAL_SUPPLY:
      return state.set('total', action.total)
    case AppActionType.REQUEST_SIGN:
      return state.set('isSigningModalShown', true)
    case AppActionType.SET_SIGN_ERROR:
      return state.set('signError', action.message)
    case AppActionType.FINISH_SIGN:
      return state.set('isSigningModalShown', false)
    case AppActionType.SET_NET_DROP_CLOSE:
      return state.set('netDrop', false)
    case AppActionType.SET_NET_DROP_OPEN:
      return state.set('netDrop', true)
    case AppActionType.SET_WALLET_DROP_CLOSE:
      return state.set('walletDrop', false)
    case AppActionType.SET_WALLET_DROP_OPEN:
      return state.set('walletDrop', true)
    case AppActionType.SET_MENU_DROP_ITEM:
      return state.set('menuDrop', action.menuItem)
    case AppActionType.SET_MENU_DROP_CLOSE:
      return state.set('menuDrop', null)
    default:
      return state
  }
}

export default appReducer
