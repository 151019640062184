import { AppActionType, SetNetworkAction } from 'js/actions/app';
import { TradeHubSDK } from 'tradehub-api-js';
import { ActionType, CoreAction } from './action';

export type CoreState = {
  sdk: TradeHubSDK | null;
  storedMnemonics: string | null;
  storedNetwork: string | null;
}

const initialState: CoreState = {
  sdk: null,
  storedMnemonics: null,
  storedNetwork: null,
};

const reducer = (
  state: CoreState = initialState,
  action: CoreAction | SetNetworkAction,
) => {
  switch (action.type) {
    case ActionType.CORE_UPDATE_SDK:
      if (action.sdk) {
        state.storedMnemonics = null;
        state.storedNetwork = null;
      }
      return {
        ...state,
        sdk: action.sdk,
      }
    case AppActionType.SET_NETWORK:
      // no change to state if
      if (
        // SDK's network is the same as target network
        TradeHubSDK.parseNetwork(action.network) === state.sdk?.network

        // SDK not initialized in the first place
        || !state.sdk
      ) {
        // return unchanged state
        return state;
      }

      // return empty SDK to trigger saga initalization
      return {
        ...state,
        sdk: null,
        storedNetwork: action.network,
        storedMnemonics: state.sdk?.wallet?.mnemonic ?? null
      }
    default:
      return state
  }
}

export default reducer
