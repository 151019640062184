import { PoolWeightObject, SetPoolRewardWeightsProposalFormState } from "js/models"
import store from "js/store"
import { BIG_ZERO, escapeHtmlGo, parseNumber } from "js/utils"
import { RPCParams } from "tradehub-api-js"

export function setPoolRewardWeightsProposalValue(): RPCParams.SetPoolRewardWeightsProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as SetPoolRewardWeightsProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, weights } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    msg: {
      weights: weights.map((weight: PoolWeightObject) => {
        return {
          pool_id: weight.poolID,
          weight: parseNumber(weight.weight, BIG_ZERO)!.toString(),
        }
      }),
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
