import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum TransactionActionType {
  CLEAR = 'CLEAR_TRANSACTION',
  SET_TRANSACTION = 'SET_TRANSACTION',
  SET_LOGS = 'SET_TRANSACTION_LOGS',
}

export type TransactionAction =
  | ClearAction
  | SetTransactionAction
  | SetTxLogAction

export interface ClearAction extends Action<TransactionActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: TransactionActionType.CLEAR,
  }
}

export interface SetTransactionAction
  extends Action<TransactionActionType.SET_TRANSACTION> {
  transaction: RestModels.TxnHistory
}
export function setTransaction(
  transaction: RestModels.TxnHistory,
): SetTransactionAction {
  return {
    type: TransactionActionType.SET_TRANSACTION,
    transaction,
  }
}

export interface SetTxLogAction extends Action<TransactionActionType.SET_LOGS> {
  log: RestModels.TxLog
}
export function setLog(log: RestModels.TxLog): SetTxLogAction {
  return {
    type: TransactionActionType.SET_LOGS,
    log,
  }
}
