import { SetCommitmentCurveProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { RPCParams } from 'tradehub-api-js'

export function setCommitmentCurveProposalValue(): RPCParams.SetCommitmentCurveProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as SetCommitmentCurveProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, maxDuration, maxRewardMultiplier } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    msg: {
      max_duration: parseNumber(maxDuration, BIG_ZERO)!.toString(),
      max_reward_multiplier: parseNumber(maxRewardMultiplier, BIG_ZERO)!.toNumber(),
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
