import { TableCell, TableRow } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { AmountDenomCell, CellLink } from 'js/components'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
import { SimpleMap } from 'js/utils'

type Props = {
  delegation: RestModels.ValidatorDelegation
  validators: SimpleMap<RestModels.Validator>
}

const DelegationRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { delegation, validators } = props
  const amountBN: BigNumber = new BigNumber(delegation?.balance.amount)

  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/validator/${delegation?.validator_address}`}>
          {delegation?.validator_address in validators
            ? validators[delegation?.validator_address]?.Description?.moniker
            : delegation?.validator_address}
        </CellLink>
      </TableCell>
      <AmountDenomCell
        amount={amountBN.shiftedBy(-8).toFormat(8)}
        denom={delegation?.balance.denom}
      />
    </TableRow>
  )
}

export default DelegationRow
