import { Box, BoxProps, Divider, FormControl, Grid, Input, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { TooltipHint } from 'js/components'
import { CreateMarketProposalFormState } from 'js/models/Governance'
import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { createFuturesMarketRequiredFields } from './Helpers/FormConstraints'

interface Props extends BoxProps {
  formState: CreateMarketProposalFormState
}

const CreateMarketProposalFuturesForm: React.FC<Props> = (props) => {
  const { className, formState, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const requiredFieldsConstraints = getRequiredFieldsConstraints(createFuturesMarketRequiredFields)

  const handleFormChange = (key: keyof CreateMarketProposalFormState) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const newFormState = {
        ...formState,
        [key]: event.target.value as string,
      }
      const errors = getErrorMessages(newFormState, requiredFieldsConstraints)

      dispatch(setSubmitProposalFormState(newFormState))
      dispatch(setSubmitProposalFormValidationErrors(errors))
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.dividerBox}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Size of Risk Step
              <TooltipHint title={'Size of quantity for each risk step. Futures market only'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.riskStepSize}
              onChange={handleFormChange('riskStepSize')}
              placeholder='1'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Initial Margin at Risk Step 0"
            InputLabelProps={{ shrink: true }}
            value={formState.initialMarginBase}
            onChange={handleFormChange('initialMarginBase')}
            placeholder='1.0'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Initial Margin per Risk Step Size"
            InputLabelProps={{ shrink: true }}
            value={formState.initialMarginStep}
            onChange={handleFormChange('initialMarginStep')}
            placeholder='0.100000000000000000'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Maintenance Margin Ratio
              <TooltipHint title={'% of initial margin required to avoid liquidation'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.maintenanceMarginRatio}
              onChange={handleFormChange('maintenanceMarginRatio')}
              placeholder='0.100000000000000000'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.inputAlt} fullWidth>
            <InputLabel shrink>
              Max Accumulated Liquidation Order Size
            </InputLabel>
            <Input
              fullWidth
              value={formState.maxLiquidationOrderTicket}
              onChange={handleFormChange('maxLiquidationOrderTicket')}
              className="inputAlt"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.inputAlt} fullWidth>
            <InputLabel shrink>
              Max Accumulated Liquidation Order Duration
              <TooltipHint title={'Duration for accumulated liquidation order to expire and send to ADL'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.maxLiquidationOrderDuration}
              onChange={handleFormChange('maxLiquidationOrderDuration')}
              placeholder='30'
              className="inputAlt"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Mark Price Brand
              <TooltipHint title={'Maximum bps a mark price can move by for each block'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.markPriceBand}
              onChange={handleFormChange('markPriceBand')}
              placeholder='1'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Last Price Protected Band
              <TooltipHint title={'Prevents mark price from changing too much in a block'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.lastPriceProtectedBand}
              onChange={handleFormChange('lastPriceProtectedBand')}
              placeholder='1'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Index Oracle ID
              <TooltipHint title={'Unique identifier for oracle'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.indexOracleId}
              onChange={handleFormChange('indexOracleId')}
              placeholder='DETH'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Impact Size
              <TooltipHint title={' Average price when we market buy/sell impact size. Used to calculate fair pricing'} />
            </InputLabel>
            <Input
              fullWidth
              value={formState.impactSize}
              onChange={handleFormChange('impactSize')}
              placeholder='1'
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dividerBox: {
    margin: theme.spacing(6, 0),
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(5, 0),
    },
  },
  inputAlt: {
    [theme.breakpoints.only('xs')]: {
      '& label': {
        position: 'initial',
      },
      '& > .inputAlt': {
        marginTop: theme.spacing(-0.5),
      },
    },
  },
}))

export default CreateMarketProposalFuturesForm
