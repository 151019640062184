import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CellLink, Section } from 'js/components'
import { GECKO_SYMBOLS } from 'js/helpers'
import { useAssetSymbol, useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { BIG_ZERO, parseNumber } from 'js/utils'
import React from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  pool: RestModels.LiquidityPool
  total: BigNumber
}

const LiquidityPoolBox: React.FC<Props> = (
  props: Props,
): React.ReactElement<Props> => {
  const { pool, total } = props
  const {
    pool_id, denom_a, denom_b, amount_a, amount_b,
    volume, swap_fee, market, rewards_weight, denom,
  } = pool

  const assetSymbol = useAssetSymbol()
  const classes = useStyles()

  const symbolA = assetSymbol(denom_a)
  const symbolB = assetSymbol(denom_b)

  const amountA = new BigNumber(amount_a)
  const amountB = new BigNumber(amount_b)
  const volumeBN = new BigNumber(volume)
  const swapFee = new BigNumber(swap_fee)

  const { geckoPrices, markets } = useRedux((state) => state.app)
  const { rewards } = useRedux((state) => state.pools)

  const quoteCurrency = !!market ? markets[market].base : ''
  const quoteUSDBN = parseNumber(geckoPrices[GECKO_SYMBOLS[quoteCurrency]]?.usd, BIG_ZERO)!
  const notionalVolume = quoteCurrency === '' ? '-' : new BigNumber(quoteUSDBN).times(volumeBN).toFormat(2)

  const quoteSymbol = assetSymbol(quoteCurrency)

  const swthUSD = parseNumber(geckoPrices.switcheo.usd, BIG_ZERO)!
  let weeklyRewards = rewards.times(rewards_weight).div(total)
  if (weeklyRewards.isNaN()) {
    weeklyRewards = BIG_ZERO
  }
  const notionalWeeklyRewards = swthUSD.times(weeklyRewards)

  const tokenAUSD = parseNumber(geckoPrices[GECKO_SYMBOLS[denom_a]]?.usd, BIG_ZERO)!
  const notionalTokenA = denom_a ? new BigNumber(amountA).times(tokenAUSD) : BIG_ZERO

  const tokenBUSD = parseNumber(geckoPrices[GECKO_SYMBOLS[denom_b]]?.usd, BIG_ZERO)!
  const notionalTokenB = denom_b ? new BigNumber(amountB).times(tokenBUSD) : BIG_ZERO

  const notionalAB = notionalTokenA.plus(notionalTokenB)
  const apy = notionalWeeklyRewards.div(notionalAB).times(100).times(52)

  let denomRemoved = denom.replace(denom_a, "")
  denomRemoved = denomRemoved.replace(denom_b, "")
  const weights = denomRemoved.split('-')
  const weightA = new BigNumber(weights[1])
  const weightB = new BigNumber(weights[3])
  const priceInA = amountA.div(amountB).div(weightA).times(weightB)
  const priceInB = amountB.div(amountA).div(weightB).times(weightA)

  return (
    <Section key={denom}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Name</Typography>
          <div>{`${weightA}% ${symbolA} / ${weightB}% ${symbolB}`}</div>
          <div className={classes.notional}>Pool ID {pool_id}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Market</Typography>
          {!!market && (
            <CellLink to={`/market/${market}`}>{market}</CellLink>
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Rewards APY</Typography>
          <div>{apy.isPositive() && !apy.isZero() ? `${apy.toFormat(2)}%` : "-"}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Token</Typography>
          <CellLink to={`/token/${denom}`}>{denom}</CellLink>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>24H Volume</Typography>
          {!!market && (
            <span>
              <div>{volumeBN.toFormat()} {quoteSymbol}</div>
              <div className={classes.notional}>{notionalVolume} USD</div>
            </span>
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Weekly Est. Rewards</Typography>
          <div>{`${weeklyRewards.toFormat(0)} SWTH / week`}</div>
          <div className={classes.notional}>{notionalWeeklyRewards.toFormat(2)} USD</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Token A</Typography>
          <div>{amountA.toFormat()} {symbolA}</div>
          <div className={classes.notional}>{notionalTokenA.isZero() ? '-' : `${notionalTokenA.toFormat(2)} USD`}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Token B</Typography>
          <div>{amountB.toFormat()} {symbolB}</div>
          <div className={classes.notional}>{notionalTokenB.isZero() ? '-' : `${notionalTokenB.toFormat(2)} USD`}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Total Value</Typography>
          <div>{notionalAB.isZero() ? '-' : `${notionalAB.toFormat(2)} USD`}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>{`Price in ${symbolA}`}</Typography>
          <div>{notionalAB.isZero() ? '-' : `${priceInA.toFormat(8)} ${symbolA}`}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>{`Price in ${symbolB}`}</Typography>
          <div>{notionalAB.isZero() ? '-' : `${priceInB.toFormat(8)} ${symbolB}`}</div>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2" className={classes.label}>Swap Fee</Typography>
          <div>{swapFee.times(100).toFormat(4)}%</div>
        </Grid>
      </Grid>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 'bold',
  },
  notional: {
    color: switcheo.mono[400],
  },
}))

export default LiquidityPoolBox
