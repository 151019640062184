import { List, ListItem, makeStyles, Theme } from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import { CellLink, Section } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'
import { RestModels } from 'tradehub-api-js'

export interface BlockInfoProps {
  blockInfo: RestModels.CosmosBlock
}

interface Props extends BlockInfoProps { }

const BlockInfo: React.FunctionComponent<Props> = ({
  blockInfo,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const validators = useRedux((state) => state.app.validatorsMap)
  const time: Dayjs = dayjs(blockInfo.block.header.time)
  const valConsAddress = blockInfo.block.header.proposer_address;

  const validator = useMemo(() => {
    return Object.values(validators).find(validator => validator.ConsAddressByte === valConsAddress);
  }, [validators, valConsAddress]);
  return (
    <Section title="Overview">
      <List>
        <ListItem disableGutters>
          <div className={classes.header}>Height</div>
          <div className={classes.value}>{blockInfo.block.header.height}</div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Created On</div>
          <div className={classes.value}>
            {time.format('YYYY-MM-DD HH:mm:ss')}
            <span className={classes.timeAgo}>({fromNow(time)})</span>
          </div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Validated By</div>
          <div className={classes.value}>
            {!validator?.OperatorAddress ? (
              validator?.Description.moniker
            ) : (
                <CellLink to={`/validator/${validator?.OperatorAddress}`}>
                  {validator?.Description.moniker}
                </CellLink>
              )}
          </div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Transaction Count</div>
          <div className={classes.value}>
            {blockInfo.block.data.txs?.length ?? 0}
          </div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Block Hash</div>
          <div className={classes.value}>{blockInfo.block_id.hash}</div>
        </ListItem>
      </List>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    flexBasis: '25%',
    paddingRight: '1rem',
  },
  value: {
    width: '47.5rem',
    overflowX: 'auto',
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[500],
  },
}))

export default BlockInfo
