import { Box, BoxProps, Grid, InputAdornment, makeStyles, TextField, Theme, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/ErrorOutline'
import clsx from 'clsx'
import { setSubmitProposalDepositAmount } from 'js/actions/governance'
import { CellLink, TooltipHint } from 'js/components'
import { Paths } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'

interface Props extends BoxProps {
}

const DepositStep = (props: Props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const depositAmount = useRedux((state) => state.governance.submitProposalDepositAmount)

  const handleFormChange = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(setSubmitProposalDepositAmount(event.target.value as string))
  }

  const depositStep = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={(
            <>
              <span>Deposit Amount (Optional)</span>
              <TooltipHint title="Proposals can be made with 0 SWTH. However, 10,000 SWTH is required for a proposal to proceed to voting within 2 days of submission. Deposits of passed proposals will be returned. Deposits of rejected proposals are returned based on the voting criteria fulfilled. In some circumstances, they are burnt." />
            </>
          )}
          type="number"
          placeholder="Deposits are at risk..."
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">SWTH</InputAdornment>
            ),
          }}
          value={depositAmount}
          onChange={handleFormChange}
        />
      </Grid>

      <Box padding={1}>
        <Typography variant="body1" className={classes.warningBox}>
          <WarningIcon fontSize="small" className={classes.warningIcon} />
            Deposit amounts are at risk of being burned in some conditions.&nbsp;
            {/*TODO: check this link*/}
          <CellLink underline='hover' href={Paths.Resources.CreateAndSubmitProposals}>
            Learn more
          </CellLink> about the potential risks.
        </Typography>
      </Box>
    </Grid>
  )

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {depositStep}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0, 3),
    },
  },
  warningBox: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.75rem',
      lineHeight: '1.3rem',
    },
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.15rem',
      marginRight: theme.spacing(0.5),
    },
  },
}))

export default DepositStep
