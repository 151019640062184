import { Box, BoxProps, Divider, makeStyles, Theme } from '@material-ui/core'
import TimeIcon from '@material-ui/icons/AccessTime'
import clsx from 'clsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { Constants } from 'js/constants/generic'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a few seconds',
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
})

type Props = BoxProps & {
  proposal: RestModels.GovProposal
}

const PeriodTag: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, proposal, ...rest } = props
  const classes = useStyles(props)

  const depositEndTime = proposal && dayjs(proposal.deposit_end_time.valueOf())
  const voteEndTime = proposal && dayjs(proposal.voting_end_time?.valueOf())

  const isVotingPeriod =
    proposal.proposal_status === Constants.Proposal.Status.VotingPeriod
  const isPassed = proposal.proposal_status === Constants.Proposal.Status.Passed
  const isRejected =
    proposal.proposal_status === Constants.Proposal.Status.Rejected

  const colorClass = {
    [classes.success]: isPassed,
    [classes.warning]: isVotingPeriod,
    [classes.disabled]: isRejected,
  }

  const getPeriodLabel = (status: string) => {
    switch (status) {
      case Constants.Proposal.Status.VotingPeriod:
        return 'Voting Period'
      case Constants.Proposal.Status.Passed:
        return 'Passed'
      case Constants.Proposal.Status.Rejected:
        return 'Rejected'
      case Constants.Proposal.Status.DepositPeriod:
      default:
        return 'Deposit Period'
    }
  }

  const getTimeLeftLabel = (proposal: RestModels.GovProposal) => {
    switch (proposal.proposal_status) {
      case Constants.Proposal.Status.VotingPeriod:
        return `${voteEndTime.fromNow(true)} left`
      case Constants.Proposal.Status.Passed:
      case Constants.Proposal.Status.Rejected:
        return `Ended ${voteEndTime?.format('D MMMM YYYY')}`
      case Constants.Proposal.Status.DepositPeriod:
      default:
        return `${depositEndTime.fromNow(true)} left`
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className, colorClass)}>
      <Box className={classes.container}>
        {getPeriodLabel(proposal.proposal_status)}
        <Divider orientation="vertical" flexItem />
        <TimeIcon /> {getTimeLeftLabel(proposal)}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: switcheo.primary[100],
    color: switcheo.primary[500],
    fontSize: '0.75rem',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      marginRight: theme.spacing(0.5),
    },
    '& .MuiDivider-root': {
      margin: theme.spacing(0.5, 1.5),
      backgroundColor: switcheo.primary[500],
      opacity: 0.25,
    },
  },
  warning: {
    backgroundColor: switcheo.beige,
    color: switcheo.warning,
    '& .MuiDivider-root': {
      backgroundColor: switcheo.yellow,
    },
  },
  success: {
    backgroundColor: switcheo.primary[200],
    color: switcheo.success,
    '& .MuiDivider-root': {
      backgroundColor: switcheo.success,
    },
  },
  disabled: {
    backgroundColor: switcheo.mono[200],
    color: switcheo.mono[400],
    '& .MuiDivider-root': {
      backgroundColor: switcheo.mono[400],
    },
  },
}))

export default PeriodTag
