import { updateOrdersFilters, updateLeAccOrdersFilters } from 'js/actions/events'
import { TableSection } from 'js/components'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { LiquidationADLs, LiquidationEngine, SettledMarketRender } from './components'
import { SettledHeaders } from './eventsConfig'
interface Props {
}
// tslint:disable:prettier
const Events: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { le, settledMarkets, leAccOrders } = useRedux((state) => state.events)
  const markets = useRedux((state) => state.app.markets)
  return (
    <Page title="Events">
      {!!le?.data.length && (
        <LiquidationADLs
          filterStatus
          title="Liquidations &amp; ADL"
          orders={le}
          action={updateOrdersFilters}
          markets={markets}
          loadName={[
            TaskNames.Events.LEOrders,
            TaskNames.Events.LEOrderFilter,
          ]}
        />
      )}
      {!!leAccOrders?.data.length && (
        <LiquidationEngine
          filterStatus
          title="Liquidation Engine"
          orders={leAccOrders}
          action={updateLeAccOrdersFilters}
          markets={markets}
          loadName={[
            TaskNames.Events.LETrades,
          ]}
        />
      )}
      {
        Object.keys(settledMarkets).length > 0 && (
          <TableSection
            headerCells={SettledHeaders}
            loadName={TaskNames.Events.SettledMarkets}
            models={Object.values(settledMarkets)}
            rowComponent={SettledMarketRender}
            title="Settled Markets"
            itemName="markets"
          />
        )
      }
    </Page>
  )
}

export default Events
