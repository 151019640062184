import { Paths } from 'js/constants'

export type MenuRouteItem = {
  label: string
  link?: string
  matchExact?: boolean
  href?: string
  items?: MenuRouteItem[]
  linkArr?: string[]
} | {
  label: string
  link?: string
  matchExact?: boolean
  href: string
  items?: MenuRouteItem[]
  linkArr?: string[]
} | {
  label: string
  link?: string
  matchExact?: boolean
  href?: string
  items: MenuRouteItem[]
  linkArr?: string[]
}

export interface MobileItem {
  label: string
  link: string
}

export const mobilemenuItems: MobileItem[] = [
  {
    label: 'Home',
    link: Paths.Home,
  },
  {
    label: 'Blocks',
    link: Paths.Blocks,
  },
  {
    label: 'Transactions',
    link: Paths.Transactions,
  },
  {
    label: 'Tokens',
    link: Paths.Tokens,
  },
  {
    label: 'Markets',
    link: Paths.Markets,
  },
  {
    label: 'Events',
    link: Paths.Events,
  },
  {
    label: 'Pools',
    link: Paths.Pools,
  },
  {
    label: 'Leaderboard',
    link: Paths.Leaderboard,
  },
  {
    label: 'Validators',
    link: Paths.Validators,
  },
  {
    label: 'Nodes',
    link: Paths.Nodes,
  },
  {
    label: 'Governance',
    link: Paths.Governance,
  },
]

export const desktopMenuItems: MenuRouteItem[] = [
  {
    label: 'Home',
    link: Paths.Home,
    matchExact: true,
  },
  {
    label: 'Blockchain',
    linkArr: [Paths.Blocks, Paths.Nodes, Paths.Transactions],
    items: [
      {
        label: 'Blocks',
        link: Paths.Blocks,
      },
      {
        label: 'Nodes',
        link: Paths.Nodes,
      },
      {
        label: 'Transactions',
        link: Paths.Transactions,
      },
    ],
  },
  {
    label: 'Tokens',
    link: Paths.Tokens,
  },
  {
    label: 'Markets',
    linkArr: [Paths.Markets, Paths.Events, Paths.Leaderboard, Paths.Pools],
    items: [
      {
        label: 'Events',
        link: Paths.Events,
      },
      {
        label: 'Leaderboard',
        link: Paths.Leaderboard,
      },
      {
        label: 'Markets',
        link: Paths.Markets,
      },
      {
        label: 'Pools',
        link: Paths.Pools,
      },
    ],
  },
  {
    label: 'Staking',
    linkArr: [Paths.Validators, Paths.EcoSystem.Stake],
    items: [
      {
        label: 'Validators',
        link: Paths.Validators,
      },
      {
        label: 'Stake',
        href: Paths.EcoSystem.Stake,
      },
    ],
  },
  {
    label: 'Governance',
    link: Paths.Governance,
  },
]
