import { Box, BoxProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { switcheo } from "js/theme/palettes/colors";
import React from "react";

interface Props extends BoxProps {
  step?: number
  active?: boolean
}

const ProposalStep: React.FC<Props> = (props: Props) => {
  const { children, className, step, active, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {!!step && (
        <Box className={clsx(classes.stepBadge, { active })}>
          <Typography component="span" className={classes.step}>{step}</Typography>
        </Box>
      )}
      <Typography>{children}</Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  step: {
    fontWeight: 'bold',
  },
  stepBadge: {
    height: '2.5em',
    width: '2.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '2.5em',
    background: switcheo.mono[50],
    marginRight: theme.spacing(1),
    '&.active': {
      background: theme.palette.secondary.main,
    },
  },
}))

export default ProposalStep;
