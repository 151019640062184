import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { updateProfitablePositions } from 'js/actions/market'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByPage, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestResponse, RestTypes } from 'tradehub-api-js'
import { bnOrZero, SimpleMap } from 'js/utils'

interface Props {
  profitablePositions?: RestTypes.ResultsPaged<RestResponse.Position>
  markets?: SimpleMap<RestResponse.Market>
}

const ActiveTasks = [
  TaskNames.App.Markets,
  TaskNames.Market.ProfitablePositions,
  TaskNames.Market.ProfitableFilter,
]

const HeaderCells: TableCellInfo[] = [
  { align: 'inherit', content: 'Account' },
  { content: 'Position Size' },
  { content: 'Avg Price' },
  { content: 'Realized PnL' },
  { content: 'Est. Unrealized PnL' },
  { content: 'Total PnL' },
  { content: 'Margin Allocated' },
  { content: 'Created At' }
]

const ProfitablePositions: React.FunctionComponent<Props> = ({
  profitablePositions,
  markets,
}: Props): ReactElement<Props> => {
  const [page, setPage] = React.useState(1)
  const [loading] = useTaskSubscriber(...ActiveTasks)

  return (
    <Section title="Most Profitable Positions">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              {HeaderCells.map((header: TableCellInfo, index: number) => (
                <TableSectionCell key={index} {...header} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {profitablePositions?.data.map(renderProfitablePosition)}
          </TableBody>
        </ListTable>

        {!!profitablePositions?.data?.length && (
          <Box marginTop={3}>
            <PaginationByPage
              action={updateProfitablePositions}
              setPage={setPage}
              page={page}
              response={profitablePositions}
            />
          </Box>
        )}
        {!profitablePositions?.data?.length && (
          <TableEmptyState itemName="positions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderProfitablePosition(
    position: RestResponse.Position,
    index: number,
  ): ReactNode {
    const entryPriceBN = bnOrZero(position?.entry_price)
    const realizedPnlBN = bnOrZero(position?.realized_pnl)
    const unrealizedPnlBN = bnOrZero(position?.unrealized_pnl)
    const totalPnlBN = bnOrZero(position?.total_pnl)
    const allocatedMarginBN = bnOrZero(position?.allocated_margin_amount)
    const lotsBN = bnOrZero(position?.lots)
    const denom = position?.market?.split('_')[0].toUpperCase()
    const lotDp: number = bnOrZero(markets?.[position?.market]?.lot_size).dp()

    const { address, username } = position
    return (
      <TableRow key={address + index} hover>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {username === '' ? address : username}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={lotsBN.toFormat(lotDp)} denom={denom} />
        <TableCell align="right">{entryPriceBN.toFormat(2)}</TableCell>
        <TableCell align="right">{realizedPnlBN.toFormat(2)}</TableCell>
        <TableCell align="right">{unrealizedPnlBN.toFormat(2)}</TableCell>
        <TableCell align="right">{totalPnlBN.toFormat(2)}</TableCell>
        <AmountDenomCell denom={position?.allocated_margin_amount} amount={allocatedMarginBN.toFormat(2)} />
        <TableCell align="right">
          <CellLink to={`/block/${position?.created_block_height}`}>
            {position?.created_block_height}
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default ProfitablePositions
