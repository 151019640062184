import React from 'react'
import { makeStyles, Theme, Box, Typography, Divider } from '@material-ui/core'
import Expandable from './Expandable'

interface IterableObject {
  [s: number]: number | string | boolean | IterableObject
}

interface Props {
  property: number | string | boolean | IterableObject
  propertyName: string
  rootProperty?: boolean
  expand?: boolean
  propertyNameProcessor?: (name: string) => string
  excludeBottomBorder: boolean
}

const ProposalProperties: React.FC<Props> = props => {
  const classes = useStyles()

  return (
    <div>
      {
        props.property ? (
          typeof props.property === 'number' ||
            typeof props.property === 'string' ||
            typeof props.property === 'boolean' ? (
              <React.Fragment>
                <React.Fragment>
                  <Box display='flex' paddingY={1.5}>
                    <Typography variant='body1' className={classes.label}>
                      {props.propertyName.toString()}
                    </Typography>
                    <Typography variant='body1' className={classes.value}>
                      {props.property.toString()}
                    </Typography>
                  </Box>
                  {!props.excludeBottomBorder && (<Divider />)}
                </React.Fragment>
              </React.Fragment>
            ) : (
              <div>
                <Expandable expanded={props.expand} show={!props.rootProperty && Object.values(props.property).length > 5}>
                  {
                    Object.values(props.property).map((property: string, index: number, { length }) => (
                      <ProposalProperties
                        key={index}
                        property={property}
                        propertyName={Object.getOwnPropertyNames(props.property)[index]}
                        propertyNameProcessor={props.propertyNameProcessor}
                        excludeBottomBorder={index === length - 1}
                        expand={Object.values(property).length < 5}
                      />
                    ))
                  }
                </Expandable>
              </div>
            )
        ) : ''
      }
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '2rem 0',
    '& div:last-child': {
      border: 0,
    },
  },
  label: {
    width: '40%',
    textTransform: 'capitalize',
  },
  value: {
    width: '60%',
    textAlign: 'left',
  },
}))

export default ProposalProperties