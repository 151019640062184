import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import {
  call,
  Effect,
  put,

  spawn,
  takeLatest
} from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setLog, setTransaction } from '../actions/transaction'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Transaction extends Saga {
  private readonly hash: string
  constructor(hash: string) {
    super()

    this.hash = hash
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchTransaction], this.hash),
      call([this, this.fetchTxLog], this.hash),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchTransaction(hash: any): any {
    yield runSagaTask(TaskNames.Transaction.Detail, function* () {
      const sdk = yield* getInitializedSDK()
      const tx = (yield sdk.api.getTx({
        hash,
      })) as RestModels.TxnHistory
      yield put(setTransaction(tx))
    });
  }

  private *fetchTxLog(hash: any): any {
    yield runSagaTask(TaskNames.Transaction.Log, function* () {
      const sdk = yield* getInitializedSDK()
      const log = (yield sdk.api.getTxLog({
        hash,
      })) as RestModels.TxLog
      yield put(setLog(log))
    });
  }
}
