import { List } from 'immutable'
import { BIG_ZERO, parseNumber } from 'js/utils'
import { RestModels } from 'tradehub-api-js'
import { fieldTypes } from '../../GenericProposal/Helpers/types'
import { getErrorMessages, getInputConstraints } from '../../Helpers/InputConstraints'
import { updateMarketValidator } from '../../UpdateMarketProposal/Helpers/FormConstraints'

export const createSpotMarketRequiredFields = [
  'name',
  'displayName',
  'marketType',
  'marketDescription',
  'base',
  'quote',
  'lotSize',
  'tickSize',
  'minQuantity',
  'takerFee',
  'makerFee',
]

export const createFuturesMarketRequiredFields = createSpotMarketRequiredFields.concat([
  'riskStepSize',
  'initialMarginBase',
  'initialMarginStep',
  'maintenanceMarginRatio',
  'maxLiquidationOrderTicket',
  'maxLiquidationOrderDuration',
  'impactSize',
  'markPriceBand',
  'lastPriceProtectedBand',
  'indexOracleId',
  'expiryTime',
])

const basicConfig = {
  name: {
    id: 'name',
    type: fieldTypes.text,
    constraints: {
      validPattern: true,
    },
  },
  lotSize: {
    id: 'lotSize',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
    },
  },
  tickSize: {
    id: 'tickSize',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
    },
  },
}

const futuresConfig = {
  expiryTime: {
    id: 'expiryTime',
    type: fieldTypes.datetime,
    constraints: {
      inFuture: true,
    },
  },
}

export function createMarketValidator(tokens: List<RestModels.Token>) {
  return (formState: any) => {
    let errors = updateMarketValidator(formState)
    errors = errors.concat(getErrorMessages(formState, getInputConstraints(basicConfig)))

    const baseTokenDecimals = parseNumber(tokens.find((token: RestModels.Token) => token.denom === formState.base)?.decimals)!
    const quoteTokenDecimals = parseNumber(tokens.find((token: RestModels.Token) => token.denom === formState.quote)?.decimals)!

    const lotSize = parseNumber(formState.lotSize, BIG_ZERO)!.shiftedBy(baseTokenDecimals.toNumber())
    const tickSize = parseNumber(formState.tickSize, BIG_ZERO)!.shiftedBy(quoteTokenDecimals.minus(baseTokenDecimals).toNumber())

    if (!lotSize.isInteger()) {
      errors.push('Lot size cannot be smaller than the smallest base token unit')
    }

    const product = lotSize.times(tickSize)
    if (!product.isInteger()) {
      errors.push('The product of lot size and tick size must be an integer')
    }

    if (formState.marketType === 'futures') {
      errors = errors.concat(getErrorMessages(formState, getInputConstraints(futuresConfig)))
    }
    return errors
  }
}
