import {
  Box, Divider,
  Grid, ListItem, makeStyles,
  SvgIcon, Theme, Typography
} from "@material-ui/core";
import {
  // CopyTradeSVG, CounterTradeSVG, 
  DemexLogoSVG
} from "assets";
import { BigNumber } from "bignumber.js";
import clsx from "clsx";
import { CellLink, Section } from "js/components";
import { useAssetSymbol } from "js/hooks";
import { switcheo } from "js/theme/palettes/colors";
import React, { ReactElement } from "react";
import { RestModels } from "tradehub-api-js";

interface CollectiveTrades {
  avgPrice: BigNumber;
  fees: BigNumber;
  denom: string;
}

interface Props {
  market: string
  side: string
  quantity: string
  entryPrice: string
  venue: string
  demexPrice: string
  collectiveTrades: CollectiveTrades
  marketDetails: RestModels.Market | undefined
  leverage: string
  token: string
  realizedPnl: string
  allocatedMarginAmount: string
  allocatedMarginDenom?: string
  estLiquidation: BigNumber
  dollarValue: number
  positionStatus: string
}

const PositionDetails: React.FunctionComponent<Props> = (
  props
): ReactElement<Props> => {
  const classes = useStyles();
  const assetSymbol = useAssetSymbol();
  const {
    market,
    marketDetails,
    side,
    leverage,
    token,
    quantity,
    dollarValue,
    entryPrice,
    allocatedMarginAmount,
    venue,
    demexPrice,
    collectiveTrades,
    realizedPnl,
    estLiquidation,
    positionStatus
  } = props;
  const { tick_size } = marketDetails!;

  const allocatedMarginAmountBN = new BigNumber(allocatedMarginAmount)
  const dollarValueBN = new BigNumber(dollarValue)
  const realizedPnlBN = new BigNumber(realizedPnl)
  const quantityBN = new BigNumber(quantity)
  const entryPriceBN = new BigNumber(entryPrice)
  const demexPriceBN = new BigNumber(demexPrice)
  const tickSizeBN = new BigNumber(tick_size)
  const decimal = tickSizeBN.dp()
  const unrealizedPnlBN = side === "buy" ? demexPriceBN.minus(entryPriceBN).times(quantityBN.abs()) : entryPriceBN.minus(demexPriceBN).times(quantityBN.abs())
  const totalPnlBN = positionStatus === "Closed" ? realizedPnlBN : realizedPnlBN.plus(unrealizedPnlBN)
  const pnlPercentageBN = totalPnlBN.plus(allocatedMarginAmountBN).dividedBy(allocatedMarginAmountBN).times(100).minus(100)

  const entryDollarValueBN = entryPriceBN.times(quantityBN).abs(); //$ in USDC
  const currentDollarValueBN = demexPriceBN.times(quantityBN).abs(); //$ in USDC

  const displayNumbers = !((demexPriceBN.isZero() && collectiveTrades.avgPrice.isZero()) || positionStatus === "Closed");

  const changeToDollar = (num: BigNumber, decimal?: number) => {
    let numString = num.toFixed(decimal || 2).toLocaleString();
    if (numString[0] === "-") {
      numString = numString.replace("-", "-$");
    } else {
      numString = "$" + numString;
    }
    return numString;
  };

  return (
    <Section variant="secondary" className={classes.section}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={positionStatus === "Closed" ? 12 : 6}>
          {
            positionStatus === "Open" && (
              <>
                <Typography
                  variant={"h2"}
                  className={clsx(classes.title, {
                    [classes.loss]: side === "sell",
                    [classes.profit]: side === "buy",
                  })}
                >
                  {side === "buy" ? "Long" : "Short"}
                </Typography>
                <Divider className={classes.divider} variant="middle" />
                <div className={classes.pnlContainer}>
                  <Typography
                    variant={"h1"}
                    display={"inline"}
                    className={clsx(classes.pnlH1, {
                      [classes.loss]: side === "sell",
                      [classes.profit]: side === "buy",
                    })}
                  >
                    {displayNumbers ? quantityBN.toString() : "0.00"}
                  </Typography>
                  <Typography
                    variant={"h3"}
                    display={"inline"}
                    className={clsx(classes.pnlH3, {
                      [classes.loss]: side === "sell",
                      [classes.profit]: side === "buy",
                    })}
                  >
                    {token}
                  </Typography>
                </div>
                <Divider className={classes.divider} variant="middle" />
              Current Value:{" "}
                {displayNumbers ? changeToDollar(currentDollarValueBN) : "-"}
              </>
            )
          }
          <Typography variant={"h2"} className={positionStatus === "Closed" ? classes.totalPNLClosed : classes.totalPNL}>
            Total PNL
          </Typography>
          <Divider className={classes.divider} variant="middle" />
          <div className={classes.pnlContainer}>
            <Typography
              variant={"h1"}
              display={"inline"}
              className={clsx(classes.pnlH1, {
                [classes.loss]: totalPnlBN.isLessThan(0),
                [classes.profit]:
                  totalPnlBN.isGreaterThanOrEqualTo(0),
              })}
            >
              {totalPnlBN.isGreaterThan(0) && "+"}
              {true ? changeToDollar(totalPnlBN) : "$0.00"}
            </Typography>
            {
              displayNumbers && (
                <Typography
                  variant={"h3"}
                  display={"inline"}
                  className={clsx(classes.pnlH3, {
                    [classes.loss]: totalPnlBN.isLessThan(0),
                    [classes.profit]:
                      totalPnlBN.isGreaterThanOrEqualTo(0),
                  })}
                >
                  {totalPnlBN.isGreaterThan(0) && "+"}
                  {pnlPercentageBN.isNaN() || !displayNumbers
                    ? "0.00"
                    : pnlPercentageBN.toFixed(2)}
                  %
                </Typography>
              )
            }
          </div>
          <Divider className={classes.divider} variant="middle" />
          {
            positionStatus === "Open" && (
              <>
                Included Unrealised PNL:{" "}
                {displayNumbers ? changeToDollar(unrealizedPnlBN) : "-"}
              </>
            )
          }
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ListItem className={positionStatus === "Closed" ? classes.listItemClosed : classes.listItem} disableGutters>
            <div className={classes.header}>Market</div>
            <div className={classes.string}>
              <CellLink to={`/market/${market}`}>
                {market.toUpperCase()}
              </CellLink>
            </div>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />
          {
            positionStatus === "Open" && (
              <>
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Avg Price</div>
                  {displayNumbers ? (
                    <>
                      <div className={classes.string}>
                        {changeToDollar(entryPriceBN, decimal)} (Current Price:{" "}
                        {changeToDollar(demexPriceBN, decimal)})
                </div>
                    </>
                  ) : (
                    "-"
                  )}
                </ListItem>
                <Divider className={classes.divider} variant="middle" />
              </>
            )
          }
          {
            positionStatus === "Open" && (
              <>
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Total Value</div>
                  <div className={classes.string}>
                    <Typography className={classes.longString}>
                      <Box fontWeight={700} component={"span"}>
                        {displayNumbers ? (
                          <>{changeToDollar(entryDollarValueBN)}</>
                        ) : (
                          "-"
                        )}
                      </Box>
                    </Typography>
                  </div>
                </ListItem>
                <Divider className={classes.divider} variant="middle" />
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Est. Liq. Price</div>
                  <div className={classes.string}>
                    <Typography className={classes.longString}>
                      <span>
                        {displayNumbers ? (
                          <>{changeToDollar(estLiquidation, decimal)}</>
                        ) : (
                          "-"
                        )}
                      </span>
                    </Typography>
                  </div>
                </ListItem>
                <Divider className={classes.divider} variant="middle" />
              </>
            )
          }
          <ListItem className={positionStatus === "Closed" ? classes.listItemClosed : classes.listItem} disableGutters>
            <div className={classes.header}>Fee Paid</div>
            <div className={classes.string}>
              {collectiveTrades.fees.isZero() && !collectiveTrades.denom
                ? "-"
                : `${collectiveTrades.fees.dp(decimal).toString()} ${assetSymbol(collectiveTrades.denom).toUpperCase()} (${changeToDollar(collectiveTrades.fees.times(dollarValueBN), decimal)})`}
            </div>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />
          {
            positionStatus === "Open" && (
              <>
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Margin</div>
                  {displayNumbers ? (
                    <>
                      <div className={classes.string}>
                        {changeToDollar(allocatedMarginAmountBN)}{" "}
                        {`(Leverage ${leverage}X)`}
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </ListItem>
                <Divider className={classes.divider} variant="middle" />
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Trade Venue</div>
                  <div className={classes.string}>
                    <CellLink href="https://app.dem.exchange">
                      <SvgIcon
                        component={DemexLogoSVG}
                        className={classes.demexIcon}
                      />
                      <Typography className={classes.venue} display="inline">
                        <Box fontWeight={700} display="inline" component={"span"}>
                          {venue}
                        </Box>
                      </Typography>
                    </CellLink>
                  </div>
                </ListItem>
              </>
            )
          }
        </Grid>
        {
          positionStatus === "Closed" && (
            <Grid item xs={12} sm={12} md={6}>
              <ListItem className={classes.listItemClosed} disableGutters>
                <div className={classes.header}>Side</div>
                <div className={classes.string}>
                  <Typography
                    // variant={"h2"}
                    className={clsx({
                      [classes.loss]: side === "sell",
                      [classes.profit]: side === "buy",
                    })}
                  >
                    {side === "buy" ? "Long" : "Short"}
                  </Typography>
                </div>
              </ListItem>
              <Divider className={classes.divider} variant="middle" />
              <ListItem className={classes.listItemClosed} disableGutters>
                <div className={classes.header}>Trade Venue</div>
                <div className={classes.string}>
                  <CellLink href="https://app.dem.exchange">
                    <SvgIcon
                      component={DemexLogoSVG}
                      className={classes.demexIcon}
                    />
                    <Typography className={classes.venue} display="inline">
                      <Box fontWeight={700} display="inline" component={"span"}>
                        {venue}
                      </Box>
                    </Typography>
                  </CellLink>
                </div>
              </ListItem>
              <Divider className={classes.divider} variant="middle" />

            </Grid>
          )
        }
        {/* <Box
          display={"flex"}
          justifyContent={"flex-end"}
          className={classes.buttonBox}
        >
          <Button
            color="primary"
            className={classes.copyButton}
            startIcon={
              <CopyTradeSVG
                fontSize={"small"}
                className={classes.buttonIcon}
              />
            }
          >
            Copy Trade
            </Button>
          <Button
            color="primary"
            className={classes.counterButton}
            startIcon={
              <CounterTradeSVG
                fontSize={"small"}
                className={classes.buttonIcon}
              />
            }
          >
            Counter Trade
            </Button>
        </Box> */}
      </Grid>
    </Section>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    alignSelf: "center",
    width: "100%",
    margin: theme.spacing(1, 0),
  },
  venue: {
    color: switcheo.black
  },
  section: {
    height: "100%",
  },
  buttonBox: {
    flex: 'auto',
    // margin: 16,
    margin: theme.spacing(2),
  },
  string: {
    wordBreak: "break-word",
  },
  header: {
    // flexBasis: '33%',
    paddingRight: "1rem",
  },
  infoIcon: {
    verticalAlign: "text-bottom",
    margin: theme.spacing(0, 1.5),
  },
  pnlContainer: {
    wordBreak: "break-word",
  },
  pnlH1: {
    margin: theme.spacing(0, 1.2, 0, 0),
  },
  pnlH3: {
    whiteSpace: "pre",
  },
  loss: {
    color: switcheo.loss,
  },
  profit: {
    color: switcheo.green[400],
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  totalPNL: {
    marginTop: theme.spacing(4.5),
    marginBottom: theme.spacing(1.5),
  },
  totalPNLClosed: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1.5),
  },
  copyButton: {
    borderRadius: 0,
    backgroundColor: switcheo.copyTrade,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.8, 1.25),
    fontSize: "x-small",
    fontWeight: "bold",
    minWidth: 0,
    textAlign: "center",
    color: switcheo.default.white,
    wordBreak: "normal",
    "&:hover": {
      backgroundColor: switcheo.copyTradeHover,
    },
  },
  counterButton: {
    borderRadius: 0,
    backgroundColor: switcheo.counterTrade,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.8, 1.25),
    fontSize: "x-small",
    fontWeight: "bold",
    minWidth: 0,
    textAlign: "center",
    color: "white",
    wordBreak: "normal",
    "&:hover": {
      backgroundColor: switcheo.counterTradeHover,
    },
  },
  demexIcon: {
    margin: theme.spacing(0, 0.5, 0, 0),
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
  buttonIcon: {
    margin: theme.spacing(0, -0.3, 0, 0.5),
    fontSize: "1rem !important",
  },
  longString: {
    wordBreak: "break-word",
  },
  listItem: {
    display: "grid",
    gridTemplateColumns: "33% auto",
  },
  listItemClosed: {
    display: "grid",
    gridTemplateColumns: "6rem auto",
  },
}));

export default PositionDetails;
