import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiCssBaseline']>> => ({
  '@global': {
    'html, body, #root': {
      // tslint:disable-next-line:prettier
      fontFamily: '\'Union\', sans-serif',
      height: '100%',
    },
  },
})
