import { actions, RootState } from 'js/store'
import { uuidv4 } from 'js/utils'
import { Action, ActionCreator } from 'redux'
import { SagaIterator } from 'redux-saga'
import { put, select, SelectEffect, take, takeEvery } from 'redux-saga/effects'
import { TradeHubSDK } from 'tradehub-api-js'

export function* putEvery<A>(
  channelOrPattern: any,
  actionCreator: ActionCreator<Action<A>>,
): SagaIterator {
  yield takeEvery(channelOrPattern, function* worker(value: A): SagaIterator {
    yield put(actionCreator(value))
  })
}

export function* runSagaTask<T>(taskname: string, task: () => Generator<unknown, T>, handleError?: (error: Error) => void) {
  const loadingUuid = uuidv4()
  yield put(actions.Layout.addBackgroundLoading(taskname, loadingUuid))
  try {
    return yield* task()
  } catch (error) {
    // TODO: throw error
    console.error(error)
    handleError?.(error)
  } finally {
    yield put(actions.Layout.removeBackgroundLoading(loadingUuid))
  }
}

export function selectState<T>(selector: (state: RootState) => T): SelectEffect {
  return select(selector);
}

export function* getInitializedSDK() {
  while (true) {
    const sdk: TradeHubSDK | undefined = yield selectState((state) => state.core.sdk);
    if (sdk?.initialized) {
      return sdk!
    }
    yield take(actions.Core.ActionType.CORE_UPDATE_SDK);
  }
}
