import BigNumber from 'bignumber.js'
import { SimpleMap } from 'js/utils'
import { Action } from 'redux'
import { RestModels, RestTypes } from 'tradehub-api-js'

export enum TransactionsActionType {
  CLEAR = 'CLEAR_TRANSACTIONS',
  SET_TRANSACTIONS = 'SET_TRANSACTIONS',
  SET_GAS_FEES = 'SET_GAS_FEES',
  SET_TYPES = 'SET_TYPES',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
}

export type TransactionsAction =
  | ClearAction
  | SetTransactionsAction
  | SetGasFeesAction
  | SetTransactionTypesAction
  | UpdateFiltersAction

export interface ClearAction extends Action<TransactionsActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: TransactionsActionType.CLEAR,
  }
}

export interface SetTransactionsAction
  extends Action<TransactionsActionType.SET_TRANSACTIONS> {
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>
}
export function setTransactions(
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>,
): SetTransactionsAction {
  return {
    type: TransactionsActionType.SET_TRANSACTIONS,
    transactions,
  }
}

export interface SetTransactionTypesAction
  extends Action<TransactionsActionType.SET_TYPES> {
  types: string[]
}
export function setTypes(types: string[]): SetTransactionTypesAction {
  return {
    type: TransactionsActionType.SET_TYPES,
    types,
  }
}

export interface SetGasFeesAction
  extends Action<TransactionsActionType.SET_GAS_FEES> {
  gasFees: SimpleMap<BigNumber>
}
export function setGasFees(gasFees: SimpleMap<BigNumber>): SetGasFeesAction {
  return {
    type: TransactionsActionType.SET_GAS_FEES,
    gasFees,
  }
}

export interface UpdateFiltersAction
  extends Action<TransactionsActionType.UPDATE_FILTERS> {
  options: any
}

export function updateFilters(options: any): UpdateFiltersAction {
  return {
    type: TransactionsActionType.UPDATE_FILTERS,
    options,
  }
}
