import { makeStyles, TableRow, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { TableCellInfo, TableSectionCell } from 'js/components'
import { FuturesDenomOverride } from 'js/constants'
import { useAssetSymbol } from 'js/hooks'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

type Props = {
  model: RestModels.Market
}

const MarketsRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> | null => {
  const { model } = props

  const assetSymbol = useAssetSymbol()
  const classes = useStyles()

  const symbolOverride = model.market_type === 'futures' ? FuturesDenomOverride : undefined
  const baseSymbol = assetSymbol(model.base, symbolOverride)
  const quoteSymbol = assetSymbol(model.quote, symbolOverride)

  const expiryDayjs = dayjs(model.expiry_time)
  let dateShort: string = expiryDayjs.format('DD MMM YYYY')
  const now: Dayjs = dayjs()
  const diffInYears: number = now.diff(expiryDayjs, 'year')
  if (diffInYears > 10) {
    dateShort = '-'
  }

  if (!model.is_active) {
    return null
  }

  const formatDisplayName = () => {
    if (model.market_type === 'spot') {
      return `${baseSymbol} / ${quoteSymbol}`
    }
    if (model.market_type === 'futures') {
      return `${baseSymbol} - ${dateShort}`
    }
    return ''
  }

  const MarketCells: TableCellInfo[] = [
    {
      align: 'inherit',
      className: classes.displayName,
      content: formatDisplayName(),
      to: `/market/${model.name}`,
    },
    {
      align: 'inherit',
      content: model.name,
    },
    {
      align: 'inherit',
      content: model.market_type,
    },
    {
      content: model.lot_size,
    },
    {
      content: model.tick_size,
    },
    {
      content: model.min_quantity,
    },
    {
      content: percentFormat(model.maker_fee),
    },
    {
      content: percentFormat(model.taker_fee),
    },
    {
      content: model.index_oracle_id.length === 0 ? '-' : model.index_oracle_id,
    },
    {
      align: 'inherit',
      content: booleanFormat(model.is_active),
    },
    {
      align: 'inherit',
      content: booleanFormat(model.is_settled),
    },
    {
      content: model.expiry_time,
      dateBoxClass: classes.dateBox,
      type: 'date',
    },
    {
      content: model.created_block_height,
      to: `/block/${model.created_block_height}`,
    }
  ]

  return (
    <TableRow hover>
      {MarketCells.map((cell: TableCellInfo, index: number) => (
        <TableSectionCell
          className={clsx(classes.tableClass, cell.className)}
          key={index}
          {...cell}
        />
      ))}
    </TableRow>
  )
}

const percentFormat = (value: string | number) => {
  if (typeof value === 'string')
    value = parseFloat(value)

  if (value) {
    return `${new BigNumber(value).times(100).toFormat(3)}%`
  }
  return '-'
}

const booleanFormat = (value: any) => {
  return value ? 'true' : 'false'
}

const useStyles = makeStyles((theme: Theme) => ({
  dateBox: {
    display: 'block',
    minHeight: '2.25rem',
  },
  displayName: {
    minWidth: '9.6rem',
    paddingRight: '1.15rem',
  },
  tableClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
  },
}))

export default MarketsRow
