import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiFormControl']>> => ({
  root: {
    '& .MuiInput-underline::before': {
      content: 'none',
    },
  },
})
