import dayjs from 'dayjs'
import { CreateMarketProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { RestModels, RPCParams } from 'tradehub-api-js'

export function createMarketProposalValue(): RPCParams.CreateMarketProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as CreateMarketProposalFormState
  const wallet = state.core.sdk?.wallet
  const tokens = state.tokens.info

  const { title, description, name, displayName, marketType, marketDescription,
    base, quote, lotSize, tickSize, minQuantity, riskStepSize, initialMarginBase,
    initialMarginStep, maintenanceMarginRatio, maxLiquidationOrderTicket,
    maxLiquidationOrderDuration, impactSize, markPriceBand, lastPriceProtectedBand,
    indexOracleId, expiryTime,
    takerFee, makerFee } = formState

  const baseTokenDecimals = parseNumber(tokens.find((token: RestModels.Token) => token.denom === base)?.decimals)!
  const quoteTokenDecimals = parseNumber(tokens.find((token: RestModels.Token) => token.denom === quote)?.decimals)!

  const time = dayjs(expiryTime)
  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    market: {
      name,
      display_name: displayName,
      market_type: marketType,
      description: marketDescription,
      base,
      quote,
      tick_size: parseNumber(tickSize, BIG_ZERO)!.shiftedBy(quoteTokenDecimals.minus(baseTokenDecimals).toNumber()).toString(10),
      lot_size: parseNumber(lotSize, BIG_ZERO)!.shiftedBy(baseTokenDecimals.toNumber()).decimalPlaces(0).toString(10),
      min_quantity: parseNumber(minQuantity, BIG_ZERO)!.shiftedBy(baseTokenDecimals.toNumber()).decimalPlaces(0).toString(10),
      risk_step_size: parseNumber(riskStepSize, BIG_ZERO)!.times(100).toString(),
      initial_margin_base: parseNumber(initialMarginBase, BIG_ZERO)!.toFixed(18),
      initial_margin_step: parseNumber(initialMarginStep, BIG_ZERO)!.toFixed(18),
      maintenance_margin_ratio: parseNumber(maintenanceMarginRatio, BIG_ZERO)!.toFixed(18),
      max_liquidation_order_ticket: parseNumber(maxLiquidationOrderTicket, BIG_ZERO)!.toString(),
      max_liquidation_order_duration: parseNumber(maxLiquidationOrderDuration, BIG_ZERO)!.toString(),
      impact_size: parseNumber(impactSize, BIG_ZERO)!.toString(),
      mark_price_band: parseNumber(markPriceBand, BIG_ZERO)!.toString(),
      last_price_protected_band: parseNumber(lastPriceProtectedBand, BIG_ZERO)!.toString(),
      index_oracle_id: indexOracleId,
      expiry_time: marketType === 'spot' ? '0' : time.unix().toString(),
      taker_fee: parseNumber(takerFee, BIG_ZERO)!.shiftedBy(-2).toString(10),
      maker_fee: parseNumber(makerFee, BIG_ZERO)!.shiftedBy(-2).toString(10),
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
