import { SubmitProposalFormState } from 'js/models/Governance'
import { Action } from 'redux'
import { RestModels, RPCParams } from 'tradehub-api-js'

export enum GovernanceActionTypes {
  SUBMIT_PROPOSAL = 'GOV_SUBMIT_PROPOSAL',
  SET_PROPOSALS = 'GOV_SET_PROPOSALS',
  SET_PROPOSAL = 'GOV_SET_PROPOSAL',
  DEPOSIT_PROPOSAL = 'GOV_DEPOSIT_PROPOSAL',
  VOTE_PROPOSAL = 'GOV_VOTE_PROPOSAL',
  SET_DEPOSIT_PARAMS = 'GOV_SET_DEPOSIT_PARAMS',
  SET_TALLY_PARAMS = 'GOV_SET_TALLY_PARAMS',
  SET_DELEGATIONS = 'GOV_SET_DELEGATIONS',
  APPEND_LIVE_VOTE_TALLY = 'GOV_APPEND_LIVE_VOTE_TALLY',
  CLEAR_LIVE_VOTE_TALLY = 'GOV_CLEAR_LIVE_VOTE_TALLY',
  SET_DEPOSIT_TXS = 'GOV_SET_DEPOSIT_TXS',
  SET_VOTE_TXS = 'GOV_SET_VOTE_TXS',
  SET_IS_SPAM_PROPOSALS_FILTERED = 'SET_IS_SPAM_PROPOSALS_FILTERED',
  SET_IS_MY_PROPOSALS = 'SET_IS_SPAM_PROPOSALS',
  SET_SUBMIT_PROPOSAL_FORM_STATE = 'SET_SUBMIT_PROPOSAL_FORM_STATE',
  CLEAR_SUBMIT_PROPOSAL_FORM_STATE = 'CLEAR_SUBMIT_PROPOSAL_FORM_STATE',
  SET_SUBMIT_PROPOSAL_FORM_VALIDATION_ERRORS = 'SET_SUBMIT_PROPOSAL_DETAILS_FORM_VALIDATION_ERRORS',
  SET_SUBMIT_PROPOSAL_DEPOSIT_AMOUNT = 'SET_SUBMIT_PROPOSAL_DEPOSIT_AMOUNT',
  SET_IS_SUBMITTING_PROPOSAL = 'SET_IS_SUBMITTING_PROPOSAL',
  SET_SUBMIT_PROPOSAL_ID = 'SET_SUBMIT_PROPOSAL_ID',
  SET_SUBMIT_PROPOSAL_ERROR = 'SET_SUBMIT_PROPOSAL_ERROR',
}

export type GovernanceAction =
  | SetProposalAction
  | SubmitProposalAction
  | SetProposalsAction
  | SetDepositParamsAction
  | SetTallyParamsAction
  | SetDelegationsAction
  | UpdateLiveVoteTallyAction
  | ClearLiveVoteTallyAction
  | SetDepositTxsAction
  | SetVoteTxsAction
  | SetIsSpamProposalsFilteredAction
  | SetIsMyProposalsAction
  | SetSubmitProposalFormStateAction
  | ClearSubmitProposalFormStateAction
  | SetSubmitProposalFormValidationErrorsAction
  | SetSubmitProposalDepositAmountAction
  | SetIsSubmittingProposalAction
  | SetSubmitProposalIdAction
  | SetSubmitProposalErrorAction

export interface SubmitProposalAction
  extends Action<GovernanceActionTypes.SUBMIT_PROPOSAL> {
  proposalType: string,
  value: RPCParams.Proposal
  deposit: RestModels.TokenAmount[]
}

export function submitProposal(proposalType: string, value: RPCParams.Proposal, deposit: RestModels.TokenAmount[]): SubmitProposalAction {
  return {
    type: GovernanceActionTypes.SUBMIT_PROPOSAL,
    proposalType,
    value,
    deposit,
  }
}

export interface SetProposalAction
  extends Action<GovernanceActionTypes.SET_PROPOSAL> {
  proposal: RestModels.GovProposal
}

export function setProposal(proposal: RestModels.GovProposal): SetProposalAction {
  return {
    type: GovernanceActionTypes.SET_PROPOSAL,
    proposal,
  }
}

export interface SetProposalsAction
  extends Action<GovernanceActionTypes.SET_PROPOSALS> {
  proposals: RestModels.GovProposal[]
}

export function setProposals(proposals: RestModels.GovProposal[]): SetProposalsAction {
  return {
    type: GovernanceActionTypes.SET_PROPOSALS,
    proposals,
  }
}

export interface DepositProposalAction
  extends Action<GovernanceActionTypes.DEPOSIT_PROPOSAL> {
  id: string
  amount: string
}

export function depositProposal(
  id: string,
  amount: string,
): DepositProposalAction {
  return {
    type: GovernanceActionTypes.DEPOSIT_PROPOSAL,
    id,
    amount,
  }
}

export interface VoteProposalAction
  extends Action<GovernanceActionTypes.VOTE_PROPOSAL> {
  id: string
  vote: string
}

export function voteProposal(id: string, vote: string): VoteProposalAction {
  return {
    type: GovernanceActionTypes.VOTE_PROPOSAL,
    id,
    vote,
  }
}

export interface SetDepositParamsAction
  extends Action<GovernanceActionTypes.SET_DEPOSIT_PARAMS> {
  depositParams: RestModels.GovDepositParams
}

export function setDepositParams(depositParams: RestModels.GovDepositParams): SetDepositParamsAction {
  return {
    type: GovernanceActionTypes.SET_DEPOSIT_PARAMS,
    depositParams,
  }
}

export interface SetTallyParamsAction
  extends Action<GovernanceActionTypes.SET_TALLY_PARAMS> {
  tallyParams: RestModels.GovTallyParams
}

export function setTallyParams(tallyParams: RestModels.GovTallyParams): SetTallyParamsAction {
  return {
    type: GovernanceActionTypes.SET_TALLY_PARAMS,
    tallyParams,
  }
}

export interface SetDelegationsAction
  extends Action<GovernanceActionTypes.SET_DELEGATIONS> {
  delegations: RestModels.ValidatorDelegation[]
}

export function setDelegations(delegations: RestModels.ValidatorDelegation[]): SetDelegationsAction {
  return {
    type: GovernanceActionTypes.SET_DELEGATIONS,
    delegations,
  }
}

export interface UpdateLiveVoteTallyAction
  extends Action<GovernanceActionTypes.APPEND_LIVE_VOTE_TALLY> {
  id: string,
  tally: RestModels.GovProposalTally,
}

export function updateLiveVoteTally(id: string, tally: RestModels.GovProposalTally): UpdateLiveVoteTallyAction {
  return {
    type: GovernanceActionTypes.APPEND_LIVE_VOTE_TALLY,
    id,
    tally,
  }
}

export interface ClearLiveVoteTallyAction
  extends Action<GovernanceActionTypes.CLEAR_LIVE_VOTE_TALLY> {
}

export function clearLiveVoteTally(): ClearLiveVoteTallyAction {
  return {
    type: GovernanceActionTypes.CLEAR_LIVE_VOTE_TALLY,
  }
}

export interface SetDepositTxsAction
  extends Action<GovernanceActionTypes.SET_DEPOSIT_TXS> {
  txs: RestModels.Txn[]
}

export function setProposalDeposits(
  txs: RestModels.Txn[],
): SetDepositTxsAction {
  return {
    type: GovernanceActionTypes.SET_DEPOSIT_TXS,
    txs,
  }
}

export interface SetVoteTxsAction
  extends Action<GovernanceActionTypes.SET_VOTE_TXS> {
  txs: RestModels.Txn[]
}

export function setProposalVotes(
  txs: RestModels.Txn[],
): SetVoteTxsAction {
  return {
    type: GovernanceActionTypes.SET_VOTE_TXS,
    txs,
  }
}

export interface SetIsSpamProposalsFilteredAction
  extends Action<GovernanceActionTypes.SET_IS_SPAM_PROPOSALS_FILTERED> {
  isSpamProposalsFiltered: boolean
}

export interface SetIsMyProposalsAction
  extends Action<GovernanceActionTypes.SET_IS_MY_PROPOSALS> {
  isMyProposals: boolean
}

export function setIsSpamProposalsFiltered(isSpamProposalsFiltered: boolean)
  : SetIsSpamProposalsFilteredAction {
  return {
    type: GovernanceActionTypes.SET_IS_SPAM_PROPOSALS_FILTERED,
    isSpamProposalsFiltered,
  }
}

export function setIsMyProposals(isMyProposals: boolean)
  : SetIsMyProposalsAction {
  return {
    type: GovernanceActionTypes.SET_IS_MY_PROPOSALS,
    isMyProposals,
  }
}

export interface SetSubmitProposalFormStateAction
  extends Action<GovernanceActionTypes.SET_SUBMIT_PROPOSAL_FORM_STATE> {
  form: SubmitProposalFormState
}

export function setSubmitProposalFormState(form: SubmitProposalFormState)
  : SetSubmitProposalFormStateAction {
  return {
    type: GovernanceActionTypes.SET_SUBMIT_PROPOSAL_FORM_STATE,
    form,
  }
}

export interface ClearSubmitProposalFormStateAction
  extends Action<GovernanceActionTypes.CLEAR_SUBMIT_PROPOSAL_FORM_STATE> {
}

export function clearSubmitProposalFormState()
  : ClearSubmitProposalFormStateAction {
  return {
    type: GovernanceActionTypes.CLEAR_SUBMIT_PROPOSAL_FORM_STATE,
  }
}

export interface SetSubmitProposalFormValidationErrorsAction
  extends Action<GovernanceActionTypes.SET_SUBMIT_PROPOSAL_FORM_VALIDATION_ERRORS> {
  errors: string[]
}

export function setSubmitProposalFormValidationErrors(errors: string[])
  : SetSubmitProposalFormValidationErrorsAction {
  return {
    type: GovernanceActionTypes.SET_SUBMIT_PROPOSAL_FORM_VALIDATION_ERRORS,
    errors,
  }
}

export interface SetSubmitProposalDepositAmountAction
  extends Action<GovernanceActionTypes.SET_SUBMIT_PROPOSAL_DEPOSIT_AMOUNT> {
  amount: string
}

export function setSubmitProposalDepositAmount(amount: string)
  : SetSubmitProposalDepositAmountAction {
  return {
    type: GovernanceActionTypes.SET_SUBMIT_PROPOSAL_DEPOSIT_AMOUNT,
    amount,
  }
}

export interface SetIsSubmittingProposalAction
  extends Action<GovernanceActionTypes.SET_IS_SUBMITTING_PROPOSAL> {
  isSubmitting: boolean
}

export function setIsSubmittingProposal(isSubmitting: boolean)
  : SetIsSubmittingProposalAction {
  return {
    type: GovernanceActionTypes.SET_IS_SUBMITTING_PROPOSAL,
    isSubmitting,
  }
}

export interface SetSubmitProposalIdAction
  extends Action<GovernanceActionTypes.SET_SUBMIT_PROPOSAL_ID> {
  id: number | null
}

export function setSubmitProposalId(id: number | null)
  : SetSubmitProposalIdAction {
  return {
    type: GovernanceActionTypes.SET_SUBMIT_PROPOSAL_ID,
    id,
  }
}

export interface SetSubmitProposalErrorAction
  extends Action<GovernanceActionTypes.SET_SUBMIT_PROPOSAL_ERROR> {
  msg: Error | null
}

export function setSubmitProposalError(msg: Error | null)
  : SetSubmitProposalErrorAction {
  return {
    type: GovernanceActionTypes.SET_SUBMIT_PROPOSAL_ERROR,
    msg,
  }
}
