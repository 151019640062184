import { Box, makeStyles, Theme, Toolbar } from '@material-ui/core'
import NetworkToggle from 'js/components/NetworkToggle'
import React, { ReactElement } from 'react'
import { HeaderMenuItem, Login } from '../components'
import { desktopMenuItems, MenuRouteItem } from '../headerConfig'

interface Props {}

const Header: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  return (
    <Toolbar className={classes.root}>
      <Box display="flex">
        {desktopMenuItems.map((route: MenuRouteItem, index: number) => (
          <HeaderMenuItem key={index} item={route} />
        ))}
      </Box>
      <Box flex={1} />
      <NetworkToggle />
      <Login />
    </Toolbar>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, -5),
    display: 'flex',
    justifyContent: "space-between",
  },
  menuItem: {
    '&.MuiLink-root.MuiTypography-colorPrimary': {
      color: theme.palette.text.primary,
    },
    fontWeight: 'normal',
    padding: '1rem 0rem',
    paddingRight: '2rem',
    textDecoration: 'none',
  },
  activeMenuItem: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
}))

export default Header
