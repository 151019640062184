import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { TokenAction, TokenActionType } from '../actions/token'

export interface TokenProps {
  token?: RestModels.Token
  rich: RestModels.UserToken[]
}
const makeState: Record.Factory<TokenProps> = Record<TokenProps>({
  token: undefined,
  rich: [],
})
export type TokenState = RecordOf<TokenProps>

function tokenReducer(
  state: TokenState = makeState(),
  action: TokenAction,
): TokenState {
  switch (action.type) {
    case TokenActionType.CLEAR:
      return makeState()
    case TokenActionType.SET_TOKEN_INFO:
      return state.set('token', action.token)
    case TokenActionType.SET_RICH_ACCOUNTS_BALANCES:
      return state.updateIn(['rich'], () => action.rich)
    default:
      return state
  }
}

export default tokenReducer
