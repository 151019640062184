import {
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery
} from '@material-ui/core'
import { DataLoadSegment, ListTable, Section, StandardBtn, TableEmptyState, TooltipHint } from 'js/components'
import React, { ReactElement, ReactNode } from 'react'
import InfoIcon from '@material-ui/icons/Info'

interface Ranking {
  label: string
  currentRank: number | undefined
  lastRank: number | undefined
  rankChange: number
  pnl: number
}

interface Props {
  rank?: Ranking
  title?: string
  loading: boolean
}

const PnlTable: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, rank, loading } = props
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Section title={title}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>
                Realised PNL
                <TooltipHint title="Only ranked PNL statistics are shown">
                  <InfoIcon className={smallScreen ? classes.infoIconSmallScreen : classes.infoIcon} />
                </TooltipHint>
              </TableCell>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="center">Previous Rank</TableCell>
              <TableCell>Rank Movement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rank && renderTransaction(rank)}
          </TableBody>
        </ListTable>
        {!rank && (
          <TableEmptyState itemName="PNL Ranking" />
        )}
        <StandardBtn
          className={classes.button}
          color="primary"
          to="/leaderboard"
          variant="contained"
        >
          View Leaderboard
        </StandardBtn>
      </DataLoadSegment>
    </Section>
  )

  function renderTransaction(
    rank: Ranking,
  ): ReactNode {
    const { label, currentRank, lastRank, rankChange, pnl } = rank
    return (
      <TableRow key={label} hover>
        <TableCell>
          {label}
        </TableCell>
        <TableCell>
          {currentRank === undefined ? "-" : pnl}
        </TableCell>
        <TableCell align="center">
          {currentRank === undefined ? 'Unranked' : `#${currentRank}`}
        </TableCell>
        <TableCell align="center">
          {lastRank === undefined ? 'Unranked' : `#${lastRank}`}
        </TableCell>
        <TableCell>
          {rankChange > 0 && "+"} {rankChange}
        </TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontSize: '1rem',
    marginTop: '2rem',
    width: '12.5rem',
    whiteSpace: 'nowrap'
  },
  infoIcon: {
    verticalAlign: 'bottom',
    margin: theme.spacing(0, 1.5)
  },
  infoIconSmallScreen: {
    verticalAlign: 'bottom',
  }
}))

export default PnlTable
