import { makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'

type Props = {
  children: any,
  headerClass?: string,
  keyVal: string,
  valueClass?: string
}

const ListTableRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { children, headerClass, keyVal, valueClass } = props
  const classes = useStyles()
  const label: string = (keyVal.charAt(0).toUpperCase() + keyVal.slice(1))
    .split('_')
    .join(' ')

  return (
    <TableRow className={classes.tableRow} hover>
      <TableCell className={clsx(classes.header, headerClass)}>{label}</TableCell>
      <TableCell className={clsx(classes.string, valueClass)}>
        {children}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  string: {
    wordBreak: 'break-all',
  },
  tableRow: {
    height: 50,
    '&:nth-of-type(even)': {
      backgroundColor: 'transparent !important',
      '&:hover': {
        backgroundColor: `${switcheo.hover} !important`,
      },
    },
  },
}))

export default ListTableRow
