import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'
import { switcheo } from '../palettes/colors'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiInput']>> => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: `1px solid ${switcheo.primary[300]}`,
    '& .MuiInput-input': {
      padding: theme.spacing(1.25),
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(1.25),
    },
  },
  underline: {
    '&::after': {
      display: 'none',
    },
  },
})
