import { SystemAddress, TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import {
  Effect,
  put,
  spawn,
  takeLatest
} from 'redux-saga/effects'
import { RestModels, RestResponse, RestTypes } from 'tradehub-api-js'
import { Comparators, SimpleMap } from 'js/utils'
import { AppActionType } from '../actions/app'
import {
  clear,
  EventsActionType,
  setExpiredMarkets,
  setLEOrders,
  setLeAccOrders,
} from '../actions/events'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Events extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchEventsLE],
      [this, this.fetchLiquidations],
      [this, this.fetchEventsSettledMarkets],
      [this, this.watchSetNetwork],
      [this, this.watchOrdersFilters],
      [this, this.watchLeAccOrdersFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchEventsLE(): any {
    yield runSagaTask(TaskNames.Events.LEOrders, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        initiator: 'system_derisk,system_adl,system_liquidate',
        limit: 20,
        order_type: 'market,liquidation',
        order_by: 'desc',
      })) as RestTypes.ResultsMinMax<RestModels.Order>

      orders.data.sort(Comparators.sortOrder);

      yield put(setLEOrders(orders))
    });
  }

  private *fetchEventsSettledMarkets(): any {
    yield runSagaTask(TaskNames.Events.SettledMarkets, function* () {
      const sdk = yield* getInitializedSDK()

      const markets = (yield sdk.api.getMarkets({
        is_settled: true,
      })) as RestResponse.Market[]
      const marketMap = markets.reduce((result, market) => {
        result[market.name] = market
        return result
      }, {} as SimpleMap<RestResponse.Market>)
      yield put(setExpiredMarkets(marketMap))
    });
  }

  private *fetchLiquidations(action?: any): any {
    yield runSagaTask(TaskNames.Events.LETrades, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        account: SystemAddress.LiquidateEngine,
        limit: 20,
        ...action?.options?.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Order>

      orders.data.sort(Comparators.sortOrder);

      yield put(setLeAccOrders(orders))
    });
  }
  private *watchLeAccOrdersFilters(): any {
    yield takeLatest(
      EventsActionType.UPDATE_LE_ACC_ORDERS_FILTERS,
      this.fetchLiquidations,
    )
  }

  private *watchOrdersFilters(): any {
    yield takeLatest(
      EventsActionType.UPDATE_ORDERS_FILTERS,
      this.handleFilters,
    )
  }
  private *handleFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Events.LEOrderFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        initiator: 'system_derisk,system_adl,system_liquidate',
        limit: 20,
        order_type: 'market,liquidation',
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Order>

      orders.data.sort(Comparators.sortOrder);

      yield put(setLEOrders(orders))
    });
  }
}
