import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'
import { ListTableRow } from '../components'

type Props = {
  distributionParams: any
}

const DistributionParams: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { distributionParams } = props
  const [loading] = useTaskSubscriber(TaskNames.Validators.Distribution)

  const cellFormat = (key: any) => {
    switch (key) {
      case 'withdraw_addr_enabled':
        return distributionParams?.result[key] ? 'Yes' : 'No'
      case 'base_proposer_reward':
      case 'bonus_proposer_reward':
      case 'community_tax':
        const num: BigNumber = new BigNumber(distributionParams?.result[key]).times(100)
        return `${num.toFormat(2)}%` || '-'
      default:
        const value: any = isNaN(parseFloat(distributionParams?.result[key]))
          ? distributionParams?.result[key]
          : parseFloat(distributionParams?.result[key].toString())
        return value || '-'
    }
  }

  return (
    <Section title="Distribution Parameters" className={classes.section}>
      <DataLoadSegment loading={loading || !distributionParams?.result}>
        {
          distributionParams?.result && (
            <ListTable>
              <TableBody>
                {
                  Object.keys(distributionParams?.result).map((each, index) => {
                    if (distributionParams?.result[each] === '') return null

                    return (
                      <ListTableRow
                        headerClass={classes.headerClass}
                        key={index}
                        keyVal={each}
                        valueClass={classes.valueClass}
                      >
                        {cellFormat(each)}
                      </ListTableRow>
                    )
                  },
                  )}
              </TableBody>
            </ListTable>
          )
        }
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default DistributionParams
