import { updateOrdersFilters, updateTradesFilters } from 'js/actions/markets'
import { TableSection } from 'js/components'
import Orders from 'js/components/Orders'
import Page from 'js/components/Page'
import Trades from 'js/components/Trades'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { MarketsRow } from './components'
import { MarketsHeader } from './marketsConfig'

interface Props { }

const Markets: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { orders, trades } = useRedux((state) => state.markets)
  const { markets } = useRedux((state) => state.app)
  return (
    <Page title="Markets">
      <TableSection
        headerCells={MarketsHeader}
        loadName={TaskNames.App.Markets}
        models={Object.values(markets)}
        rowComponent={MarketsRow}
        title="Parameters"
        itemName="markets"
      />
      <Orders
        filterStatus
        orders={orders}
        title="Recent Orders"
        action={updateOrdersFilters}
        markets={markets}
        loadName={[
          TaskNames.Markets.Orders,
          TaskNames.Markets.OrderFilter,
        ]}
      />
      <Trades
        filterStatus
        trades={trades}
        title="Recent Trades"
        action={updateTradesFilters}
        markets={markets}
        loadName={[
          TaskNames.Markets.Trades,
          TaskNames.Markets.TradeFilter,
        ]}
      />
    </Page>
  )
}

export default Markets
