import BigNumber from 'bignumber.js'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setBlockInfo, setEvents, setTransactions } from '../actions/block'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Block extends Saga {
  private readonly height: number

  constructor(height: number) {
    super()

    this.height = height
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchBlockTransaction], this.height),
      call([this, this.fetchBlockEvents], this.height),
      call([this, this.fetchBlockInfo], this.height),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchBlockTransaction(height: number): any {
    yield runSagaTask(TaskNames.Block.Transaction, function* () {
      const sdk = yield* getInitializedSDK()
      const txs = (yield sdk.api.getTxs({
        height: new BigNumber(height).toString(),
      })) as RestTypes.GetTxsResponse

      yield put(setTransactions(txs))
    });
  }

  private *fetchBlockInfo(height: number): any {
    yield runSagaTask(TaskNames.Block.Info, function* () {
      const sdk = yield* getInitializedSDK()

      const block = (yield sdk.api.getCosmosBlockInfo({ height })) as RestModels.CosmosBlock
      yield put(setBlockInfo(block))
    });
  }

  private *fetchBlockEvents(height: number): any {
    yield runSagaTask(TaskNames.Block.Events, function* () {
      const sdk = yield* getInitializedSDK()

      const blockEvents = (yield sdk.tm.getBlockEvents({ height })) as RestModels.BlockEvents
      yield put(setEvents(blockEvents))
    });
  }
}
