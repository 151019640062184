
import BigNumber from 'bignumber.js'

//taken from switcheo-disco
export default function estLiqPrice(
    entryPrice: BigNumber, allocatedMargin: BigNumber,
    initialMarginFraction: BigNumber, lots: BigNumber,
  ) {
    if (!lots.isZero()) {
      const lp = entryPrice.minus(
        allocatedMargin.times(
          (new BigNumber(1).minus(initialMarginFraction))
            .div(lots),
        ),
      )
      if (lp.isPositive()) return lp
      return new BigNumber(0)
    }
  
    return new BigNumber(0)
  }
  
  export function getInitialMarginFraction(
    size: BigNumber,
    initialMarginBase: BigNumber,
    riskStepSize: BigNumber,
    initialMarginStep: BigNumber,
  ) {
    return initialMarginBase.plus(
      (size.abs().div(riskStepSize)).integerValue(BigNumber.ROUND_FLOOR),
    ).times(initialMarginStep)
  }
  