import { Action } from 'redux'

export enum ValidatorsActionType {
  CLEAR = 'CLEAR_ACCOUNT',
  SET_SLASHING_PARAMS = 'SET_SLASHING_PARAMS',
  SET_DISTRIBUTION_PARAMS = 'SET_DISTRIBUTION_PARAMS',
  SET_BLOCK_TIME = 'SET_BLOCK_TIME',
}

export type ValidatorsAction =
  | ClearAction
  | SetSlashingParamsAction
  | SetDistributionParamsAction
  | SetBlockTimeAction

export interface ClearAction extends Action<ValidatorsActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: ValidatorsActionType.CLEAR,
  }
}

export interface SetSlashingParamsAction
  extends Action<ValidatorsActionType.SET_SLASHING_PARAMS> {
  params: any
}

export function setSlashingParams(params: any): SetSlashingParamsAction {
  return {
    type: ValidatorsActionType.SET_SLASHING_PARAMS,
    params,
  }
}

export interface SetDistributionParamsAction
  extends Action<ValidatorsActionType.SET_DISTRIBUTION_PARAMS> {
  distributionParams: any
}

export function setDistributionParams(
  distributionParams: any,
): SetDistributionParamsAction {
  return {
    type: ValidatorsActionType.SET_DISTRIBUTION_PARAMS,
    distributionParams,
  }
}

export interface SetBlockTimeAction
  extends Action<ValidatorsActionType.SET_BLOCK_TIME> {
  blockTime: string
}
export function setBlockTime(blockTime: string): SetBlockTimeAction {
  return {
    type: ValidatorsActionType.SET_BLOCK_TIME,
    blockTime,
  }
}
