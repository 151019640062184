import { Backdrop, Box, Button, makeStyles, MenuItem, MenuList, Paper, Theme, Typography } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import clsx from 'clsx'
import { setMenuDropClose, setNetDropClose, setWalletDropClose, setWalletDropOpen } from 'js/actions/app'
import { useRedux } from 'js/hooks'
import { returnUrlNet } from 'js/models'
import { actions } from 'js/store'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { User } from '../assets'

type Props = {
}

const YourWalletDropdown: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { network, walletDrop } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.sdk)

  const handleClick = () => {
    dispatch(setMenuDropClose())
    dispatch(setNetDropClose())
    dispatch(setWalletDropOpen())
  }

  const handleClose = () => dispatch(setWalletDropClose())
  const logout = () => {
    sdk?.disconnect()

    dispatch(actions.Core.updateSDK(null))
    history.push('/login')
  }
  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        className={classes.root}
        startIcon={<User className={classes.userIcon} />}
      >
        <Typography className={classes.loginText} variant='h3'>
          Your Wallet
        </Typography>
        {walletDrop ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      {
        walletDrop && (
          <Box>
            <Backdrop
              className={clsx(classes.backdrop, { open: walletDrop })}
              invisible
              open={walletDrop}
              onClick={handleClose}
            />
            <Paper className={classes.menu}>
              <MenuList className={classes.menuList}>
                <MenuItem
                  component={NavLink}
                  to={`/account/${sdk?.wallet?.bech32Address}?net=${returnUrlNet(network)}`}
                  className={classes.menuItem}
                  activeClassName={classes.activeMenuItem}
                >
                  Your Address Profile
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={logout}>
                  Logout
                </MenuItem>
                {/* <ListSubheader>Your Voting Power</ListSubheader>
                <MenuItem>TODO</MenuItem> */}
              </MenuList>
            </Paper>
          </Box>
        )
      }
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  activeMenuItem: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  backdrop: {
  },
  menu: {
    top: '3.25rem',
    right: 0,
    zIndex: 10,
    position: 'absolute',
  },
  menuList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  menuItem: {
    fontSize: '1rem',
    padding: theme.spacing(2, 3),
    minHeight: theme.spacing(6),
  },
  root: {
    minWidth: '8.9375rem',
    padding: theme.spacing(1),
  },
  loginText: {
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    textTransform: 'initial',
  },
  userIcon: {
    height: '1.125rem',
    width: '1.125rem',
  },
}))

export default YourWalletDropdown
