import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiMenuItem']>> => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiTypography-colorPrimary': {
      color: theme.palette.primary.contrastText,
    },
  },
})
