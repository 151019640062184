import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'
import { switcheo } from 'js/theme/palettes/colors'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiTab']>> => ({
  root: {
    fontSize: "1rem",
    padding: theme.spacing(1, 2.25),
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
  },
  textColorInherit: {
    opacity: 1,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiTab-wrapper': {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
      },
    },
  },
})
