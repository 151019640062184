import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { Blockchain } from "tradehub-api-js";

interface Props extends SelectProps {
  defaultText?: string
}

const blockchains: {
  name: string
  value: string
}[] = [{
  value: Blockchain.Ethereum,
  name: "Ethereum",
}, {
  value: Blockchain.Neo,
  name: "Neo",
}, {
  value: Blockchain.BinanceSmartChain,
  name: "Binance Smart Chain",
}]

const BlockchainSelectInput: React.FC<Props> = (props: Props) => {
  const { children, label, className, onChange, defaultText, ...rest } = props;
  const classes = useStyles()

  const _defaultText = defaultText ?? "Select Blockchain"

  const _onChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>, child: React.ReactNode) => {
    onChange?.(event, child)
  }

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Select
          displayEmpty
          {...rest}
          className={clsx(classes.select, className)}
          onChange={_onChange}
        >
          <MenuItem value="" disabled>{_defaultText}</MenuItem>
          {
            blockchains.map((blockchain: any) =>
              <MenuItem key={`${blockchain.value}`} value={blockchain.value}>
                {blockchain.name}
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  select: {

  },
}))

export default BlockchainSelectInput;
