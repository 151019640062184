import BigNumber from 'bignumber.js'
import { TaskNames } from 'js/constants'
import { actions } from 'js/store'
import { SimpleMap, uuidv4 } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import {
  clear, setGasFees, setTransactions, setTypes,
  TransactionsActionType
} from '../actions/transactions'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Transactions extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      // [this, this.fetchBlocks],
      [this, this.fetchTransactions],
      [this, this.fetchTransactionTypes],
      [this, this.watchFilters],
      [this, this.fetchGasFees],
      [this, this.watchSetNetwork],
      // [this, this.watchNewBlock],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchTransactions(): any {
    const transactionsUuid = uuidv4()

    yield put(actions.Layout.addBackgroundLoading(TaskNames.Transactions.List, transactionsUuid))
    try {
      const sdk = yield* getInitializedSDK()
      const txs = (yield sdk.api.getTxsPaged({
        limit: "10",
      })) as RestTypes.ResultsMinMax<RestModels.Txn>
      yield put(setTransactions(txs))
    } catch (err) {
      yield put(setTransactions({
        data: [],
        min: 0,
        max: 0,
      }))
    } finally {
      yield put(actions.Layout.removeBackgroundLoading(transactionsUuid))
    }
  }

  private *fetchTransactionTypes(): any {
    yield runSagaTask(TaskNames.Transactions.Types, function* () {
      const sdk = yield* getInitializedSDK()

      const types = (yield sdk.api.getTxTypes()) as string[]
      yield put(setTypes(types))
    });
  }

  private *watchFilters(): any {
    yield takeLatest(
      TransactionsActionType.UPDATE_FILTERS,
      this.handleFilters,
    )
  }

  private *handleFilters(action: any): any {
    const transactFilterUuid = uuidv4()
    let msgType = ''
    const filters = action.options.filters
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key]) {
          msgType = `${msgType}${key},`
        }
      }
    }

    yield put(actions.Layout.addBackgroundLoading(TaskNames.Transactions.Filter, transactFilterUuid))
    try {
      const sdk = yield* getInitializedSDK()
      const txs = (yield sdk.api.getTxsPaged({
        limit: "10",
        msg_type: msgType,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Txn>
      // const txs: any = yield call(
      //   [nodeClient, nodeClient.getTransactionsWithPagination],
      //   {
      //     limit: 10,
      //     msgType,
      //     ...action.options.pagination,
      //   },
      // )
      if (action?.options?.pagination?.order_by === "asc") {
        txs.data.reverse();
      }
      yield put(setTransactions(txs))
    } catch (err) {
      yield put(setTransactions({
        data: [],
        min: 0,
        max: 0,
      }))
    } finally {
      yield put(actions.Layout.removeBackgroundLoading(transactFilterUuid))
    }
  }

  private *fetchGasFees(): any {
    yield runSagaTask(TaskNames.Transactions.Types, function* () {
      const sdk = yield* getInitializedSDK()
      const fees = (yield sdk.api.getTxnFees()) as SimpleMap<BigNumber>
      yield put(setGasFees(fees))
    })
  }
}
