import { TableCellInfo } from 'js/components'

export const SettledHeaders: TableCellInfo[] = [
  {
    align: 'inherit',
    content: 'Market',
  },
  {
    content: 'Expiry Date',
  },
  {
    content: 'Settled At Height',
  }
]

export const TradesHeaders: TableCellInfo[] = [
  {
    align: 'inherit',
    content: 'Market',
  },
  {
    align: 'inherit',
    content: 'Order ID',
  },
  {
    align: 'inherit',
    content: 'Action',
  },
  {
    content: 'Quantity',
  },
  {
    content: 'Price',
  },
  {
    content: 'Liquidated At',
  }
]
