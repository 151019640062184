import { Grid } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, Page } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { BIG_ZERO } from 'js/utils'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
import CommitmentCurve from './components/CommitmentCurve'
import LiquidityPoolBox from './components/LiquidityPoolBox'
import RewardCurve from './components/RewardCurve'

interface Props { }

const LiquidityPools: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { pools, rewardCurve, commitmentCurve } = useRedux((state) => state.pools)
  const [loading] = useTaskSubscriber(TaskNames.Pools.List)

  // TODO: add a loading state. load only when `markets` is not empty
  let total: BigNumber = BIG_ZERO
  pools.forEach((p: RestModels.LiquidityPool) =>
    total = total.plus(new BigNumber(p.rewards_weight)),
  )
  return (
    <Page title="Liquidity Pools">
      <DataLoadSegment loading={loading}>
        {pools.map((pool: RestModels.LiquidityPool) => (
          <LiquidityPoolBox
            key={pool.denom}
            pool={pool}
            total={total}
          />
        ))}
      </DataLoadSegment>
      <Grid container spacing={3} style={{ marginTop: '12px' }}>
        {rewardCurve && (
          <Grid item xs={12} md={6}>
            <RewardCurve params={rewardCurve} />
          </Grid>
        )}
        {commitmentCurve && (
          <Grid item xs={12} md={6}>
            <CommitmentCurve params={commitmentCurve} />
          </Grid>
        )}
      </Grid>
    </Page>
  )
}

export default LiquidityPools
