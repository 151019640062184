import { Grid, TableBody, TableHead, TableRow } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, ListTable, Page, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { ValidatorsProps } from 'js/reducers/validators'
import { bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'
import { DistributionParams, SlashingParams, TooltipCell, ValidatorRow, ValidatorsSummary } from './components'
import { HeaderCells } from './validatorsConfig'

interface Props extends ValidatorsProps {
}

const Validators: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.App.SigningInfo)
  const { blockTime, params, distributionParams } = useRedux((state) => state.validators)
  const { delegatorsMap, info, validatorsMap } = useRedux((state) => state.app)

  let totalShares = new BigNumber(0)
  const validators = Object.values(validatorsMap).sort((lhs, rhs) => bnOrZero(rhs.DelegatorShares).comparedTo(lhs.DelegatorShares))
  for (const validator of validators) {
    totalShares = totalShares.plus(bnOrZero(validator.DelegatorShares))
  }
  return (
    <Page title="Validators">
      <ValidatorsSummary
        blockTime={blockTime}
        info={info}
        totalShares={totalShares}
        validators={validators}
      />
      <Section title="Validator Pools">
        <DataLoadSegment
          loading={loading}
        >
          <ListTable>
            <TableHead>
              <TableRow>
                {HeaderCells.map((header, id) =>
                  <TooltipCell {...header} key={id} />,
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {validators.map((validator, index) => {
                const delegatorList = delegatorsMap[validator.OperatorAddress]
                return (
                  <ValidatorRow
                    delegatorList={delegatorList}
                    key={validator.OperatorAddress}
                    index={index}
                    info={info}
                    totalShares={totalShares}
                    validator={validator}
                  />
                )
              })}
            </TableBody>
          </ListTable>
          {!validators.length && (
            <TableEmptyState itemName="validators" />
          )}
        </DataLoadSegment>
      </Section>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SlashingParams params={params} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DistributionParams distributionParams={distributionParams} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Validators
