import { ListItem, makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { CellLink, Section } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  transaction: RestModels.TxnHistory,
}

const TransactionDetail: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { transaction } = props
  const time: Dayjs = dayjs(transaction?.block_time)

  let gasDeducted: BigNumber = new BigNumber(0)
  transaction?.msgs.forEach((m: any) => {
    switch (m.msgType) {
      case 'create_order':
      case 'create_oracle_vote':
        break
      default:
        gasDeducted = gasDeducted.plus(1)
    }
  })

  return (
    <Section>
      <ListItem disableGutters>
        <div className={classes.header}>Transaction Hash</div>
        <div className={clsx(classes.string, classes.value)}>
          {transaction?.hash}
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>From</div>
        <CellLink to={`/account/${transaction?.address}`} className={classes.value}>
          {transaction?.username === '' ? transaction?.address : transaction?.username}
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Status</div>
        <div className={classes.value}>
          {transaction?.code === '0' ? 'Success' : 'Fail'}
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Height</div>
        <CellLink to={`/block/${transaction?.height}`} className={classes.value}>
          {transaction?.height}
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Block Time</div>
        <div className={classes.value}>
          {time.format('YYYY-MM-DD HH:mm:ss')}
          <span className={classes.timeAgo}>({fromNow(time)})</span>
        </div>
      </ListItem>
      {/* <ListItem disableGutters>
          <div className={classes.header}>Gas (used / limit)</div>
          <div className={classes.value}>
            <div style={{ display: 'inline' }}>
              {gasUsed} / {gasLimit}
            </div>
            <div
              style={{
                color: grey[500],
                display: 'inline',
                marginLeft: 6,
                fontStyle: 'italic',
              }}
            >
              ({gasUsedBN.toFormat(8)} SWTH / {gasLimitBN.toFormat(8)} SWTH)
            </div>
          </div>
        </ListItem> */}
      <ListItem disableGutters>
        <div className={classes.header}>Gas Deducted</div>
        <div className={classes.value}>
          <div className={classes.mainContent}>
            {gasDeducted.toFixed(0)}
          </div>
          <div className={classes.sideContent}>
            SWTH
          </div>
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Memo</div>
        <div className={classes.value}>{transaction?.memo || '-'}</div>
      </ListItem>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '25%',
    paddingRight: '1rem',
  },
  value: {
    width: '47.5rem',
    overflowX: 'auto',
  },
  mainContent: {
    display: 'inline',
  },
  sideContent: {
    color: theme.palette.grey[500],
    display: 'inline',
    marginLeft: theme.spacing(0.75),
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[500],
  },
}))

export default TransactionDetail
