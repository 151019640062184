import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiFilledInput']>> => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiFilledInput-input': {
      padding: theme.spacing(1.5),
    },
  },
})
