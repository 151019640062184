import { darken, makeStyles, Table, TableContainer, TableProps, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = TableProps

const ListTable: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <TableContainer>
      <Table {...rest} className={clsx(classes.root, className)}>
        {children}
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
        textAlign: 'right',
      },
    },
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: darken(theme.palette.background.default, 0.005),
    },
  },
}))

export default ListTable
