import BigNumber from 'bignumber.js'
import { Record, RecordOf } from 'immutable'
import { BIG_ZERO } from 'js/utils'
import { RestModels } from 'tradehub-api-js'
import { DashboardAction, DashboardActionType } from '../actions/dashboard'

export interface DashboardProps {
  blocks: RestModels.Block[]
  transactions: Array<RestModels.Txn>
  blockTime: string
  insurance?: RestModels.InsuranceFundBalance
  insuranceIsLoaded: boolean
  oi: BigNumber
  oiIsLoaded: boolean
  volume: BigNumber
  volumeIsLoaded: boolean
  avgReward: BigNumber
  activeWallets: BigNumber
  rewardsDistributed: RestModels.TokenAmount[]
}

const makeState: Record.Factory<DashboardProps> = Record<DashboardProps>({
  blocks: [],
  transactions: [],
  blockTime: '00:00:00',
  insurance: undefined,
  insuranceIsLoaded: false,
  oiIsLoaded: false,
  volume: BIG_ZERO,
  oi: BIG_ZERO,
  volumeIsLoaded: false,
  avgReward: BIG_ZERO,
  activeWallets: BIG_ZERO,
  rewardsDistributed: [],
})
export type DashboardState = RecordOf<DashboardProps>

function dashboardReducer(
  state: DashboardState = makeState(),
  action: DashboardAction,
): DashboardState {
  switch (action.type) {
    case DashboardActionType.CLEAR:
      return makeState()
    case DashboardActionType.SET_INSURANCE_BALANCES:
      return state
        .updateIn(['insurance'], () => action.insurance)
        .updateIn(['insuranceIsLoaded'], () => true)
    case DashboardActionType.SET_TRANSACTIONS:
      return state.updateIn(
        ['transactions'],
        (): Array<RestModels.Txn> => {
          return action.transactions
        },
      )
    case DashboardActionType.SET_BLOCKS:
      return state.updateIn(['blocks'], () => action.blocks)
    case DashboardActionType.SET_BLOCK_TIME:
      return state.updateIn(['blockTime'], (): string => {
        return action.blockTime
      })
    case DashboardActionType.SET_OI:
      return state
        .updateIn(['oi'], (): any => {
          return action.oi
        })
        .updateIn(['oiIsLoaded'], () => true)
    case DashboardActionType.SET_VOLUME:
      return state
        .updateIn(['volume'], (): any => {
          return action.volume
        })
        .updateIn(['volumeIsLoaded'], () => true)
    case DashboardActionType.SET_AVG_REWARD:
      return state.set('avgReward', action.avgReward)
    case DashboardActionType.SET_ACTIVE_WALLETS:
      return state.set('activeWallets', action.activeWallets)
    case DashboardActionType.SET_REWARDS_DISTRIBUTED:
      return state.set('rewardsDistributed', action.rewardsDistributed)
    default:
      return state
  }
}

export default dashboardReducer
