import { makeStyles, Theme } from '@material-ui/core'
import { StandardBtn } from 'js/components'
import React, { ReactElement } from 'react'

interface Props {
  disabled: boolean
  onClick: any
  title: string
}

const DepositBtn: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { disabled, onClick, title } = props
  const classes = useStyles()

  return (
    <StandardBtn
      disabled={disabled}
      className={classes.connectButton}
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      {title}
    </StandardBtn>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  connectButton: {
    margin: '8px 8px 8px 0 !important',
  },
}))

export default DepositBtn
