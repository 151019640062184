import { Record, RecordOf } from 'immutable'
import { RestModels, RestResponse, RestTypes } from 'tradehub-api-js'
import { SimpleMap } from 'js/utils'
import { EventsAction, EventsActionType } from '../actions/events'

export interface EventProps {
  le?: RestTypes.ResultsMinMax<RestModels.Order>
  leAccOrders?: RestTypes.ResultsMinMax<RestModels.Order>
  settledMarkets: SimpleMap<RestResponse.Market>
}

const makeState: Record.Factory<EventProps> = Record<EventProps>({
  le: undefined,
  leAccOrders: undefined,
  settledMarkets: {},
})
export type EventsState = RecordOf<EventProps>

function eventsReducer(
  state: EventsState = makeState(),
  action: EventsAction,
): EventsState {
  switch (action.type) {
    case EventsActionType.CLEAR:
      return makeState()
    case EventsActionType.SET_LE_ORDERS:
      return state.updateIn(['le'], () => action.le)
    case EventsActionType.SET_EXPIRED_MARKETS:
      return state.updateIn(['settledMarkets'], () => action.settledMarkets)
    case EventsActionType.SET_LE_ACC_ORDERS:
      return state.updateIn(['leAccOrders'], () => action.leAccOrders)
    default:
      return state
  }
}

export default eventsReducer
