import BigNumber from 'bignumber.js'
import { List } from 'immutable'
import { setTokensInfo } from 'js/actions/tokens'
import { Constants, TaskNames } from 'js/constants'
import { GECKO_SYMBOLS } from 'js/helpers'
import { actions } from 'js/store'
import { CoreState } from 'js/store/core'
import { bnOrZero, SimpleMap, uuidv4 } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { all, call, delay, Effect, put, race, select, spawn, take, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes, TradeHubSDK } from 'tradehub-api-js'
import { AppActionType, setConsensus, setDelegators, setGeckoPrices, setLatestBlock, setMarkets, setNeoPrice, setOperators, setOracles, setSigningInfo, setTokensMap, setTotalBonded, setTotalStaked, setTotalSupply, setValidators } from '../actions/app'
import { parseNet, parseNetwork } from '../models/Network'
import { defaultGeckoPrice, defaultNeoPrice } from '../reducers/app'
import { getInitializedSDK, runSagaTask, selectState } from './helper'
import Saga from './Saga'

async function queryGecko(): Promise<Response> {
  const removeDuplicateMap: any = {}
  let tokensQuery: string = ""
  const geckoEntriesArray: string[][] = Object.entries(GECKO_SYMBOLS)
  // Get all Gecko Coins from GECKO_SYMBOLS and insert into query
  for (const [, value] of geckoEntriesArray) {
    if (!removeDuplicateMap[value]) {
      removeDuplicateMap[value] = 1
      tokensQuery += `${value},`
    }
  }
  tokensQuery = tokensQuery.slice(0, -1)
  return fetch(
    `https://api.coingecko.com/api/v3/simple/price?ids=${tokensQuery}&vs_currencies=usd,btc,eth,sgd`,
  )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
      }
      return {
        switcheo: defaultGeckoPrice,
        ethereum: defaultGeckoPrice,
        bitcoin: defaultGeckoPrice,
        "usd-coin": defaultGeckoPrice,
        "celsius-degree-token": defaultGeckoPrice,
        "neon-exchange": defaultGeckoPrice,
        neo: defaultGeckoPrice,
        "binance-usd": defaultGeckoPrice,
        "bitcoin-bep2": defaultGeckoPrice,
        "wrapped-bitcoin": defaultGeckoPrice,
        binancecoin: defaultGeckoPrice,
      }
    })
    .catch((error) => ({
      switcheo: defaultGeckoPrice,
      ethereum: defaultGeckoPrice,
      bitcoin: defaultGeckoPrice,
      "usd-coin": defaultGeckoPrice,
      "celsius-degree-token": defaultGeckoPrice,
      "neon-exchange": defaultGeckoPrice,
      neo: defaultGeckoPrice,
      "binance-usd": defaultGeckoPrice,
      "bitcoin-bep2": defaultGeckoPrice,
      "wrapped-bitcoin": defaultGeckoPrice,
      binancecoin: defaultGeckoPrice,
    }))
}

async function getNeoPrice(): Promise<Response> {
  return fetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=neo&vs_currencies=usd',
  )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
      }
      return { neo: defaultNeoPrice }
    })
    .catch((error) => ({ neo: defaultNeoPrice }))
}

export default class App extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchValidators]),
      call([this, this.fetchMarkets]),
      call([this, this.fetchTokens]),
      call([this, this.fetchCoinGecko]),
      call([this, this.fetchNeo]),
      call([this, this.fetchSigningInfo]),
      call([this, this.fetchOracles]),
      call([this, this.fetchSWTHsupply]),
      call([this, this.fetchTokensInfo]),
      call([this, this.fetchTotalSupply]),
      call([this, this.fetchStakingPool]),

      spawn([this, this.watchSDK]),
      spawn([this, this.watchSetNetwork]),
      spawn([this, this.pollBlockHeight]),
    ]
  }

  private *fetchSWTHsupply(): any {
    yield runSagaTask(TaskNames.App.SwthSupply, function* () {
      const sdk = yield* getInitializedSDK()

      const swthSupply = (yield call([sdk.api, sdk.api.getTotalSupply], { denom: 'swth' })) as string
      yield put(setTotalSupply(bnOrZero(swthSupply).shiftedBy(-Constants.Decimals.SWTH)))
    });
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
    const search = window.location.search
    const params = new URLSearchParams(search)
    const previousNet = params.get('net')
    if (previousNet !== null) {
      const previousNetwork = parseNetwork(previousNet)
      const network: any = yield select((state) => state.app.network)
      if (network !== previousNetwork) {
        const net = parseNet(network)
        params.set('net', net)
        window.history.replaceState(
          '',
          '',
          `${window.location.pathname}?${params}`,
        )
      }
    }
  }

  private *watchSDK() {
    while (true) {
      try {
        const sdk = (yield selectState((state) => state.core.sdk)) as TradeHubSDK | null;
        if (!sdk) {
          yield runSagaTask(TaskNames.App.InitializeSDK, function* () {
            const coreState = (yield selectState((state) => state.core)) as CoreState;
            const network = (yield selectState((state) => state.app.network)) as TradeHubSDK.Network;
            const params = new URLSearchParams(window.location.search);
            const rawNetwork = params.get('net') ?? coreState.storedNetwork ?? network;
            const parsedNetwork = TradeHubSDK.parseNetwork(rawNetwork, TradeHubSDK.Network.MainNet);

            let sdk = new TradeHubSDK({
              network: TradeHubSDK.parseNetwork(parsedNetwork),
            });

            if (coreState.storedMnemonics) {
              sdk = (yield call([sdk, sdk.connectWithMnemonic], coreState.storedMnemonics)) as TradeHubSDK
            } else if (coreState.sdk?.wallet) {
              sdk = (yield call([sdk, sdk.connect], coreState.sdk?.wallet)) as TradeHubSDK
            }

            yield call([sdk, sdk.initialize]);

            yield put(actions.Core.updateSDK(sdk));
          });
        }
      } catch (error) {
        console.error(error)
      } finally {
        yield race({
          sdkUpdated: take(actions.Core.ActionType.CORE_UPDATE_SDK),
        })
      }
    }
  }

  private *fetchValidators(): any {
    yield runSagaTask(TaskNames.App.Validators, function* () {
      const sdk = yield* getInitializedSDK()
      const validators = (yield sdk.api.getAllValidators()) as RestModels.Validator[]
      const delegatorsMap: SimpleMap<RestModels.ValidatorDelegation[]> = {}
      const validatorsMap: SimpleMap<RestModels.Validator> = {}
      const consensusMap: any = {}
      const operatorsMap: any = {}
      let totalBonded: BigNumber = new BigNumber(0)

      for (const validator of validators) {
        if (validator.BondStatus === 'bonded') {
          totalBonded = totalBonded.plus(new BigNumber(validator.Tokens))
        }
        validatorsMap[validator.OperatorAddress] = validator
        consensusMap[validator.ConsAddress] = {
          moniker: validator.Description.moniker,
          operatorAddress: validator.OperatorAddress,
        }
        operatorsMap[validator.OperatorAddress] = validator.ConsAddress
      }
      const delegatorsList = (yield all(validators.map((val: RestModels.Validator) => {
        return call([
          sdk.api,
          sdk.api.getValidatorDelegations,
        ], { validator: val.OperatorAddress })
      }))) as RestTypes.CosmosResponse<RestModels.ValidatorDelegation[]>[]
      validators.forEach((val, index) => {
        delegatorsMap[val.OperatorAddress] = delegatorsList[index].result
      })
      yield put(setDelegators(delegatorsMap))
      yield put(setValidators(validatorsMap))
      yield put(setConsensus(consensusMap))
      yield put(setOperators(operatorsMap))
      yield put(setTotalBonded(totalBonded))
    })
  }

  private *fetchMarkets(): any {
    yield runSagaTask(TaskNames.App.Markets, function* () {
      const sdk = yield* getInitializedSDK()

      const markets = (yield sdk.api.getMarkets()) as RestModels.Market[]
      const marketMap = markets.reduce((result, market) => {
        result[market.name] = market
        return result
      }, {} as SimpleMap<RestModels.Market>)
      yield put(setMarkets(marketMap))
    });
  }

  private *fetchSigningInfo(): any {
    yield runSagaTask(TaskNames.App.SigningInfo, function* () {
      const sdk = yield* getInitializedSDK()

      const info = (yield sdk.api.getSlashingSigningInfos()) as RestTypes.GetSlashingSigningInfoResponse

      const output = {} as SimpleMap<RestModels.SlashingSigningInfo>
      info.result.forEach((item: RestModels.SlashingSigningInfo) => {
        output[item.address] = item
      })
      yield put(setSigningInfo(output))
    });
  }
  private *fetchStakingPool(): any {
    yield runSagaTask(TaskNames.App.StakingPool, function* () {
      const sdk = yield* getInitializedSDK()

      const pool = (yield sdk.api.getStakingPool()) as RestTypes.GetStakingPoolResponse
      const totalStaked = bnOrZero(pool.result.bonded_tokens)
        .plus(bnOrZero(pool.result.not_bonded_tokens))
        .shiftedBy(-Constants.Decimals.SWTH)

      yield put(setTotalStaked(totalStaked))
    });
  }

  private *fetchCoinGecko(): any {
    const geckoPriceUuid = uuidv4()

    yield put(actions.Layout.addBackgroundLoading(TaskNames.App.GeckoCoin, geckoPriceUuid))
    try {
      const results = (yield call(queryGecko)) as any
      yield put(setGeckoPrices(results))
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      yield put(actions.Layout.removeBackgroundLoading(geckoPriceUuid))
    }
  }

  private *fetchNeo(): any {
    const neoUuid = uuidv4()

    yield put(actions.Layout.addBackgroundLoading(
      TaskNames.App.NeoPrice,
      neoUuid,
    ))
    try {
      const results = (yield call(getNeoPrice)) as any
      yield put(setNeoPrice(results.neo))
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      yield put(actions.Layout.removeBackgroundLoading(neoUuid))
    }
  }

  private *fetchOracles(): any {
    yield runSagaTask(TaskNames.App.Oracles, function* () {
      const sdk = yield* getInitializedSDK()

      const oracles = (yield sdk.api.getOracleResults()) as SimpleMap<RestModels.Oracle>
      yield put(setOracles(oracles))
    });
  }

  private *fetchTokensInfo(): any {
    yield runSagaTask(TaskNames.Tokens.Info, function* () {
      const sdk = yield* getInitializedSDK()
      const allTokens = {
        ...sdk.token.tokens,
        ...sdk.token.poolTokens,
      }

      yield put(setTokensMap(allTokens))
    });
  }
  private *fetchTotalSupply(): any {
    yield runSagaTask(TaskNames.App.TotalSupply, function* () {
      const sdk = yield* getInitializedSDK()

      const totalSupply = (yield call([sdk.api, sdk.api.getSWTHSupply])) as BigNumber
      yield put(setTotalSupply(totalSupply.shiftedBy(-Constants.Decimals.SWTH)))
    });
  }

  private *pollBlockHeight(): any {
    while (true) {
      try {
        yield runSagaTask(TaskNames.App.LatestBlock, function* () {
          const sdk = yield* getInitializedSDK();

          const block = (yield call([sdk.api, sdk.api.getLatestBlock])) as RestModels.CosmosBlock
          yield put(setLatestBlock(block))
        });
      } finally {
        yield delay(10000)
      }
    }
  }

  private *fetchTokens(): any {
    yield runSagaTask(TaskNames.Tokens.Info, function* () {
      const sdk = yield* getInitializedSDK()

      const tokens = List([
        ...Object.values(sdk.token.tokens),
        ...Object.values(sdk.token.poolTokens),
      ]);
      yield put(setTokensInfo(tokens))
    });
  }
}
