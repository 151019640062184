import { Button, ButtonProps, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { ReactComponent as RightArrowSVG } from 'assets/ButtonLabel_Light.svg'
import clsx from 'clsx'
import React, { Fragment, ReactElement } from 'react'

interface Props extends ButtonProps {
  loading?: boolean
  hideArrow?: boolean
}

const StatefulButton: React.FC<Props> = (props: Props): ReactElement<Props> => {
  const { className, loading, hideArrow, children, ...rest } = props
  let { disabled } = props
  const classes = useStyles(props)

  if (disabled === undefined && loading) {
    disabled = true
  }

  const rootClasses = [
    classes.root,
    className,
    {
      [classes.loading]: loading,
    },
  ]

  return (
    <Button {...rest} disabled={disabled} className={clsx(rootClasses)}>
      {!loading && (
        <Fragment>
          {children}
          {!hideArrow && <RightArrowSVG className={classes.icon} />}
        </Fragment>
      )}
      {!!loading && (
        <CircularProgress size='1em' className={classes.progressIcon} />
      )}
    </Button>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '12.5rem',
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: theme.spacing(1.5),
    "&:hover $icon, &:focus $icon": {
      transform: "rotate(-45deg)",
    },
  },
  icon: {
    marginLeft: theme.spacing(2),
    transition: "transform .05s ease-in-out",
    transform: "rotate(0deg)",
    height: "1.375rem",
    width: "1.375rem",
    [theme.breakpoints.down("md")]: {
      height: "1rem",
      width: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(0)",
    },
  },
  loading: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  progressIcon: {
    color: theme.palette.common.white,
  },
}))

export default StatefulButton
