import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import { ReactComponent as LinkIcon } from 'assets/HorizontalArrow.svg'
import { CellLink, TooltipHint } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useAssetSymbol, useRedux } from 'js/hooks'
import { returnUrlNet } from 'js/models'
import { switcheo } from 'js/theme/palettes/colors'
import { bnOrZero, scrollToView, SimpleMap } from 'js/utils'
import React, { ReactElement, useMemo } from 'react'
import { HashLink } from 'react-router-hash-link'
import { RestModels } from 'tradehub-api-js'

export interface LiquidationADLRowProps {
  order: RestModels.Order;
  markets: SimpleMap<RestModels.Market>
}

const LiquidationADLRow: React.FunctionComponent<LiquidationADLRowProps> = ({
  order,
  markets,
}: LiquidationADLRowProps): ReactElement<LiquidationADLRowProps> => {
  const {
    address,
    username,
    market: marketName,
    initiator,
    side,
    quantity,
    price,
    block_height,
    order_id,
  } = order
  const assetSymbol = useAssetSymbol({
    WBTC: 'BTC'
  })
  const classes = useStyles();
  const network = useRedux(state => returnUrlNet(state.app.network))
  const market = markets[marketName]
  const quantityBN = bnOrZero(quantity)
  const priceBN = bnOrZero(price)
  const lotDp = bnOrZero(market?.lot_size).dp()
  const tickDp = bnOrZero(market?.tick_size).dp()

  const positionSide = useMemo(() => side === 'buy' ? 'Short' : 'Long', [side])

  const eventName = useMemo(() => {
    switch (initiator) {
      case 'system_liquidate': return `Liquidate ${positionSide} Position`
      case 'system_adl': return `ADL ${positionSide} Position`
      case 'system_derisk': return `Derisk ${positionSide} Position`
      default: return `${initiator.replace(/^system_/i, '')} ${positionSide} Position`
    }
  }, [initiator, positionSide])

  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/order/${order_id}`}>{shorten(order_id)}</CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/account/${address}`}>
          {username === '' ? shorten(address) : username}
        </CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/market/${marketName}`}>{market.display_name}</CellLink>
      </TableCell>
      <TableCell>
        {eventName}
        <TooltipHint title="See account positions" placement="left">
          <HashLink
            scroll={(el) => scrollToView(el)}
            to={`/account/${address}?net=${network}#position-history`}
            style={{ color: switcheo.primary[500] }}
          >
            <LinkIcon className={classes.icon} />
          </HashLink>
        </TooltipHint>
      </TableCell>
      <TableCell align="right">{quantityBN.toFormat(lotDp)} {assetSymbol(market.base)}</TableCell>
      <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
      <TableCell align="right">
        <CellLink to={`/block/${block_height}`}>{block_height}</CellLink>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    maxWidth: theme.spacing(2),
    height: theme.spacing(2),
    verticalAlign: "bottom",
    opacity: 0.5,
    transition: "transform .05s ease-in-out",
    transform: "rotate(0deg)",
    "&:hover, &:focus": {
      transform: "rotate(-45deg)",
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(0)",
    },
  },
}))

export default LiquidationADLRow
