import { List } from 'immutable'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import {
  call,
  Effect,
  put,

  spawn,
  takeLatest
} from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setTokensInfo, setTotalBalances } from '../actions/tokens'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Positions extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchTotalBalances],
      [this, this.fetchTokens],
      [this, this.watchSetNetwork],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchTotalBalances(): any {
    yield runSagaTask(TaskNames.Tokens.CirculateSupply, function* () {
      const sdk = yield* getInitializedSDK()

      const balances = (yield call([sdk.api, sdk.api.getTotalBalances])) as RestModels.Balance[]
      yield put(setTotalBalances(balances))
    });
  }

  private *fetchTokens(): any {
    yield runSagaTask(TaskNames.Tokens.Info, function* () {
      const sdk = yield* getInitializedSDK()

      const tokens = List([
        ...Object.values(sdk.token.tokens),
        ...Object.values(sdk.token.poolTokens),
      ]);
      yield put(setTokensInfo(tokens))
    });
  }
}
