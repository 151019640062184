import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CellLink, DataLoadSegment, DatetimeCell, ListTable, Section, StandardBtn, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { RestModels } from 'tradehub-api-js'

interface Props {
  blocks: RestModels.Block[]
  consensusMap: any
}

const Blocks: React.FunctionComponent<Props> = ({
  blocks,
  consensusMap,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.Dashboard.Blocks)
  // Use TaskNames.App.Validators because consensusMap was created inside
  // TaskNames.App.Validators task

  return (
    <Section className={classes.container} title="Latest Blocks">
      <DataLoadSegment loading={loading && !blocks}>
        <ListTable className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell className={classes.wideTitle}>Proposer</TableCell>
              <TableCell align="right">Txs</TableCell>
              <TableCell align="right" className={classes.wideTitle}>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TransitionGroup
              enter
              component={null}
              appear={false}
              exit={false}
            >
              {blocks.map(renderBlock)}
            </TransitionGroup>
          </TableBody>
        </ListTable>
        {!!blocks.length && (
          <StandardBtn
            className={classes.button}
            color="primary"
            to="/blocks"
            variant="contained"
          >
            View More
          </StandardBtn>
        )}
        {!blocks.length && (
          <TableEmptyState itemName="blocks" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderBlock(block: RestModels.Block, index: number): ReactNode {
    const { block_height: blockHeight, time, count, proposer_address: proposerAddress } = block
    const operator = consensusMap[proposerAddress]
    return (
      <CSSTransition key={index} timeout={400}>
        <TableRow hover>
          <TableCell>
            <CellLink to={`/block/${blockHeight}`}>{blockHeight}</CellLink>
          </TableCell>
          <TableCell>
            {!operator ? (
              proposerAddress
            ) : (
                <CellLink to={`/validator/${operator.operatorAddress}`}>
                  {operator.moniker}
                </CellLink>
              )}
          </TableCell>
          <TableCell align="right">{count}</TableCell>
          <DatetimeCell data={time} />
        </TableRow>
      </CSSTransition>
    )
  }
}

const useStyles: any = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontSize: '1.375rem',
    fontWeight: 700,
    paddingBottom: theme.spacing(2),
  },
  row: {
    height: '3.3125rem',
    '&.enter': {
      opacity: 0.1,
    },
    '&.enter-active': {
      opacity: 1,
      transition: 'opacity 400ms ease-in',
    },
  },
  wideTitle: {
    minWidth: '10.625rem',
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: 'auto',
    width: '12.5rem',
  },
}))

export default Blocks
