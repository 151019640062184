import { Typography } from '@material-ui/core'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import { TransactionDetail, TransactMessages } from './components'

interface Props {
}

const Transaction: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.Transaction.Log)
  const { transaction, log } = useRedux((state) => state.transaction)

  return (
    <Page title="Transaction Details">
      <DataLoadSegment loading={loading}>
        {transaction?.hash && !!log && (
          <Fragment>
            <TransactionDetail transaction={transaction} />
            <TransactMessages msgs={transaction?.msgs} log={log} />
          </Fragment>
        )}
        {!transaction?.hash && (
          <NotFoundState title="No Transaction Found">
            <Typography variant="body1">
              We can’t find any transaction with this hash. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Transactions}>
                Transactions
              </CellLink>
              &nbsp;page to view existing transactions.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

export default Transaction
