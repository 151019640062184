import { TextProposalFormState } from "js/models"
import store from "js/store"
import { escapeHtmlGo } from "js/utils"
import { RPCParams } from "tradehub-api-js"

export function textProposalValue(): RPCParams.Proposal {
  const formState = store.getState().governance.submitProposalFormState as TextProposalFormState

  const { title, description } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
  }

  return value
}
