import { Record, RecordOf } from 'immutable'
import { PositionActon, PositionActionType } from 'js/actions/position'
import { RestModels } from 'tradehub-api-js'

export interface PositionProps {
  position?: RestModels.Position
}

const makeState: Record.Factory<PositionProps> = Record<PositionProps>({
  position: undefined,
})
export type PositionState = RecordOf<PositionProps>

export default function positionReducer(
  state: PositionState = makeState(),
  action: PositionActon,
  ): PositionState {
  switch (action.type) {
    case PositionActionType.CLEAR:
      return makeState()
    case PositionActionType.SET_POSITION:
      return state.updateIn(
        ['position'],
        (): RestModels.Position => {
          return action.position
        },
      )
    default:
      return state
  }
}
