import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import CellLink from 'js/components/CellLink'
import { Paths } from 'js/constants'
import React, { ReactElement } from 'react'
import { ReactComponent as ArtworkSvg } from './art-deposit.svg'

type Props = BoxProps

const Step2: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ArtworkSvg className={classes.artwork} />
      <Typography variant='body1' paragraph>
        Proposals require <strong> a minimum of
        10,000 (not voting power) deposited SWTH </strong> within 2 days of submissions to
        proceed for voting.
      </Typography>
      <Typography variant='body1' paragraph>
        Deposits of proposals that are passed will be returned.
        Deposits of rejected proposals will be returned or burnt depending
        on a few factors like quorum, threshold and the % of the “NoWithVeto” vote.
        Learn more about the potential risks <CellLink
          underline='hover'
          href={Paths.Resources.CreateAndSubmitProposals}
        >here
        </CellLink>.
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  artwork: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '15rem',
    width: '100%',
  },
}))

export default Step2
