import { CssBaseline, ThemeProvider } from '@material-ui/core'
import RequestSigningModal from 'js/components/RequestSigningModal'
import { MuiTheme } from 'js/constants'
import { useRedux } from 'js/hooks'
import createTheme, { ThemeKey } from 'js/theme/createTheme'
import React, { ReactElement, useEffect, useState } from 'react'
import Body from './Body'
import Footer from './Footer'
import Header from './Header'
import Tutorials from './Tutorials'

interface Props { }

const App: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { mode, network } = useRedux((state) => state.app)
  const [theme, setTheme] = useState(createTheme(MuiTheme.Mode.Bright))
  useEffect(() => {
    const newTheme = createTheme(mode as ThemeKey)
    setTheme(newTheme)
  }, [mode])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header network={network} />
      <Body />
      <Footer />
      <Tutorials />
      <RequestSigningModal />
    </ThemeProvider>
  )
}

export default App
