import { UpdateMarketProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { RestModels, RPCParams } from "tradehub-api-js"

export function updateMarketProposalValue(): RPCParams.UpdateMarketProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as UpdateMarketProposalFormState
  const wallet = state.core.sdk?.wallet
  const tokens = state.tokens.info
  const markets = state.app.markets

  const { title, description, name, displayName, marketDescription,
    minQuantity, riskStepSize, initialMarginBase,
    initialMarginStep, maintenanceMarginRatio, maxLiquidationOrderTicket,
    maxLiquidationOrderDuration, impactSize, markPriceBand, lastPriceProtectedBand,
    takerFee, makerFee } = formState

  const market = markets[name]

  const base = market.base
  const baseTokenDecimals = parseNumber(tokens.find((token: RestModels.Token) => token.denom === base)?.decimals)!

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    market: {
      name,
      display_name: displayName,
      description: marketDescription,
      min_quantity: parseNumber(minQuantity, BIG_ZERO)!.shiftedBy(baseTokenDecimals.toNumber()).decimalPlaces(0).toString(10),
      maker_fee: parseNumber(makerFee, BIG_ZERO)!.shiftedBy(-2).toFixed(18),
      taker_fee: parseNumber(takerFee, BIG_ZERO)!.shiftedBy(-2).toFixed(18),

      risk_step_size: parseNumber(riskStepSize, BIG_ZERO)!.times(100).toString(),
      initial_margin_base: parseNumber(initialMarginBase, BIG_ZERO)!.toFixed(18),
      initial_margin_step: parseNumber(initialMarginStep, BIG_ZERO)!.toFixed(18),
      maintenance_margin_ratio: parseNumber(maintenanceMarginRatio, BIG_ZERO)!.toFixed(18),
      max_liquidation_order_ticket: parseNumber(maxLiquidationOrderTicket, BIG_ZERO)!.toString(),
      max_liquidation_order_duration: parseNumber(maxLiquidationOrderDuration, BIG_ZERO)!.toString(),
      impact_size: parseNumber(impactSize, BIG_ZERO)!.toString(),
      mark_price_band: parseNumber(markPriceBand, BIG_ZERO)!.toString(),
      last_price_protected_band: parseNumber(lastPriceProtectedBand, BIG_ZERO)!.toString(),
      is_active: true,

      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
