import { TableBody, TableHead, TableRow } from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import { DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { FullTokenBalance } from 'js/models/Balance'
import React, { ReactElement, useMemo } from 'react'
import { RestModels } from 'tradehub-api-js'
import { bnOrZero } from 'js/utils'
import HoldingsRow from './HoldingsRow'

interface Props {
  className?: string
  balance: RestModels.Balance[]
  delegations: any
  stakedMap: Record<string, BigNumber>
  loader?: boolean
}

const Holdings: React.FunctionComponent<Props> = ({
  className,
  balance,
  delegations,
  stakedMap,
  loader = false
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.Account.Holdings)

  const stakedSWTH = useMemo(() => delegations.reduce((acc: BigNumber, del: any) => acc.plus(del.balance.amount), new BigNumber(0)), [delegations]);

  const headerCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: 'Tokens',
    },
    {
      content: 'Total',
      bold: true,
    },
    {
      content: 'Available',
    },
    {
      content: 'Order',
    },
    {
      content: 'Position',
    },
    {
      content: 'Staked',
    },
  ]

  const finalBalance = useMemo(() => {
    if (loading) return [];
    return [...balance.map((bal) => {
      const { available, order, position, denom } = bal;
      const availableBN = bnOrZero(available)
      const orderBN = bnOrZero(order)
      const positionBN = bnOrZero(position)
      let totalBN: BigNumber = availableBN.plus(orderBN).plus(positionBN)
      let stakedAmount = bnOrZero(0)
      if (denom === 'swth') {
        stakedAmount = stakedSWTH.shiftedBy(-8)
        totalBN = totalBN.plus(stakedAmount)
      }
      if (stakedMap[denom]) {
        const stakedBN = new BigNumber(stakedMap[denom])
        totalBN = totalBN.plus(stakedBN)
        stakedAmount = stakedAmount.plus(stakedBN)
      }
      return {
        total: totalBN,
        available: availableBN,
        order: orderBN,
        position: positionBN,
        staked: stakedAmount,
        denom
      };
    })]
  }, [balance, stakedMap, loading, stakedSWTH]);

  return (
    <Section title="Wallet Balance">
      <DataLoadSegment
        loading={loading || loader}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              {headerCells.map((cell: any, index: number) => (
                <TableSectionCell key={index} {...cell} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalBalance?.filter((o: FullTokenBalance) => o.total.isGreaterThan(0))
              .sort((a: FullTokenBalance, b: FullTokenBalance) => Number(b.total.toString()) - Number(a.total.toString()))
              .map((balance: FullTokenBalance, index: number) => (
                <HoldingsRow
                  key={index}
                  {...balance}
                />
              ))}
          </TableBody>
        </ListTable>
        {!balance?.length && (
          <TableEmptyState itemName="balance records" />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default Holdings
