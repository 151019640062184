import {
  Box,
  BoxProps,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import { FooterLinksBox, SocialLink } from './components'
import { ecosystemLinks, foundationLinks, resourcesLinks, socialBtns } from './footerConfig'
import { SvgSwitcheoLogo } from './icons'

const Footer: React.FunctionComponent<BoxProps> = (props: BoxProps): ReactElement<BoxProps> => {
  const { className, ...rest } = props

  const classes = useStyles(props)

  const gridSizes = {
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
  }

  return (
    <Box component="footer" {...rest} className={clsx(className, classes.root)}>
      <Container maxWidth="lg">
        <Grid container spacing={3} className={classes.container}>
          <FooterLinksBox
            gridSizes={gridSizes}
            title="Foundation"
            models={foundationLinks}
          />
          <FooterLinksBox
            gridSizes={gridSizes}
            title="Ecosystem"
            models={ecosystemLinks}
          />
          <FooterLinksBox
            gridSizes={gridSizes}
            title="Resources"
            models={resourcesLinks}
          />
          <Hidden smDown>
            <Grid item md={2} lg={2} />
          </Hidden>
          <Grid
            className={clsx(classes.mediumGrid, classes.rightContainer)}
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
          >
            <Typography variant="h3">Follow Us</Typography>
            <Box className={clsx(classes.linkContainer, classes.socialsBox)}>
              {socialBtns.map((btn, index) => (
                <SocialLink
                  key={index}
                  href={btn.href}
                  icon={btn.icon}
                  label={btn.label}
                />
              ))}
            </Box>
            <SvgSwitcheoLogo className={classes.brandLogo} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: switcheo.mono[300],
    // padding workaround for overflowing margin
    // that causes footer to be 1rem above where
    // it should be
    paddingTop: '1px',
    minHeight: '20rem',
  },
  container: {
    padding: theme.spacing(3, 0),
    paddingBottom: theme.spacing(6),
    '& h3': {
      padding: theme.spacing(0.5, 0),
      borderTop: `1px solid ${switcheo.primary[300]}`,
    },
    '& .MuiGrid-item:not(:first-child)': {
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(8),
      },
    },
  },
  linkContainer: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  rightContainer: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  brandLogo: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(1.75),
    width: '10rem',
    [theme.breakpoints.down('xs')]: {
      position: 'initial',
      alignSelf: 'center',
    },
  },
  mediumGrid: {
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  socialsBox: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(8),
      textAlign: 'center',
    },
  },
  subscriptionTextField: {
    marginTop: theme.spacing(2),
  },
}))

export default Footer
