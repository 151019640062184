import { Box, BoxProps, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import { setIsSpamProposalsFiltered } from 'js/actions/governance'
import { DataLoadSegment, Page, Section, StandardBtn, TableEmptyState } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber, useWallet } from 'js/hooks'
import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { RestModels } from 'tradehub-api-js'
import { CheckboxFilter, SectionTable } from './components'

interface Props extends BoxProps { }

// const escapeRegExp = (str: string) => str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
// const mapFriendlyName = (name: string) => escapeRegExp(name)?.replace(/\s+/g, '\\s*')
// const parameterFriendlyNames = Object.values(ProposalTypes.Parameters).map(mapFriendlyName)
// const PARAMS_REGEX = new RegExp(`(${parameterFriendlyNames.join('|')})`, 'i')

const Governance: React.FC<Props> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const proposals = useRedux((state) => state.governance.proposals)
  const wallet = useWallet()
  const isSpamProposalsFiltered = useRedux<boolean>((state) => state.governance.isSpamProposalsFiltered)
  const [loading] = useTaskSubscriber(TaskNames.Governance.Proposals)
  // const isMyProposals = useRedux<RootState, boolean>(
  //   (state) => state.governance.isMyProposals
  // )

  const makeFilter = (status: string | string[]) => {
    if (typeof status === 'string') {
      status = [status]
    }
    return (proposal: RestModels.GovProposal) => status.includes(proposal.proposal_status)
  }

  let votingProposals = proposals.filter(makeFilter('VotingPeriod'))
  let depositingProposals = proposals.filter(makeFilter('DepositPeriod'))
  let completedProposals = proposals.filter(makeFilter(['Passed', 'Rejected']))

  const filterSpam = (proposal: RestModels.GovProposal) => {
    return proposal.content.value.title?.length > 5
  }

  if (isSpamProposalsFiltered) {
    votingProposals = votingProposals.filter(filterSpam)
    depositingProposals = depositingProposals.filter(filterSpam)
    completedProposals = completedProposals.filter(filterSpam)
  }

  const handleChangeSpamFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsSpamProposalsFiltered(!isSpamProposalsFiltered))
  }

  // const handleChangeMyProposals = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch(setIsMyProposals(!isMyProposals))
  // }

  return (
    <Page title="TradeHub Improvement Proposals (TIP)">
      <Section variant="secondary" className={classes.descriptionContainer}>
        <Box>
          <Typography component="h2" variant="h1">
            Drive growth of the Switcheo TradeHub ecosystem
          </Typography>

          <Box marginTop={3} width={'0px'} minWidth="100%">
            <Typography variant="body1" className={classes.description}>
              Exchange ideas with the community via the forum and Discord on
              topics that will radically decentralize Switcheo TradeHub and
              improve the protocol. Take the useful ideas and submit governance
              proposals, vote on proposals, and support proposals that you
              resonate with by making deposits.
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
          flex={1}
        >
          <Tooltip arrow placement="top" title={wallet ? '' : "Login before submitting a proposal"}>
            <span>
              <StandardBtn
                className={classes.submitButton}
                to={wallet ? Paths.SubmitProposal : Paths.Login}
                variant="contained"
                color="primary"
              >
                Submit Proposal
              </StandardBtn>
            </span>
          </Tooltip>
        </Box>
      </Section>

      <DataLoadSegment
        loading={loading}
        itemClass={classes.loadingRoot}
      >
        {proposals.length > 0 && (
          <Box display='flex' justifyContent='flex-end' className={classes.space}>
            <div>
              {/* <CheckboxFilter
                checked={isMyProposals}
                label="My submitted proposals"
                onChange={handleChangeMyProposals}
              /> */}
              <CheckboxFilter
                checked={isSpamProposalsFiltered}
                label="Hide potential spam proposals"
                onChange={handleChangeSpamFilter}
              />
            </div>
          </Box>
        )}

        {proposals.length > 0 && (
          <Fragment>
            <SectionTable
              title="Proposals in Voting Period"
              models={votingProposals}
            />
            <SectionTable
              title="Proposals in Deposit Period"
              models={depositingProposals}
            />
            <SectionTable
              title="Completed Proposals"
              models={completedProposals}
            />
          </Fragment>
        )}

        {proposals.length === 0 && (
          <Section className={classes.emptySection}>
            <div className={classes.outerDiv}>
              <TableEmptyState boxClass={classes.box} itemName="proposals" />
              <div className={classes.submitDiv}>
                <StandardBtn
                  className={classes.emptySubmitBtn}
                  color="primary"
                  to={wallet ? Paths.SubmitProposal : Paths.Login}
                  variant="contained"
                >
                  Submit Proposal
                </StandardBtn>
              </div>
            </div>
          </Section>
        )}
      </DataLoadSegment>
    </Page >
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    margin: theme.spacing(0, 0, 4),
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: "0px !important",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  description: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
  },
  emptySection: {
    marginTop: theme.spacing(4),
  },
  emptySubmitBtn: {
    fontSize: '1rem',
    minWidth: '11rem',
  },
  outerDiv: {
    margin: theme.spacing(3, 0),
  },
  loadingRoot: {
    paddingTop: theme.spacing(3),
  },
  space: {
    margin: theme.spacing(2, 0),
  },
  submitButton: {
    fontSize: "1rem",
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  submitDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default Governance
