import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { useRedux } from "js/hooks"
import React from "react"
import { RestModels } from "tradehub-api-js"

interface Props extends SelectProps {
  defaultText?: string
}

const PoolSelectInput: React.FC<Props> = (props: Props) => {
  const { children, label, className, onChange, defaultText, ...rest } = props
  const classes = useStyles()
  const pools = useRedux((state) => state.pools.pools)

  const _defaultText = defaultText ?? "Select a Pool"

  const _onChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>, child: React.ReactNode) => {
    onChange?.(event, child)
  }

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Select
          displayEmpty
          {...rest}
          className={clsx(classes.select, className)}
          onChange={_onChange}
        >
          <MenuItem value="" disabled>{_defaultText}</MenuItem>
          {
            pools.map((pool: RestModels.LiquidityPool) =>
              <MenuItem key={pool.pool_id} value={pool.pool_id?.toString()}>
                {pool.name}
              </MenuItem>,
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  select: {

  },
}))

export default PoolSelectInput
