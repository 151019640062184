import { Box, BoxProps, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, ListTable, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props extends BoxProps {
  proposal: RestModels.GovProposal
}

interface VoteHeaderCell {
  align?: 'center'
    | 'inherit'
    | 'justify'
    | 'left'
    | 'right'
  content: string
}

const ValidatorVotes: React.FC<Props> = (props: Props) => {
  const { className, proposal, ...rest } = props
  const classes = useStyles(props)
  const votes = useRedux((state) => state.governance.voteTxs)
  const { delegatorsMap, validatorsMap } = useRedux((state) => state.app)

  const VoteHeaders: VoteHeaderCell[] = [{
    content: 'Height',
  }, {
    content: 'Validator',
  }, {
    content: 'Address',
  }, {
    align: 'right',
    content: 'Delegations',
  }, {
    content: 'Option',
  }, {
    content: 'Hash',
  }]

  const validatorWallet: { [key: string]: any } = {}
  Object.values(validatorsMap).forEach((validator: RestModels.Validator) => {
    if (validator?.WalletAddress) {
      validatorWallet[validator.WalletAddress] = {
        ...validator,
        delegators: delegatorsMap[validator.OperatorAddress]?.length ?? 0,
      }
    }
  })

  const validatorVotes = React.useMemo(() => {
    const validatorAddresses = Object.keys(validatorWallet)

    const validatorVotes: { [key: string]: any } = {}
    votes.forEach((voteValidator: RestModels.Txn) => {
      const msg = JSON.parse(voteValidator.msg)
      const newAddress = msg.voter
      const proposalId = msg.proposal_id
      if (newAddress
          && proposalId
          && validatorAddresses.includes(newAddress)
          && proposalId === parseInt(proposal.id)) {
        validatorVotes[newAddress] = {
          delegators: validatorWallet[newAddress]?.delegators,
          height: voteValidator.height,
          moniker: validatorWallet[newAddress]?.Description.moniker ?? '',
          address: newAddress,
          operatorAddress: validatorWallet[newAddress].OperatorAddress,
          option: JSON.parse(voteValidator.msg).option,
          txhash: voteValidator.hash,
        }
      }
    })
    return validatorVotes
  }, [proposal, validatorWallet, votes])

  const validatorSort = Object.values(validatorVotes).sort((voteA: any, voteB: any) => {
    const delegatorsA = parseInt(voteA.delegators ?? '0')
    const delegatorsB = parseInt(voteB.delegators ?? '0')
    return delegatorsB - delegatorsA
  })

  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.Governance.VoteHistory)

  return (
    <Box
      className={clsx(classes.root, className)}
      {...rest}
    >
      <DataLoadSegment loading={loading && !validatorSort}>
        {!!validatorSort && (
          <>
            <ListTable>
              <TableHead>
                <TableRow>
                  {VoteHeaders.map((header: VoteHeaderCell) => (
                    <TableCell
                      align={header.align ?? 'inherit'}
                      key={header.content}
                    >
                      {header.content}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {validatorSort.map((vote: any, index: number) => {
                  return (
                    <TableRow key={vote.txhash + index} hover>
                      <TableCell>
                        <CellLink to={`/block/${vote?.height}`}>
                          {vote?.height ?? '-'}
                        </CellLink>
                      </TableCell>
                      <TableCell className={classes.moniker}>
                        <CellLink to={`/validator/${vote.operatorAddress}`}>
                          {vote?.moniker ?? '-'}
                        </CellLink>
                      </TableCell>
                      <TableCell>
                        {vote?.address ?? '-'}
                      </TableCell>
                      <TableCell align="right">
                        {vote.delegators}
                      </TableCell>
                      <TableCell>
                        {vote.option ?? '-'}
                      </TableCell>
                      <TableCell>
                        <CellLink to={`/transaction/${vote.txhash}`}>
                          {vote.txhash}
                        </CellLink>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </ListTable>
          </>
        )}
        {validatorSort.length === 0 && (
          <TableEmptyState itemName="votes" />
        )}
      </DataLoadSegment>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  meIcon: {
    width: '0.75rem',
    height: '0.75rem',
    verticalAlign: 'sub',
    marginLeft: theme.spacing(2),
  },
  moniker: {
    lineHeight: '1.2rem',
    minWidth: '14rem',
  },
}))

export default ValidatorVotes
