import { Blockchain, TradeHubSDK } from "tradehub-api-js"

export const Paths = {
  Foundation: {
    About: "https://switcheo.network/",
    Press: "https://switcheo.network/press",
    Blog: "https://blog.switcheo.network/",
    Support: "https://support.switcheo.network/",
  },
  EcoSystem: {
    Spot: 'https://switcheo.exchange',
    Derivatives: 'https://app.dem.exchange/trade',
    Stake: 'https://app.dem.exchange/stake',
    Pool: 'https://app.dem.exchange/pools',
  },
  Resources: {
    API: 'https://docs.switcheo.org/',
    Github: 'https://github.com/switcheo',
    MediaKit: 'https://switcheo-assets.s3-ap-southeast-1.amazonaws.com/Switcheo_MediaKit_2020.zip',
    PrivacyPolicy: 'https://switcheo.network/privacy-policy',
    GovernanceGuide: 'https://forum.switcheo.foundation/topic/35/what-is-governance',
    TypesOfProposals: 'https://forum.switcheo.foundation/topic/36/types-of-proposals',
    HowToVote: 'https://forum.switcheo.foundation/topic/34/how-to-vote-for-proposals',
    CreateAndSubmitProposals: 'https://forum.switcheo.foundation/topic/33/how-to-create-and-submit-proposals',
  },
  Social: {
    Github: 'https://github.com/switcheo',
    Discord: 'https://discord.com/invite/SPh62Hf',
    Telegram: 'https://t.me/switcheo',
    Twitter: 'https://twitter.com/switcheonetwork',
    Reddit: 'https://www.reddit.com/r/Switcheo/',
    Medium: 'https://medium.com/switcheo',
    LinkedIn: 'https://www.linkedin.com/company/switcheonetwork/',
    Facebook: 'https://www.facebook.com/SwitcheoExchange/',
    Youtube:
      'https://www.youtube.com/channel/UCqZ9sxvw-0UoHzno4-d97oQ/playlists',
  },

  Home: '/',
  Dashboard: '/dashboard',
  Proposal: '/governance/proposal/:proposalId',
  SubmitProposal: '/governance/submit-proposal',
  Governance: '/governance',
  Account: '/account/:address',
  Blocks: '/blocks',
  Block: '/block/:height',
  Transaction: '/transaction/:hash',
  Transactions: '/transactions',
  Tokens: '/tokens',
  Token: '/token/:symbol',
  Markets: '/markets',
  Market: '/market/:market',
  Nodes: '/nodes',
  Order: '/order/:id',
  Position: '/position/:id',
  Events: '/events',
  Validators: '/validators',
  Validator: '/validator/:address',
  Deposit: '/deposit',
  Login: '/login',
  Search: '/search',
  Pools: '/pools',
  Leaderboard: '/leaderboard',
  LeaderboardHistory: '/leaderboard_history',
} as const

export const BlockChainPaths = {
  [TradeHubSDK.Network.MainNet]: {
    [Blockchain.Ethereum]: 'https://etherscan.io:path',
    [Blockchain.BinanceSmartChain]: 'https://bscscan.com:path',
    [Blockchain.Neo]: 'https://neotube.io:path',
    [Blockchain.Zilliqa]: 'https://viewblock.io/zilliqa:path',
  } as const,
  [TradeHubSDK.Network.DevNet]: {
    [Blockchain.Ethereum]: 'https://ropsten.etherscan.io:path',
    [Blockchain.BinanceSmartChain]: 'https://testnet.bscscan.com:path',
    [Blockchain.Neo]: 'https://testnet.neotube.io:path',
    [Blockchain.Zilliqa]: 'https://viewblock.io/zilliqa:path?network=testnet',
  } as const,
} as const

export const getBlockChain = (network: string, chainID: number) => {
  switch (network) {
    case 'MAINNET':
      return BlockChainMapMainNet[chainID]
    case 'TESTNET':
      return BlockChainMapTestNet[chainID]
    case 'DEVNET':
      return BlockChainMapDevNet[chainID]
    default:
      return undefined
  }
}

const BlockChainMapMainNet: { [string: number]: string } = {
  1: 'Btc',
  2: 'Eth',
  3: 'Ont',
  4: 'Neo',
  5: 'Switcheo',
  6: 'Bsc',
  7: 'Heco',
  8: 'OKex',
}

const BlockChainMapDevNet: { [string: number]: string } = {
  1: 'Btc',
  2: 'Eth',
  3: 'Ont',
  5: 'Neo(N2)',
  79: 'Bsc',
  7: 'Heco',
  200: 'OKex',
  106: 'Palette',
  11: 'NEO(N3)',
  110: 'Zil'
}

const BlockChainMapTestNet: { [string: number]: string } = {
  1: 'Btc',
  2: 'Eth',
  3: 'Ont',
  4: 'Neo',
  182: 'Switcheo',
  8: 'Cosmos',
  111: 'Zil'
}
