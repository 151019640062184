import { makeStyles, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels } from 'tradehub-api-js'
import { bnOrZero, BN_ZERO } from 'js/utils'

interface Props {
  className?: string
  delegations?: RestModels.ValidatorDelegation[]
  title: string
}

const Component: React.FunctionComponent<Props> = ({
  className,
  delegations,
  title,
}: Props): ReactElement<Props> => {
  let totalShares = BN_ZERO
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Validator.Delegations)

  delegations?.forEach((delegation) => {
    totalShares = totalShares.plus(new BigNumber(delegation.shares))
  })
  return (
    <Section title={title} titleClass={clsx(classes.title, className)}>
      <DataLoadSegment
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Share</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{delegations?.map(renderDelegator)}</TableBody>
        </ListTable>
        {!delegations?.length && (
          <TableEmptyState itemName="delegations" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderDelegator(delegation: RestModels.ValidatorDelegation): ReactNode {
    const delegatorAddress = delegation.delegator_address
    const amountBN = bnOrZero(delegation.balance.amount).shiftedBy(-8)
    const shareBN = bnOrZero(delegation.shares).div(totalShares).times(100)
    return (
      <TableRow key={delegatorAddress} hover>
        <TableCell>
          <CellLink to={`/account/${delegatorAddress}`}>
            {delegatorAddress}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={amountBN.toFormat(8)} denom={delegation.balance.denom} />
        <TableCell align="right">{shareBN.toFormat(2)}%</TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'left',
  },
}))

export default Component
