import { Box, makeStyles, Theme } from '@material-ui/core'
import { Timeline, TimelineContent, TimelineItem, TimelineSeparator } from '@material-ui/lab'
import Section from 'js/components/Section'
import { Constants } from 'js/constants'
import React from 'react'
import { RestModels } from 'tradehub-api-js'
import { ProcessDot } from '../components'

interface Props {
  proposal: RestModels.GovProposal
}

const ProposalHistory: React.FC<Props> = (props: Props) => {
  const { proposal } = props
  const classes = useStyles()

  const proposalStatus = proposal.proposal_status
  const submitTime = proposal.submit_time.format('YYYY-MM-DD HH:mm:ss')
  const depositEndTime = proposal.deposit_end_time.format('YYYY-MM-DD HH:mm:ss')
  const votingEndTime = proposal.voting_end_time?.format('YYYY-MM-DD HH:mm:ss')

  const isDepositPeriod =
    proposalStatus === Constants.Proposal.Status.DepositPeriod
  const isVotingPeriod =
    proposalStatus === Constants.Proposal.Status.VotingPeriod
  const isPassed = proposalStatus === Constants.Proposal.Status.Passed
  const isRejected = proposalStatus === Constants.Proposal.Status.Rejected

  const getDotStatus = () => {
    if (isPassed) {
      return 'success'
    } else if (isRejected) {
      return 'rejected'
    }
    return ''
  }

  return (
    <Section title="Proposal History">
      <Timeline align="left" className={classes.timeline}>
        <TimelineItem className={classes.timelineItem}>
          <TimelineSeparator>
            <ProcessDot status="" />
            <div className={classes.timelineConnector} />
          </TimelineSeparator>
          <TimelineContent>
            <Box className={classes.timelineContent}>
              <Box>Proposal Submitted</Box>
              <Box>{submitTime}</Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem className={classes.timelineItem}>
          <TimelineSeparator>
            <ProcessDot status={isDepositPeriod ? "completed" : ""} />
            {isDepositPeriod || <div className={classes.timelineConnector} />}
          </TimelineSeparator>
          <TimelineContent>
            <Box className={classes.timelineContent}>
              <Box>
                {proposalStatus === Constants.Proposal.Status.DepositPeriod
                  ? 'Deposit in progress until'
                  : 'End of deposit'}
              </Box>
              <Box>{depositEndTime}</Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        {(!isDepositPeriod || isVotingPeriod) && (
          <>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <span>
                  <ProcessDot status={isVotingPeriod ? "completed" : ""} />
                  {isVotingPeriod || (
                    <div className={classes.timelineConnector} />
                  )}
                </span>
              </TimelineSeparator>
              <TimelineContent>
                <Box className={classes.timelineContent}>
                  <Box>
                    {proposalStatus === Constants.Proposal.Status.VotingPeriod
                      ? 'Voting in progress until'
                      : 'End of voting'}
                  </Box>
                  <Box>{votingEndTime}</Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </>
        )}
        {(isPassed || isRejected) && (
          <>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <ProcessDot status={getDotStatus()} />
                {/* {isPassed || isRejected || (
                  <div className={classes.timelineConnector} />
                )} */}
              </TimelineSeparator>
              <TimelineContent>
                <Box className={classes.timelineContent}>
                  <Box>{proposalStatus}</Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </>
        )}
      </Timeline>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  timeline: {
    padding: 'unset',
  },
  timelineItem: {
    '&:before': {
      content: 'unset',
    },
    minHeight: '3.3rem',
  },
  timelineContent: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.75rem',
  },
  timelineConnector: {
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
    flexGrow: 1,
  },
  timelineDotTransparent: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    boxShadow: 'unset',
    border: 0,
  },
  nonCurrentProcessDot: {
    fontSize: '0.8rem',
  },
  CurrentProcessDot: {
    fontSize: '0.6rem',
  },
}))

export default ProposalHistory
