import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { Loader, PaperBox } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'

export interface StatsBox {
  asset: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  content: any
  footer: string
  isLoaded: boolean
  loadname?: string[]
  title: string
}

export const HomeStatsBox: React.FunctionComponent<StatsBox> = (
  props: StatsBox,
): ReactElement<StatsBox> => {
  const { asset: Asset, content, footer, isLoaded, loadname = [], title } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(...loadname)

  return (
    <Grid item xs={12} sm={6} md={3}>
      <PaperBox title={title}>
        <Loader isLoaded={!loading && isLoaded} c={classes.blocktime}>
          <Typography className={classes.loaderValue}>
            {content}
          </Typography>
        </Loader>
        <div className={classes.footer}>{footer}</div>
        <Asset className={classes.statsIcon} />
      </PaperBox>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  blocktime: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    borderTopWidth: 1,
  },
  footer: {
    alignSelf: 'flex-start',
    color: switcheo.default.primary,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  loaderValue: {
    width: '100%',
    borderTop: `1px solid ${switcheo.default.primary}`,
    borderBottom: `1px solid ${switcheo.default.primary}`,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1.5, 0),
    fontWeight: 700,
    fontSize: '2.25rem',
    color: switcheo.default.primary,
    textAlign: 'center',
  },
  statsIcon: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      height: '2rem',
      width: '2rem',
    },
  },
}))
