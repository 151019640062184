import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import basePalette from './base'
import { switcheo } from './colors'

export default {
  ...basePalette,
  type: 'light',
  text: {
    primary: switcheo.primary[500],
    secondary: switcheo.primary[400],
  },
  background: {
    paper: switcheo.mono[50],
    default: switcheo.default.white,
  },
  divider: switcheo.primary[300],
} as PaletteOptions
