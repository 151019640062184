import { Grid, makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
import { bnOrZero } from 'js/utils'
import { StatsSummaryBox } from '../components'

type Props = {
  blockTime: string,
  info: any
  totalShares: any,
  validators: RestModels.Validator[]
}

const ValidatorsSummary: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { blockTime, info, totalShares, validators } = props
  const classes = useStyles()
  const latestBlock = useRedux((state) => state.app.latestBlock)

  // Filter and get number of bonded validators
  const bondedValidators = validators.filter((each) => each?.BondStatus === 'bonded')
  const activeValidators = bnOrZero(bondedValidators.length || 0)

  // Get total number of validators
  const totalValidators = bnOrZero(validators.length || 0)

  // Calculate average block time
  const timeArray: any = blockTime.split(':')
  const hours = bnOrZero(timeArray[0]).times(60 * 60)
  const minutes = bnOrZero(timeArray[1]).times(60)
  const seconds = bnOrZero(timeArray[2])
  const blockTimeBN: BigNumber = hours.plus(minutes).plus(seconds)

  const infoArr: any = Object.values(info)
  infoArr.sort((aa: any, bb: any) => {
    const aHeight = bnOrZero(aa.start_height)
    const bHeight = bnOrZero(bb.start_height)
    if (bHeight.comparedTo(aHeight) === 1) {
      return 1
    } if (bHeight.comparedTo(aHeight) === -1) {
      return -1
    }
    return 0
  })
  const latestBlockBN = bnOrZero(latestBlock?.block?.header?.height || 0)

  return (
    <div className={classes.gridMargin}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Active Validators"
            loadCondition={!totalValidators.isNaN()}
            loadname={[
              TaskNames.App.Validators,
            ]}
          >
            {activeValidators.toFormat(0)}
            <span className={classes.smallText}> / {totalValidators.toFormat(0)}</span>
          </StatsSummaryBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Height"
            loadCondition={!latestBlockBN.isNaN()}
            loadname={[TaskNames.App.LatestBlock]}
          >
            {latestBlockBN.toFormat()}
          </StatsSummaryBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Total Stake"
            loadCondition={!totalShares.isNaN()}
            loadname={[
              TaskNames.App.Validators,
            ]}
          >
            {totalShares.toFormat(0)}
          </StatsSummaryBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Average Block Time"
            loadCondition={!blockTimeBN.isNaN()}
            loadname={[
              TaskNames.Validators.BlockTime,
            ]}
          >
            {blockTimeBN.toFixed(2)}s
          </StatsSummaryBox>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  gridMargin: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  smallText: {
    ...theme.typography.h2,
  },
}))

export default ValidatorsSummary
