import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { Loader } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'

interface Props {
  children: any
  className?: string
  filter?: boolean
  itemClass?: string
  loaderClass?: string
  loading?: boolean
  size?: number | string
  spinnerClass?: string
  tasknames?: string[] | string
}

const DataLoadSegment: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { children, className, filter = false, loaderClass, loading = false, tasknames, itemClass, size, spinnerClass } = props
  const [tasksLoading] = useTaskSubscriber(...(tasknames ?? []))
  const classes = useStyles()

  const _loading = loading || tasksLoading

  if (!filter) {
    return (
      <Fragment>
        {_loading && (
          <Grid container justify="center" className={clsx(classes.root, className)}>
            <Grid item xs={12} className={clsx(classes.gridItem, itemClass)}>
              <CircularProgress size={size} className={clsx(classes.spinner, spinnerClass)} />
            </Grid>
          </Grid>
        )}
        <Loader isLoaded={!_loading} className={clsx(classes.loader, loaderClass)}>
          {children}
        </Loader>
      </Fragment>
    )
  }

  const displayStyle = {
    [classes.block]: _loading,
    [classes.none]: !_loading,
  }
  return (
    <div className={classes.loadSegment}>
      <div className={clsx(classes.loaderAlt, loaderClass, displayStyle)}>
        <Grid container justify="center" className={clsx(classes.root, className)}>
          <Grid item xs={12} className={clsx(classes.gridItem, itemClass)}>
            <CircularProgress size={size} className={clsx(classes.spinner, spinnerClass)} />
          </Grid>
        </Grid>
      </div>
      <Loader isLoaded={!_loading} className={clsx(classes.loader, loaderClass)}>
        {children}
      </Loader>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  block: {
    display: 'block',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    display: 'block',
    textAlign: 'left',
  },
  loaderAlt: {
    position: 'absolute',
    top: theme.spacing(1),
    width: '100%',
  },
  loadSegment: {
    position: 'relative',
  },
  none: {
    display: 'none',
  },
  spinner: {
    margin: theme.spacing(3, 0),
  },
}))

export default DataLoadSegment
