import { InputAdornment, InputBaseProps } from '@material-ui/core'
import React from 'react'

export interface Module {
  name: string
  params: string[]
}
export const Modules: Module[] = [
  {
    name: 'auth',
    params: [
      "MaxMemoCharacters",
      "TxSigLimit",
      "TxSizeCostPerByte",
      "SigVerifyCostED25519",
      "SigVerifyCostSecp256k1",
    ],
  },
  {
    name: 'bank',
    params: [
      "sendenabled",
    ],
  },
  {
    name: 'gov',
    params: [
      "depositparams",
      "votingparams",
      "tallyparams",
    ],
  },
  {
    name: 'distribution',
    params: [
      "communitytax",
      "baseproposerreward",
      "bonusproposerreward",
      "withdrawaddrenabled",
    ],
  },
  {
    name: 'slashing',
    params: [
      "SignedBlocksWindow",
      "MinSignedPerWindow",
      "DowntimeJailDuration",
      "SlashFractionDoubleSign",
      "SlashFractionDowntime",
    ],
  },
  {
    name: 'staking',
    params: [
      "UnbondingTime",
      "MaxValidators",
      "KeyMaxEntries",
      "HistoricalEntries",
      "BondDenom",
    ],
  },
]

const decimalInputProps: InputBaseProps = {
  type: 'number',
}
const percentInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0, max: 100 },
  endAdornment: (
    <InputAdornment position="end">%</InputAdornment>
  ),
}
const unintInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0, step: 1 },
}
const coinInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0 },
  endAdornment: (
    <InputAdornment position="end">SWTH</InputAdornment>
  ),
}
const timeInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0 },
  endAdornment: (
    <InputAdornment position="end">hours</InputAdornment>
  ),
}

type ModuleParamsTypeObject = {
  parameter: string
  type: string
  example: string
  inputProps?: InputBaseProps
}
const ModuleParamsTypes: ModuleParamsTypeObject[] = [
  { parameter: 'MaxMemoCharacters', type: 'uint64', example: '256', inputProps: unintInputProps },
  { parameter: 'TxSigLimit', type: 'uint64', example: '7', inputProps: unintInputProps },
  { parameter: 'TxSizeCostPerByte', type: 'uint64', example: '10', inputProps: unintInputProps },
  { parameter: 'SigVerifyCostED25519', type: 'uint64', example: '590', inputProps: unintInputProps },
  { parameter: 'SigVerifyCostSecp256k1', type: 'uint64', example: '1000', inputProps: unintInputProps },
  { parameter: 'sendenabled', type: 'bool', example: 'true' },
  { parameter: 'depositparams-min_deposit', type: 'coin', example: '1.0000000', inputProps: coinInputProps },
  { parameter: 'depositparams-max_deposit_period', type: 'time', example: '48', inputProps: timeInputProps },
  { parameter: 'votingparams-voting_period', type: 'time', example: '48', inputProps: timeInputProps },
  { parameter: 'tallyparams-quorum', type: 'percent', example: '33.4%', inputProps: percentInputProps },
  { parameter: 'tallyparams-threshold', type: 'percent', example: '50%', inputProps: percentInputProps },
  { parameter: 'tallyparams-veto', type: 'percent', example: '33.4%', inputProps: percentInputProps },
  { parameter: 'communitytax', type: 'percent', example: '2%', inputProps: percentInputProps },
  // { parameter: 'baseproposerreward', type: 'percent', example: '1%', inputProps: percentInputProps },  // must add up
  // tslint:disable-next-line: max-line-length
  // { parameter: 'bonusproposerreward', type: 'percent', example: '4%', inputProps: percentInputProps }, // to less than 1
  { parameter: 'withdrawaddrenabled', type: 'bool', example: 'true' },
  { parameter: 'SignedBlocksWindow', type: 'int64', example: '100', inputProps: unintInputProps },
  { parameter: 'MinSignedPerWindow', type: 'number', example: '0.500000000000000000', inputProps: decimalInputProps },
  { parameter: 'DowntimeJailDuration', type: 'time', example: '0.166667', inputProps: timeInputProps },
  { parameter: 'SlashFractionDoubleSign', type: 'number', example: '0.050000000000000000', inputProps: decimalInputProps },
  { parameter: 'SlashFractionDowntime', type: 'number', example: '0.010000000000000000', inputProps: decimalInputProps },
  { parameter: 'UnbondingTime', type: 'time', example: '72', inputProps: timeInputProps },
  { parameter: 'MaxValidators', type: 'uint16', example: '100', inputProps: unintInputProps },
  { parameter: 'KeyMaxEntries', type: 'uint16', example: '7', inputProps: unintInputProps },
  { parameter: 'HistoricalEntries', type: 'uint16', example: '3', inputProps: unintInputProps },
  // { parameter: 'BondDenom', type: 'string', example: '"swth"' },
]

export const getParamType = (parameter: string) => {
  return ModuleParamsTypes.find((moduleParamType) => {
    return moduleParamType.parameter === parameter
  })
}
