import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { updateBlocksFilters } from 'js/actions/validator'
import { Blocks, CellLink, DataLoadSegment, NotFoundState, Page, Section, TabsButtons } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { bnOrZero } from 'js/utils'
import React, { ReactElement, useEffect, useState } from 'react'
import { CurrentPool, Degelations, ProfileAvatar, ProfileDetails } from './components'
import { items } from './validatorConfig'

interface Props {
}

const Component: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.App.SigningInfo, TaskNames.App.Validators, TaskNames.Validator.Detail)
  const { validator, delegations, blocks, selfbond } = useRedux((state) => state.validator)
  const { consensusMap, info }: { consensusMap: any, info: any } = useRedux((state) => state.app)
  const [tabId, setTabId] = useState(0)
  const [keybaseProfile, setKeybaseProfile] = useState({
    url: '',
    image: '',
  })
  const [imgLoading, setImgLoading] = useState(false)

  const delegatorShares = bnOrZero(validator?.DelegatorShares)
  const validatorProfile = {
    ...validator?.Description,
    operator_address: validator?.OperatorAddress,
    consensus_pubkey: validator?.ConsPubKey,
    consensus_address: validator?.ConsAddress,
    consensus_address_hex: validator?.ConsAddressByte,
    wallet_address: validator?.WalletAddress,
    tokens: bnOrZero(validator?.Tokens).toFormat(8),
    delegator_shares: delegatorShares.toFormat(8),
    delegators: delegations.length,
    self_bond: `${selfbond.toFormat(8)} (${selfbond
      .div(delegatorShares)
      .times(100)
      .toFormat(2)}%)`,
    jailed: validator?.Jailed,
    bond_status: validator?.BondStatus,
    unbonding_height: validator?.UnbondingHeight,
    unbonding_time: validator?.UnbondingCompletionTime,
    commission_rate: bnOrZero(validator?.Commission.commission_rates.rate).toNumber(),
    max_rate: bnOrZero(validator?.Commission.commission_rates.max_rate).toNumber(),
    max_change_rate: bnOrZero(validator?.Commission.commission_rates.max_change_rate).toNumber(),
    active_since: bnOrZero(info[validator?.ConsAddress ?? '']?.start_height).toNumber(),
    missed_blocks_count: bnOrZero(info[validator?.ConsAddress ?? '']?.missed_blocks_counter).toNumber(),
  }

  useEffect(() => {
    const identity = validator?.Description.identity
    if (identity?.length !== 40) return
    setImgLoading(true)

    fetch(
      `https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${identity}&fields=basics,pictures`,
    )
      .then((r: any) => (r ? r.json() : null))
      .then((json: any) => {
        if (json && json?.them && json?.them[0]) {
          const user = json?.them[0]
          const image = user?.pictures?.primary?.url
          const url = `https://keybase.io/${user?.basics?.username}`
          setKeybaseProfile({ url, image })
        }
      })
  }, [validator])

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue)
  }

  return (
    <Page title="Validator Details">
      <DataLoadSegment
        loading={loading}
        size="2.5rem"
      >
        {validator?.WalletAddress && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ProfileAvatar
                imgLoading={imgLoading}
                keybaseProfile={keybaseProfile}
                setImgLoading={setImgLoading}
                validator={validatorProfile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <ProfileDetails keybaseProfile={keybaseProfile} validator={validatorProfile} />
            </Grid>
            <Grid item xs={12}>
              <Section className={classes.tabsRoot}>
                <TabsButtons handleChange={handleChange} items={items} tabClass={classes.tab} value={tabId} />
                <div role="tabpanel" hidden={tabId !== 0}>
                  <CurrentPool validator={validatorProfile} />
                </div>
                <div role="tabpanel" hidden={tabId !== 1}>
                  <Degelations
                    title="Delegations"
                    delegations={delegations}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 2}>
                  <Blocks
                    action={updateBlocksFilters}
                    blocks={blocks}
                    className={classes.section}
                    consensusMap={consensusMap}
                    filter
                    loadname={[
                      TaskNames.Validator.Blocks,
                      TaskNames.Validator.BlocksFilter,
                    ]}
                    title="Blocks Proposed"
                    titleClass={classes.title}
                  />
                </div>
              </Section>
            </Grid>
          </Grid>
        )}
        {!validator?.WalletAddress && (
          <NotFoundState title="No Validator Found">
            <Typography variant="body1">
              We can’t find any validator with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Validators}>
                Validators
              </CellLink>
              &nbsp;page to view existing validators.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: theme.spacing(3),
  },
  tab: {
    fontSize: '1.125rem',
  },
  tabsRoot: {
    padding: 0,
  },
  title: {
    textAlign: 'left',
  },
}))

export default Component
