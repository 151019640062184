import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { updateFilters, updateOpenOrders, updateOrders, updatePositions, updatePositionsHistory, updateTrades } from 'js/actions/account'
import { DataLoadSegment, NotFoundState, Orders, Page, Positions, Section, TabsButtons, TradesByAccount, Transactions } from 'js/components'
import { TaskNames } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { bnOrZero } from 'js/utils'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Timeline } from 'react-twitter-widgets'
import { GetBlockHeightResponse, GetLeaderboardResponse, LeaderboardDataResponse, RestModels, TradeHubSDK } from 'tradehub-api-js'
import { Delegations, ExternalTransfers, Holdings, PnlTable, Profile, Rewards } from './components'


interface PNLItem {
  label: string
  unix: number
  prevUnix: number
}

interface Ranking {
  label: string
  currentRank: number | undefined
  lastRank: number | undefined
  rankChange: number
  pnl: number
}

interface Props {
}

const AccountComponent: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const {
    balance,
    transactions,
    profile,
    positions,
    positionsHistory,
    openOrders,
    orders,
    tradesByAccount,
    delegations,
    rewards,
    externalTransfers,
  } = useRedux((state) => state.account)
  const { validatorsMap, markets } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.sdk)
  const [getLiquidityPools, getLiquidityPoolsLoading] = useAsyncTask('getLiquidityPools')
  const [getRanking, getRankingLoading] = useAsyncTask('getRanking')
  const [pnlRank, setPnlRank] = useState<Ranking>()
  const [liquidityPools, setLiquidityPools] = useState<RestModels.LiquidityPool[]>([])
  const [stakedMap, setStakedMap] = useState<Record<string, BigNumber>>({})
  const [isSingleLpLoading, setSingleLpLoading] = useState(false)
  const [singleLpDetails, setSingleLpDetails] = useState<Record<string, string>>({})
  const classes = useStyles()
  const accountExists = dayjs(profile?.last_seen_time).unix() >= 0
  const [tabId, setTabId] = useState(0)
  const items = [
    { label: 'Holding' },
    { label: 'Stakings' },
    { label: 'Positions' },
    { label: 'Orders' },
    { label: 'Trades' },
    { label: 'External Transfers' },
    { label: 'Transactions' },
    { label: 'PNL Ranking' },
  ]

  const currentDateTime = dayjs()
  const timeline: PNLItem = {
    label: 'Last 24H',
    unix: currentDateTime.subtract(24, 'hour').unix(),
    prevUnix: currentDateTime.subtract(48, 'hour').unix(),
  }

  useEffect(() => {
    if (!profile || !sdk || getRankingLoading) return

    getRanking(async () => {
      const blockheight: GetBlockHeightResponse = await sdk.api.getBlockHeightfromUnix({ unix: timeline.unix })
      const prevBlockHeight: GetBlockHeightResponse = await sdk.api.getBlockHeightfromUnix({ unix: timeline.prevUnix })
      const result: GetLeaderboardResponse = await sdk.api.getLeaderboard({ from: Number(blockheight.height), limit: 100 }) as GetLeaderboardResponse
      const rank = result.data.findIndex((o: LeaderboardDataResponse) => o.address === profile?.address)
      const prevResult: GetLeaderboardResponse = await sdk.api.getLeaderboard({ from: Number(prevBlockHeight.height), to: Number(blockheight.height), limit: 100 }) as GetLeaderboardResponse
      const prevRank = prevResult.data.findIndex((o: LeaderboardDataResponse) => o.address === profile?.address)
      //find rank
      let currentRank = rank >= 0 ? rank + 1 : undefined
      let lastRank = prevRank >= 0 ? prevRank + 1 : undefined
      let rankChange = 0
      if (currentRank === undefined && lastRank === undefined) rankChange = 0
      else if (currentRank === undefined) rankChange = -1 * (result.data.length - Number(lastRank))
      else if (lastRank === undefined) rankChange = result.data.length - Number(currentRank)
      else rankChange = Number(lastRank) - Number(currentRank)
      // setPnlRank(ranks as Ranking)
      setPnlRank({ label: timeline.label, currentRank, lastRank, rankChange, pnl: result?.data[Number(currentRank) - 1]?.realized_pnl || 0 } as Ranking)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const balanceRemovedLpN = useMemo(() => {
    return Object.values(balance).filter((balance) => !TradeHubSDK.TokenClient.isPoolToken(balance.denom));
  }, [balance])

  const allLpTokenArrayN = useMemo(() => {
    return Object.values(balance).filter((balance) => TradeHubSDK.TokenClient.isPoolToken(balance.denom));
  }, [balance])

  const getStakedTokenInfo = useCallback(async (poolDenom: string) => {
    if (!sdk || !profile?.address) return
    const poolID = bnOrZero(poolDenom.split("-lp")[1]).toNumber()
    const result = await sdk.api.getStakedPoolTokenInfo({ account: profile.address, pool_id: poolID })
    return result.result.amount
  }, [profile, sdk])

  const lpDenoms = useMemo(() => [...allLpTokenArrayN.map((balance) => balance.denom)], [allLpTokenArrayN]);
  const lpDenomLength = lpDenoms.length
  useEffect(() => {
    if (!profile || isSingleLpLoading) return
    const getSingleLpDetails = async () => {
      if (!isSingleLpLoading && lpDenomLength > 0) {
        setSingleLpLoading(true)
        const promises = lpDenoms.map(getStakedTokenInfo)
        const responses = await Promise.allSettled(promises)
        const values = responses.map(res => {
          if (res.status === 'fulfilled') return res.value || "0"
          else return '0'
        });
        const singleLpDetailsMap = lpDenoms.reduce((acc, denom, index) => {
          return { ...acc, [denom]: values[index] }
        }, {})
        setSingleLpDetails(singleLpDetailsMap)
        setSingleLpLoading(false)
      }
    }
    getSingleLpDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDenomLength, lpDenoms, profile]);

  const finalLpDetails = useMemo(() => {
    const finalAllLpToken = new Map(); //
    allLpTokenArrayN.forEach((balance) => {
      const { denom, available } = balance;
      const entry = Object.entries(singleLpDetails).find(([key]) => key === denom);
      if (entry) {
        const [, singleLpValue] = entry;
        const singleLpValueBN = new BigNumber(singleLpValue)
        const availableBN = new BigNumber(available)
        finalAllLpToken.set(denom, { ...balance, available: availableBN.plus(singleLpValueBN) })
      }
    })
    return finalAllLpToken
  }, [allLpTokenArrayN, singleLpDetails])

  useEffect(() => {
    if (finalLpDetails?.size && liquidityPools?.length && Object.keys(stakedMap).length === 0) {
      let stakedMapTemp: Record<string, BigNumber> = {}
      finalLpDetails.forEach((lp) => {
        const tokenABIndex = liquidityPools?.findIndex((lps: any) => lps.denom === lp.denom)
        if (tokenABIndex > 0) {
          const tokenAB = liquidityPools[tokenABIndex]
          const denomA = tokenAB.denom_a
          const denomB = tokenAB.denom_b
          const tokenABAvailableBN = new BigNumber(lp.available)
          const poolSharesAmountBN = new BigNumber(tokenAB.shares_amount)
          const poolTokenAAmountBN = new BigNumber(tokenAB.amount_a)
          const poolTokenBAmountBN = new BigNumber(tokenAB.amount_b)
          const poolSharesBN = tokenABAvailableBN.div(poolSharesAmountBN)
          const tokenA = poolSharesBN.times(poolTokenAAmountBN)
          const tokenB = poolSharesBN.times(poolTokenBAmountBN)

          if (stakedMapTemp[denomA]) {
            stakedMapTemp[denomA] = stakedMapTemp[denomA].plus(tokenA)
          }
          else {
            stakedMapTemp[denomA] = tokenA
          }
          if (stakedMapTemp[denomB]) {
            stakedMapTemp[denomB] = stakedMapTemp[denomB].plus(tokenB)
          }
          else {
            stakedMapTemp[denomB] = tokenB
          }
        }
      })
      setStakedMap(stakedMapTemp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalLpDetails, liquidityPools])

  //get Liquidity
  useEffect(() => {
    getLiquidityPools(async () => {
      if (!sdk) return
      const pools = await sdk.api.getLiquidityPools()
      setLiquidityPools(pools)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue)
  }

  return (
    <Page title="Address Details">
      <DataLoadSegment
        tasknames={[TaskNames.Account.Profile]}
        size="2.5rem"
      >
        {accountExists && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={profile?.twitter ? 8 : 12}>
                  <Profile profile={profile} />
                </Grid>
                {
                  profile?.twitter && (
                    <Grid item xs={12} sm={profile?.twitter ? 4 : 12}>
                      <Timeline
                        dataSource={{
                          sourceType: 'profile',
                          screenName: `${profile?.twitter || `switcheonetwork`}`,
                        }}
                        options={{
                          height: '275',
                          width: '100%',
                        }}
                      />
                    </Grid>
                  )
                }
              </Grid>
              {/* <ProfileDetails keybaseProfile={keybaseProfile} validator={validatorProfile} /> */}
            </Grid>
            <Grid item xs={12}>
              <Section className={classes.tabsRoot}>
                <TabsButtons handleChange={handleChange} items={items} tabClass={classes.tab} value={tabId} />
                <div role="tabpanel" hidden={tabId !== 0}>
                  <Holdings
                    className={classes.holdingsContainer}
                    balance={balanceRemovedLpN}
                    delegations={delegations}
                    stakedMap={stakedMap}
                    loader={getLiquidityPoolsLoading || isSingleLpLoading}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 1}>
                  <Delegations
                    delegations={delegations}
                    validators={validatorsMap}
                    title="Stakings"
                  />
                  <Rewards
                    validatorsMap={validatorsMap}
                    title="Staking Rewards"
                    rewards={rewards}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 2}>
                  <Positions
                    title="Positions"
                    action={updatePositions}
                    positions={positions}
                    loadName={[
                      TaskNames.Account.Positions
                    ]}
                  />
                  <Positions
                    title="Positions History"
                    action={updatePositionsHistory}
                    positions={positionsHistory}
                    closedOnly={true}
                    loadName={[
                      TaskNames.Account.PositionsHistory
                    ]}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 3}>
                  <Orders
                    filterStatus={false}
                    orders={openOrders}
                    title="Open Orders"
                    action={updateOpenOrders}
                    markets={markets}
                    loadName={[
                      TaskNames.Account.OpenOrders,
                      TaskNames.Account.OpenOrdersFilter,
                    ]}
                    loadCondition={!openOrders}
                    sectionClass={classes.sectionClass}
                  />
                  <Orders
                    filterStatus
                    orders={orders}
                    title="Order History"
                    action={updateOrders}
                    markets={markets}
                    loadName={[
                      TaskNames.Account.OrderList,
                      TaskNames.Account.OrderFilter,
                    ]}
                    loadCondition={!orders}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 4}>
                  <TradesByAccount
                    title="Recent Trades"
                    tradesByAccount={tradesByAccount}
                    action={updateTrades}
                    markets={markets}
                    loadname={[
                      TaskNames.Account.TradesByAccount,
                      TaskNames.Account.TradesFilter,
                    ]}
                    loadCondition={!tradesByAccount}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 5}>
                  <ExternalTransfers sectionClass={classes.sectionClass} externalTransfers={externalTransfers} />
                </div>
                <div role="tabpanel" hidden={tabId !== 6}>
                  <Transactions
                    title="Transactions"
                    transactions={transactions}
                    action={updateFilters}
                    filterStatus
                    loadName={[
                      TaskNames.Account.Transactions,
                      TaskNames.Account.TransactionType,
                      TaskNames.Account.TransactionFilter,
                    ]}
                    sectionClass={classes.transactions}
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 7}>
                  <PnlTable
                    title="PNL Ranking"
                    rank={pnlRank}
                    loading={getRankingLoading}
                  />
                </div>
              </Section>
            </Grid>
          </Grid>
        )}
        {!accountExists && (
          <NotFoundState title="No Account Found">
            <Typography variant="body1">
              We can’t find any account with this address. Please check your network&nbsp;
              setting or try searching for another account.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: theme.spacing(3),
  },
  tab: {
    fontSize: '1.125rem',
  },
  tabsRoot: {
    padding: 0,
  },
  title: {
    textAlign: 'left',
  },
  container: {},
  // title: {
  //   // textTransform: 'uppercase',
  //   marginTop: '1rem',
  //   display: 'inline',
  // },
  gridContainer: {
    display: 'grid',
    gridGap: '1.2rem',
    gridTemplateAreas: `'holdings'`,
    marginTop: '1.2rem',
  },
  item: {
    padding: '1.5rem 2rem',
  },
  address: {
    marginLeft: '0.8rem',
  },
  addressContainer: {
    extend: 'item',
  },
  holdingsContainer: {
    extend: 'item',
    gridArea: 'holdings',
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  sectionClass: {
    marginBottom: theme.spacing(3),
  },
  transactions: {
    padding: theme.spacing(3),
  },
}))

export default AccountComponent
