import { makeStyles, TableBody, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { ListTable, Section } from 'js/components'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import { ProfileDetailRow } from '../components'

interface KeyBase {
  url: string
  image: string
}

interface Props {
  validator: any
  keybaseProfile: KeyBase
}

const ProfileDetails: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { keybaseProfile, validator } = props
  const classes = useStyles()

  const rows = [
    {
      href: keybaseProfile.url !== '' ? keybaseProfile.url : '',
      keyClass: classes.border,
      title: 'Identity',
      value: cellFormat(validator?.identity),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Security contact',
      value: cellFormat(validator?.securityContact),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      to: `/account/${validator?.wallet_address}`,
      title: 'Wallet address',
      value: cellFormat(validator?.wallet_address),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Operator address',
      value: cellFormat(validator?.operator_address),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Consensus pubkey',
      value: cellFormat(validator?.consensus_pubkey),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Consensus address',
      value: cellFormat(validator?.consensus_address),
      valueClass: classes.border,
    },
    {
      keyClass: clsx(classes.border, classes.endRow),
      title: 'Consensus address hex',
      value: cellFormat(validator?.consensus_address_hex),
      valueClass: clsx(classes.border, classes.endRow),
    }
  ]

  return (
    <Section className={classes.box}>
      <ListTable>
        <TableBody>
          {
            rows.map((row, id) =>
              <ProfileDetailRow key={id} {...row} />,
            )
          }
        </TableBody>
      </ListTable>
    </Section>
  )
}

const cellFormat = (value: any) => {
  if (value) {
    return value
  }
  return "-"
}

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderBottom: `1px solid ${theme.palette.primary.dark} !important`,
  },
  box: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
  },
  endRow: {
    "&.MuiTableCell-root": {
      borderBottom: '1px solid transparent',
    },
  },
  row: {
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: switcheo.hover,
      },
    },
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
  },
}))

export default ProfileDetails
