import {
  Avatar,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import { CellLink, ListTable, Section, TooltipHint } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Follow } from 'react-twitter-widgets'
import { RestModels } from 'tradehub-api-js'
import { copyTextToClipBoard, shorten } from 'js/helpers'
import { CopyTradeDarkSVG } from 'assets'

interface Props {
  className?: string
  profile?: RestModels.Profile
  validator?: RestModels.ValDescription
}

const Profile: React.FunctionComponent<Props> = ({
  profile,
  validator,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )
  const xsmallScreen = useMediaQuery('(max-width:375px)');
  const combined: any = { ...profile, ...validator }
  const [copied, setCopied] = useState(false);
  const [keybaseProfile, setKeybaseProfile] = useState({
    url: '',
    image: '',
  })
  const address: string = profile?.address ? profile.address : 'Invalid address'
  const cellRows = ['username', 'twitter', 'last_seen_block', 'last_seen_time']
  useEffect(() => {
    if (validator?.identity.length !== 40) return

    fetch(
      `https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${validator.identity}&fields=basics,pictures`,
    )
      .then((r: any) => (r ? r.json() : null))
      .then((json: any) => {
        if (json && json?.them && json?.them[0]) {
          const user = json?.them[0]
          const image = user?.pictures?.primary?.url
          const url = `https://keybase.io/${user?.basics?.username}`
          setKeybaseProfile({ url, image })
        }
      })
  }, [validator])

  const copyText = (text: string) => {
    copyTextToClipBoard(text)
    //set Copied to true to show Copied UI
    setCopied(true);
    setTimeout(() => setCopied(false), 1300)
  }

  return (
    <Section className={xsmallScreen ? classes.sectionXSmallScreen : classes.section}>
      <div className={classes.titleContainer}>
        <TooltipHint title={address}>
          <span className={classes.title}>
            {smallScreen ? shorten(address) : address}
          </span>
        </TooltipHint>
        <TooltipHint title={"Copied!"} open={copied}>
          <CopyTradeDarkSVG onClick={() => copyText(address)} fontSize={"small"} className={classes.iconButton} />
        </TooltipHint>
      </div>
      <div className={classes.profileContainer}>
        <div className={classes.profile}>
          <div className={classes.profileHeader}>
            {keybaseProfile.image !== '' && (
              <Avatar
                alt="Profile Image from Keybase"
                variant="square"
                className={classes.avatar}
                src={keybaseProfile.image}
              />
            )}
          </div>
          <ListTable>
            <TableBody>{cellRows.map(renderItem)}</TableBody>
          </ListTable>
        </div>
      </div>
    </Section>
  )

  function renderItem(key: any): ReactNode {
    if (combined[key] === '' && key !== 'twitter') return
    const label: string = (key.charAt(0).toUpperCase() + key.slice(1))
      .split('_')
      .join(' ')
    switch (label) {
      case 'Identity':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span>
                {keybaseProfile.url === '' ? (
                  combined[key]
                ) : (
                  <CellLink href={keybaseProfile.url}>{combined[key]}</CellLink>
                )}
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Twitter':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span className={classes.stringWithoutTwitter}>
                {!combined[key] ? "-" :
                  <>
                    <CellLink href={`https://twitter.com/${combined[key]}`}>
                      {combined[key]}
                    </CellLink>
                    <Follow username={`${combined[key]}`} />
                  </>
                }
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Website':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span>
                <CellLink href={`${combined[key]}`}>{combined[key]}</CellLink>
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Last seen block':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {combined[key] === '0' ? (
                <div className={classes.never}>never</div>
              ) : (
                <CellLink to={`/block/${combined[key]}`}>
                  <span>
                    {combined[key]}
                  </span>
                </CellLink>
              )}
            </TableCell>
          </TableRow>
        )
      case 'Last seen time':
        const time: Dayjs = dayjs(combined[key])
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span>
                {time.year() < 2019 ? (
                  <div className={classes.never}>never</div>
                ) : (
                  <React.Fragment>
                    {time.format('YYYY-MM-DD HH:mm:ss')}
                    <span className={classes.timeAgo}>({fromNow(time)})</span>
                  </React.Fragment>
                )}
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Jailed':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {combined[key] ? 'yes' : 'no'}
            </TableCell>
          </TableRow>
        )
      case 'Wallet address':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <CellLink to={`/account/${combined[key]}`}>
                {combined[key]}
              </CellLink>
            </TableCell>
          </TableRow>
        )
      case 'Commission rate':
      case 'Max rate':
      case 'Max change rate':
        const num: BigNumber = new BigNumber(combined[key]).times(100)
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>{num.toFormat(2)}%</TableCell>
          </TableRow>
        )
      case 'Active since':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {combined.bond_status === 'bonded' ? (
                <CellLink to={`/block/${combined[key]}`}>
                  {combined[key]}
                </CellLink>
              ) : (
                '-'
              )}
            </TableCell>
          </TableRow>
        )
      case 'Unbonding time':
        if (combined.bond_status === 'bonded') return
        const unbondTime: Dayjs = dayjs(combined[key])
        if (unbondTime.year() < 2019) return
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {unbondTime.format('YYYY-MM-DD HH:mm:ss')}
            </TableCell>
          </TableRow>
        )
      case 'Unbonding height':
        if (combined[key] === 0) return
        if (combined.bond_status === 'bonded') return
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <CellLink to={`/block/${combined[key]}`}>
                {combined[key]}
              </CellLink>
            </TableCell>
          </TableRow>
        )
      default:
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>{combined[key]}</TableCell>
          </TableRow>
        )
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    backgroundColor: theme.palette.secondary.main
  },
  sectionXSmallScreen: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2, 1.3)
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  string: {
    wordBreak: 'break-all',
  },
  stringWithoutTwitter: {
    wordBreak: 'break-all',
  },
  profileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileContainer: {
    display: 'flex',
  },
  profile: {
    flexGrow: 1,
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
  },
  tableRow: {
    height: '3.125rem',
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: switcheo.hover,
      },
    },
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
  },
  never: {
    color: theme.palette.grey[500],
  },
  avatar: {
    width: '3.125rem',
    height: '3.125rem',
  },
  titleContainer: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1.25),
  },
  title: {
    fontWeight: 700,
  },
  iconButton: {
    margin: theme.spacing(0, 0.5, 0, 0.5),
    cursor: 'pointer'
  },
}))

export default Profile
