import { Backdrop, Box, CircularProgress, IconButton, makeStyles, MenuList, MenuItem, Paper, Tooltip } from '@material-ui/core'
import { ReactComponent as NetworkIcon } from 'assets/Network.svg'
import clsx from 'clsx'
import { setMenuDropClose, setNetDropClose, setNetDropOpen, setNetwork, setWalletDropClose } from 'js/actions/app'
import { useRedux } from 'js/hooks'
import { Network } from 'js/models/Network'
import { switcheo } from 'js/theme/palettes/colors'
import React from 'react'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  backdrop: {
  },
  button: {
    marginRight: theme.spacing(1.25),
  },
  buttonIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  menuList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  menuPaper: {
    position: 'absolute',
    top: '2.5rem',
    right: 0,
  },
  networkIcon: {
    width: 16,
    height: 16,
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
  dropdownItem: {
    fontSize: '1rem',
    minWidth: 116,
    justifyContent: 'flex-start',
    minHeight: theme.spacing(6),
    '&:hover': {
      backgroundColor: switcheo.primary[300],
    },
    '&.Mui-selected:hover': {
      backgroundColor: switcheo.primary[300],
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))
const NetworkToggle: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props: any) => {
  const { children, className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { netDrop, network } = useRedux((state) => state.app)

  const onOpenMenu = () => {
    dispatch(setMenuDropClose())
    dispatch(setWalletDropClose())
    dispatch(setNetDropOpen())
  }
  const onCloseMenu = () => {
    dispatch(setNetDropClose())
  }
  const onSelectNetwork = (newNetwork: Network) => {
    onCloseMenu()
    if (newNetwork === network) return

    dispatch(setNetwork(newNetwork))
  }

  const getNetworkLabel = (network: Network) => {
    switch (network) {
      case Network.Main: return 'MainNet'
      case Network.Test: return 'TestNet'
      case Network.Dev: return 'DevNet'
      case Network.Local: return 'localhost'
    }
  }

  const filterNetworks = (network: Network) => {
    return true
    // if (process.env.NODE_ENV !== 'production') return true
    // return ![Network.Dev, Network.Local].includes(network)
  }

  const selectNetworkHandler = (option: Network) => {
    return () => onSelectNetwork(option)
  }

  const isLoading = false
  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Tooltip arrow title={getNetworkLabel(network)} placement="left">
        <IconButton
          onClick={onOpenMenu}
          className={classes.button}
        >
          {!isLoading && (
            <NetworkIcon className={classes.networkIcon} />
          )}

          {isLoading && (
            <CircularProgress size={16} className={classes.progress} />
          )}
        </IconButton>
      </Tooltip>
      {
        netDrop && (
          <React.Fragment>
            <Backdrop
              className={clsx(classes.backdrop, { open: netDrop })}
              invisible
              open={netDrop}
              onClick={onCloseMenu}
            />
            <Paper className={classes.menuPaper}>
              <MenuList className={classes.menuList}>
                {Object.values(Network).filter(filterNetworks).map((option, index) =>
                  <MenuItem
                    className={classes.dropdownItem}
                    selected={network === option}
                    key={index}
                    onClick={selectNetworkHandler(option)}
                  >
                    {getNetworkLabel(option)}
                  </MenuItem>,
                )}
              </MenuList>
            </Paper>
          </React.Fragment>
        )
      }
    </Box>
  )
}

export default NetworkToggle
