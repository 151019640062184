import { Link, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import { setLeaderboardMarket, update30dPnl, updateLifetimePnl } from 'js/actions/leaderboard'
import { Page } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import Pnl from './components/Pnl'

interface Props { }

export default (): ReactElement<Props> => {
  const { d30Pnl, lifetimePnl, leaderboardMarket } = useRedux((state) => state.leaderboard)
  const markets = useRedux((state) => state.app.markets)
  const [linkAnchor, setLinkAnchor] = useState<null | HTMLElement>(null)
  const classes = useStyles()
  const dispatch = useDispatch()

  const marketsList = ['all']
  for (const key of Object.keys(markets)) {
    if (markets[key].market_type === 'futures') {
      marketsList.push(markets[key].name)
    }
  }
  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLinkAnchor(event.currentTarget)
  }
  const onCloseMenu = () => setLinkAnchor(null)
  const renderItem = (item: string) => {
      return (
        <div className={classes.menuItem} onClick={() => dispatch(setLeaderboardMarket(item))}>{item.toUpperCase()}</div>
      )
  }
  return (
    <Page title="Leaderboard">
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -60, }}>
          <Link
            underline="none"
            className={classes.menuItem}
            variant="h3"
            onClick={onOpenMenu}
          >
            {leaderboardMarket.toUpperCase()} <ArrowIcon className={classes.dropdownIcon} />
          </Link>
          <Menu
            anchorEl={linkAnchor}
            keepMounted
            open={Boolean(linkAnchor)}
            onClose={onCloseMenu}
          >
            {marketsList.map((item, index) => (
              <MenuItem
                className={classes.dropdownMenuItem}
                key={index}
                onClick={onCloseMenu}
              >
                {renderItem(item)}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Pnl pnl={d30Pnl} title="Last 30 Days P&L" tasks={[TaskNames.Leaderboard.D30, TaskNames.App.Markets]} action={update30dPnl} />
        <Pnl pnl={lifetimePnl} title="Lifetime P&L" tasks={[TaskNames.Leaderboard.Lifetime, TaskNames.App.Markets]} action={updateLifetimePnl} />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropdownMenuItem: {
    fontWeight: 'normal',
    padding: 0,
    minWidth: '10rem',
    '& a.MuiLink-root': {
      padding: theme.spacing(2, 3),
    },
  },
  dropdownIcon: {
    verticalAlign: 'middle',
    marginBottom: theme.spacing(0.5),
  },
  menuItem: {
    cursor: 'pointer',
    fontWeight: 'normal',
    padding: theme.spacing(2),
    textDecoration: 'none',
    // width: "100%",
  },
  activeMenuItem: {
    minWidth: 'unset',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
}))
