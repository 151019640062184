import { Page } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import Pnl from 'js/views/Leaderboard/components/Pnl'
import { updateLeaderboardHistory } from 'js/actions/leaderboardHistory'


interface Props { }

export default (): ReactElement<Props> => {
  const { leaderboard } = useRedux((state) => state.leaderboardHistory)

  return (
    <Page title="Leaderboard">
        <Pnl pnl={leaderboard} title="P&L Leaderboard" tasks={[TaskNames.LeaderboardHistory.leaderboard]} action={updateLeaderboardHistory} />
    </Page>
  )
}
