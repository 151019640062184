import BigNumber from 'bignumber.js'
import { Constants } from 'js/constants'
import { useRedux } from 'js/hooks'
import { ProposalStatuses } from 'js/models/Governance'
import { BIG_ZERO } from 'js/utils'
import React from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  proposal: RestModels.GovProposal
}

enum states {
  WaitMinDeposit,
  WaitQuorum,
  RejectedVeto,
  Rejected,
  Executed,
  RejectedQuorum,
}

const DepositStatus: React.FC<Props> = (props: Props) => {
  const { proposal } = props
  const tallyParams = useRedux((state) => state.governance.tallyParams)
  const validatorsMap = useRedux((state) => state.app.validatorsMap)
  const voteTallies = useRedux((state) => state.governance.liveVoteTallies)

  const voteTally = voteTallies[proposal.id]
  const totalStakingPower = Object.values(validatorsMap).reduce((sumShares: BigNumber, validator) => {
    return sumShares = sumShares.plus(validator.DelegatorShares)
  }, BIG_ZERO)

  const state = getState(proposal, voteTally, totalStakingPower, tallyParams)
  return (
    <React.Fragment>
      {getMessage(state)}
    </React.Fragment>
  )
}

function getMessage(state: states) {
  switch (state) {
    case states.WaitMinDeposit:
      return 'Waiting for minimum deposit'
    case states.WaitQuorum:
      return 'Waiting for voting period to end'
    case states.Executed: // Proposal Passed
      return 'Deposit was refunded'
    case states.Rejected: // Porposal Rejected
      return 'Deposit was refunded' // 2
    case states.RejectedVeto: // Over 1/3 voted no_with_veto
      return `Deposit was burnt`
    case states.RejectedQuorum: // Failed Quorum
      return `Deposit was burnt`
    default:
      return ''
  }
}

function getState(proposal: RestModels.GovProposal,
  voteTally: RestModels.GovProposalTally | undefined,
  totalStakingPower: BigNumber,
  tallyParams: RestModels.GovTallyParams | undefined,
) {
  let state = states.WaitMinDeposit
  switch (proposal.proposal_status) {
    case ProposalStatuses.DepositPeriod:
      return states.WaitMinDeposit
    case ProposalStatuses.Passed:
      return states.Executed
    case ProposalStatuses.Rejected:
      state = states.Rejected
      break;
    case ProposalStatuses.VotingPeriod:
      return states.WaitQuorum
  }
  const resultYes = voteTally?.yes || BIG_ZERO
  const resultNo = voteTally?.no || BIG_ZERO
  const resultVeto = voteTally?.no_with_veto || BIG_ZERO
  const resultAbstain = voteTally?.abstain || BIG_ZERO
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  const totalStakingPowerUnitless = totalStakingPower.shiftedBy(Constants.Decimals.SWTH)
  // Quorum: more than 40% of the total staked tokens at
  //  the end of the voting period need to have voted
  if ((totalVoted.div(totalStakingPowerUnitless)).lt(tallyParams?.quorum || 0.4)) {
    state = states.RejectedQuorum
    // Veto: More than 33.4% of the tokens that participated in the vote,
    //  not counting “Abstain” votes, have vetoed the decision “No (With Veto)”.
  } else if ((resultVeto.div(totalVoted.minus(resultAbstain))).gt(tallyParams?.veto || 0.334)) {
    state = states.RejectedVeto
  }

  return state
}

export default DepositStatus
