import { TableCellInfo } from "js/components"

export const InfoHeaders: TableCellInfo[] = [
  {
    align: "left",
    content: "",
  },
  {
    align: 'left',
    content: 'Name',
  },
  {
    align: "left",
    content: 'Denom',
  },
  {
    align: "left",
    content: 'AssetID',
  },
  {
    align: "right",
    content: 'Decimals',
  },
]

export const CirculateHeaders: TableCellInfo[] = [
  { align: 'inherit', content: 'Token' },
  { content: 'Available' },
  { content: 'Order' },
  { content: 'Position' },
  { content: 'Staked' },
  { content: 'Total' },
]
