import { Record, RecordOf } from 'immutable'
import { ValidatorsAction, ValidatorsActionType } from '../actions/validators'

export interface ValidatorsProps {
  params: any
  distributionParams: any
  blockTime: string
}

const makeState: Record.Factory<ValidatorsProps> = Record<ValidatorsProps>({
  params: {},
  distributionParams: {},
  blockTime: '00:00:00',
})
export type ValidatorsState = RecordOf<ValidatorsProps>

function validatorsReducer(
  state: ValidatorsState = makeState(),
  action: ValidatorsAction,
): ValidatorsState {
  switch (action.type) {
    case ValidatorsActionType.CLEAR:
      return makeState()
    case ValidatorsActionType.SET_SLASHING_PARAMS:
      return state.updateIn(['params'], (): [] => {
        return action.params
      })
    case ValidatorsActionType.SET_DISTRIBUTION_PARAMS:
      return state.updateIn(['distributionParams'], (): [] => {
        return action.distributionParams
      })
    case ValidatorsActionType.SET_BLOCK_TIME:
      return state.updateIn(['blockTime'], (): string => {
        return action.blockTime
      })
    default:
      return state
  }
}

export default validatorsReducer
