import { Record, RecordOf } from 'immutable'
import { RestResponse, RestTypes } from 'tradehub-api-js'
import { MarketAction, MarketActionType } from '../actions/market'
export interface MarketProps {
  market?: RestResponse.Market
  stats?: RestResponse.MarketStat
  orders?: RestTypes.ResultsMinMax<RestResponse.Order>
  trades?: RestTypes.ResultsMinMax<RestResponse.Trade>
  largePositions?: RestTypes.ResultsPaged<RestResponse.Position>
  riskyLongs?: RestTypes.ResultsPaged<RestResponse.Position>
  riskyShorts?: RestTypes.ResultsPaged<RestResponse.Position>
  profitablePositions?: RestTypes.ResultsPaged<RestResponse.Position>
  topProfits: any
}

const makeState: Record.Factory<MarketProps> = Record<MarketProps>({
  market: undefined,
  stats: undefined,
  orders: undefined,
  trades: undefined,
  largePositions: undefined,
  riskyLongs: undefined,
  riskyShorts: undefined,
  profitablePositions: undefined,
  topProfits: [],
})
export type MarketState = RecordOf<MarketProps>

function marketsReducer(
  state: MarketState = makeState(),
  action: MarketAction,
): MarketState {
  switch (action.type) {
    case MarketActionType.CLEAR:
      return makeState()
    case MarketActionType.SET_MARKET:
      return state.updateIn(['market'], () => action.market)
    case MarketActionType.SET_STATS:
      return state.updateIn(['stats'], () => action.stats)
    case MarketActionType.SET_ORDERS:
      return state.updateIn(['orders'], () => action.orders)
    case MarketActionType.SET_TRADES:
      return state.updateIn(['trades'], () => action.trades)
    case MarketActionType.SET_LARGE_POSITIONS:
      return state.updateIn(['largePositions'], () => action.largePositions)
    case MarketActionType.SET_RISKY_LONG_POSITIONS:
      return state.updateIn(['riskyLongs'], () => action.riskyPositions)
    case MarketActionType.SET_RISKY_SHORT_POSITIONS:
      return state.updateIn(['riskyShorts'], () => action.riskyPositions)
    case MarketActionType.SET_PROFITABLE_POSITIONS:
      return state.updateIn(['profitablePositions'], () => action.profitablePositions)
    default:
      return state
  }
}

export default marketsReducer
