import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { OrderActionType, OrderActon } from '../actions/order'

export interface OrderProps {
  order?: RestModels.Order
}

const makeState: Record.Factory<OrderProps> = Record<OrderProps>({
  order: undefined,
})
export type OrderState = RecordOf<OrderProps>

export default function orderReducer(
  state: OrderState = makeState(),
  action: OrderActon,
): OrderState {
  switch (action.type) {
    case OrderActionType.CLEAR:
      return makeState()
    case OrderActionType.SET_ORDER:
      return state.updateIn(['order'], () => action.order)
    default:
      return state
  }
}
