import { Record, RecordOf } from 'immutable'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { BlocksAction, BlocksActionType } from '../actions/blocks'

export interface BlocksProps {
  blocks?: RestTypes.ResultsMinMax<RestModels.Block>
}
const makeState: Record.Factory<BlocksProps> = Record<BlocksProps>({
  blocks: undefined,
})
export type BlocksState = RecordOf<BlocksProps>

function blocksReducer(
  state: BlocksState = makeState(),
  action: BlocksAction,
): BlocksState {
  switch (action.type) {
    case BlocksActionType.CLEAR:
      return makeState()
    case BlocksActionType.SET_BLOCKS:
      return state.set('blocks', action.blocks)
    default:
      return state
  }
}

export default blocksReducer
