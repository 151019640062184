import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlProps,
  Input,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core'
import clsx from 'clsx'
import { TxTypeLabel } from 'js/components/ParsedLabel'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'

export interface FilterOption {
  label?: string
  value: string
  selected?: boolean
}

type Props = FormControlProps & {
  options: FilterOption[]
  filters?: { [index: string]: boolean }
  onFilter?: (types: string[]) => void
}

const TransactionFilter: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    title,
    className,
    children,
    options,
    filters,
    onFilter,
    ...rest
  } = props
  const classes = useStyles(props)
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])

  useEffect(() => {
    if (filters) {
      setSelectedTypes(Object.keys(filters))
    }
  }, [filters])

  const onChange = (evt: ChangeEvent<any>, child: React.ReactNode) => {
    setSelectedTypes([...evt.target.value])
  }
  const onConfirmFilter = () => {
    if (typeof onFilter === 'function') {
      onFilter(selectedTypes)
    }
  }

  const renderValue = (input: any) => {
    const selectedTypes = input as string[]
    if (!selectedTypes.length) return 'All Transaction Types'
    return `Types Selected: ${selectedTypes.length}`
  }
  return (
    <FormControl {...rest} className={clsx(classes.root, className)}>
      <Select
        multiple
        displayEmpty
        variant="filled"
        value={selectedTypes}
        onChange={onChange}
        onClose={onConfirmFilter}
        input={<Input />}
        MenuProps={{ className: classes.popOver }}
        className={classes.selectInput}
        renderValue={renderValue}
      >
        <ListSubheader className={classes.subheader}>Types</ListSubheader>
        {!options.length && (
          <MenuItem disabled className={classes.loadingItem}>
            <CircularProgress size='1rem' color='secondary' />
          </MenuItem>
        )}
        {options.map((option, index) => {
          
          return (
            <MenuItem
              className={classes.menuItem}
              key={index}
              value={option.value}
            >
              <Checkbox
                color="secondary"
                checked={selectedTypes.includes(option.value)}
              />
              <ListItemText
                primary={
                  <TxTypeLabel>{option.label || option.value}</TxTypeLabel>
                }
              />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '14rem',
    maxWidth: '40rem',
  },
  subheader: {
    backgroundColor: theme.palette.primary.dark,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '-0.0125rem',
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  loadingItem: {
    justifyContent: 'center',
  },
  popOver: {
    '& .MuiMenu-paper': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  selectInput: {
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      color: theme.palette.common.white,
    },
  },
  menuItem: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.75, 1.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .MuiCheckbox-root': {
      padding: 0,
      paddingRight: theme.spacing(1),
    },

    '& .MuiListItemText-root': {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: '-0.0125rem',
      color: theme.palette.primary.contrastText,
    },
  },
}))

export default TransactionFilter
