import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum TokenActionType {
  CLEAR = 'CLEAR_TOKEN_INFO',
  SET_TOKEN_INFO = 'SET_TOKEN_INFO',
  SET_RICH_ACCOUNTS_BALANCES = 'SET_RICH_ACCOUNTS_BALANCES',
}

export type TokenAction =
  | ClearAction
  | SetTokenInfoAction
  | SetRichAccountsBalancesAction

export interface ClearAction extends Action<TokenActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: TokenActionType.CLEAR,
  }
}

export interface SetTokenInfoAction
  extends Action<TokenActionType.SET_TOKEN_INFO> {
  token: RestModels.Token
}

export function setTokenInfo(token: RestModels.Token): SetTokenInfoAction {
  return {
    type: TokenActionType.SET_TOKEN_INFO,
    token,
  }
}

export interface SetRichAccountsBalancesAction
  extends Action<TokenActionType.SET_RICH_ACCOUNTS_BALANCES> {
  rich: RestModels.UserToken[]
}

export function setRichAccountsBalances(
  rich: RestModels.UserToken[],
): SetRichAccountsBalancesAction {
  return {
    type: TokenActionType.SET_RICH_ACCOUNTS_BALANCES,
    rich,
  }
}
