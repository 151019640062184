import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, MarketsActionType, setOrders, setTrades } from '../actions/markets'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Markets extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchOrders],
      [this, this.fetchTrades],
      [this, this.watchSetNetwork],
      [this, this.watchOrdersFilters],
      [this, this.watchTradesFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchOrders(): any {
    yield runSagaTask(TaskNames.Markets.Orders, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        limit: 20,
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      yield put(setOrders(orders))
    });
  }

  private *watchOrdersFilters(): any {
    yield takeLatest(
      MarketsActionType.UPDATE_ORDERS_FILTERS,
      this.handleOrderFilters,
    )
  }

  private *handleOrderFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Markets.OrderFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        limit: 20,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      if (action.options.pagination.order_by === "asc") {
        orders.data.reverse();
      }
      yield put(setOrders(orders))
    });
  }

  private *fetchTrades(): any {
    yield runSagaTask(TaskNames.Markets.Trades, function* () {
      const sdk = yield* getInitializedSDK()

      const trades = (yield sdk.api.getTradesPaged({
        limit: 20,
      })) as RestTypes.ResultsMinMax<RestModels.Trade>
      yield put(setTrades(trades))
    });
  }

  private *watchTradesFilters(): any {
    yield takeLatest(
      MarketsActionType.UPDATE_TRADES_FILTERS,
      this.handleTradesFilters,
    )
  }
  private *handleTradesFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Markets.TradeFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const trades = (yield sdk.api.getTradesPaged({
        limit: 20,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Trade>
      if (action.options.pagination.order_by === "asc") {
        trades.data.reverse()
      }
      yield put(setTrades(trades))
    });
  }
}
