import dayjs from 'dayjs'

export const timeNow = dayjs().toISOString()

export const CreateTokenProposalInitialFormState = {
  title: '',
  description: '',
  name: '',
  symbol: '',
  denom: '',
  decimals: '',
  nativeDecimals: '',
  blockchain: '',
  chainId: '',
  assetId: '',
  isCollateral: 'false',
  lockProxyHash: '',
  delegatedSupply: '',
}

export const CreateOracleProposalInitialFormState = {
  title: '',
  description: '',
  oracleId: '',
  oracleDescription: '',
  minTurnoutPercentage: '',
  maxResultAge: '',
  securityType: 'SecuredByValidators',
  resultStrategy: '',
  resolution: '',
  spec: '',
}

export const CreateMarketProposalInitialFormState = {
  title: '',
  description: '',
  name: '',
  displayName: '',
  marketType: 'spot',
  marketDescription: '',
  base: '',
  quote: '',
  lotSize: '',
  tickSize: '',
  minQuantity: '',
  takerFee: '',
  makerFee: '',

  riskStepSize: '0',
  initialMarginBase: '1.0',
  initialMarginStep: '0.000000000000000000',
  maintenanceMarginRatio: '0.000000000000000000',
  maxLiquidationOrderTicket: '0',
  maxLiquidationOrderDuration: '0',
  impactSize: '0',
  markPriceBand: '0',
  lastPriceProtectedBand: '0',
  indexOracleId: '',
  expiryTime: timeNow,
}

export const UpdateMarketProposalInitialFormState = {
  title: '',
  description: '',
  name: '',
  displayName: '',
  marketType: 'spot',
  marketDescription: '',
  minQuantity: '',
  takerFee: '',
  makerFee: '',

  riskStepSize: '0',
  initialMarginBase: '1.0',
  initialMarginStep: '0.000000000000000000',
  maintenanceMarginRatio: '0.000000000000000000',
  maxLiquidationOrderTicket: '0',
  maxLiquidationOrderDuration: '0',
  impactSize: '0',
  markPriceBand: '0',
  lastPriceProtectedBand: '0',
}

export const LinkPoolProposalInitialFormState = {
  title: '',
  description: '',
  poolID: '',
  marketName: '',
}

export const UnlinkPoolProposalInitialFormState = {
  title: '',
  description: '',
  poolID: '',
}

export const SetPoolRewardWeightsProposalInitialFormState = {
  title: '',
  description: '',
  weights: [{
    poolID: '',
    weight: '',
  }],
}

export const SetRewardCurveProposalInitialFormState = {
  title: '',
  description: '',
  startTime: timeNow,
  initialRewardBps: '',
  reductionMultiplierBps: '',
  reductionIntervalSeconds: '',
  reductions: '',
  finalRewardBps: '',
}

export const SetCommitmentCurveProposalInitialFormState = {
  title: '',
  description: '',
  maxDuration: '',
  maxRewardMultiplier: '',
}

export const SetSettlementPriceProposalInitialFormState = {
  title: '',
  description: '',
  market: '',
  settlementPrice: '',
}

export const CommunityPoolSpendProposalInitialFormState = {
  title: '',
  description: '',
  denom: '',
  amount: '',
  recipient: '',
}

export const ParameterChangeProposalInitialFormState = {
  title: '',
  description: '',
  topic: '',
  parameter: '',
  value: '',
}

export const SoftwareUpgradeProposalInitialFormState = {
  title: '',
  description: '',
  name: '',
  time: '',
  height: '',
  info: '',
}

export const TextProposalInitialFormState = {
  title: '',
  description: '',
}
