import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiButton']>> => ({
  root: {
    minWidth: '12.5rem',
    justifyContent: 'flex-start',
  },
  containedPrimary: {
    borderRadius: 0,
    fontSize: '1.125rem',
    fontWeight: 700,
    textTransform: 'none',
    padding: theme.spacing(1.25),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  containedSizeSmall: {
    minWidth: '8.5rem',
    justifyContent: 'center',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    letterSpacing: '-0.0125rem',
    fontWeight: 700,
    padding: theme.spacing(1.375, 3.4325),
  },
})
