import { SignedBlocksCount } from "./generic"

type Messages = {
  [index: string]: string
}

export const TooltipsMsgs: Messages = {
  commission_rate: 'Service fees charged to delegators.',
  est_yield: 'Estimated reward you will earn per year after commission.',
  self_stake: 'The amount of tokens the validator has staked in this pool. Percentage shows the amount that this validator has staked as compared to the validator\'s total stake.',
  total_stake: 'Sum of all tokens staked with the Validator.',
  total_stake_percent: 'Sum of all tokens staked with the Validator. Percentage shows total stake of this validator over the network.',
  uptime: `Percentage of missed blocks in the last ${SignedBlocksCount.toFormat(0)} blocks`,
}
