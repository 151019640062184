import { makeStyles, SvgIcon, Theme } from '@material-ui/core'
import { AccessTimeOutlined, FiberManualRecord } from '@material-ui/icons'
import { TimelineDot } from '@material-ui/lab'
import { ReactComponent as CloseIcon } from 'assets/Close.svg'
import { ReactComponent as TickIcon } from 'assets/Tick.svg'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'

interface Props {
  status: string
}

const ProcessDot: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { status } = props
  const classes = useStyles()

  switch(status) {
    case 'success':
      return (
        <TimelineDot className={classes.successDot}>
          <SvgIcon
            component={TickIcon}
            className={classes.successIcon}
          />
        </TimelineDot>
      )
    case 'rejected':
      return (
        <TimelineDot color="primary">
          <SvgIcon
            component={CloseIcon}
            className={classes.CurrentProcessDot}
          />
        </TimelineDot>
      )
    case 'completed':
      return (
        <TimelineDot color='primary'>
          <SvgIcon
            component={AccessTimeOutlined}
            className={classes.CurrentProcessDot}
          />
        </TimelineDot>
      )
    default:
      return (
        <TimelineDot className={classes.timelineDotTransparent}>
          <SvgIcon
            component={FiberManualRecord}
            className={classes.nonCurrentProcessDot}
          />
        </TimelineDot>
      )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  timelineDotTransparent: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    boxShadow: 'unset',
    border: 0,
  },
  nonCurrentProcessDot: {
    fontSize: '0.8rem',
  },
  CurrentProcessDot: {
    fontSize: '0.6rem',
  },
  successDot: {
    backgroundColor: switcheo.success,
  },
  successIcon: {
    fontSize: '0.7rem',
  },
}))

export default ProcessDot
