import { TableBody, TableHead, TableRow } from '@material-ui/core'
import { DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'

interface Props {
  cellClass?: string
  headerCells: TableCellInfo[]
  loadName: string
  models: any,
  rowComponent: any
  title?: string
  addInfo?: {
    [index: string]: any
  }
  itemName?: string
}

const TableSection: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { cellClass, headerCells = [], loadName, models = [], rowComponent: RowComponent, title, addInfo, itemName } = props
  const [loading] = useTaskSubscriber(loadName)
  return (
    <Section title={title}>
      <DataLoadSegment
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              {headerCells.map((cell, index) => {
                const { align = 'right', content } = cell
                return (
                  <TableSectionCell
                    align={align}
                    className={cellClass}
                    content={content}
                    key={index}
                  />
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model: any, index: number) => (
                <RowComponent
                  key={`rowComponent${index}`}
                  model={model}
                  addInfo={addInfo}
                />
              ))
            }
          </TableBody>
        </ListTable>
        {(!models || models?.length <= 0 || models?.size <= 0) && (
          <TableEmptyState itemName={itemName}  />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default TableSection
