import { Box, BoxProps, Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { CellLink, Section, StandardBtn } from 'js/components'
import { Constants, Paths } from 'js/constants'
import { useRedux, useWallet } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { BIG_ZERO, makeMoneyLabel } from 'js/utils'
import React from 'react'
import { RestModels } from 'tradehub-api-js'
import { DataRow, DataScale } from '../../components'

type Props = BoxProps & {
  proposal: RestModels.GovProposal
  onClickVote: () => void
}

const VoteDetails: React.FC<Props> = (props: Props) => {
  const { className, proposal, onClickVote, ...rest } = props
  const validatorsMap = useRedux((state) => state.app.validatorsMap)
  const wallet = useWallet()
  const voteTallies = useRedux((state) => state.governance.liveVoteTallies)
  const classes = useStyles(props)
  const denom = proposal.total_deposit[0].denom.toUpperCase()

  const totalStakingPower = Object.values(validatorsMap).reduce((sumShares, validator) => {
    return sumShares = sumShares.plus(validator.DelegatorShares)
  }, BIG_ZERO)

  const voteTally = voteTallies[proposal.id]

  const resultYes = (voteTally?.yes || BIG_ZERO).shiftedBy(-8)
  const resultNo = (voteTally?.no || BIG_ZERO).shiftedBy(-8)
  const resultVeto = (voteTally?.no_with_veto || BIG_ZERO).shiftedBy(-8)
  const resultAbstain = (voteTally?.abstain || BIG_ZERO).shiftedBy(-8)
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  const makeVotedLabel = (result: BigNumber) => {
    const resultPercent = totalVoted.gt(0) ? result.div(totalVoted) : BIG_ZERO
    return `${makeMoneyLabel(result.shiftedBy(8))} ${denom} (${resultPercent.shiftedBy(2).toFormat(3)}%)`
  }

  const getVotedProgress = (result: BigNumber) => {
    const resultPercent = totalStakingPower.gt(0) ? result.div(totalStakingPower) : BIG_ZERO
    if (resultPercent.eq(0)) return 0
    return BigNumber.max(resultPercent.shiftedBy(2), 1).toNumber()
  }

  return (
    <Section
      className={clsx(classes.root, className)}
      title='Participate in Voting'
      {...rest}
    >
      <Box marginBottom={1.5}>
        <DataRow header='Total staking power' value={`${totalStakingPower.toFormat(0)} ${denom}`} />
        <Divider className={classes.divider} />
        <DataRow header='Voted power' value={`${makeMoneyLabel(totalVoted, { decimals: 0 })} ${denom}`} />
      </Box>

      <Box>
        <DataScale
          header='Yes'
          value={makeVotedLabel(resultYes)}
          progress={getVotedProgress(resultYes)}
          color={switcheo.green[400]}
        />

        <DataScale
          header='No'
          value={makeVotedLabel(resultNo)}
          progress={getVotedProgress(resultNo)}
          color={switcheo.danger}
        />

        <DataScale
          header='No with veto'
          value={makeVotedLabel(resultVeto)}
          progress={getVotedProgress(resultVeto)}
          color={switcheo.yellow}
        />

        <DataScale
          header='Abstain'
          value={makeVotedLabel(resultAbstain)}
          progress={getVotedProgress(resultAbstain)}
          color='#B3B3B3'
        />
      </Box>
      {proposal.proposal_status === Constants.Proposal.Status.VotingPeriod && (
        wallet ?
          <StandardBtn
            variant="contained"
            color="primary"
            onClick={onClickVote}
            className={classes.button}
          >
            <Typography variant="h3">Vote</Typography>
          </StandardBtn>
          :
          <StandardBtn
            to="/login"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            <Typography variant="h3">Login to vote</Typography>
          </StandardBtn>
      )}
      <Box marginTop={1.5}>
        <Typography variant='body2'>How is voting tallied?&nbsp;
          <CellLink underline="hover" href={Paths.Resources.GovernanceGuide}>
            Learn more
          </CellLink>
        </Typography>
      </Box>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  divider: {
    backgroundColor: switcheo.primary[300],
  },
  button: {
    marginTop: '1rem',
  },
}))

export default VoteDetails
