import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, ListTable, PaginationByIndex, Section, TableEmptyState, TxTypeLabel } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestTypes, RestModels } from 'tradehub-api-js'

// tslint:disable:jsx-no-lambda
interface Props {
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>
  title?: string
  filters?: any
  filterStatus?: boolean
  action: any
  loadName: string[]
  sectionClass?: string
}

const Transactions: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { sectionClass, title, transactions, filters, filterStatus = false, action, loadName } = props
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )
  const [loading] = useTaskSubscriber(...loadName)
  const tx = transactions?.data
  return (
    <Section className={clsx(classes.root, sectionClass)} title={title}>
      <DataLoadSegment loading={loading} filter={filterStatus}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tx.map((txn: RestModels.Txn, index: number) =>
              renderTransaction(txn, index, smallScreen),
            )}
          </TableBody>
        </ListTable>
        {tx && tx?.length > 0 && (
          <Box paddingTop={3}>
            <PaginationByIndex
              response={transactions}
              action={action}
              filters={filters}
              useBlockHeight={false}
            />
          </Box>
        )}
        {(!tx || tx?.length <= 0) && (
          <TableEmptyState itemName="transactions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransaction(
    transaction: RestModels.Txn,
    index: number,
    isScreenSmall: boolean,
  ): ReactNode {
    const { hash, address, username, height, msg_type, code } = transaction
    const shortenedHash = isScreenSmall
      ? hash
        .substring(0, 12)
        .concat('... ')
        .concat(hash.slice(-12))
      : hash
    return (
      <TableRow key={hash + index} hover>
        <TableCell>
          <CellLink to={`/block/${height}`}>{height}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {username === '' ? shorten(address) : username}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/transaction/${hash}`}>{shortenedHash}</CellLink>
        </TableCell>
        <TableCell>
          <TxTypeLabel>{msg_type}</TxTypeLabel>
        </TableCell>
        <TableCell>{code === '0' ? 'Success' : 'Fail'}</TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 3, 3),
  },
}))

export default Transactions
