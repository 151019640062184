import { CreateTokenProposalFormState } from 'js/models'
import store from 'js/store'
import { escapeHtmlGo } from 'js/utils'
import { RPCParams } from 'tradehub-api-js'

export function createTokenProposalValue(): RPCParams.CreateTokenProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as CreateTokenProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, name, symbol, denom, decimals, nativeDecimals,
    blockchain, chainId, assetId, isCollateral, lockProxyHash,
    delegatedSupply } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    token: {
      name,
      symbol,
      denom: denom.toLowerCase(),
      decimals: decimals.toString(),
      native_decimals: nativeDecimals.toString(),
      blockchain,
      chain_id: chainId.toString(),
      asset_id: assetId.replace(/^0x/, '').toLowerCase(),
      is_collateral: isCollateral === 'true',
      lock_proxy_hash: lockProxyHash.replace(/^0x/, '').toLowerCase(),
      delegated_supply: delegatedSupply.toString(),
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
