import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, DatetimeCell, ListTable, PaginationByIndex, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels, RestTypes } from 'tradehub-api-js'

export interface BlocksProps {
  action: any
  blocks?: RestTypes.ResultsMinMax<RestModels.Block>
  className?: string
  consensusMap: any
  filter: boolean
  loadname?: string[]
  title?: string
  titleClass?: string
}

interface Props extends BlocksProps { }

const Blocks: React.FunctionComponent<Props> = ({
  blocks,
  action,
  className,
  consensusMap,
  filter = false,
  loadname = [],
  title,
  titleClass,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(
    TaskNames.App.Validators,
    ...loadname,
  )

  return (
    <Section className={clsx(classes.root, className)} title={title} titleClass={titleClass}>
      <DataLoadSegment
        filter={filter}
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell>Proposer</TableCell>
              <TableCell align="right">Txs</TableCell>
              <TableCell align="right">Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{blocks?.data.map(renderBlock)}</TableBody>
        </ListTable>
        {!!blocks?.data.length && (
          <Box paddingTop={3}>
            <PaginationByIndex
              response={blocks as any}
              action={action}
              useBlockHeight
            />
          </Box>
        )}
        {!blocks?.data.length && (
          <TableEmptyState itemName="blocks" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderBlock(block: RestModels.Block): ReactNode {
    const {
      block_height: blockHeight,
      time,
      count,
      proposer_address: proposerAddress,
    } = block
    // const daysTime: Dayjs = dayjs(time)
    if (typeof consensusMap[proposerAddress] === 'undefined') return
    return (
      <TableRow key={blockHeight} hover>
        <TableCell>
          <CellLink to={`/block/${blockHeight}`}>{blockHeight}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink
            to={`/validator/${consensusMap[proposerAddress].operatorAddress}`}
          >
            {consensusMap[proposerAddress].moniker}
          </CellLink>
        </TableCell>
        <TableCell align="right">{count}</TableCell>
        <DatetimeCell data={time} />
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 3, 3),
  },
}))

export default Blocks
