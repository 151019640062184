import { Box, ListItem, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import { CellLink, Section } from 'js/components'
import { FuturesDenomOverride, FuturesNameOverride } from 'js/constants'
import { useAssetSymbol } from 'js/hooks'
import React, { ReactElement } from 'react'
import { RestResponse } from 'tradehub-api-js'

interface Props {
  market?: RestResponse.Market
}

interface ListItem {
  header: string
  content: number | string | React.ReactNode
}

const MarketInfo: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { market } = props
  const assetSymbol = useAssetSymbol()
  const classes = useStyles()

  const symbolOverride = market?.market_type === 'futures' ? FuturesDenomOverride : undefined
  const baseSymbol = assetSymbol(market?.base, symbolOverride)
  const quoteSymbol = assetSymbol(market?.quote, symbolOverride)

  const expiryDayjs = dayjs(market?.expiry_time)
  let formattedDate: string = expiryDayjs.format('YYYY-MM-DD HH:mm:ss')
  let dateShort: string = expiryDayjs.format('DD MMM YYYY')
  const now: Dayjs = dayjs()
  const diffInYears: number = now.diff(expiryDayjs, 'year')
  if (diffInYears > 10) {
    formattedDate = '-'
    dateShort = '-'
  }

  const formatDisplayName = () => {
    if (market?.market_type === 'spot') {
      return `${baseSymbol} / ${quoteSymbol}`
    }
    if (market?.market_type === 'futures') {
      return `${baseSymbol} - ${dateShort}`
    }
    return ''
  }

  const ListItems: ListItem[] = [
    {
      header: 'Name',
      content: market?.name,
    },
    {
      header: 'Display Name',
      content: formatDisplayName(),
    },
    {
      header: 'Description',
      content: market?.description,
    },
    {
      header: 'Type',
      content: market?.market_type,
    },
    {
      header: 'Base Currency',
      content: (
        <Box display="flex" alignItems="center">
          {baseSymbol}
          <Typography className={classes.subtitle}>
            ({market?.market_type === 'futures' ? (FuturesNameOverride[market?.base_name] ?? market?.base_name) : market?.base_name})
          </Typography>
        </Box>
      ),
    },
    {
      header: 'Base Precision',
      content: market?.base_precision,
    },
    {
      header: 'Quote Currency',
      content: (
        <Box display="flex" alignItems="center">
          {quoteSymbol}
          <Typography className={classes.subtitle}>
            ({market?.market_type === 'futures' ? (FuturesNameOverride[market?.quote_name] ?? market?.quote_name) : market?.quote_name})
          </Typography>
        </Box>
      ),
    },
    {
      header: 'Quote Precision',
      content: market?.quote_precision,
    },
    {
      header: 'Lot Size',
      content: market?.lot_size,
    },
    {
      header: 'Tick Size',
      content: market?.tick_size,
    },
    {
      header: 'Min. Quantity',
      content: market?.min_quantity,
    },
    {
      header: 'Maker Fee',
      content: percentFormat(market?.maker_fee),
    },
    {
      header: 'Taker Fee',
      content: percentFormat(market?.taker_fee),
    },
    {
      header: 'Risk Step Size',
      content: market?.risk_step_size,
    },
    {
      header: 'Initial Margin Base',
      content: market?.initial_margin_base,
    },
    {
      header: 'Initial Margin Step',
      content: market?.initial_margin_step,
    },
    {
      header: 'Maintenance Margin Ratio',
      content: market?.maintenance_margin_ratio,
    },
    {
      header: 'Index Oracle ID',
      content: !market?.index_oracle_id.length ? '-' : market?.index_oracle_id,
    },
    {
      header: 'Is Active',
      content: booleanFormat(market?.is_active),
    },
    {
      header: 'Is Settled',
      content: booleanFormat(market?.is_settled),
    },
    {
      header: 'Max Liquidation Order Ticket',
      content: market?.max_liquidation_order_ticket,
    },
    {
      header: 'Max Liquidation Order Duration',
      content: market?.max_liquidation_order_duration,
    },
    {
      header: 'Impact Size',
      content: market?.impact_size,
    },
    {
      header: 'Mark Price Band',
      content: market?.mark_price_band,
    },
    {
      header: 'Last Price Protected Bank',
      content: market?.last_price_protected_band,
    },
    {
      header: 'Expiry Time',
      content: formattedDate,
    },
    {
      header: 'Created At',
      content: linkFormat(market?.created_block_height),
    },
    {
      header: 'Closed At',
      content: linkFormat(market?.closed_block_height),
    },
  ]

  return (
    <Section title="Information">
      {ListItems.map((item) => (
        <ListItem disableGutters key={item?.header.replace(' ', '-')}>
          <div className={classes.header}>
            {item?.header}
          </div>
          <div className={classes.string}>
            {item?.content}
          </div>
        </ListItem>
      ))}
    </Section>
  )
}

const percentFormat = (value: any) => {
  if (value) {
    const valueBN = new BigNumber(value).times(100).toFormat(3)
    return `${valueBN}%`
  }
  return '-'
}

const booleanFormat = (value: any) => {
  return value ? 'true' : 'false'
}

const linkFormat = (value: any) => {
  if (value) {
    return (
      <CellLink to={`/block/${value}`}>
        {value}
      </CellLink>
    )
  }
  return '-'
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  subtitle: {
    color: theme.palette.grey[500],
    fontSize: '0.8rem',
    marginLeft: theme.spacing(0.75),
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
}))

export default MarketInfo
