import { Box, BoxProps, Grid, makeStyles, Theme } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { SubmitProposalFormState } from 'js/models'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import FieldMapper from './Helpers/FieldMapper'
import { fieldTypes } from './Helpers/types'

interface Props extends BoxProps {
  config: any
  initialFormState: SubmitProposalFormState
  requiredFields: string[]
}

const GenericProposalMainForm: React.FC<Props> = (props) => {
  const { className, config, initialFormState, requiredFields, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const formState = ((useRedux((state) => state.governance.submitProposalFormState)) || initialFormState) as SubmitProposalFormState
  const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: SubmitProposalFormState) => {
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const form = (config: any) => {
    return Object.values(config).map((value: any) => (
      <Grid item xs={12}>
        {
          FieldMapper(
            value,
            formState,
            value.type === fieldTypes.datetime ? handleDatetimeChange : handleFormChange,
          )
        }
      </Grid>
    ))
  }

  const handleFormChange = (key: keyof SubmitProposalFormState) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const newFormState = {
        ...formState,
        [key]: value,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  const handleDatetimeChange = (key: keyof SubmitProposalFormState, date: MaterialUiPickersDate) => {
    if (!date) return
    const newFormState = {
      ...formState,
      [key]: date.toISOString(),
    }

    dispatch(setSubmitProposalFormState(newFormState))
    validateRequiredFields(newFormState)
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        {
          form(config)
        }
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  loginLabel: {
    marginRight: theme.spacing(2),
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default GenericProposalMainForm
