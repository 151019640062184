import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import PaginationButtons from './PaginationButtons'
// tslint:disable:jsx-no-lambda

interface Props {
  response: any
  action: any
  page: number
  setPage: any
}

const PaginationByPage: React.FunctionComponent<Props> = ({
  response,
  action,
  page,
  setPage,
}: Props): ReactElement<Props> => {
  const dispatch = useDispatch()
  const data: any = response.data
  const handleButtonClick = (event: any) => {
    if (data.size > 0) {
      let newPage: any = page
      switch (event) {
        case 'next':
          newPage += 1
          break
        case 'last':
          newPage = response.totalPages
          break
        case 'previous':
          newPage -= 1
          break
        case 'first':
          newPage = 0
          break
        default:
          break
      }
      setPage(newPage)
      dispatch(
        action({
          page: newPage,
        }),
      )
    }
  }

  const disableBack: boolean = page <= 1 ? true : false
  const disableFoward: boolean = page >= response.totalPages ? true : false

  return (
    <PaginationButtons
      handleButtonClick={handleButtonClick}
      disableBack={disableBack}
      disableFoward={disableFoward}
    />
  )
}

export default PaginationByPage
