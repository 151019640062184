import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'
import { ListTableRow } from '../components'

type Props = {
  params: any
}

const SlashingParams: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { params } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Validators.Slashing)

  const cellFormat = (key: any) => {
    switch (key) {
      case 'downtime_jail_duration':
        const downtime: BigNumber = new BigNumber(params?.result[key]).div(1000000000)
        return downtime.toFormat(2) || '-'
      case 'slash_fraction_double_sign':
      case 'slash_fraction_downtime':
      case 'min_signed_per_window':
        const num: BigNumber = new BigNumber(params?.result[key]).times(100)
        return `${num.toFormat(2)}%` || '-'
      default:
        const value: any = isNaN(parseFloat(params?.result[key]))
          ? params?.result[key]
          : parseFloat(params?.result[key].toString())
        return value || '-'
    }
  }
  return (
    <Section title="Slashing Parameters" className={classes.section}>
      <DataLoadSegment loading={loading || !params?.result}>
        {
          params?.result && (
            <ListTable>
              <TableBody>
                {
                  Object.keys(params?.result).map((each) => {
                    if (params?.result[each] === '') return null

                    if (each === 'downtime_jail_duration') {
                      return (
                        <ListTableRow
                          headerClass={classes.headerClass}
                          key={each}
                          keyVal={each}
                          valueClass={classes.valueClass}
                        >
                          <div className={classes.stringContainer}>
                            <div>{cellFormat(each)}</div>
                            <div className={classes.sharePercent}>seconds</div>
                          </div>
                        </ListTableRow>
                      )
                    }
                    return (
                      <ListTableRow
                        headerClass={classes.headerClass}
                        key={each}
                        keyVal={each}
                        valueClass={classes.valueClass}
                      >
                        {cellFormat(each)}
                      </ListTableRow>
                    )
                  })
                }
              </TableBody>
            </ListTable>

          )
        }
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  sharePercent: {
    color: theme.palette.grey[500],
    marginLeft: 6,
  },
  stringContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default SlashingParams
