import { Box, BoxProps, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import { shorten } from 'js/helpers'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestResponse } from 'tradehub-api-js'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByIndex, Section, TableEmptyState } from '../components'

export interface OrdersProps extends BoxProps {
  loadName: string[]
  positions: any
  action: any
  closedOnly?: boolean
  title: string
}

interface Props extends OrdersProps { }

const Positions: React.FunctionComponent<Props> = ({
  loadName,
  positions,
  action,
  closedOnly = false,
  ...rest
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(...loadName)
  return (
    <Section {...rest}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Position ID</TableCell>
              <TableCell>Market</TableCell>
              {!closedOnly && (
                <TableCell align="right">Position Size</TableCell>
              )}
              <TableCell align="right">Avg Price</TableCell>
              <TableCell align="right">Realized PnL</TableCell>
              {!closedOnly && (
                <TableCell align="right">Margin Allocated</TableCell>
              )}
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{positions?.data?.length > 0 && (positions.data.map(renderPosition))}</TableBody>
        </ListTable>
        {positions?.data?.length > 0 && (
          <Box marginTop={3}>
            <PaginationByIndex
              response={positions}
              action={action}
              useBlockHeight={false}
            />
          </Box>
        )}
        {(positions?.length <= 0 || positions?.data?.length <= 0) && (
          <TableEmptyState itemName="positions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderPosition(position: RestResponse.Position): ReactNode {
    const entryPriceBN: BigNumber = new BigNumber(position.entry_price)
    const realizedPnlBN: BigNumber = new BigNumber(position.realized_pnl)
    const allocatedMarginBN: BigNumber = new BigNumber(position.allocated_margin_amount)
    const denom: string = position.market.split('_')[0].toUpperCase()
    const market = position.market;
    const positionId = position.position_id;
    return (
      <TableRow key={market + positionId} hover>
        <TableCell>
          <CellLink to={`/position/${positionId}`}>{shorten(positionId)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${market}`}>{market}</CellLink>
        </TableCell>
        {!closedOnly && (
          <TableCell align="right">
            {position.lots} {denom}
          </TableCell>
        )}
        <TableCell align="right">{entryPriceBN.toFormat(2)}</TableCell>
        <TableCell align="right">{realizedPnlBN.toFormat(2)}</TableCell>
        {!closedOnly && (
          <AmountDenomCell denom={position.allocated_margin_denom} amount={allocatedMarginBN.toFormat(2)} />
        )}
        <TableCell align="right">
          <CellLink to={`/block/${position.created_block_height}`}>
            {position.created_block_height}
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default Positions
