import BigNumber from 'bignumber.js'
import { Record, RecordOf } from 'immutable'
import { SimpleMap } from 'js/utils'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { TransactionsAction, TransactionsActionType } from '../actions/transactions'

export interface TransactionsProps {
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>
  types: string[]
  gasFees: SimpleMap<BigNumber>
}

const makeState: Record.Factory<TransactionsProps> = Record<TransactionsProps>({
  transactions: {
    data: [],
    min: 0,
    max: 0,
  },
  types: [],
  gasFees: {},
})
export type TransactionsState = RecordOf<TransactionsProps>

function transactionsReducer(
  state: TransactionsState = makeState(),
  action: TransactionsAction,
): TransactionsState {
  switch (action.type) {
    case TransactionsActionType.CLEAR:
      return makeState()
    case TransactionsActionType.SET_TRANSACTIONS:
      return state.updateIn(
        ['transactions'],
        (): RestTypes.ResultsMinMax<RestModels.Txn> => {
          return action.transactions
        },
      )
    case TransactionsActionType.SET_TYPES:
      return state.updateIn(
        ['types'],
        (): string[] => {
          return action.types
        },
      )
    case TransactionsActionType.SET_GAS_FEES:
      return state.updateIn(
        ['gasFees'],
        (): SimpleMap<BigNumber> => {
          return action.gasFees
        },
      )
    default:
      return state
  }
}

export default transactionsReducer
