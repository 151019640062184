import { u } from '@cityofzion/neon-core'
import { Box, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import bech32 from 'bech32'
import BigNumber from 'bignumber.js'
import { useRedux } from 'js/hooks'
import { Network } from 'js/models/Network'
import neoDapi from 'neo-dapi'
import React, { ReactElement, useState } from 'react'
import { DepositBtn } from './components'

interface Props { }

function fromBech32(prefix: string, bech32Addr: string): string {
  try {
    const { prefix: prefixCheck, words } = bech32.decode(bech32Addr)
    if (prefix !== prefixCheck) {
      throw new Error('wrong prefix')
    }
    const buffer = Buffer.from(bech32.fromWords(words))
    return buffer.toString('hex')
  } catch (err) {
    throw new Error(`Address is invalid: ${err.message}`)
  }
}

const Events: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { network } = useRedux((state) => state.app)
  const [address, setAddress] = useState('')
  const [amount, setAmount] = useState('')
  const [isAwaitingWallet, setIsAwaitingWallet] = useState(false)
  const [neoAddress, setNeoAddress] = useState(null)
  const [wallet, setWallet] = useState(null)
  const [balance, setBalance] = useState('0')
  const [message, setMessage] = useState('')

  const onAmountChange = (e: any) => setAmount(e.target.value)
  const onAddressChange = (e: any) => setAddress(e.target.value)
  const onConnectO3 = async () => {
    if (network !== Network.Main) {
      setMessage('Only MainNet is supported.')
      return
    }

    try {
      setMessage('')
      setWallet(null)
      setIsAwaitingWallet(true)
      const { address } = await neoDapi.getAccount()
      const balances = await neoDapi.getBalance({
        params: { address },
        network: 'MainNet',
      })
      const balance = balances[address].find(
        (item: any) =>
          item.assetID === 'ab38352559b8b203bde5fddfa0b07d8b2525e132',
      )
      setBalance(balance ? balance.amount : '0')
      setNeoAddress(address)
      setWallet(neoDapi)
      setMessage(`Connected to: ${address}.`)
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.error(err)
      switch (err.type) {
        case 'NO_PROVIDER':
          setMessage('Wallet not detected.')
          break
        case 'CONNECTION_DENIED':
          setMessage('Connection request rejected.')
          break
        default:
          setMessage(`Failed to connect to wallet: ${err.message}`)
      }
    } finally {
      setIsAwaitingWallet(false)
    }
  }

  const onConnectNeoLine = async () => {
    if (network !== Network.Main) {
      setMessage('Only MainNet is supported.')
      return
    }

    try {
      setMessage('')
      setWallet(null)
      setIsAwaitingWallet(true)
      if (!(window as any).NEOLine) {
        throw new Error(
          'Wallet not installed or still loading, try again in a few seconds.',
        )
      }
      const neoline = new (window as any).NEOLine.Init()
      const { address: a } = await neoline.getAccount()
      const balances = await neoline.getBalance({
        params: { address: a },
        network: 'MainNet',
      })
      const b = balances[a].find(
        (item: any) =>
          item.assetID === 'ab38352559b8b203bde5fddfa0b07d8b2525e132',
      )
      setBalance(b ? b.amount : '0')
      setNeoAddress(a)
      setWallet(neoline)
      setMessage(`Connected to: ${a}.`)
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.error(err)
      switch (err.type) {
        case 'NO_PROVIDER':
          setMessage('Wallet not detected.')
          break
        case 'CONNECTION_DENIED':
          setMessage('Connection request rejected.')
          break
        default:
          setMessage(`Failed to connect to wallet: ${err.message}`)
      }
    } finally {
      setIsAwaitingWallet(false)
    }
  }

  const onDeposit = async () => {
    try {
      setMessage(`Depositing from: ${address}.`)
      setIsAwaitingWallet(true)

      const amt = new BigNumber(amount!).shiftedBy(8)
      if (amt.isNaN()) {
        throw new Error('Invalid amount')
      }
      if (amt.lt('1000000000')) {
        throw new Error('Minumum of 10 SWTH required')
      }
      const hexAddress = fromBech32('swth', address)
      const feeAddress: string = '08d8f59e475830d9a1bb97d74285c4d34c6dac08' // little endian
      const genesisAddress: string = 'db8afcccebc026c6cae1d541b25f80a83b065c8a' // little endian
      const swthContractHash: string =
        'ab38352559b8b203bde5fddfa0b07d8b2525e132'
      const lockContractHash: string =
        'cd19745dbf1305f206978ddcfdcb7fca6ef6d017'
      const swthDenom: string = 'swth'
      const nonce: string = Math.floor(Math.random() * 1000000).toString()

      const { txid } = await (wallet as any).invoke({
        scriptHash: lockContractHash,
        operation: 'lock',
        args: [
          {
            type: 'Hash160',
            value: swthContractHash, // fromAssetHash
          },
          {
            type: 'Address',
            value: neoAddress!, // fromAddress
          },
          {
            type: 'Hash160',
            value: u.reverseHex(genesisAddress), // targetProxyHash
          },
          {
            type: 'String',
            value: swthDenom, // toAssetHash
          },
          {
            type: 'Hash160',
            value: u.reverseHex(hexAddress), // toAddress
          },
          {
            type: 'Integer',
            value: amt.toString(), // amount
          },
          {
            type: 'Integer',
            value: '100000000', // feeAmount
          },
          {
            type: 'Hash160',
            value: u.reverseHex(feeAddress), // feeAddress
          },
          {
            type: 'Integer',
            value: nonce, // nonce
          },
        ],
        network: 'MainNet',
      })
      setAmount('')
      setMessage(
        `Deposit txn sent: ${txid}. Please wait a minute for your balance to update.`,
      )
    } catch (err) {
      switch (err.type) {
        case 'NO_PROVIDER':
          setMessage('Wallet not found.')
          break
        case 'RPC_ERROR':
          setMessage(
            'There was an error when broadcasting this transaction to the network.',
          )
          break
        case 'CANCELED':
          setMessage('Deposit transaction canceled.')
          break
        default:
          setMessage(`Failed to send deposit txn: ${err.message}`)
      }
    } finally {
      setIsAwaitingWallet(false)
    }
  }

  return (
    <React.Fragment>
      <Box p={2}>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Switcheo TradeHub is still in beta — proceed at your own risk. By
          using the deposit form below, your SWTH tokens will be transferred to
          Switcheo TradeHub. This transfer is one-way and cannot be reversed at
          this time, meaning that tokens deposited cannot be withdrawn to any
          other blockchain yet. You will lose your tokens if you input an
          address you do not control.
        </Alert>
      </Box>
      <Box p={2}>
        <Paper elevation={0} variant="outlined">
          <Box p={2}>
            <Typography variant="h5" gutterBottom>
              Deposit SWTH
            </Typography>
            <Typography className={classes.instructions}>
              You will need to use a CLI program to generate a Switcheo TradeHub
              wallet. After retrieving your deposit address from your Switcheo
              TradeHub wallet, you can transfer NEP-5 SWTH tokens from O3 or
              NeoLine wallets to your Switcheo TradeHub wallet. Connect your O3
              or NeoLine wallet to begin.
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              variant="filled"
              placeholder="Your Switcheo TradeHub Address (swth1....)"
              onChange={onAddressChange}
              value={address}
            />
            <TextField
              fullWidth
              margin="dense"
              inputProps={{
                type: 'number',
              }}
              variant="filled"
              placeholder={`SWTH To Deposit (max: ${balance})`}
              onChange={onAmountChange}
              value={amount}
            />
            {neoAddress === null ? (
              <React.Fragment>
                <DepositBtn
                  disabled={isAwaitingWallet}
                  onClick={onConnectO3}
                  title="Connect O3 Wallet"
                />
                <DepositBtn
                  disabled={isAwaitingWallet}
                  onClick={onConnectNeoLine}
                  title="Connect NeoLine Wallet"
                />
              </React.Fragment>
            ) : (
                <DepositBtn
                  disabled={isAwaitingWallet}
                  onClick={onDeposit}
                  title="Deposit Now"
                />
              )}
            <Typography className={classes.message}>{message}</Typography>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  instructions: {
    paddingBottom: 10,
  },
  message: {
    paddingTop: 5,
    paddingLeft: 1,
  },
})

export default Events
