import dayjs from 'dayjs'
import { SoftwareUpgradeProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from "js/utils"
import { RPCParams } from "tradehub-api-js"
import { fieldTypes } from "../Helpers/types"

export const softwareUpgradeFormRequiredFields = ['name']

export function softwareUpgradeConfig() {
  return {
    name: {
      id: 'name',
      label: 'Name',
      type: fieldTypes.text,
      tooltip: 'Name of the plan',
    },
    time: {
      id: 'time',
      label: 'Time',
      type: fieldTypes.text,
      tooltip: 'When chain reaches proposed time, software upgrade will begin. Write in ISO 8601 datetime format. Leave blank if using height.',
      placeholder: 'Eg. 2021-07-16T19:20:00Z',
    },
    height: {
      id: 'height',
      label: 'Height',
      type: fieldTypes.number,
      tooltip: 'When chain reaches proposed height, software upgrade will begin. Leave blank if using time.',
    },
    info: {
      id: 'info',
      label: 'Info',
      type: fieldTypes.text,
      tooltip: 'Metadata about the upgrade.',
    },
  }
}

export function softwareUpgradeValidator(formState: any): string[] {
  const errors = []
  const time = formState.time as string
  const height = formState.height as string
  if ((time.length > 0 && height.length > 0) || (time.length === 0 && height.length === 0)) {
    errors.push('Must set either time or height, but not both')
  }

  if (time.length > 0) {
    const date = dayjs(time, 'YYYY-MM-DDTHH:mm:ss[Z]', true)
    const now = dayjs()
    if (!date.isValid()) {
      errors.push('Proposed time must be valid and in ISO 8601 format')
    }
    if (date.isBefore(now)) {
      errors.push('Proposed time must be in the future')
    }
  }

  if (height.length > 0) {
    const heightNo = parseNumber(height, BIG_ZERO)
    if (!heightNo?.isInteger() || heightNo.isLessThan(0)) {
      errors.push('Height must be a non-negative integer')
    }
  }
  return errors
}

export function softwareUpgradeProposalValue(): RPCParams.SoftwareUpgradeProposal {
  const formState = store.getState().governance.submitProposalFormState as SoftwareUpgradeProposalFormState

  const { title, description, name, time, height, info } = formState!

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    plan: {
      name,
      time: time.length === 0 ? undefined : time,
      height: height.length === 0 ? undefined : parseNumber(height, BIG_ZERO)!.toString(),
      info,
    },
  }

  return value
}
