import { List, ListItem, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { JSONBlock } from 'js/components'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  logs: object[]
  msgLog: RestModels.TxnMessage
}

const LogMessage: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { logs, msgLog } = props
  const { msg_type: msgType, msg } = msgLog
  const classes = useStyles()

  const msgObj: object = JSON.parse(msg)

  return (
    <List className={classes.list}>
      <ListItem className={clsx(classes.bottom,classes.type)}>
        <JSONBlock header='Type' content={{ msgType }} />
      </ListItem>
      <ListItem className={clsx(classes.bottom,classes.type)}>
        <JSONBlock header='Message' content={msgObj} />
      </ListItem>
      <ListItem className={classes.type}>
        <JSONBlock header='Logs' content={logs} />
      </ListItem>
    </List>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0, 3),
    padding: 0,
  },
  type: {
    fontWeight: 'bold',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    alignItems: 'flex-start',
  },
  bottom: {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  },
}))

export default LogMessage
