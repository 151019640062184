import { Constants } from "js/constants"
import { ParameterChangeProposalFormState } from 'js/models/Governance'
import store from "js/store"
import { BIG_ZERO, escapeHtmlGo, parseNumber } from "js/utils"
import { RPCParams } from 'tradehub-api-js'
import { getParamType } from './Modules'

export function parameterChangeProposalValue(): RPCParams.ParameterChangeProposal {
  const formState = store.getState().governance.submitProposalFormState as ParameterChangeProposalFormState

  return postProcessParameterChange(formState)
}

type PostProcessResult = {
  value: string
  parameter: string
}
type InputPostProcessor = (input: string) => PostProcessResult

const postProcessors: {
  [index: string]: InputPostProcessor
} = {
  'tallyparams-quorum': (input) => {
    return {
      parameter: 'tallyparams',
      value: JSON.stringify({
        quorum: input,
      }),
    }
  },
  'tallyparams-threshold': (input) => {
    return {
      parameter: 'tallyparams',
      value: JSON.stringify({
        threshold: input,
      }),
    }
  },
  'tallyparams-veto': (input) => {
    return {
      parameter: 'tallyparams',
      value: JSON.stringify({
        veto: input,
      }),
    }
  },
  'depositparams-min_deposit': (input) => {
    return {
      parameter: 'depositparams',
      value: JSON.stringify({
        min_deposit: [{
          denom: 'swth',
          amount: input,
        }],
      }),
    }
  },
  'depositparams-max_deposit_period': (input) => {
    return {
      parameter: 'depositparams',
      value: JSON.stringify({
        max_deposit_period: input,
      }),
    }
  },
  'votingparams-voting_period': (input) => {
    return {
      parameter: 'votingparams',
      value: JSON.stringify({
        voting_period: input,
      }),
    }
  },
}

function postProcessParameterChange(formState: ParameterChangeProposalFormState): RPCParams.ParameterChangeProposal {
  const { topic } = formState
  let { title, description, value, parameter } = formState

  title = escapeHtmlGo(title)
  description = escapeHtmlGo(description)

  const paramType = getParamType(parameter || '')
  if (paramType) {
    switch (paramType.type) {
      case 'uint64':
      case 'int64':
      case 'uint16':
        value = parseNumber(value, BIG_ZERO)!.toString()
        break
      case 'percent':
        value = parseNumber(value, BIG_ZERO)!.shiftedBy(-2).toString()
        break
      case 'coin':
        value = parseNumber(value, BIG_ZERO)!.shiftedBy(Constants.Decimals.SWTH).integerValue().toString()
        break
      case 'time':
        value = parseNumber(value, BIG_ZERO)!.times(Constants.TimeFactor.NanoToHour).toString()
        break
    }
  }

  const postProcessor = postProcessors[parameter || '']
  if (postProcessor) {
    const result = postProcessor(value || '')
    parameter = result.parameter
    value = result.value
  } else if (['percent', 'uint64', 'int64', 'coin', 'number', 'time'].includes(paramType?.type || '')) {
    value = `"${value}"`
  }

  return {
    title,
    description,
    changes: [
      {
        subspace: topic,
        key: parameter,
        value,
      },
    ],
  }
}
