import { setPositionsHistory, UpdateTradesAction } from 'js/actions/account'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, delay, Effect, put, spawn, take, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AccountActionType, clear, setAddress, setBalance, setDelegations, setExternalTransfers, setOpenOrders, setOrders, setPositions, setProfile, setRewards, setTrades, setTransactions, setTypes } from '../actions/account'
import { AppActionType } from '../actions/app'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Account extends Saga {
  private address: string
  private msgType: any
  constructor(address: string, msgType: string | null) {
    super()

    this.address = address
    this.msgType = msgType ? msgType.split(',') : []
    this.handleFilters = this.handleFilters.bind(this)
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    if (this.address.length > 15) {
      return [
        call([this, this.fetchBalance], this.address),
        call([this, this.fetchTransactions], this.address),
        call([this, this.fetchTransactionTypes]),
        call([this, this.fetchProfile]),
        call([this, this.fetchExternalTransfers], this.address),
        call([this, this.fetchDelegations]),
        call([this, this.fetchRewards]),
        call([this, this.fetchPositions]),
        call([this, this.fetchPositionsHistory]),
        call([this, this.fetchOpenOrders]),
        call([this, this.fetchAddress]),
        call([this, this.fetchOrders]),
        call([this, this.fetchTrades]),
        spawn([this, this.watchPositions]),
        spawn([this, this.watchPositionsHistory]),
        spawn([this, this.watchOrders]),
        spawn([this, this.watchOpenOrders]),
        spawn([this, this.watchTrades]),
        spawn([this, this.watchFilters]),
        spawn([this, this.watchSetNetwork]),
      ]
    }
    return [call([this, this.fetchAddressFromUsername])]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchAddressFromUsername(): any {
    const sdk = yield* getInitializedSDK()
    const result = yield sdk.api.getAddressForUsername({ username: this.address })
    if (result !== null && result !== '') {
      this.address = result
      yield* this.stop()
      yield* this.getStartEffects()
    }
  }

  private *fetchBalance(address: string): any {
    const account = address
    yield runSagaTask(TaskNames.Account.Holdings, function* () {
      const sdk = yield* getInitializedSDK()
      const balances = (yield call([sdk.api, sdk.api.getWalletBalance], {
        account,
      })) as RestModels.Balances;
      yield put(setBalance(balances))
    });
  }

  private *fetchTransactions(address: string): any {
    const msgType = this.msgType
    yield runSagaTask(TaskNames.Account.Transactions, function* () {
      // TODO: Don't know what's going on with this code
      // let msgType = ''
      // this.msgType.forEach((t: string) => {
      //   msgType = `${msgType}${t},`
      // })
      let curMsgType = ''
      msgType.forEach((t: string) => {
        curMsgType = `${msgType}${t},`
      })
      const sdk = yield* getInitializedSDK()
      const txs = (yield sdk.api.getTxsPaged({
        limit: "20",
        address,
        msg_type: curMsgType,
      })) as RestTypes.ResultsMinMax<RestModels.Txn>
      yield put(setTransactions(txs as RestTypes.ResultsMinMax<RestModels.Txn>))
    });
  }

  private *fetchTransactionTypes(): any {
    yield runSagaTask(TaskNames.Account.TransactionType, function* () {
      const sdk = yield* getInitializedSDK()

      const types = (yield sdk.api.getTxTypes()) as string[]
      yield put(setTypes(types))
    });
  }

  private *fetchProfile(): any {
    const account = this.address
    yield runSagaTask(TaskNames.Account.Profile, function* () {
      const sdk = yield* getInitializedSDK()
      const profile = (yield call([sdk.api, sdk.api.getProfile], {
        account,
      })) as RestModels.Profile;
      yield put(setProfile(profile))
    });
  }

  private *watchPositions(): any {
    while (true) {
      const account = this.address
      yield runSagaTask(TaskNames.Account.PositionsFilter, function* () {
        const action: any = yield take(AccountActionType.UPDATE_POSITIONS)
        const sdk = yield* getInitializedSDK()

        const positions = (yield sdk.api.getPositionsPaged({
          account,
          limit: 5,
          status: 'open',
          ...action.options.pagination,
        })) as RestTypes.ResultsMinMax<RestModels.Position>
        yield put(setPositions(positions))
      });
      yield delay(6020)
    }
  }

  private *fetchPositions(): any {
    const account = this.address
    yield runSagaTask(TaskNames.Account.Positions, function* () {
      const sdk = yield* getInitializedSDK()
      const positions = (yield call([sdk.api, sdk.api.getPositionsPaged], {
        account,
        limit: 5,
        status: 'open'
      })) as RestTypes.ResultsMinMax<RestModels.Position>
      yield put(setPositions(positions))
    });
  }

  private *watchPositionsHistory(): any {
    while (true) {
      const account = this.address
      yield runSagaTask(TaskNames.Account.PositionsHistoryFilter, function* () {
        const action: any = yield take(AccountActionType.UPDATE_POSITIONS_HISTORY)
        const sdk = yield* getInitializedSDK()
        const positions = (yield sdk.api.getPositionsPaged({
          account,
          limit: 10,
          status: 'closed',
          ...action.options.pagination,
        })) as RestTypes.ResultsMinMax<RestModels.Position>
        if (action.options.pagination.order_by === "asc") {
          positions.data.reverse();
        }
        yield put(setPositionsHistory(positions))
      });
    }
  }

  private *fetchPositionsHistory(): any {
    const account = this.address
    yield runSagaTask(TaskNames.Account.PositionsHistory, function* () {
      const sdk = yield* getInitializedSDK()
      const positions = (yield call([sdk.api, sdk.api.getPositionsPaged], {
        account,
        limit: 10,
        status: 'closed',
      })) as RestTypes.ResultsMinMax<RestModels.Position>
      yield put(setPositionsHistory(positions))
    });
  }

  private *fetchOpenOrders(): any {
    const account = this.address
    yield runSagaTask(TaskNames.Account.OpenOrders, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        account,
        order_status: 'open',
        limit: 5
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      yield put(setOpenOrders(orders))
    });
  }

  private *watchOpenOrders(): any {
    while (true) {
      const account = this.address
      yield runSagaTask(TaskNames.Account.OpenOrdersFilter, function* () {
        const action: any = yield take(AccountActionType.UPDATE_OPEN_ORDERS)
        const sdk = yield* getInitializedSDK()

        const orders = (yield sdk.api.getOrdersPaged({
          account,
          order_status: 'open',
          limit: 5,
          ...action.options.pagination,
        })) as RestTypes.ResultsMinMax<RestModels.Order>
        if (action.options.pagination.order_by === "asc") {
          orders.data.reverse();
        }
        yield put(setOpenOrders(orders))
      });
      yield delay(6020)
    }
  }

  private *fetchOrders(): any {
    const account = this.address
    yield runSagaTask(TaskNames.Account.OrderList, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        account,
        limit: 5
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      yield put(setOrders(orders))
    });
  }

  private *watchOrders(): any {
    while (true) {
      const account = this.address
      yield runSagaTask(TaskNames.Account.OrderFilter, function* () {
        const action: any = yield take(AccountActionType.UPDATE_ORDERS)
        const sdk = yield* getInitializedSDK()

        const orders = (yield sdk.api.getOrdersPaged({
          account,
          limit: 5,
          ...action.options.pagination
        })) as RestTypes.ResultsMinMax<RestModels.Order>
        if (action.options.pagination.order_by === "asc") {
          orders.data.reverse();
        }
        yield put(setOrders(orders))
      });
    }
  }

  private *fetchTrades(): any {
    const account = this.address
    yield runSagaTask(TaskNames.Account.TradesByAccount, function* () {
      const sdk = yield* getInitializedSDK()

      const trades = (yield sdk.api.getAccountTradesPaged({
        account,
        limit: 5
      })) as RestTypes.ResultsMinMax<RestModels.AccountTrade>
      yield put(setTrades(trades))
    });
  }

  private *watchTrades(): any {
    while (true) {
      const account = this.address
      yield runSagaTask(TaskNames.Account.TradesByAccount, function* () {
        const action = (yield take(AccountActionType.UPDATE_TRADES)) as UpdateTradesAction
        const sdk = yield* getInitializedSDK()

        const trades = (yield sdk.api.getAccountTradesPaged({
          account,
          limit: 5,
          ...action.options.pagination,
        })) as RestTypes.ResultsMinMax<RestModels.AccountTrade>
        if (action.options.pagination.order_by === "asc") {
          trades.data.reverse()
        }
        yield put(setTrades(trades))
      });
    }
  }

  private *fetchDelegations(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.Delegations, function* () {
      const sdk = yield* getInitializedSDK()

      const response = (yield sdk.api.getDelegatorDelegations({
        address,
      })) as RestTypes.GetDelegatorDelegationsResponse
      yield put(setDelegations(response.result))
    });
  }

  private *fetchRewards(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.Rewards, function* () {
      const sdk = yield* getInitializedSDK()

      const response = (yield sdk.api.getDelegatorDelegationRewards({
        address,
      })) as RestTypes.GetDelegatorDelegationRewardsResponse

      yield put(setRewards(response.result))
    });
  }

  private *fetchAddress(): any {
    yield put(setAddress(this.address))
  }

  private *watchFilters(): any {
    yield takeLatest(AccountActionType.UPDATE_FILTERS, this.handleFilters)
  }

  private *handleFilters(action: any): any {
    let msgType = ''
    const filters = action.options.filters
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key]) {
          msgType = `${msgType}${key},`
        }
      }
    }
    const address = this.address
    yield runSagaTask(TaskNames.Account.TransactionFilter, function* () {
      try {
        const sdk = yield* getInitializedSDK()
        const txs = (yield sdk.api.getTxsPaged({
          limit: "100",
          msg_type: msgType,
          address,
          ...action.options.pagination,
        })) as RestTypes.ResultsMinMax<RestModels.Txn>
        if (action.options.pagination.order_by === "asc") {
          txs.data.reverse();
        }
        yield put(setTransactions(txs as RestTypes.ResultsMinMax<RestModels.Txn>))
      } catch (err) {
        yield put(setTransactions({
          data: [],
          min: 0,
          max: 0,
        }))
      }
    });
  }

  private *fetchExternalTransfers(address: string): any {
    yield runSagaTask(TaskNames.Account.ExternalTransfers, function* () {
      const sdk = yield* getInitializedSDK()

      const transfers = (yield sdk.api.getTransfers({
        account: address,
      })) as RestModels.Transfer[]

      yield put(setExternalTransfers(transfers))
    });
  }
}
