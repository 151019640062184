import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiTooltip']>> => ({
  tooltipArrow: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  tooltip: {
    ...theme.typography.body2,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
})
