import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { NodesAction, NodesActionType } from '../actions/nodes'

export interface NodesProps {
  nodes: RestModels.NodeData[]
}

const makeState: Record.Factory<NodesProps> = Record<NodesProps>({
  nodes: [],
})
export type NodesState = RecordOf<NodesProps>

function nodesReducer(
  state: NodesState = makeState(),
  action: NodesAction,
): NodesState {
  switch (action.type) {
    case NodesActionType.CLEAR:
      return makeState()
    case NodesActionType.SET_NODES:
      return state.updateIn(['nodes'], () => action.nodes)
    default:
      return state
  }
}

export default nodesReducer
