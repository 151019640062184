import { Box, BoxProps, makeStyles } from '@material-ui/core'
import cls from 'classnames'
import React from 'react'
import ReactJson from 'react-json-view'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowX: 'auto',
  },
  header: {
    flexBasis: '80px',
    minWidth: '80px',
  },
}))

interface Props extends BoxProps {
  header?: string
  content?: any
}

const JSONBlock: React.FC<Props> = (props: Props) => {
  const { children, className, header, content, ...rest } = props
  const classes = useStyles()
  return (
    <Box {...rest} className={cls(classes.root, className)}>
      <div className={classes.header}>{header}</div>
      <ReactJson
        src={content}
        displayDataTypes={false}
        enableClipboard={false}
        displayObjectSize={false}
        name={false}
      />
    </Box>
  )
}

export default JSONBlock
