import { Grid, makeStyles, Theme } from '@material-ui/core'
import { clearSubmitProposalFormState } from 'js/actions/governance'
import { Page } from 'js/components'
import { Paths } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { ConfirmSubmitDialog, ProposalInfo, TallyCriteriaInfo } from './components'
import MainForm from './components/SubmitProposalForm/Forms/MainForm'

interface Props { }

const SubmitProposal: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const history = useHistory()
  const openConfirmationDialog = false
  const formState = useRedux((state) => state.governance.submitProposalFormState)
  const depositAmount = useRedux((state) => state.governance.submitProposalDepositAmount)
  const submittedProposalId = useRedux((state) => state.governance.submitProposalId)

  useEffect(() => {
    if (!submittedProposalId) return
    history.push(Paths.Proposal.replace(':proposalId', submittedProposalId?.toString()))
  }, [history, submittedProposalId])

  const resetFormState = () => {
    dispatch(clearSubmitProposalFormState())
  }

  const onConfirm = () => { }

  return (
    <Page
      backLink={Paths.Governance}
      backLabel="Governance Proposals"
      title="Submit a Proposal"
      className={classes.root}
    >

      {/* Use for parameter change proposal description */}
      {/* <Typography variant="body1">
        Submit a parameter-change governance proposal to enact real changes to Switcheo TradeHub.
        Learn more about the role of module and parameter
        {' '}
        <CellLink
          underline='hover'
          href={Paths.Resources.TypesOfProposals}
        >
          here
        </CellLink>.

      </Typography> */}

      <MainForm />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ProposalInfo />
        </Grid>
        <Grid item xs={12} md={6}>
          <TallyCriteriaInfo />
        </Grid>
      </Grid>
      <ConfirmSubmitDialog
        formState={formState}
        depositAmount={depositAmount}
        onClose={resetFormState}
        onConfirm={onConfirm}
        isOpen={openConfirmationDialog}
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
}))

export default SubmitProposal
