import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByIndex, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { shorten } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels, RestTypes } from 'tradehub-api-js'

export interface TradesProps {
  tradesByAccount?: RestTypes.ResultsMinMax<RestModels.AccountTrade>
  title: string
  action: any
  markets: any
  loadname: string[]
  loadCondition?: boolean
}

interface Props extends TradesProps { }

const TradesByAccount: React.FunctionComponent<Props> = ({
  tradesByAccount,
  title,
  action,
  markets,
  loadname = [],
  loadCondition = true,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.App.Markets, ...loadname)

  return (
    <Section title={title}>
      <DataLoadSegment loading={loading && loadCondition}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Market</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Side</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Fees</TableCell>
              <TableCell align="right">Matched At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tradesByAccount?.data.map(renderTrade)}</TableBody>
        </ListTable>
        {!!tradesByAccount?.data.length && (
          <Box marginTop={3}>
            <PaginationByIndex
              response={tradesByAccount}
              action={action}
              useBlockHeight={false}
            />
          </Box>
        )}
        {!tradesByAccount?.data.length && (
          <TableEmptyState itemName="trades" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTrade(tradeByAccount: RestModels.AccountTrade, index: number): ReactNode {
    const {
      market,
      side,
      price,
      quantity,
      order_id: orderId,
      fee_amount: feeAmount,
      fee_denom: feeDenom,
      block_height: blockHeight,
    } = tradeByAccount
    const priceBN: BigNumber = new BigNumber(price)
    const quantityBN: BigNumber = new BigNumber(quantity)
    const feeBN: BigNumber = new BigNumber(feeAmount)
    const lotDp: number = new BigNumber(markets[market].lotSize).dp()
    const tickDp: number = new BigNumber(markets[market].tickSize).dp()
    return (
      <TableRow
        key={`${orderId}${index.toString()}accountTrade`}
        hover
      >
        <TableCell>
          <CellLink to={`/market/${market}`}>{market}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/order/${orderId}`}>{shorten(orderId)}</CellLink>
        </TableCell>
        <TableCell>{side}</TableCell>
        <TableCell align="right">{quantityBN.toFormat(lotDp)}</TableCell>
        <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
        <AmountDenomCell denom={feeDenom} amount={feeBN.toFormat(8)} />
        <TableCell align="right">
          <CellLink to={`/block/${blockHeight}`}>{blockHeight}</CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default TradesByAccount
