import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import {
  Effect,
  put,
  spawn,
  takeLatest
} from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setOrder } from '../actions/order'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class O extends Saga {
  private readonly id: string
  constructor(id: string) {
    super()
    this.id = id
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.fetchOrder], [this, this.watchSetNetwork]].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchOrder(): any {
    const order_id = this.id
    yield runSagaTask(TaskNames.Order.Detail, function* () {
      const sdk = yield* getInitializedSDK()

      const order = (yield sdk.api.getOrder({
        order_id,
      })) as RestModels.Order
      yield put(setOrder(order))
    });
  }
}
