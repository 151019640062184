import { TableCell, TableRow } from '@material-ui/core'
import { CellLink, OrderStatusLabel } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useAssetSymbol } from 'js/hooks'
import { bnOrZero, SimpleMap } from 'js/utils'
import React, { ReactElement, useMemo } from 'react'
import { RestModels } from 'tradehub-api-js'

export interface LiquidationEngineRowProps {
  order: RestModels.Order;
  markets: SimpleMap<RestModels.Market>
}

const LiquidationEngineRow: React.FunctionComponent<LiquidationEngineRowProps> = ({
  order,
  markets,
}: LiquidationEngineRowProps): ReactElement<LiquidationEngineRowProps> => {
  const {
    market: marketName,
    side,
    order_status,
    quantity,
    block_height,
    order_id,
    price,
  } = order
  const assetSymbol = useAssetSymbol({
    WBTC: 'BTC'
  })
  const market = markets[marketName]
  const quantityBN = bnOrZero(quantity)
  const priceBN = bnOrZero(price)
  const lotDp = bnOrZero(market?.lot_size).dp()
  const tickDp = bnOrZero(market?.tick_size).dp()

  const positionSide = useMemo(() => side === 'buy' ? 'Short' : 'Long', [side])

  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/order/${order_id}`}>{shorten(order_id)}</CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/market/${marketName}`}>{market.display_name}</CellLink>
      </TableCell>
      <TableCell>{positionSide}</TableCell>
      <TableCell>
        <OrderStatusLabel labelsOverride={{ Cancelled: 'Failed', Filled: 'Success' }}>{order_status}</OrderStatusLabel>
      </TableCell>
      <TableCell align="right">{quantityBN.toFormat(lotDp)} {assetSymbol(market.base)}</TableCell>
      <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
      <TableCell align="right">
        <CellLink to={`/block/${block_height}`}>{block_height}</CellLink>
      </TableCell>
    </TableRow>
  )
}

export default LiquidationEngineRow
