import { TableRow } from '@material-ui/core'
import { TableCellInfo, TableSectionCell } from 'js/components'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  model: RestModels.Market
}

const SettledMarketRender: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props

  const cellData: TableCellInfo[] = [
    {
      align: 'inherit',
      content: model?.name,
      to: `/market/${model?.name}`,
    },
    {
      content: model?.expiry_time,
      type: 'date',
    },
    {
      content: model?.closed_block_height,
      to: `/block/${model?.closed_block_height}`,
    }
  ]

  return (
    <TableRow key={model?.name} hover>
      {
        cellData.map((cell: TableCellInfo, index: number) => (
          <TableSectionCell
            key={index}
            {...cell}
          />
        ))
      }
    </TableRow>
  )
}

export default SettledMarketRender
