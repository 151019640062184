import { Box, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CellLink, Section, StandardBtn } from 'js/components'
import { Constants, Paths } from 'js/constants'
import { useRedux, useWallet } from 'js/hooks'
import { BIG_ZERO, BIG_ONE, formatTokenNumber, makeMoneyLabel, parseNumber } from 'js/utils'
import React from 'react'
import { RestModels } from 'tradehub-api-js'
import { CustomTable } from '../../components'

interface Props {
  proposal: RestModels.GovProposal
  onClickDeposit: () => void
}

const DepositDetails: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { proposal, onClickDeposit } = props
  const wallet = useWallet()

  const totalCurrentDeposit = parseNumber(
    proposal.total_deposit
      .find((deposit: RestModels.TokenAmount) => deposit.denom === 'swth')
      ?.amount,
    BIG_ZERO
  )!

  const depositParams = useRedux((state) => state.governance.depositParams)

  const minDeposit = parseNumber(
    depositParams?.min_deposit?.find((value: RestModels.TokenAmount) => value.denom === 'swth')?.amount,
    BIG_ZERO
  )!
  const remainingDeposit = BigNumber.max(
    formatTokenNumber(minDeposit.minus(totalCurrentDeposit), Constants.Decimals.SWTH),
    BIG_ZERO
  )

  let normalisedProgress = totalCurrentDeposit.times(100).div(minDeposit)

  // Show a bit of progress if current deposit is too low
  normalisedProgress = normalisedProgress.lt(1) ? BIG_ONE : normalisedProgress

  return (
    <>
      <Section title="Participate in Deposit">
        You can support this proposal by contributing to the deposit.
        When it reaches the minimum deposit amount, the proposal will enter
        it's voting period.
        <CustomTable
          headerAndCells={[
            { header: 'Current deposit', cell: `${formatTokenNumber(totalCurrentDeposit, Constants.Decimals.SWTH).toFixed(8)} SWTH` },
            { header: 'Min deposit', cell: `${makeMoneyLabel(minDeposit)} SWTH` },
          ]}
        />
        <LinearProgress variant="determinate" value={normalisedProgress.toNumber()} />
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Box display="inline" fontWeight="bold">
            {`${remainingDeposit.toString()} SWTH`}
          </Box>
          <Box display="inline">&nbsp; left to enter voting period</Box>
        </Box>
        {
          wallet ?
            <StandardBtn
              variant="contained"
              color="primary"
              onClick={onClickDeposit}
              className={classes.button}
            >
              <Typography variant="h3">Deposit</Typography>
            </StandardBtn>
            :
            <StandardBtn
              to="/login"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Typography variant="h3">Login to deposit</Typography>
            </StandardBtn>
        }
        <Box marginTop={1.5}>
          <Typography variant='body2'>How does deposits work?&nbsp;
            <CellLink underline="hover" href={Paths.Resources.CreateAndSubmitProposals}>
              Learn more
            </CellLink>
          </Typography>
        </Box>
      </Section>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: '1rem',
    justifyContent: 'space-between',
  },
}))

export default DepositDetails
