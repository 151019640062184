import { TableRow } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { TableCellInfo, TableSectionCell, TooltipHint } from 'js/components'
import { useRedux } from 'js/hooks'
import { FullTokenBalance } from 'js/models/Balance'
import React, { ReactElement } from 'react'

interface Props extends FullTokenBalance {
}

const HoldingsRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const sdk = useRedux(state => state.core.sdk)
  const { total, staked, available, order, position, denom } = props
  const availableBN: BigNumber = new BigNumber(available)
  const orderBN: BigNumber = new BigNumber(order)
  const positionBN: BigNumber = new BigNumber(position)
  const decimals = sdk?.token.getDecimals(denom) ?? 0
  const symbol = sdk?.token.getTokenName(denom) ?? denom;

  const HoldingCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: (
        <TooltipHint title={denom}>
          <span>{symbol}</span>
        </TooltipHint>
      ),
      to: `/token/${denom}`,
    },
    {
      content: total.toFormat(decimals),
      bold: true
    },
    {
      content: availableBN.toFormat(decimals),
    },
    {
      content: orderBN.toFormat(decimals),
    },
    {
      content: positionBN.toFormat(decimals),
    },
    {
      content: staked.toFormat(decimals),
    },
  ]

  return (
    <TableRow key={denom} hover>
      {HoldingCells.map((cell: any, index: number) => (
        <TableSectionCell
          key={index}
          {...cell}
        />
      ))}
    </TableRow>
  )
}

export default HoldingsRow
