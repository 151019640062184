import { Box, Breadcrumbs, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import { BigNumber } from 'bignumber.js'
import { CellLink, DataLoadSegment, NotFoundState, Page, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { Paths, TaskNames } from 'js/constants'
import { GECKO_SYMBOLS, MARKETS_PAIR } from 'js/helpers'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, useEffect, useState } from 'react'
import { RestModels } from 'tradehub-api-js'
import { OrderDetails, OrderUserDetails, RelatedTrades } from './components'

interface Props { }

interface CollectiveTrades {
  avgPrice: BigNumber,
  fees: BigNumber,
  denom: string
}

const O: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Order.Detail, TaskNames.App.GeckoCoin, TaskNames.Account.Profile, TaskNames.App.Markets)
  const sdk = useRedux((state) => state.core.sdk)
  //APIs
  const { order } = useRedux((state) => state.order)
  const { geckoPrices, markets } = useRedux((state) => state.app)
  const [getTrades, tradeLoading] = useAsyncTask('getTrades')
  const [getProfile, profileLoading] = useAsyncTask('getProfile')
  const [getMarketStats, marketsLoading] = useAsyncTask('getMarketStats')
  //States
  const [currentPrice, setCurrentPrice] = useState<number>(0)
  const [dollarValue, setDollarValue] = useState<number>(0)
  const [trades, setTrades] = useState<RestModels.Trade[]>([])
  const [twitter, setTwitter] = useState<string>("")
  const [demexPrice, setDemexPrice] = useState<string>("")
  const [pairNotFound, setPairNotFound] = useState<Boolean>(false)
  const [takerOrMaker, setTakerOrMaker] = useState<string>("taker")
  const [marketDetails, setMarketDetails] = useState<RestModels.Market | undefined>()
  const [collectiveTrades, setCollectiveTrades] = useState<CollectiveTrades>({ avgPrice: new BigNumber(0), fees: new BigNumber(0), denom: "" })
  const [totalDollarValue, setTotalDollarValue] = useState<number>(0)
  const [isFutures, setIsFutures] = useState<boolean>(false)
  //For pagination
  const [page, SetPage] = useState<number>(1)
  const itemsPerPage = 5
  const shareMsg = totalDollarValue >= 20000 ? `🚨 🐋 🚨 🐋  WHALE ALERT!! Check out this position on %23Demex!` : `Check out this position on %23Demex!`

  useEffect(() => {
    getTrades(async () => {
      if (!order || !sdk) return
      try {
        const result = await sdk.api.getTrades({ account: order.address, order_id: order.order_id })
        const filtered = result.filter((trade: any) => trade.taker_id === order.order_id || trade.maker_id === order.order_id)
        if (filtered && filtered.length > 0) {
          if (filtered[0].taker_id === order.order_id) setTakerOrMaker("taker")
          else setTakerOrMaker("maker")
        }
        setTrades(filtered)
      } catch (err) {
        console.error(err)
      }
    })

    getProfile(async () => {
      if (!sdk) return
      try {
        const result = await sdk.api.getProfile({ account: order?.address })
        setTwitter(result.twitter)
      } catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, sdk])

  //get current price
  useEffect(() => {
    if (!order || loading) return
    if (!MARKETS_PAIR.includes(order.market) && markets[order.market].market_type === "spot") {
      setPairNotFound(true)
      return
    }
    //Set tick_size, marketType ..etc
    setMarketDetails(markets[order.market])

    //Get token price in USD
    const tradingPair = order.market.split("_")
    const pair1 = GECKO_SYMBOLS[tradingPair[0]]
    let pair2 = GECKO_SYMBOLS[tradingPair[1]]
    if (markets[order.market].market_type === "futures" || !pair2) pair2 = "usd-coin"
    if (markets[order.market].market_type === "futures") setIsFutures(true)
    setCurrentPrice(geckoPrices[pair1].usd / geckoPrices[pair2].usd)
    setDollarValue(geckoPrices[pair2].usd)

    //API for getting demex price
    getMarketStats(async () => {
      if (!sdk) return;
      try {
        const result = await sdk.api.getMarketStats({ market: order.market })
        setDemexPrice(result[0].last_price)
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, sdk, loading])

  useEffect(() => {
    if (!loading && trades.length > 0) {
      setCollectiveTrades(collectiveTrade => {
        let totalQuantity = new BigNumber(0)
        let avgPrice = new BigNumber(0);
        let fees = new BigNumber(0);
        for (let i = 0; i < trades.length; i++) {
          totalQuantity = totalQuantity.plus(trades[i].quantity)
          avgPrice = avgPrice.plus(Number(trades[i].price) * Number(trades[i].quantity))
          fees = fees.plus(Number(trades[i][takerOrMaker === "taker" ? "taker_fee_amount" : "maker_fee_amount"]))
        }
        collectiveTrade.avgPrice = avgPrice.div(totalQuantity)
        collectiveTrade.fees = fees
        collectiveTrade.denom = trades[0][takerOrMaker === "taker" ? "taker_fee_denom" : "maker_fee_denom"]
        return collectiveTrade
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerOrMaker, trades.length])

  useEffect(() => {
    if (order && dollarValue > 0) {
      const totalValue = Number(order.quantity) * Number(order.price) * dollarValue
      setTotalDollarValue(totalValue)
    }
  }, [dollarValue, order])

  //If trading pair is not found , return NotFoundState
  if (!loading && pairNotFound) return (
    <Page>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={`/markets`}>Markets</CellLink>
        <CellLink to={`/market/${order?.market}`}>{order?.market.toUpperCase()}</CellLink>
        <Typography>Order Details</Typography>
      </Breadcrumbs>
      <NotFoundState title="Trading pair not found">
        <Typography variant="body1">
          We can’t find any order with this ID. Please check your network setting or go back to the&nbsp;
          <CellLink to={Paths.Markets}>
            Orders
          </CellLink>
          &nbsp;page to view existing orders.
        </Typography>
      </NotFoundState>
    </Page>
  )

  return (
    <Page>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={`/markets`}>Markets</CellLink>
        <CellLink to={`/market/${order?.market}`}>
          <span>
            {order?.market.toUpperCase()}
          </span>
        </CellLink>
        <Typography>Order Details</Typography>
      </Breadcrumbs>
      <DataLoadSegment loading={loading || tradeLoading || profileLoading || marketsLoading}>
        <Box display="flex" className={classes.box}>
          <Typography display={"inline"} className={classes.title} variant="h1">Order Details</Typography>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<TwitterIcon fontSize={'small'} className={classes.twitterIcon} />}
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?text=${shareMsg}&url=https://switcheo.org/order/${order?.order_id}`,
                '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600',
              )
            }}
          >
            Share
          </Button>
        </Box>
        {!!order?.order_id && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={4}>
              <OrderUserDetails
                address={order.address}
                orderId={order.order_id}
                username={order.username}
                orderStatus={order.is_liquidation ? "Liquidated" : order.order_status}
                initiator={order.initiator}
                blockCreatedAt={order.block_created_at}
                blockHeight={order.block_height}
                twitterHandler={twitter || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              {!!marketDetails && (
                <OrderDetails
                  marketDetails={marketDetails}
                  orderStatus={order.order_status}
                  market={order.market}
                  side={order.side}
                  orderType={order.order_type}
                  quantity={order.quantity}
                  price={order.price}
                  currentPrice={currentPrice}
                  dollarValue={dollarValue}
                  filled={order.filled}
                  timeInForce={order.time_in_force}
                  allocatedMarginDenom={order.allocated_margin_denom}
                  stopPrice={order.stop_price}
                  demexPrice={demexPrice}
                  collectiveTrades={collectiveTrades}
                  venue={"Demex"}
                  isFutures={isFutures}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Section className={classes.section}>
          <div className={classes.titleContainer}>
            <Typography variant={"h2"} display={"inline"} className={classes.title}> Related Trades </Typography>
            <Typography display={"inline"}> Include all the trades that filled this order</Typography>
          </div>
          {(trades?.length > 0) && (
            <RelatedTrades
              trades={trades.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)}
              type={order?.order_type}
              takerOrMaker={takerOrMaker} />
          )}
          {(!trades?.length) && (<TableEmptyState itemName={"trades"} />)}
          {trades && trades.length > 0 && (
            <Box paddingTop={3}>
              <PaginationByData
                data={trades}
                setPage={SetPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Box>
          )}
        </Section>
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  title: {
    marginRight: theme.spacing(0.5)
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  twitterIcon: {
    margin: theme.spacing(0, -0.5, 0, 0.5),
  },
  button: {
    borderRadius: 0,
    marginLeft: 'auto',
    backgroundColor: switcheo.twitter,
    padding: theme.spacing(1.5, 3),
    alignSelf: 'center',
    height: 'fit-content',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: 'white',
    wordBreak: 'normal',
    justifyContent: 'center',
    "&:hover": {
      backgroundColor: switcheo.twitterHover
    }
  },
  box: {
    marginBottom: theme.spacing(1.5)
  },
  section: {
    margin: theme.spacing(5, 0)
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0)
  }
}))

// Construct canvas banner and return its dataURL
// const OrderBanner = ( username: string, market: string, orderType: string, lots: string, denom: string, width: number = 600, height: number = 314) => {
//   const textColor = "#E2FCA4";
//   const background = "#073C50";
//   const sellTextColor = "#F24F4C";
//   const buyTextColor = "#38DD2A";
//   // const [base64, setBase64] = useState("");

//   const canvas = document.createElement("canvas");
//   canvas.width = width;
//   canvas.height = height;
//   const context = canvas.getContext("2d");
//   const innerWidth = canvas.width - 20;
//   const innerHeight = canvas.height - 20;
//   context!.fillStyle = "#FFFFFF";
//   context!.fillRect(10, 10, canvas.width, canvas.height);

//   context!.fillStyle = background;
//   context!.fillRect(10, 10, innerWidth, innerHeight);

//   context!.font = "30px Union Regular";
//   context!.fillStyle = textColor;
//   context!.fillText("TRADESCAN", 30, 50);

//   context!.font = "18px Union Regular";
//   context!.fillText(`${username} placed an order on Demex`, 30, 110);

//   context!.fillRect(30, 130, 300, 1);

//   context!.font = "18px Union Regular";
//   context!.fillText(`${market}`, 30, 160);
//   let textWidth = context!.measureText(market);
//   context!.fillText(`|`, 30 + textWidth.width + 10, 160);
//   context!.fillStyle = buyTextColor;
//   context!.font = "bold 18px Union Regular";
//   context!.fillText(`${orderType}`, 30 + textWidth.width + 25, 160);

//   context!.fillStyle = textColor;
//   context!.font = "bold 60px Union Regular";
//   context!.fillText(`${lots} ${denom}`, 30, 220);

//   context!.font = "18px Union Regular";
//   context!.fillText(`Price:`, 30, 260);
//   context!.fillText(`Created at:`, 30, 280);
//   textWidth = context!.measureText("Created at:");
//   context!.fillText(`0.0485 USDC`, 30 + textWidth.width + 30, 260);
//   context!.fillText(`2021-03-08 14:41:34`, 30 + textWidth.width + 30, 280);

//   return canvas.toDataURL("image/png", 1);
// };

export default O
