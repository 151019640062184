import BigNumber from 'bignumber.js'
import { Action } from 'redux'
import { RestModels, RewardsDistributedResponse } from 'tradehub-api-js'

export enum DashboardActionType {
  CLEAR = 'CLEAR_DASHBOARD',
  SET_BLOCK_TIME = 'SET_BLOCK_TIME',
  RECEIVE_TRANSACTION = 'RECEIVE_DASHBOARD_TRANSACTION',
  SET_BLOCKS = 'SET_DASHBOARD_BLOCKS',
  SET_TRANSACTIONS = 'SET_DASHBOARD_TRANSACTIONS',
  SET_INSURANCE_BALANCES = 'SET_INSURANCE_BALANCES',
  SET_OI = 'SET_OI',
  SET_VOLUME = 'SET_VOLUME',
  SET_AVG_REWARD = 'SET_AVG_REWARD',
  SET_ACTIVE_WALLETS = 'SET_ACTIVE_WALLETS',
  SET_REWARDS_DISTRIBUTED = 'SET_REWARDS_DISTRIBUTED',
}

export type DashboardAction =
  | ClearAction
  | SetBlockTimeAction
  | SetBlocksAction
  | SetTransactionsAction
  | SetInsuranceBalancesAction
  | SetOIAction
  | SetVolumeAction
  | SetAvgRewardAction
  | SetActiveWalletAction
  | SetRewardsDistributedAction

export interface ClearAction extends Action<DashboardActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: DashboardActionType.CLEAR,
  }
}

export interface SetBlockTimeAction
  extends Action<DashboardActionType.SET_BLOCK_TIME> {
  blockTime: string
}
export function setBlockTime(blockTime: string): SetBlockTimeAction {
  return {
    type: DashboardActionType.SET_BLOCK_TIME,
    blockTime,
  }
}

export interface SetAvgRewardAction
  extends Action<DashboardActionType.SET_AVG_REWARD> {
  avgReward: BigNumber
}
export function setAvgReward(avgReward: BigNumber): SetAvgRewardAction {
  return {
    type: DashboardActionType.SET_AVG_REWARD,
    avgReward,
  }
}
export interface SetActiveWalletAction
  extends Action<DashboardActionType.SET_ACTIVE_WALLETS> {
  activeWallets: BigNumber
}
export function setActiveWallets(
  activeWallets: BigNumber,
): SetActiveWalletAction {
  return {
    type: DashboardActionType.SET_ACTIVE_WALLETS,
    activeWallets,
  }
}

export interface SetBlocksAction
  extends Action<DashboardActionType.SET_BLOCKS> {
  blocks: RestModels.Block[]
}
export function setBlocks(blocks: RestModels.Block[]): SetBlocksAction {
  return {
    type: DashboardActionType.SET_BLOCKS,
    blocks,
  }
}

export interface SetTransactionsAction
  extends Action<DashboardActionType.SET_TRANSACTIONS> {
  transactions: Array<RestModels.Txn>
}
export function setTransactions(
  transactions: Array<RestModels.Txn>,
): SetTransactionsAction {
  return {
    type: DashboardActionType.SET_TRANSACTIONS,
    transactions,
  }
}

export interface SetInsuranceBalancesAction
  extends Action<DashboardActionType.SET_INSURANCE_BALANCES> {
  insurance: RestModels.InsuranceFundBalance
}

export function setInsuranceBalances(
  insurance: RestModels.InsuranceFundBalance,
): SetInsuranceBalancesAction {
  return {
    type: DashboardActionType.SET_INSURANCE_BALANCES,
    insurance,
  }
}

export interface SetOIAction extends Action<DashboardActionType.SET_OI> {
  oi: any
}

export function setOI(oi: any): SetOIAction {
  return {
    type: DashboardActionType.SET_OI,
    oi,
  }
}
export interface SetVolumeAction
  extends Action<DashboardActionType.SET_VOLUME> {
  volume: any
}

export function setVolume(volume: any): SetVolumeAction {
  return {
    type: DashboardActionType.SET_VOLUME,
    volume,
  }
}

export interface SetRewardsDistributedAction
  extends Action<DashboardActionType.SET_REWARDS_DISTRIBUTED> {
  rewardsDistributed: RewardsDistributedResponse
}

export function setRewardsDistributed(rewardsDistributed: RewardsDistributedResponse): SetRewardsDistributedAction {
  return {
    type: DashboardActionType.SET_REWARDS_DISTRIBUTED,
    rewardsDistributed,
  }
}
