import { Box, Container, createStyles, makeStyles, Button, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { ReactComponent as EncryptedKeyIcon } from 'assets/login/encrypted-key.svg'
import { ReactComponent as KeplrIcon } from 'assets/login/keplr.svg'
import { ReactComponent as LedgerIcon } from 'assets/login/ledger.svg'
import { ReactComponent as CaretIcon } from 'assets/CaretLeft.svg'

import EncryptedKeyLogin from './EncryptedKeyLogin'
import LedgerLogin from './LedgerLogin'
import KeplrWalletLogin from './KeplrWalletLogin'

interface Props {
}

interface LoginMethods {
  [key: string]: LoginMethod
}

interface LoginMethod {
  key: string
  name: string
  icon: React.ReactNode
  Component: React.FC
}

const Login: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState('menu')


  const handleLoginMethodChosen = (method: string) => {
    setCurrentPage(method)
  }

  const handleBack = () => {
    setCurrentPage('menu')
  }

  const loginMethods: LoginMethods = {
    ledger: {
      key: 'ledger',
      name: 'Ledger',
      icon: (
        <LedgerIcon className={classes.logoSvg} />
      ),
      Component: LedgerLogin,
    },
    keplrWallet: {
      key: 'keplrWallet',
      name: 'Keplr Wallet',
      icon: (
        <KeplrIcon className={classes.logoSvg} />
      ),
      Component: KeplrWalletLogin,
    },
    encryptedKey: {
      key: 'encryptedKey',
      name: 'Encrypted Key',
      icon: (
        <EncryptedKeyIcon className={classes.logoSvg} />
      ),
      Component: EncryptedKeyLogin,
    },
  }

  const chosenMethod: any = loginMethods[currentPage]

  if (currentPage === 'menu') {
    return (
      <Box className={classes.container}>
        <Container maxWidth="sm">
          <Typography variant="h1">
            Connect Wallet
          </Typography>
          <Typography className={classes.subtitle} variant="body1">
            Choose your preferred wallet to interact with.
          </Typography>
          <div className={classes.loginMethods}>
            {
              Object.entries(loginMethods).map(([key, method]) => {
                const { icon, name } = method
                return (
                  <Box
                    key={key}
                    className={classes.row}
                    onClick={() => handleLoginMethodChosen(key)}
                    onKeyPress={() => null} // hack lint: jsx-a11y/click-events-have-key-events
                    role="presentation"
                  >
                    {icon}
                    <div className={classes.name}>{name}</div>
                  </Box>
                )
              })
            }
          </div>
        </Container>
      </Box>
    )
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      {
        currentPage !== 'menu' &&
        <Button
          onClick={handleBack}
          className={classes.button}
        >
          <CaretIcon className={classes.logoSvg} />Back
        </Button>
      }
      <chosenMethod.Component />
    </Container>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    margin: theme.spacing(4.5, 'auto', 12),
    '& .MuiInputLabel-root': {
      ...theme.typography.body1,
      transform: 'unset',
    },
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(6, 'auto', 12),
    padding: theme.spacing(0, 0, 2),
  },
  buttonPromptSignup: {
    ...theme.typography.body1,
  },
  title: {
    ...theme.typography.h1,
    fontSize: '1.5rem',
    marginBottom: '0.875rem',
  },
  loginMethods: {
    marginTop: theme.spacing(4),
  },
  row: {
    width: '100%',
    padding: '1.125rem 1.75rem',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '2px',
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.main,
    },
  },
  logoSvg: {
    fontSize: '2rem',
    height: '1em',
    width: '1em',
    marginRight: '.5rem',
  },
  name: {
    flexGrow: 1,
    ...theme.typography.h2,
    fontWeight: 500,
    fontSize: '1.125rem',
    marginLeft: '1.375rem',
    letterSpacing: '-0.2px',
    lineHeight: '26px',
  },
  button: {
    justifyContent: 'center',
    minWidth: 'unset',
    ...theme.typography.body2,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: theme.spacing(0.75, 0.25),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1.25),
  },
}))

export default Login
