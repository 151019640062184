import { TaskNames } from 'js/constants'
import { paginationLimit } from 'js/constants/pagination'
import { SagaIterator } from 'redux-saga'
import { Effect, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, LeaderboardActionType, set30dPnl, setLifetimePnl } from '../actions/leaderboard'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

const limit = paginationLimit

export default class Leaderboard extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchLeaderboard30D],
      [this, this.fetchLeaderboardLifetime],
      [this, this.watchSetNetwork],
      [this, this.watch30dPnlFilters],
      [this, this.watchLifetimePnlFilters],
      [this, this.watchSetLeaderboardMarket],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchLeaderboard30D(): any {
    yield runSagaTask(TaskNames.Leaderboard.D30, function* () {
      const sdk = yield* getInitializedSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string

      const leaderboardResult = (yield sdk.api.getLeaderboard({
        limit,
        ...selectedMarket !== 'all' && {
          market: selectedMarket,
        }
      })) as RestModels.LeaderboardResult
      yield put(set30dPnl(leaderboardResult))
    })
  }

  private *fetchLeaderboardLifetime(): any {
    yield runSagaTask(TaskNames.Leaderboard.Lifetime, function* () {
      const sdk = yield* getInitializedSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string

      const leaderboardResult = (yield sdk.api.getLeaderboard({
        from: 0,
        limit,
        ...selectedMarket !== 'all' && {
          market: selectedMarket,
        }
      })) as RestModels.LeaderboardResult
      yield put(setLifetimePnl(leaderboardResult))
    })
  }

  private *watch30dPnlFilters(): any {
    yield takeLatest(
      LeaderboardActionType.UPDATE_30D_PNL_FILTERS,
      this.handle30dPnlFilters,
    )
  }

  private *handle30dPnlFilters(action: any): any {
    yield runSagaTask(TaskNames.Leaderboard.D30Filters, function* () {
      const sdk = yield* getInitializedSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string

      const leaderboardResult = (yield sdk.api.getLeaderboard({
        limit,
        ...selectedMarket !== 'all' && {
          market: selectedMarket,
        },
        ...action.options
      })) as RestModels.LeaderboardResult
      yield put(set30dPnl(leaderboardResult))
    })
  }
  private *watchLifetimePnlFilters(): any {
    yield takeLatest(
      LeaderboardActionType.UPDATE_LIFETIME_PNL_FILTERS,
      this.handleLifetimePnlFilters,
    )
  }

  private *handleLifetimePnlFilters(action: any): any {
    yield runSagaTask(TaskNames.Leaderboard.LifetimeFilters, function* () {
      const sdk = yield* getInitializedSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string

      const leaderboardResult = (yield sdk.api.getLeaderboard({
        from: 0,
        limit,
        ...selectedMarket !== 'all' && {
          market: selectedMarket,
        },
        ...action.options
      })) as RestModels.LeaderboardResult
      yield put(setLifetimePnl(leaderboardResult))
    })
  }

  private *watchSetLeaderboardMarket(): any {
    yield takeLatest(
      LeaderboardActionType.SET_LEADERBOARD_MARKET,
      this.handle30dPnlFilters,
    )
    yield takeLatest(
      LeaderboardActionType.SET_LEADERBOARD_MARKET,
      this.handleLifetimePnlFilters,
    )
  }
}
