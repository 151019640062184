import { BoxProps, makeStyles, TableBody, TableCell, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { DataLoadSegment, ListTable, Section, TooltipHint } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { BIG_ZERO, makePercentLabel, parseNumber } from 'js/utils'
import React, { ReactElement } from 'react'

interface Props extends BoxProps {
}

const TallyCriteriaInfo: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const [loading] = useTaskSubscriber(TaskNames.Governance.TallyParams)
  const tallyParams = useRedux((state) => state.governance.tallyParams)

  return (
    <Section marginTop={2} title="Tally Criteria" {...rest} className={clsx(classes.root, className)}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableBody>
            <TableRow>
              <TableCell>
                Quorum
                <TooltipHint title="A minimum of 33.4% of the network's voting power (quorum) is required to participate to make the proposal valid." />
              </TableCell>
              <TableCell>{makePercentLabel(parseNumber(tallyParams?.quorum, BIG_ZERO)!)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Passing Threshold
                <TooltipHint title="Once quorum is reached, over 50% of the participating voting power must vote 'yes' to pass the proposal." />
              </TableCell>
              <TableCell>{makePercentLabel(parseNumber(tallyParams?.threshold, BIG_ZERO)!)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Veto Threshold
                <TooltipHint title="A 'no-with-veto' vote of 33.4% of participating voting power or greater, can override this outcome and cause the proposal to fail." />
              </TableCell>
              <TableCell>{makePercentLabel(parseNumber(tallyParams?.veto, BIG_ZERO)!)}</TableCell>
            </TableRow>
          </TableBody>
        </ListTable>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

export default TallyCriteriaInfo
