import { TableRow } from '@material-ui/core'
import { TableCellInfo, TableSectionCell } from 'js/components'
import { shorten } from 'js/helpers/utils'
import React, { ReactElement } from 'react'

interface Props {
  model: any
}

const NodeRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props

  const NodeRowValues: TableCellInfo[] = [
    {
      align: 'inherit',
      content: model?.moniker,
    },
    {
      align: 'inherit',
      content: model?.country,
      type: 'flag',
    },
    {
      align: 'inherit',
      content: model?.ip,
    },
    {
      align: 'inherit',
      content: model?.nodeId,
    },
    {
      align: 'inherit',
      content: model?.appVersion,
    },
    {
      align: 'inherit',
      content: model?.appCommit ? shorten(model?.appCommit) : model?.appCommit,
    },
    {
      align: 'inherit',
      content: model?.latestBlockHeight,
      to: `/block/${model?.latestBlockHeight}`,
    }
  ]

  return (
    <TableRow key={model?.nodeId} hover>
      {NodeRowValues.map((nodeVal: TableCellInfo, index: number) => (
        <TableSectionCell {...nodeVal} key={model?.nodeId + index} />
      ))}
    </TableRow>
  )
}

export default NodeRow
