import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { FooterNavLink } from './../../components'

interface Link {
  label: string
  href: string
}

type Props = {
  title: string
  models: Link[]
  gridSizes: any
}

const FooterLinksBox: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { gridSizes, models, title } = props
  const classes = useStyles()

  return (
    <Grid item {...gridSizes}>
      <Typography variant="h3">{title}</Typography>

      <Box className={classes.linkContainer}>
        {
          models.map((link, index) =>
            <FooterNavLink href={link.href} key={index}>
              {link.label}
            </FooterNavLink>,
          )
        }
      </Box>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  linkContainer: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
}))

export default FooterLinksBox
