import { List, ListItem, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { JSONBlock, Section } from 'js/components'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  events?: RestModels.BlockEvents
}

const BlockEvents: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { events } = props
  const classes = useStyles()

  return (
    <Section title="Block Events">
      <List className={classes.list}>
        <ListItem className={clsx(classes.bottom, classes.listItem)}>
          <JSONBlock header='Begin Block' content={events?.begin_block_events ?? {}} />
        </ListItem>
        <ListItem className={classes.listItem}>
          <JSONBlock header='End Block' content={events?.end_block_events ?? {}} />
        </ListItem>
      </List>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0, 3),
    padding: 0,
  },
  listItem: {
    fontWeight: 'bold',
    padding: theme.spacing(1.25, 0),
    alignItems: 'flex-start',
  },
  bottom: {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  },
}))

export default BlockEvents
