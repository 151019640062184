import { Action } from 'redux'
import { RestModels, RestTypes } from 'tradehub-api-js'

export enum BlocksActionType {
  CLEAR = 'CLEAR_BLOCKS',
  RECEIVE_BLOCK = 'RECEIVE_BLOCKS_BLOCK',
  SET_BLOCKS = 'SET_BLOCKS_BLOCKS',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
}

export type BlocksAction = ClearAction | SetBlocksAction | UpdateFiltersAction

export interface ClearAction extends Action<BlocksActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: BlocksActionType.CLEAR,
  }
}

export interface SetBlocksAction extends Action<BlocksActionType.SET_BLOCKS> {
  blocks: RestTypes.ResultsMinMax<RestModels.Block>
}
export function setBlocks(
  blocks: RestTypes.ResultsMinMax<RestModels.Block>,
): SetBlocksAction {
  return {
    type: BlocksActionType.SET_BLOCKS,
    blocks,
  }
}

export interface UpdateFiltersAction
  extends Action<BlocksActionType.UPDATE_FILTERS> {
  options: any
}

export function updateFilters(options: any): UpdateFiltersAction {
  return {
    type: BlocksActionType.UPDATE_FILTERS,
    options,
  }
}
