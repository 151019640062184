import { TaskNames } from 'js/constants'
import { Comparators } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestResponse, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, MarketActionType, setLargePositions, setMarket, setMarketStats, setOrders, setProfitablePositions, setRiskyLongPositions, setRiskyShortPositions, setTrades } from '../actions/market'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class M extends Saga {
  private readonly market: string
  constructor(market: string) {
    super()

    this.market = market
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchMarket],
      [this, this.fetchMarketStats],
      [this, this.fetchOrders],
      [this, this.fetchTrades],
      [this, this.watchSetNetwork],
      [this, this.watchOrdersFilters],
      [this, this.watchTradesFilters],
      [this, this.fetchLargePositions],
      [this, this.watchLargeFilters],
      [this, this.fetchRiskyLongPositions],
      [this, this.watchRiskyLongsFilters],
      [this, this.fetchRiskyShortPositions],
      [this, this.watchRiskyShortsFilters],
      [this, this.watchProfitableFilters],
      [this, this.fetchProfitablePositions],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchMarket() {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.Detail, function* () {
      const sdk = yield* getInitializedSDK()

      const market = (yield sdk.api.getMarket({ market: marketName })) as RestModels.Market
      yield put(setMarket(market))
    })
  }

  private *fetchMarketStats(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.Detail, function* () {
      const sdk = yield* getInitializedSDK()

      const [marketStats] = (yield sdk.api.getMarketStats({ market: marketName })) as RestModels.MarketStat[]
      yield put(setMarketStats(marketStats))
    })
  }

  private *fetchOrders(): any {
    const market = this.market
    yield runSagaTask(TaskNames.Market.OrderList, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        market,
        limit: 20,
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      orders.data.sort(Comparators.sortOrder)
      yield put(setOrders(orders))
    });
  }

  private *watchOrdersFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_ORDERS_FILTERS,
      this.handleOrdersFilters.bind(this),
    )
  }

  private *handleOrdersFilters(action: any): Generator {
    const market = this.market
    yield runSagaTask(TaskNames.Market.OrderFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const orders = (yield sdk.api.getOrdersPaged({
        market,
        limit: 20,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      orders.data.sort(Comparators.sortOrder)
      yield put(setOrders(orders))
    });
  }

  private *fetchTrades(): any {
    const market = this.market
    yield runSagaTask(TaskNames.Market.TradeList, function* () {
      const sdk = yield* getInitializedSDK()

      const trades = (yield sdk.api.getTradesPaged({
        market,
        limit: 20,
      })) as RestTypes.ResultsMinMax<RestModels.Trade>
      yield put(setTrades(trades))
    });
  }

  private *watchTradesFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_TRADES_FILTERS,
      this.handleTradesFilters.bind(this),
    )
  }

  private *handleTradesFilters(action: any): Generator {
    const market = this.market
    yield runSagaTask(TaskNames.Market.TradeFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const trades = (yield sdk.api.getTradesPaged({
        market,
        limit: 20,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Trade>
      if (action.options.pagination.order_by === "asc") {
        trades.data.reverse()
      }
      yield put(setTrades(trades))
    });
  }

  private *fetchLargePositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LargePositions, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsBySize({ market: marketName })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setLargePositions(result))
    })
  }

  private *fetchRiskyLongPositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LongRisky, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsByRisk({
        market: marketName,
        direction: 'long'
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setRiskyLongPositions(result))
    })
  }

  private *fetchRiskyShortPositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ShortRisky, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsByRisk({
        market: marketName,
        direction: 'short'
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setRiskyShortPositions(result))
    })
  }

  private *fetchProfitablePositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ProfitablePositions, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsByPNL({
        market: marketName,
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setProfitablePositions(result))
    })
  }

  private *watchLargeFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_LARGE_POSITIONS,
      this.handleLargePositions.bind(this),
    )
  }

  private *handleLargePositions(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LargeFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsBySize({
        ...action.options,
        market: marketName,
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setLargePositions(result))
    })
  }

  private *watchProfitableFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_PROFITABLE_POSITIONS,
      this.handleProfitablePositions.bind(this),
    )
  }

  private *handleProfitablePositions(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ProfitableFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsByPNL({
        ...action.options,
        market: marketName,
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setProfitablePositions(result))
    })
  }

  private *watchRiskyLongsFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_RISKY_LONG_POSITIONS,
      this.handleRiskyLongs.bind(this),
    )
  }

  private *handleRiskyLongs(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LongRiskyFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsByRisk({
        ...action.options,
        market: marketName,
        direction: 'long',
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setProfitablePositions(result))
    })
  }

  private *watchRiskyShortsFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_RISKY_SHORT_POSITIONS,
      this.handleRiskyShorts.bind(this),
    )
  }

  private *handleRiskyShorts(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ShortRiskyFilter, function* () {
      const sdk = yield* getInitializedSDK()

      const result = (yield sdk.api.getPositionsByRisk({
        ...action.options,
        market: marketName,
        direction: 'short',
      })) as RestTypes.ResultsPaged<RestResponse.Position>
      yield put(setProfitablePositions(result))
    })
  }
}
