import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { BigNumber } from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByOffset, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  pnl?: RestModels.LeaderboardResult
  title: string
  tasks: any
  action: any
}

const HeaderCells: TableCellInfo[] = [
  { align: 'inherit', content: 'Rank' },
  { align: 'inherit', content: 'Account' },
  { content: 'Amount' },
]

const Pnl: React.FunctionComponent<Props> = ({
  pnl,
  title,
  tasks,
  action,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(tasks)
  const fromTime: Dayjs = dayjs.unix(parseInt(pnl?.from_time ?? "0"))
  const toTime: Dayjs = dayjs.unix(parseInt(pnl?.to_time ?? "0"))
  return (
    <Section title={title}>
      <DataLoadSegment loading={loading && !pnl}>
        <div className={classes.dateParams}>
          <div className={classes.group}>
            <b style={{ minWidth: 40 }}>From </b>
            <b style={{ minWidth: 40 }}>To </b>
          </div>
          <div className={classes.group}>
            <CellLink to={`/block/${pnl?.from_block}`}><span>{pnl?.from_block}</span></CellLink>
            <CellLink to={`/block/${pnl?.to_block}`}><span>{pnl?.to_block}</span></CellLink>
          </div>
          <div className={classes.group}>
            <span className={classes.time}>({fromTime.format('YYYY-MM-DD HH:mm:ss')})</span>
            <span className={classes.time}>({toTime.format('YYYY-MM-DD HH:mm:ss')})</span>
          </div>
        </div>
        <ListTable>
          <TableHead>
            <TableRow>
              {HeaderCells.map((header: TableCellInfo, index: number) => (
                <TableSectionCell key={index} {...header} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pnl?.data.map(renderPnl)}
          </TableBody>
        </ListTable>

        {pnl?.data && pnl?.data?.length > 0 && (
          <Box marginTop={3}>
            <PaginationByOffset
              action={action}
              response={pnl}
            />
          </Box>
        )}
        {(!pnl || pnl?.data?.length <= 0) && (
          <TableEmptyState itemName="pnl-30d" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderPnl(
    p: RestModels.Leaderboard,
    index: number,
  ): ReactNode {
    const realizedPnlBN: BigNumber = new BigNumber(p?.realized_pnl)
    const offset = parseInt(pnl?.offset ?? "0")
    const decimals: number = 6
    return (
      <TableRow key={p?.address} hover>
        <TableCell style={{ width: '60px' }}>
          {index + 1 + offset}
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${p?.address}`}>
            {p?.username === '' ? p?.address : p?.username ?? ''}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={realizedPnlBN.toFormat(decimals)} denom="USDC" />
      </TableRow>
    )
  }
}

const useStyles = makeStyles(() => ({
  dateParams: {
    display: 'flex',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
  },
  time: {
    color: grey[500],
  },
}))

export default Pnl
