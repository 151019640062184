import { Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, Section, TokenIcon } from 'js/components'
import { TaskNames } from 'js/constants'
import { GECKO_SYMBOLS } from 'js/helpers'
import { useAssetSymbol, useRedux, useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { BIG_ZERO, parseNumber } from 'js/utils'
import React, { ReactElement } from 'react'
import { RewardsDistributedResponse } from 'tradehub-api-js'

interface Props {
  params?: RewardsDistributedResponse
}

const RewardsDistributed: React.FunctionComponent<Props> = ({
  params = [],
}: Props): ReactElement<Props> => {
  const assetSymbol = useAssetSymbol()
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Dashboard.Distribution)
  const { geckoPrices } = useRedux((state) => state.app)
  let totalUSD = BIG_ZERO
  params.forEach(token => {
    const priceBN = parseNumber(geckoPrices[GECKO_SYMBOLS[token.denom]]?.usd, BIG_ZERO)!
    const value = new BigNumber(token.amount).times(priceBN)
    totalUSD = totalUSD.plus(value)
  })

  return (
    <Section title="Distributed Rewards - Last 24H" className={classes.section}>
      <DataLoadSegment loading={loading}>
      <div className={classes.usdTotal}>TOTAL: ${totalUSD.toFormat(0)}</div>
      <Box marginTop={3}>
            <Grid container spacing={3} className={classes.gridContainer}>
              {
                params.map((each) => {
                  const amountBN = new BigNumber(each.amount)
                  return (
                    <Grid item xs={6} sm={4} md={3} lg={2} className={classes.gridItem} key={each.denom}>
                      <Box className={classes.box}>
                      <TokenIcon className={classes.icon} denom={each.denom} />
                          <div className={classes.amount}>
                            {amountBN.toFormat()}
                          </div>
                          <div className={classes.denom}>
                            {assetSymbol(each.denom)}
                          </div>
  
                      </Box>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
    headerClass: {
      [theme.breakpoints.only('xs')]: {
        width: '60%',
      },
    },
    section: {
      height: '100%',
    },
    sharePercent: {
      color: theme.palette.grey[500],
      marginLeft: 6,
    },
    stringContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.only('xs')]: {
        display: 'block',
      },
    },
    valueClass: {
      [theme.breakpoints.only('xs')]: {
        width: '40%',
      },
    },

    header: {
      flexBasis: '33%',
      paddingRight: '1rem',
    },
    string: {
      wordBreak: 'break-all',
    },
    tableRow: {
      height: 50,
      '&:nth-of-type(even)': {
        backgroundColor: 'transparent !important',
        '&:hover': {
          backgroundColor: `${switcheo.hover} !important`,
        },
      },
    },
    gridContainer: {
      '@media (max-width: 320px)': {
        display: 'block',
      },
    },
    gridItem: {
      '@media (max-width: 320px)': {
        maxWidth: 'unset',
        width: '100%',
        marginTop: theme.spacing(3),
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
    tokenName: {
      color: theme.palette.text.secondary,
    },
    figures: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
      height: '180px',
      border: '1px solid #E3E8F7',
      borderRadius: '1px',
    },
    denom: {
      fontSize: '18px',
      fontWeight: 800,
    },
    amount: {
      fontSize: '16px',
      marginBottom: '6px',
    },
    usdTotal: {
      fontSize: 20,
      fontWeight: 800
    },
    icon: {
      marginBottom: '6px',
      width: '4em',
      height: '4em',
    },
  }))

export default RewardsDistributed
