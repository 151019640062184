import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { LeaderboardAction, LeaderboardActionType } from '../actions/leaderboard'

export interface LeaderboardProps {
  d30Pnl?: RestModels.LeaderboardResult
  lifetimePnl?: RestModels.LeaderboardResult
  leaderboardMarket: string
}

const makeState: Record.Factory<LeaderboardProps> = Record<LeaderboardProps>({
  d30Pnl: undefined,
  lifetimePnl: undefined,
  leaderboardMarket: "all",
})

export type LeaderboardState = RecordOf<LeaderboardProps>

function leaderboardReducer(
  state: LeaderboardState = makeState(),
  action: LeaderboardAction,
): LeaderboardState {
  switch (action.type) {
    case LeaderboardActionType.CLEAR:
      return makeState()
    case LeaderboardActionType.SET_30D_PNL:
      return state.updateIn(['d30Pnl'], () => action.d30Pnl)
    case LeaderboardActionType.SET_LIFETIME_PNL:
      return state.updateIn(['lifetimePnl'], () => action.lifetimePnl)
    case LeaderboardActionType.SET_LEADERBOARD_MARKET:
      return state.updateIn(['leaderboardMarket'], () => action.leaderboardMarket)
    default:
      return state
  }
}

export default leaderboardReducer

