import BigNumber from 'bignumber.js'
import { Constants, TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import { bnOrZero, SimpleMap } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { call, delay, Effect, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setActiveWallets, setAvgReward, setBlocks, setBlockTime, setInsuranceBalances, setOI, setRewardsDistributed, setTransactions, setVolume } from '../actions/dashboard'
import { Network } from '../models/Network'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Dashboard extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchInsuranceBalances],
      [this, this.pollBlocks],
      [this, this.pollTransactions],
      [this, this.pollAvgBlockTime],
      [this, this.fetchMarketStats],
      [this, this.fetchActiveWallets],
      [this, this.fetchRewardsDistributed],
      [this, this.watchSetNetwork],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *pollBlocks(): any {

    while (true) {
      yield runSagaTask(TaskNames.Dashboard.Blocks, function* () {
        const sdk = yield* getInitializedSDK()
        const blocks = (yield sdk.api.getBlocks({
          limit: 5,
          page: 1,
        })) as RestModels.Block[]
        yield put(setBlocks(blocks))
      })

      yield runSagaTask(TaskNames.Dashboard.Apr, function* () {
        const sdk = yield* getInitializedSDK()
        const blocks = (yield select((state: RootState) => state.dashboard.blocks)) as RestModels.Block[]
        if (blocks.length) {
          let totalRewards = new BigNumber(0)
          let blockCount = new BigNumber(0)

          const blockEvents = (yield call([sdk.tm, sdk.tm.getBlockEvents], {
            height: parseInt(blocks[0].block_height),
          })) as RestModels.BlockEvents

          const rewardEvents = blockEvents.begin_block_events.filter((event) => event.type === 'rewards')
          for (const rewardEvent of rewardEvents) {
            const rewards = rewardEvent.attributes.amount
            if (!rewards) continue
            const swthReward = rewards.split(',').find((reward) => reward.substr(reward.length - 4) === 'swth')
            if (swthReward) {
              const amount = bnOrZero(swthReward.substring(0, swthReward.length - 4)).shiftedBy(-Constants.Decimals.SWTH)
              totalRewards = totalRewards.plus(amount)
            }
          }
          blockCount = blockCount.plus(1)
          yield put(setAvgReward(totalRewards.div(blockCount)))
        }
      })

      yield delay(6001)
    }
  }

  private * pollTransactions(): any {
    while (true) {
      yield runSagaTask(TaskNames.Dashboard.Transactions, function* () {
        const sdk = yield* getInitializedSDK()
        const txs = (yield sdk.api.getTxs({
          limit: "5",
        })) as RestTypes.GetTxsResponse
        yield put(setTransactions(txs))
      })
      yield delay(6015)
    }
  }

  private * pollAvgBlockTime(): any {
    while (true) {
      yield runSagaTask(TaskNames.Dashboard.BlockTime, function* () {
        const sdk = yield* getInitializedSDK()

        const blockTime = (yield sdk.api.getAverageBlocktime()) as string
        yield put(setBlockTime(blockTime))
      })
      yield delay(11015)
    }
  }

  private *fetchInsuranceBalances(): any {
    yield runSagaTask(TaskNames.App.InsuranceSupply, function* () {
      const sdk = yield* getInitializedSDK()

      const balances = (yield sdk.api.getInsuranceFundBalance()) as RestModels.InsuranceFundBalance[]
      if (balances.length) {
        yield put(setInsuranceBalances(balances[0]))
      }
    })
  }

  private *fetchActiveWallets(): any {
    yield runSagaTask(TaskNames.Dashboard.Wallets, function* () {
      const sdk = yield* getInitializedSDK()

      const wallets = (yield sdk.api.getActiveWallets({
        token: 'swth',
      })) as string
      yield put(setActiveWallets(bnOrZero(wallets)))
    })
  }

  private *fetchMarketStats(): any {
    yield runSagaTask(TaskNames.Dashboard.MarketStats, function* () {
      const sdk = yield* getInitializedSDK()

      const stats = (yield sdk.api.getMarketStats({})) as RestModels.MarketStat[]
      let markets = (yield select((state) => state.app.markets)) as SimpleMap<RestModels.Market>
      let oracles: any = yield select((state) => state.app.oracles)
      let retryMarketCount = 0
      let retryOracleCount = 0
      let oiBN = new BigNumber(0)
      let volumeBN = new BigNumber(0)

      // tslint:disable:no-increment-decrement
      while (
        Object.keys(markets).length === 0 &&
        markets.constructor === Object &&
        retryMarketCount < 10
      ) {
        retryMarketCount++
        yield delay(501)
        markets = (yield select((state) => state.app.markets)) as SimpleMap<RestModels.Market>
      }

      while (
        Object.keys(oracles).length === 0 &&
        oracles.constructor === Object &&
        retryOracleCount < 10
      ) {
        retryOracleCount++
        yield delay(501)
        oracles = (yield select((state) => state.app.oracles)) as SimpleMap<RestModels.Oracle>
      }

      stats.forEach((stat: RestModels.MarketStat) => {
        if (stat.market_type === 'futures') {
          if (markets[stat.market] === undefined) return false
          const oracleID: string = markets[stat.market].index_oracle_id
          if (oracles[oracleID] === undefined) return false
          const interest: BigNumber = new BigNumber(stat.open_interest).times(
            new BigNumber(oracles[oracleID].data),
          )
          oiBN = oiBN.plus(interest)
          volumeBN = volumeBN.plus(stat.day_quote_volume)
        }
      })
      yield put(setVolume(volumeBN))
      yield put(setOI(oiBN))
    })
  }

  private *fetchRewardsDistributed(): any {
    yield runSagaTask(TaskNames.Dashboard.Distribution, function* () {
      const sdk = yield* getInitializedSDK()
      const network = (yield select((state) => state.app.network)) as Network
      const account = (network === Network.Main || network === Network.Dev)
        ? 'swth1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8cpw26x'
        : 'tswth1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8ukl6rr'
      const rewards = (yield sdk.api.getRewardsDistributed({ account })) as RestModels.TokenAmount[]
      yield put(setRewardsDistributed(rewards))
    })
  }
}
