import { StorageKeys, TutorialKeys } from 'js/constants'
import { RootState } from 'js/store'
import { makeState as makeTutorialState, TutorialKey } from 'js/store/tutorial'
import { ActionType as TutorialActionType } from 'js/store/tutorial/action'
import { Middleware } from 'redux'
import { AppActionType } from './js/actions/app'
import { Network, parseNetwork } from './js/models/Network'
import { makeState as AppDefaultInitialState } from './js/reducers/app'

// tslint:disable-next-line:ter-arrow-parens
const localStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const { type } = action
  switch (type) {
    case AppActionType.SET_NETWORK: {
      localStorage.setItem(AppActionType.SET_NETWORK, action.network)
      break
    }
    case AppActionType.SET_MODE: {
      localStorage.setItem(AppActionType.SET_MODE, action.mode)
      break
    }

    case TutorialActionType.UPDATE_PROGRESS: {
      const storeState: RootState = store.getState()
      localStorage.setItem(StorageKeys.TutorialProgress, JSON.stringify(storeState.tutorial))
      break
    }
  }
  return next(action)
}

export const loadLocalStorageState = () => {
  const state: Partial<RootState> = {}

  const network: any = localStorage.getItem(AppActionType.SET_NETWORK)
  const mode: any = localStorage.getItem(AppActionType.SET_MODE)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const net = params.get('net')
  if (network && net === null) {
    state.app = AppDefaultInitialState({
      network: parseNetwork(network),
      mode: mode ? mode : 'bright',
    })
  } else if (net !== null) {
    switch (net) {
      case 'main':
        localStorage.setItem(AppActionType.SET_NETWORK, Network.Main)
        break
      case 'test':
        localStorage.setItem(AppActionType.SET_NETWORK, Network.Test)
        break
      case 'dev':
        localStorage.setItem(AppActionType.SET_NETWORK, Network.Dev)
        break
      case 'local':
        localStorage.setItem(AppActionType.SET_NETWORK, Network.Local)
        break
      default:
      // do nothing
    }
  }

  // load tutorial progress
  try {
    const savedTutorialState = localStorage.getItem(StorageKeys.TutorialProgress)
    if (savedTutorialState) {
      const tutorialState = JSON.parse(savedTutorialState)
      state.tutorial = makeTutorialState(tutorialState)
      if (state.tutorial) {
        // prevent any tutorial from showing
        // on initial start
        Object.values(TutorialKeys).forEach((tutorialKey) => {
          const tutorial = state.tutorial![tutorialKey as TutorialKey]
          if (tutorial?.showing) {
            state.tutorial = state.tutorial!.setIn([tutorialKey], {
              ...tutorial,
              showing: false,
            })
          }
        })
      }
    }
  } catch (e) {
    // tslint:disable: no-console
    console.error('load tutorial state failed')
    console.error(e)
    // parse failed, eat error
  }

  return state
}

export default localStorageMiddleware
