import BigNumber from 'bignumber.js'
import { Blockchain } from 'tradehub-api-js'

export const Constants = {
  Proposal: {
    Status: {
      DepositPeriod: 'DepositPeriod',
      VotingPeriod: 'VotingPeriod',
      Passed: 'Passed',
      Rejected: 'Rejected',
    },

    MaxTitleLength: 140,
    MaxDescLength: 5000,
  },

  TimeFactor: {
    ExpNanoToMilli: -6,
    NanoToHour: 3600000000000,
  },

  Decimals: {
    SWTH: 8,
  },

  InternalChainId: {
    [Blockchain.BinanceSmartChain]: 6,
    [Blockchain.Ethereum]: 2,
    [Blockchain.Neo]: 4,
    [Blockchain.TradeHub]: 5,
    [Blockchain.Zilliqa]: 7, // TODO: update once implemented on mainnet
  } as const,
} as const

export const SystemAddress = {
  LiquidateEngine: 'swth12uxz5eayngcls6lx55j9g28npen6z5fr7rkjww',
}

export const TaskName = {
  ParseFile: 'ParseFile',
  SubmitProposal: 'SubmitProposal',
  Login: 'Login',
  DepositProposal: 'DepositProposal',
  VoteProposal: 'VoteProposal',
} as const

export const TutorialKeys = {
  SubmitProposal: 'Governance/SubmitProposal',
  DepositProposal: 'Governance/DepositProposal',
} as const

export const StorageKeys = {
  TutorialProgress: 'tradescan:tutorial-state',
} as const

export const SignedBlocksCount: BigNumber = new BigNumber(36000)
