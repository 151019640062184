import { Box, Breadcrumbs, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import { BigNumber } from 'bignumber.js'
import { CellLink, DataLoadSegment, NotFoundState, Page, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { Paths, TaskNames } from 'js/constants'
import { GECKO_SYMBOLS, getInitialMarginFraction, shorten } from 'js/helpers'
import estLiqPrice from 'js/helpers/liquidation'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, useEffect, useState } from 'react'
import { RestModels } from 'tradehub-api-js'
import { OrderUserDetails, RelatedTrades } from '../Order/components'
import { PositionDetails } from './components'

interface Props { }

interface CollectiveTrades {
  avgPrice: BigNumber,
  fees: BigNumber,
  denom: string
}

const Position: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Position.Detail, TaskNames.App.GeckoCoin, TaskNames.Account.Profile, TaskNames.App.Markets)
  //APIs
  const { position } = useRedux((state) => state.position)
  const { geckoPrices, markets } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.sdk)
  const [getTrades, tradeLoading] = useAsyncTask('getTrades') //related trades
  const [getProfile, profileLoading] = useAsyncTask('getProfile') //for twitter profile
  const [getMarketStats, marketsLoading] = useAsyncTask('getMarketStats') //tick_size .etc
  const [getLeverage, leverageLoading] = useAsyncTask('getLeverage') //get margin leverage
  const [getMarket, marketLoading] = useAsyncTask('getMarket') //get params needed for liquidation 
  //States
  const [token, setToken] = useState<string>("")
  const [dollarValue, setDollarValue] = useState<number>(0)
  const [side, setSide] = useState<string>("")
  const [trades, setTrades] = useState<RestModels.Trade[]>([])
  const [twitter, setTwitter] = useState<string>("")
  const [demexPrice, setDemexPrice] = useState<string>("")
  // const [positionNotFound, setPositionNotFound] = useState<Boolean>(false)
  const [takerOrMaker, setTakerOrMaker] = useState<string>("taker")
  const [marketDetails, setMarketDetails] = useState<RestModels.Market | undefined>()
  const [leverage, setLeverage] = useState<string>("1")
  const [estLiquidation, setEstLiquidation] = useState<BigNumber>(new BigNumber(0))
  const [createdTime, setCreatedTime] = useState<string>("-")
  const [collectiveTrades, setCollectiveTrades] = useState<CollectiveTrades>({ avgPrice: new BigNumber(0), fees: new BigNumber(0), denom: "" })
  const [positionStatus, setPositionStatus] = useState<string>("")
  const [isPnlMoreThanZero, setIsPnlMoreThanZero] = useState<boolean | undefined>(undefined)
  //For pagination
  const [page, SetPage] = useState<number>(1)
  const itemsPerPage = 5
  const shareMsg = isPnlMoreThanZero ? `🌙 TO THE MOOOON!! Check out this position on %23Demex.` : isPnlMoreThanZero === undefined ? `Check out this position on %23Demex!` : `🔴 Rekkkkttt. Condolences to this position on %23Demex.`

  useEffect(() => {
    if (!position || !sdk) return
    getLeverage(async () => {
      try {
        const result = await sdk?.api.getLeverage({ account: position?.address, market: position.market })
        if (result?.leverage) {
          setLeverage(result?.leverage)
        }
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, sdk])

  useEffect(() => {
    if (!position || !sdk) return
    getTrades(async () => {
      try {
        const created_block_height = new BigNumber(position.created_block_height)
        const result = await sdk?.api.getTrades({ account: position.address, market: position.market, after_block: created_block_height.minus(1).toNumber(), before_block: created_block_height.plus(1).toNumber() })
        const filtered = result.filter((o: any) => o.taker_address === position.address || o.maker_address === position.address)
        if (filtered && filtered.length > 0) {
          if (filtered[filtered.length - 1].taker_address === position.address) {
            setTakerOrMaker("taker")
            setSide(filtered[filtered.length - 1].taker_side)
          }
          else {
            setTakerOrMaker("maker")
            setSide(filtered[filtered.length - 1].maker_side)
          }
          setCreatedTime(filtered[filtered.length - 1].block_created_at)
        }
        setTrades(filtered)
      }
      catch (err) {
        console.error(err)
      }
    })

    getProfile(async () => {
      try {
        const result = await sdk?.api.getProfile({ account: position.address })
        setTwitter(result.twitter)
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, sdk])

  useEffect(() => {
    if (demexPrice && position && side) {
      const quantityBN = new BigNumber(position.lots)
      const entryPriceBN = new BigNumber(position.entry_price)
      const demexPriceBN = new BigNumber(demexPrice)
      const realizedPnlBN = new BigNumber(position.realized_pnl)
      const unrealizedPnlBN = side === "buy" ? demexPriceBN.minus(entryPriceBN).times(quantityBN.abs()) : entryPriceBN.minus(demexPriceBN).times(quantityBN.abs())
      const totalPnlBN = realizedPnlBN.plus(unrealizedPnlBN)
      if (totalPnlBN.isGreaterThan(0)) setIsPnlMoreThanZero(true)
      else if (totalPnlBN.isLessThan(0)) setIsPnlMoreThanZero(false)
    }
  }, [demexPrice, position, side])

  //get current price
  useEffect(() => {
    if (!position || !sdk || loading) return
    //Set tick_size, marketType ..etc
    setMarketDetails(markets[position.market])
    //set position status
    const closedBlockHeightBN = new BigNumber(position.closed_block_height || 0)
    setPositionStatus(closedBlockHeightBN.isGreaterThan(0) ? "Closed" : "Open")

    //Get token price in USD
    const tradingPair = position.market.split("_")
    const pair1 = tradingPair[0]
    let pair2 = GECKO_SYMBOLS[tradingPair[1]]
    if (!pair2) pair2 = "usd-coin"
    setDollarValue(geckoPrices[pair2].usd)
    setToken(pair1.toUpperCase())

    //API for getting demex price
    getMarketStats(async () => {
      try {
        const result = await sdk?.api.getMarketStats({ market: position.market })
        setDemexPrice(result[0].last_price)
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, loading])

  //get Est Liquidation Price using getMarket information
  useEffect(() => {
    if (!position || !sdk || loading) return
    getMarket(async () => {
      try {
        const result = await sdk?.api.getMarket({ market: position.market })
        const iniMarginFraction = getInitialMarginFraction(
          new BigNumber(position.lots),
          new BigNumber(result.initial_margin_base),
          new BigNumber(result.risk_step_size),
          new BigNumber(result.initial_margin_step)
        )
        const estLiq = estLiqPrice(
          new BigNumber(position.entry_price),
          new BigNumber(position.allocated_margin_amount),
          iniMarginFraction,
          new BigNumber(position.lots)
        )
        setEstLiquidation(estLiq)
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, loading])


  useEffect(() => {
    if (!loading && trades.length > 0) {
      setCollectiveTrades(collectiveTrade => {
        let totalQuantity = new BigNumber(0)
        let avgPrice = new BigNumber(0);
        let fees = new BigNumber(0);
        for (let i = 0; i < trades.length; i++) {
          totalQuantity = totalQuantity.plus(trades[i].quantity)
          avgPrice = avgPrice.plus(Number(trades[i].price) * Number(trades[i].quantity))
          fees = fees.plus(Number(trades[i][takerOrMaker === "taker" ? "taker_fee_amount" : "maker_fee_amount"]))
        }
        collectiveTrade.avgPrice = avgPrice.div(totalQuantity)
        collectiveTrade.fees = fees
        collectiveTrade.denom = trades[0][takerOrMaker === "taker" ? "taker_fee_denom" : "maker_fee_denom"]
        return collectiveTrade
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerOrMaker, trades.length])

  //If trading pair is not found , return NotFoundState
  if (!loading && !position?.address) return (
    <Page>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={Paths.Leaderboard}>Leaderboard</CellLink>
        <Typography>Position Details</Typography>
      </Breadcrumbs>
      <NotFoundState title="Position not found">
        <Typography variant="body1">
          We can’t find any position with this ID. Please check your network setting or go back to the&nbsp;
          <CellLink to={Paths.Leaderboard}>
            leaderboard
          </CellLink>
          &nbsp;page to view existing positions.
        </Typography>
      </NotFoundState>
    </Page>
  )
  return (
    <Page>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={Paths.Leaderboard}>Leaderboard</CellLink>
        <CellLink to={`/account/${position?.address}`}>{shorten(position?.address)}</CellLink>
        <Typography>Position Details</Typography>
      </Breadcrumbs>
      <DataLoadSegment loading={loading || tradeLoading || profileLoading || marketsLoading || leverageLoading || marketLoading}>
        <Box display="flex" className={classes.box}>
          <Typography display={"inline"} className={classes.title} variant="h1">Position Details</Typography>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<TwitterIcon fontSize={'small'} className={classes.twitterIcon} />}
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?text=${shareMsg}&url=https://switcheo.org/position/${position?.position_id}`,
                '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600',
              )
            }}
          >
            Share
          </Button>
        </Box>
        {position && marketDetails && Boolean(position?.position_id) && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={4}>
              <OrderUserDetails
                address={position.address}
                orderId={position.position_id}
                username={position.username}
                orderStatus={positionStatus}
                initiator={"N/A"}
                blockCreatedAt={createdTime}
                blockHeight={Number(position.created_block_height)}
                twitterHandler={twitter || "-"}
                isPosition={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <PositionDetails
                positionStatus={positionStatus}
                dollarValue={dollarValue}
                estLiquidation={estLiquidation}
                token={token}
                leverage={leverage}
                marketDetails={marketDetails}
                market={position.market}
                side={side}
                quantity={position.lots}
                allocatedMarginAmount={position.allocated_margin_amount}
                allocatedMarginDenom={position.allocated_margin_denom}
                demexPrice={demexPrice}
                collectiveTrades={collectiveTrades}
                entryPrice={position.entry_price}
                venue={"Demex"}
                realizedPnl={position.realized_pnl}
              />
            </Grid>
          </Grid>
        )}
        <Section className={classes.section}>
          <div className={classes.titleContainer}>
            <Typography variant={"h2"} display={"inline"} className={classes.title}> Related Trades </Typography>
            <Typography display={"inline"}> Include all the trades that filled this order</Typography>
          </div>
          {(trades && trades.length > 0) && <RelatedTrades trades={trades.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)} takerOrMaker={takerOrMaker} />}
          {(!trades || !trades.length) && (<TableEmptyState itemName={"trades"} />)}
          {trades && trades.length > 0 && (
            <Box paddingTop={3}>
              <PaginationByData
                data={trades}
                setPage={SetPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Box>
          )}
        </Section>
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  title: {
    marginRight: theme.spacing(0.5)
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  twitterIcon: {
    margin: theme.spacing(0, -0.5, 0, 0.5),
  },
  button: {
    borderRadius: 0,
    marginLeft: 'auto',
    backgroundColor: switcheo.twitter,
    padding: theme.spacing(1.5, 3),
    alignSelf: 'center',
    height: 'fit-content',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: 'white',
    wordBreak: 'normal',
    justifyContent: 'center',
    "&:hover": {
      backgroundColor: switcheo.twitterHover
    }
  },
  box: {
    marginBottom: theme.spacing(1.5)
  },
  section: {
    margin: theme.spacing(5, 0)
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0)
  }
}))

export default Position
