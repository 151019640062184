import { Box, BoxProps, Grid, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { PoolSelectInput } from 'js/components/form'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { UnlinkPoolProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UnlinkPoolProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { requiredFields } from './Helpers/ProposalValue'

interface Props extends BoxProps {
}

const UnlinkPoolMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || UnlinkPoolProposalInitialFormState) as UnlinkPoolProposalFormState
  const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: UnlinkPoolProposalFormState) => {
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof UnlinkPoolProposalFormState, inputValue: string): string => {
    const poolID = formKey === 'poolID' ? inputValue : formState.poolID

    return `Unlink ${poolID} from its market`
  }

  const handleFormChange = (key: keyof UnlinkPoolProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const newFormState = {
        ...formState,
        [key]: value,
        title: isTitleInvolved ? autoGenerateTitle(key, value) : formState.title,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(formState)
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PoolSelectInput value={formState.poolID} onChange={handleFormChange('poolID', true)} />
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0, 3),
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default UnlinkPoolMainForm
