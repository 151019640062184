import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { TransactionAction, TransactionActionType } from '../actions/transaction'

export interface TransactionProps {
  transaction?: RestModels.TxnHistory
  log?: RestModels.TxLog
}
const makeState: Record.Factory<TransactionProps> = Record<TransactionProps>({
  transaction: undefined,
  log: undefined,
})
export type TransactionState = RecordOf<TransactionProps>

function transactionReducer(
  state: TransactionState = makeState(),
  action: TransactionAction,
): TransactionState {
  switch (action.type) {
    case TransactionActionType.CLEAR:
      return makeState()
    case TransactionActionType.SET_TRANSACTION:
      return state.set('transaction', action.transaction)
    case TransactionActionType.SET_LOGS:
      return state.set('log', action.log)
    default:
      return state
  }
}

export default transactionReducer
