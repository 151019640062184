import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { switcheo } from './colors'

export default {
  primary: {
    main: switcheo.default.primary,
    dark: switcheo.primary[500],
    light: switcheo.primary[300],
    contrastText: switcheo.default.white,
  },
  secondary: {
    main: switcheo.default.accent,
    contrastText: switcheo.default.primary,
  },
} as PaletteOptions
