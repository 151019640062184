import BigNumber from 'bignumber.js'
import { Record, RecordOf } from 'immutable'
import { LiquidityPoolsAction, LiquidityPoolsActionType } from 'js/actions/liquidityPools'
import { BIG_ZERO } from 'js/utils'
import { RestModels } from 'tradehub-api-js'

export interface LiquidityPoolsProps {
  pools: RestModels.LiquidityPool[]
  rewards: BigNumber
  rewardCurve?: RestModels.RewardCurve
  commitmentCurve?: RestModels.CommitmentCurve
}

const makeState: Record.Factory<LiquidityPoolsProps> = Record<LiquidityPoolsProps>({
  pools: [],
  rewards: BIG_ZERO,
  rewardCurve: undefined,
  commitmentCurve: undefined,
})
export type LiquidityPoolsState = RecordOf<LiquidityPoolsProps>

function poolsReducer(
  state: LiquidityPoolsState = makeState(),
  action: LiquidityPoolsAction,
): LiquidityPoolsState {
  switch (action.type) {
    case LiquidityPoolsActionType.CLEAR:
      return makeState()
    case LiquidityPoolsActionType.SET_LIQUIDITY_POOLS:
      return state.updateIn(['pools'], () => action.pools)
    case LiquidityPoolsActionType.SET_WEEKLY_POOL_REWARDS:
      return state.updateIn(['rewards'], () => action.rewards)
    case LiquidityPoolsActionType.SET_REWARD_CURVE:
      return state.updateIn(['rewardCurve'], () => action.rewardCurve)
    case LiquidityPoolsActionType.SET_COMMITMENT_CURVE:
      return state.updateIn(['commitmentCurve'], () => action.commitmentCurve)
    default:
      return state
  }
}

export default poolsReducer
