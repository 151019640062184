import { Action } from 'redux'
import { TradeHubSDK } from 'tradehub-api-js'

export enum ActionType {
  CORE_UPDATE_SDK = 'CORE_UPDATE_SDK',
}

export type CoreAction =
  | UpdateSDKAction

export interface UpdateSDKAction extends Action<ActionType.CORE_UPDATE_SDK> {
  sdk: TradeHubSDK | null
}
export function updateSDK(sdk: TradeHubSDK | null) {
  return {
    type: ActionType.CORE_UPDATE_SDK,
    sdk,
  }
}
