import { Box, BoxProps, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { ProposalParameterLabel, ProposalTopicLabel, TooltipHint } from 'js/components'
import { ProposalTypes, TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { ParameterChangeProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ParameterChangeProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { requiredFields } from './Helpers/FormConstraints'
import { Module, Modules, getParamType } from './Helpers/Modules'

interface Props extends BoxProps {
}

type ReplacementParams = {
  [index: string]: string[]
}
const replaceParams: ReplacementParams = {
  'gov:tallyparams': [
    'tallyparams-quorum',
    'tallyparams-threshold',
    'tallyparams-veto',
  ],
  'gov:depositparams': [
    'depositparams-min_deposit',
    'depositparams-max_deposit_period',
  ],
  'gov:votingparams': [
    'votingparams-voting_period',
  ],
}

const ParameterChangeProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || ParameterChangeProposalInitialFormState) as ParameterChangeProposalFormState
  const [module, setModule] = useState<Module | null>(null)
  const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const module =
      Modules.find((m: Module) => m.name === formState.topic) || null
    setModule(module)
    if (module && !module.params.includes(formState.parameter)) {
      // clear parameter if module is changed
      dispatch(setSubmitProposalFormState({
        ...formState,
        parameter: '',
      }))
    }

    // eslint-disable-next-line
  }, [formState.topic])

  const validateRequiredFields = (formState: ParameterChangeProposalFormState) => {
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof ParameterChangeProposalFormState, inputValue: string): string => {
    const parameter = formKey === 'parameter' ? inputValue : formState.parameter
    let value = formKey === 'value' ? inputValue : formState.value

    const parameterType = getParamType(parameter || '')
    if (parameterType && value) {
      switch (parameterType.type) {
        case 'coin':
          value += ' SWTH'
          break

        case 'percent':
          value += '%'
          break

        case 'time':
          value += ' hours'
          break
      }
    }

    const friendlyParameterLabel = ProposalTypes.Parameters[parameter || ''] || parameter

    return `Change ${friendlyParameterLabel} to ${value}`
  }

  const handleFormChange = (key: keyof ParameterChangeProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const newFormState = {
        ...formState,
        [key]: value,
        title: isTitleInvolved ? autoGenerateTitle(key, value) : formState.title,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  const generateParams = (module: Module): string[] => {
    return module.params.reduce<string[]>((outputParams, currentParam): string[] => {
      const paramKey = `${module.name}:${currentParam}`

      // replacement found, use replacement params
      if (replaceParams[paramKey]) {
        return outputParams.concat(...replaceParams[paramKey])
      }

      // no replacement found, use current param
      return [...outputParams, currentParam]
    }, [])
  }

  const valueInput = () => {
    if (!formState.parameter) {
      return (
        <TextField label='Value' value='-' fullWidth disabled />
      )
    }
    const parameterType = getParamType(formState.parameter)

    switch (parameterType?.type) {
      case 'uint64':
      case 'int64':
      case 'uint16':
      case 'percent':
      case 'coin':
      case 'time':
      case 'string': {
        return (
          <TextField
            fullWidth
            label="Value"
            InputLabelProps={{ shrink: true }}
            InputProps={parameterType.inputProps}
            value={formState.value}
            onChange={handleFormChange('value', true)}
            placeholder={parameterType.example}
          />
        )
      }
      case 'bool': {
        return (
          <FormControl fullWidth>
            <InputLabel shrink>Value</InputLabel>
            <Select
              displayEmpty
              placeholder="Select Value"
              value={formState.value}
              onChange={handleFormChange('value', true)}
            >
              <MenuItem value="true" className={classes.menuItems}>True</MenuItem>
              <MenuItem value="false" className={classes.menuItems}>False</MenuItem>
            </Select>
          </FormControl>
        )
      }
      default: {
        return (
          <TextField label='Value' value='Not Available' fullWidth disabled />
        )
      }
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h3">
            Deposit
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Topic
              <TooltipHint title="Choose the module that is responsible for the parameter you want to change." />
            </InputLabel>
            <Select
              displayEmpty
              placeholder="Select Module"
              value={formState.topic}
              onChange={handleFormChange('topic')}
            >
              <MenuItem className={classes.menuItems} value="" disabled>
                Select Module
              </MenuItem>
              {Modules.map((m: Module, index: number) => (
                <MenuItem key={index} value={m.name} className={classes.menuItems}>
                  <ProposalTopicLabel>{m.name}</ProposalTopicLabel>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Parameter
              <TooltipHint title="Parameter represents the function of Switcheo TradeHub. Choose the parameter that you desire to alter." />
            </InputLabel>
            <Select
              displayEmpty
              placeholder="Select Parameter"
              value={formState.parameter}
              onChange={handleFormChange('parameter', true)}
            >
              {!module && (
                <MenuItem value="" disabled className={classes.menuItems}>
                  Enter topic first
                </MenuItem>
              )}
              {module && (
                <MenuItem value="" disabled className={classes.menuItems}>
                  Select Parameter
                </MenuItem>
              )}
              {!!module &&
                generateParams(module).map((param, index) => (
                  <MenuItem key={index} value={param} className={classes.menuItems}>
                    <ProposalParameterLabel>{param}</ProposalParameterLabel>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          {valueInput()}
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
}))

export default ParameterChangeProposalMainForm
