import { Box, makeStyles, TableRow, Theme, Tooltip, Typography } from '@material-ui/core'
import { TableCellInfo, TableSectionCell, TokenIcon } from 'js/components'
import { BlockChainMap, BlockChainPaths } from 'js/constants'
import { useRedux } from 'js/hooks'
import { reverseStr } from 'js/utils'
import React, { ReactElement, useMemo } from 'react'
import { Blockchain, RestModels, TradeHubSDK } from 'tradehub-api-js'
import { toBech32Address } from '@zilliqa-js/crypto'


interface Props {
  model: RestModels.Token
}

const TokenInfoRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props
  const sdk = useRedux((state) => state.core.sdk);
  const classes = useStyles()

  const { tokenName, tokenDesc } = useMemo(() => {
    return {
      tokenName: sdk?.token.getTokenName(model.denom),
      tokenDesc: sdk?.token.getTokenDesc(model.denom),
    }
  }, [model, sdk])

  const getAssetIDLink = () => {
    const paths = sdk?.network === TradeHubSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet;
    switch (model?.blockchain) {
      case Blockchain.Ethereum: {
        if (model?.denom === 'eth1') {
          return paths.eth.replace(':path', '/')
        }
        return paths.eth.replace(':path', `/token/0x${model?.asset_id}`)
      }
      case Blockchain.Zilliqa: {
        if (model?.denom === 'zil1' || model?.asset_id === '0000000000000000000000000000000000000000') {
          return paths.zil.replace(':path', '/')
        }
        return paths.zil.replace(':path', `/address/${toBech32Address(model?.asset_id)}`)
      }
      case Blockchain.BinanceSmartChain: {
        if (model?.denom === 'bnb1') {
          return paths.bsc.replace(':path', '/')
        }
        return paths.bsc.replace(':path', `/token/0x${model?.asset_id}`)
      }
      case Blockchain.Neo: {
        if (model?.denom === 'nneo1') {
          return paths.neo.replace(':path', `/nep5/${model?.asset_id}/page/1`)
        }
        return paths.neo.replace(':path', `/nep5/${reverseStr(model?.asset_id, 2)}/page/1`)
      }
      default:
        return ''
    }
  }

  const TokenInfoValues: TableCellInfo[] = [
    {
      align: 'center',
      content: (
        <Box paddingLeft={1}>
          <Tooltip title={BlockChainMap[model?.blockchain]} placement="right" arrow>
            <span>
              <TokenIcon className={classes.blockChainImg} denom={model?.blockchain} />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      align: 'left',
      content: (
        <Box className={classes.singlelineText}>
          <Typography variant="body1">
            <strong>{tokenName}</strong>
          </Typography>
          <Typography variant="body2">{tokenDesc}</Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      content: model?.denom,
      to: `/token/${model?.denom}`,
    },
    {
      align: 'left',
      content: model?.asset_id,
      href: getAssetIDLink(),
    },
    {
      align: 'right',
      content: (
        <Box paddingRight={1}>
          <strong>{model?.decimals}</strong>
        </Box>
      ),
    },
  ]

  return (
    <TableRow key={model?.denom} hover>
      {TokenInfoValues.map((info: TableCellInfo, index: number) => (
        <TableSectionCell className={classes.cellClass} {...info} key={index} />
      ))}
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  blockChainImg: {
    marginRight: theme.spacing(1),
    width: '1.125rem',
    height: '1.125rem',
  },
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
  },
  singlelineText: {
    maxWidth: 200,
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }
  },
}))

export default TokenInfoRow
