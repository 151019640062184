import { makeStyles, Typography } from '@material-ui/core'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import BlockEvents from './BlockEvents'
import BlockInfo from './BlockInfo'
import TransactionMsgs from './TransactionMsgs'

interface Props { }

const Block: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const block = useRedux((state) => state.block)
  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.Block.Info)

  const { events, transactions, blockInfo } = block
  const blockHeight = !!blockInfo?.block ? blockInfo?.block.header.height : ''

  return (
    <Page title={`Block ${blockHeight}`} className={classes.root}>
      <DataLoadSegment loading={loading}>
        {!!blockInfo && (
          <Fragment>
            <BlockInfo blockInfo={blockInfo} />
            <TransactionMsgs transactions={transactions} />
            <BlockEvents events={events} />
          </Fragment>
        )}
        {!blockInfo && (
          <NotFoundState title="No Block Found">
            <Typography variant="body1">
              We can’t find any block with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Blocks}>
                Blocks
              </CellLink>
              &nbsp;page to view existing blocks.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles({
  root: {},
})

export default Block
