import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setRichAccountsBalances, setTokenInfo } from '../actions/token'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Token extends Saga {
  private readonly symbol: string
  constructor(symbol: string) {
    super()

    this.symbol = symbol
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchToken], this.symbol),
      call([this, this.fetchRichList], this.symbol),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchToken(symbol: any): any {
    yield runSagaTask(TaskNames.Token.Detail, function* () {
      const sdk = yield* getInitializedSDK()
      const token = (yield sdk.api.getToken({ token: symbol })) as RestModels.Token
      yield put(setTokenInfo(token))
    })
  }

  private *fetchRichList(symbol: string): any {
    yield runSagaTask(TaskNames.Token.RichList, function* () {
      const sdk = yield* getInitializedSDK()
      const richList = (yield sdk.api.getRichList({ token: symbol })) as RestModels.UserToken[]
      yield put(setRichAccountsBalances(richList))
    })
  }
}
