import { BIG_ZERO, parseNumber } from 'js/utils'
import { fieldTypes } from '../../GenericProposal/Helpers/types'
import { getErrorMessages, getInputConstraints } from '../../Helpers/InputConstraints'

export const updateSpotMarketRequiredFields = [
  'name',
  'displayName',
  'marketDescription',
  'minQuantity',
  'takerFee',
  'makerFee',
]

export const updateFuturesMarketRequiredFields = updateSpotMarketRequiredFields.concat([
  'riskStepSize',
  'initialMarginBase',
  'initialMarginStep',
  'maintenanceMarginRatio',
  'maxLiquidationOrderTicket',
  'maxLiquidationOrderDuration',
  'impactSize',
  'markPriceBand',
  'lastPriceProtectedBand',
])

const basicConfig = {
  minQuantity: {
    id: 'minQuantity',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
    },
  },
  takerFee: {
    id: 'takerFee',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
      lessThan: 100,
    },
  },
  makerFee: {
    id: 'makerFee',
    type: fieldTypes.number,
    constraints: {
      lessThan: 100,
    },
  },
}

const futuresConfig = {
  riskStepSize: {
    id: 'riskStepSize',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
    },
  },
  initialMarginBase: {
    id: 'initialMarginBase',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
    },
  },
  initialMarginStep: {
    id: 'initialMarginStep',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
    },
  },
  maintenanceMarginRatio: {
    id: 'maintenanceMarginRatio',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 1,
    },
  },
  maxLiquidationOrderTicket: {
    id: 'maxLiquidationOrderTicket',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
  maxLiquidationOrderDuration: {
    id: 'maxLiquidationOrderDuration',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 30,
      onlyInteger: true,
    },
  },
  impactSize: {
    id: 'impactSize',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
  markPriceBand: {
    id: 'markPriceBand',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 20000,
      onlyInteger: true,
    },
  },
  lastPriceProtectedBand: {
    id: 'lastPriceProtectedBand',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 20000,
      onlyInteger: true,
    },
  },
}

export function updateMarketValidator(formState: any) {
  let errors = getErrorMessages(formState, getInputConstraints(basicConfig))

  const takerFee = parseNumber(formState.takerFee, BIG_ZERO)!
  const makerFee = parseNumber(formState.makerFee, BIG_ZERO)!
  const sum = takerFee.plus(makerFee)
  if (takerFee.isLessThan(makerFee)) {
    errors.push('Taker fee should not be less than maker fee')
  }
  if (sum.isLessThan(0)) {
    errors.push('The sum of taker fee and maker fee must not be negative')
  }
  if (takerFee.dp() > 3 || makerFee.dp() > 3) {
    errors.push('Taker and maker fee should only have up to 0.1 bps precision')
  }

  if (formState.marketType === 'futures') {
    errors = errors.concat(getErrorMessages(formState, getInputConstraints(futuresConfig)))

    const riskStepSize = parseNumber(formState.riskStepSize, BIG_ZERO)!
    const initialMarginStep = parseNumber(formState.initialMarginStep, BIG_ZERO)!
    if (riskStepSize.isZero() !== initialMarginStep.isZero()) {
      errors.push('Risk step size and initial margin step must be either both zero or both not zero')
    }
  }
  return errors
}
