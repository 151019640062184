import { List, Record, RecordOf } from 'immutable'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { MarketsAction, MarketsActionType } from '../actions/markets'

export interface MarketsProps {
  markets: List<RestModels.Market>
  orders?: RestTypes.ResultsMinMax<RestModels.Order>
  trades?: RestTypes.ResultsMinMax<RestModels.Trade>
}

const makeState: Record.Factory<MarketsProps> = Record<MarketsProps>({
  markets: List<RestModels.Market>(),
  orders: undefined,
  trades: undefined,
})
export type MarketsState = RecordOf<MarketsProps>

function marketsReducer(
  state: MarketsState = makeState(),
  action: MarketsAction,
): MarketsState {
  switch (action.type) {
    case MarketsActionType.CLEAR:
      return makeState()
    case MarketsActionType.SET_ORDERS:
      return state.updateIn(['orders'], () => action.orders)
    case MarketsActionType.SET_TRADES:
      return state.updateIn(['trades'], () => action.trades)
    default:
      return state
  }
}

export default marketsReducer
