import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CellLink, DataLoadSegment, ListTable, Section, StandardBtn, TableEmptyState, TxTypeLabel } from 'js/components'
import { TaskNames } from 'js/constants'
import { shorten } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { RestModels } from 'tradehub-api-js'

interface Props {
  transactions: Array<RestModels.Txn>
}

const Transactions: React.FunctionComponent<Props> = ({
  transactions,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Dashboard.Transactions)

  return (
    <Section title="Latest Transactions" className={classes.container}>
      <DataLoadSegment loading={loading && !transactions}>
        <ListTable className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell align="right" className={classes.wideTitle}>Tx Hash</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TransitionGroup
              enter
              component={null}
              appear={false}
              exit={false}
            >
              {transactions.map(renderTransaction)}
            </TransitionGroup>
          </TableBody>
        </ListTable>
        {transactions && transactions?.length > 0 && (
          <StandardBtn
            to="/transactions"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            View More
          </StandardBtn>
        )}
        {(!transactions || transactions?.length <= 0) && (
          <TableEmptyState itemName="transactions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransaction(
    transaction: RestModels.Txn,
    index: number,
  ): ReactNode {
    const { hash, height, msg_type, code } = transaction
    
    return (
      <CSSTransition key={index} timeout={400}>
        <TableRow hover className={classes.row}>
          <TableCell>
            <CellLink to={`/block/${height}`}>{height}</CellLink>
          </TableCell>
          <TableCell align="right">
            <CellLink to={`/transaction/${hash}`}>{shorten(hash)}</CellLink>
          </TableCell>
          <TableCell align="right">
            <TxTypeLabel>{msg_type}</TxTypeLabel>
          </TableCell>
          <TableCell>{code === '0' ? 'Success' : 'Fail'}</TableCell>
        </TableRow>
      </CSSTransition>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontSize: '1.375rem',
    fontWeight: 700,
    paddingBottom: theme.spacing(2),
  },
  row: {
    height: '3.3125rem',
    '&.enter': {
      opacity: 0.1,
    },
    '&.enter-active': {
      opacity: 1,
      transition: 'opacity 400ms ease-in',
    },
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
    },
  },
  wideTitle: {
    minWidth: '13.125rem',
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: 'auto',
    width: '12.5rem',
  },
}))

export default Transactions
