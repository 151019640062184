import BigNumber from 'bignumber.js'
import { SimpleMap } from 'js/utils'
import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'
import { Network } from '../models/Network'
import { MenuDropValue } from '../reducers/app'

export enum AppActionType {
  SET_NETWORK = 'SET_NETWORK',
  SET_DELEGATORS_MAP = 'SET_DELEGATORS_MAP',
  SET_VALIDATORS_MAP = 'SET_VALIDATORS_MAP',
  SET_CONSENSUS_MAP = 'SET_CONSENSUS_MAP',
  SET_OPERATORS_MAP = 'SET_OPERATORS_MAP',
  SET_SIGNING_INFO = 'SET_SIGNING_INFO',
  SET_TOTAL_BONDED = 'SET_TOTAL_BONDED',
  SET_TOTAL_STAKED = 'SET_TOTAL_STAKED',
  SET_MARKETS = 'SET_MARKETS',
  SET_GECKO_PRICES = 'SET_GECKO_PRICES',
  SET_LATEST_BLOCK = 'SET_LATEST_BLOCK',
  SET_NEO_PRICE = 'SET_NEO_PRICE',
  SET_ORACLE_RESULTS = 'SET_ORACLE_RESULTS',
  SET_SWTH_SUPPLY = 'SET_SWTH_SUPPLY',
  SET_TOKENS_MAP = 'SET_TOKENS_MAP',
  SET_MODE = 'SET_MODE',
  SET_TOTAL_SUPPLY = 'SET_TOTAL_SUPPLY',
  REQUEST_SIGN = 'REQUEST_SIGN',
  SET_SIGN_ERROR = 'SET_SIGN_ERROR',
  FINISH_SIGN = 'FINISH_SIGN',
  SET_NET_DROP_CLOSE = 'SET_NET_DROP_CLOSE',
  SET_NET_DROP_OPEN = 'SET_NET_DROP_OPEN',
  SET_WALLET_DROP_CLOSE = 'SET_WALLET_DROP_CLOSE',
  SET_WALLET_DROP_OPEN = 'SET_WALLET_DROP_OPEN',
  SET_MENU_DROP_ITEM = 'SET_MENU_DROP_ITEM',
  SET_MENU_DROP_CLOSE = 'SET_MENU_DROP_CLOSE',
}

export type AppAction =
  | SetNetworkAction
  | SetDelegatorsAction
  | SetValidatorsAction
  | SetConsensusAction
  | SetOperatorsAction
  | SetSigningInfoAction
  | SetTotalBondedAction
  | SetTotalStakedAction
  | SetMarketsAction
  | SetGeckoPricesAction
  | SetNeoPriceAction
  | SetLatestBlockAction
  | SetOraclesAction
  | SetSWTHSupplyAction
  | SetTokensInfoAction
  | SetModeAction
  | SetTotalSupplyAction
  | RequestSignAction
  | SetSignErrorAction
  | FinishSignAction
  | NetDropCloseAction
  | NetDropOpenAction
  | WalletDropCloseAction
  | WalletDropOpenAction
  | MenuDropItemAction
  | MenuDropCloseAction

export interface SetNetworkAction extends Action<AppActionType.SET_NETWORK> {
  network: Network
}
export function setNetwork(network: Network): SetNetworkAction {
  return {
    type: AppActionType.SET_NETWORK,
    network,
  }
}

export interface SetDelegatorsAction
  extends Action<AppActionType.SET_DELEGATORS_MAP> {
  delegators: SimpleMap<RestModels.ValidatorDelegation[]>
}

export function setDelegators(delegators: SimpleMap<RestModels.ValidatorDelegation[]>): SetDelegatorsAction {
  return {
    type: AppActionType.SET_DELEGATORS_MAP,
    delegators,
  }
}

export interface SetValidatorsAction
  extends Action<AppActionType.SET_VALIDATORS_MAP> {
  validators: SimpleMap<RestModels.Validator>
}

export function setValidators(validators: SimpleMap<RestModels.Validator>): SetValidatorsAction {
  return {
    type: AppActionType.SET_VALIDATORS_MAP,
    validators,
  }
}

export interface SetSWTHSupplyAction
  extends Action<AppActionType.SET_SWTH_SUPPLY> {
  swthSupply: any
}

export function setSWTHSupply(swthSupply: any): SetSWTHSupplyAction {
  return {
    type: AppActionType.SET_SWTH_SUPPLY,
    swthSupply,
  }
}

export interface SetConsensusAction
  extends Action<AppActionType.SET_CONSENSUS_MAP> {
  consensus: any
}

export function setConsensus(consensus: any): SetConsensusAction {
  return {
    type: AppActionType.SET_CONSENSUS_MAP,
    consensus,
  }
}

export interface SetOperatorsAction
  extends Action<AppActionType.SET_OPERATORS_MAP> {
  operators: any
}

export function setOperators(operators: any): SetOperatorsAction {
  return {
    type: AppActionType.SET_OPERATORS_MAP,
    operators,
  }
}

export interface SetSigningInfoAction
  extends Action<AppActionType.SET_SIGNING_INFO> {
  info: any
}

export function setSigningInfo(info: any): SetSigningInfoAction {
  return {
    type: AppActionType.SET_SIGNING_INFO,
    info,
  }
}
export interface SetTotalBondedAction
  extends Action<AppActionType.SET_TOTAL_BONDED> {
  totalBonded: any
}

export function setTotalBonded(totalBonded: any): SetTotalBondedAction {
  return {
    type: AppActionType.SET_TOTAL_BONDED,
    totalBonded,
  }
}
export interface SetTotalStakedAction
  extends Action<AppActionType.SET_TOTAL_STAKED> {
  totalStaked: any
}

export function setTotalStaked(totalStaked: any): SetTotalStakedAction {
  return {
    type: AppActionType.SET_TOTAL_STAKED,
    totalStaked,
  }
}
export interface SetMarketsAction extends Action<AppActionType.SET_MARKETS> {
  markets: SimpleMap<RestModels.Market>
}

export function setMarkets(markets: SimpleMap<RestModels.Market>): SetMarketsAction {
  return {
    type: AppActionType.SET_MARKETS,
    markets,
  }
}

export interface SetGeckoPricesAction
  extends Action<AppActionType.SET_GECKO_PRICES> {
  geckoPrices: any
}

export function setGeckoPrices(geckoPrices: any): SetGeckoPricesAction {
  return {
    type: AppActionType.SET_GECKO_PRICES,
    geckoPrices,
  }
}
export interface SetLatestBlockAction extends Action<AppActionType.SET_LATEST_BLOCK> {
  latestBlock: RestModels.CosmosBlock
}

export function setLatestBlock(latestBlock: RestModels.CosmosBlock): SetLatestBlockAction {
  return {
    type: AppActionType.SET_LATEST_BLOCK,
    latestBlock,
  }
}
export interface SetNeoPriceAction extends Action<AppActionType.SET_NEO_PRICE> {
  neoPrice: any
}

export function setNeoPrice(neoPrice: any): SetNeoPriceAction {
  return {
    type: AppActionType.SET_NEO_PRICE,
    neoPrice,
  }
}

export interface SetOraclesAction
  extends Action<AppActionType.SET_ORACLE_RESULTS> {
  oracles: SimpleMap<RestModels.Oracle>
}

export function setOracles(oracles: SimpleMap<RestModels.Oracle>): SetOraclesAction {
  return {
    type: AppActionType.SET_ORACLE_RESULTS,
    oracles,
  }
}

export interface SetTokensInfoAction
  extends Action<AppActionType.SET_TOKENS_MAP> {
  tokens: SimpleMap<RestModels.Token>
}

export function setTokensMap(
  tokens: SimpleMap<RestModels.Token>,
): SetTokensInfoAction {
  return {
    type: AppActionType.SET_TOKENS_MAP,
    tokens,
  }
}

export interface SetModeAction extends Action<AppActionType.SET_MODE> {
  mode: string
}

export function setMode(mode: string): SetModeAction {
  return {
    type: AppActionType.SET_MODE,
    mode,
  }
}
export interface SetTotalSupplyAction
  extends Action<AppActionType.SET_TOTAL_SUPPLY> {
  total: BigNumber
}

export function setTotalSupply(total: BigNumber): SetTotalSupplyAction {
  return {
    type: AppActionType.SET_TOTAL_SUPPLY,
    total,
  }
}

export interface RequestSignAction
  extends Action<AppActionType.REQUEST_SIGN> { }

export function requestSign(): RequestSignAction {
  return {
    type: AppActionType.REQUEST_SIGN
  }
}

export interface SetSignErrorAction
  extends Action<AppActionType.SET_SIGN_ERROR> { 
    message: string | undefined
  }

export function setSignErrors(message?: string): SetSignErrorAction {
  return {
    type: AppActionType.SET_SIGN_ERROR,
    message,
  }
}

export interface FinishSignAction
  extends Action<AppActionType.FINISH_SIGN> { }

export function finishSign(): FinishSignAction {
  return {
    type: AppActionType.FINISH_SIGN,
  }
}

export interface NetDropOpenAction
  extends Action<AppActionType.SET_NET_DROP_OPEN> { }

export function setNetDropOpen(): NetDropOpenAction {
  return {
    type: AppActionType.SET_NET_DROP_OPEN,
  }
}

export interface NetDropCloseAction
  extends Action<AppActionType.SET_NET_DROP_CLOSE> { }

export function setNetDropClose(): NetDropCloseAction {
  return {
    type: AppActionType.SET_NET_DROP_CLOSE,
  }
}

export interface WalletDropOpenAction
  extends Action<AppActionType.SET_WALLET_DROP_OPEN> { }

export function setWalletDropOpen(): WalletDropOpenAction {
  return {
    type: AppActionType.SET_WALLET_DROP_OPEN,
  }
}

export interface WalletDropCloseAction
  extends Action<AppActionType.SET_WALLET_DROP_CLOSE> { }

export function setWalletDropClose(): WalletDropCloseAction {
  return {
    type: AppActionType.SET_WALLET_DROP_CLOSE,
  }
}

export interface MenuDropItemAction
  extends Action<AppActionType.SET_MENU_DROP_ITEM> {
    menuItem: MenuDropValue
  }

export function setMenuDropItem(menuItem: MenuDropValue): MenuDropItemAction {
  return {
    type: AppActionType.SET_MENU_DROP_ITEM,
    menuItem,
  }
}

export interface MenuDropCloseAction
  extends Action<AppActionType.SET_MENU_DROP_CLOSE> { }

export function setMenuDropClose(): MenuDropCloseAction {
  return {
    type: AppActionType.SET_MENU_DROP_CLOSE,
  }
}
