import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { BlockAction, BlockActionType } from '../actions/block'

export interface BlockProps {
  transactions: Array<RestModels.Txn>
  events?: RestModels.BlockEvents
  blockInfo?: RestModels.CosmosBlock
}
const makeState: Record.Factory<BlockProps> = Record<BlockProps>({
  transactions: [],
  events: undefined,
  blockInfo: undefined,
})
export type BlockState = RecordOf<BlockProps>

function blockReducer(
  state: BlockState = makeState(),
  action: BlockAction,
): BlockState {
  switch (action.type) {
    case BlockActionType.CLEAR:
      return makeState()
    case BlockActionType.SET_TRANSACTIONS:
      return state.updateIn(
        ['transactions'],
        (): Array<RestModels.Txn> => {
          return action.transactions
        },
      )
    case BlockActionType.SET_EVENTS:
      return state.set('events', action.events)
    case BlockActionType.SET_BLOCK_INFO:
      return state.set('blockInfo', action.blockInfo)
    default:
      return state
  }
}

export default blockReducer
