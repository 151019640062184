import { RootState } from "js/store"
import { useSelector } from "react-redux"

/**
 * Convenience override for specifying selector parameter type
 * Overrides hook useSelector()
 *
 * Before:
 * ```
 * const value = useSelector((state: RootState) => state.container.value)
 * ```
 *
 * With useRedux:
 * `state` is implicitly typed with `RootState`
 * ```
 * const value = useRedux((state) => state.container.value)
 * ```
 */
export default <TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => {
  return useSelector<RootState, TSelected>(selector, equalityFn)
}
