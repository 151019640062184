import { UnlinkPoolProposalFormState } from 'js/models'
import store from 'js/store'
import { escapeHtmlGo } from 'js/utils'
import { RPCParams } from "tradehub-api-js"

export const requiredFields = ['poolID']

export function unlinkPoolProposalValue(): RPCParams.UnlinkPoolProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as UnlinkPoolProposalFormState
  const wallet = state.core.sdk?.wallet

  const { title, description, poolID } = formState

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    msg: {
      pool_id: poolID,
      originator: wallet?.bech32Address || '',
    },
  }

  return value
}
