import BigNumber from 'bignumber.js'
import { Constants } from 'js/constants'
import { useRedux } from 'js/hooks'
import { ProposalStatuses } from 'js/models/Governance'
import { BIG_ZERO } from 'js/utils'
import React from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  proposal: RestModels.GovProposal
}

enum states {
  WaitMinDeposit,
  WaitQuorum,
  PassedThreshold,
  FailedThreshold,
  RejectedVeto,
  Rejected,
  RejectedMinQuorum,
  Executed,
}

const ProposalStatus: React.FC<Props> = (props: Props) => {
  const validatorsMap = useRedux((state) => state.app.validatorsMap)
  const voteTallies = useRedux((state) => state.governance.liveVoteTallies)
  const tallyParams = useRedux((state) => state.governance.tallyParams)

  const { proposal } = props
  const voteTally = voteTallies[proposal.id]
  const totalStakingPower = Object.values(validatorsMap).reduce((sumShares, validator) => {
    return sumShares = sumShares.plus(validator.DelegatorShares)
  }, BIG_ZERO)

  const state = getState(proposal, voteTally, totalStakingPower, tallyParams)
  return (
    <React.Fragment>
      {getMessage(state, tallyParams)}
    </React.Fragment>
  )
}

function getState(proposal: RestModels.GovProposal,
  voteTally: RestModels.GovProposalTally | undefined,
  totalStakingPower: BigNumber,
  tallyParams: RestModels.GovTallyParams | undefined,
) {
  let state = states.WaitMinDeposit

  switch (proposal.proposal_status) {
    case ProposalStatuses.DepositPeriod:
      return state
    case ProposalStatuses.Passed:
      return states.Executed
    case ProposalStatuses.Rejected:
      return states.Rejected
    case ProposalStatuses.VotingPeriod:
      state = states.WaitQuorum
  }

  const resultYes = voteTally?.yes || BIG_ZERO
  const resultNo = voteTally?.no || BIG_ZERO
  const resultVeto = voteTally?.no_with_veto || BIG_ZERO
  const resultAbstain = voteTally?.abstain || BIG_ZERO
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  // Quorum: more than 40% of the total staked tokens at
  //  the end of the voting period need to have voted
  const totalStakingPowerUnitless = totalStakingPower.shiftedBy(Constants.Decimals.SWTH)
  // console.log(totalVoted.toString(10), totalStakingPowerUnitless.toString(10), totalVoted.div(totalStakingPowerUnitless).toString(10))
  if ((totalVoted.div(totalStakingPowerUnitless)).lt(tallyParams?.quorum || 0.4)) {
    state = states.WaitQuorum

    // Veto: More than 33.4% of the tokens that participated in the vote,
    //  not counting “Abstain” votes, have vetoed the decision “No (With Veto)”.
  } else if ((resultVeto.div(totalVoted.minus(resultAbstain))).gt(tallyParams?.veto || 0.334)) {
    state = states.RejectedVeto

    // Threshold: More than 50% or a majority of the tokens that participated in the vote,
    //  excluding “Abstain” votes must have voted “Yes”
  } else if ((resultYes.div(totalVoted.minus(resultAbstain))).gt(tallyParams?.threshold || 0.5)) {
    state = states.PassedThreshold

  } else if (((resultNo.plus(resultVeto)).div(totalVoted.minus(resultAbstain))).gt(tallyParams?.threshold || 0.5)) {
    state = states.FailedThreshold
  }

  return state
}

function getPercent(value: BigNumber) {
  return `${value.shiftedBy(2).toFormat(2)}%`
}

function getMessage(state: states, tallyParams: RestModels.GovTallyParams | undefined) {
  switch (state) {
    case states.WaitMinDeposit:
      return 'Waiting to reach minimum deposit'
    case states.WaitQuorum:
      return 'Waiting to reach quorum'
    case states.PassedThreshold:
      return `Reached pass threshold`
    case states.Executed:
      return 'Proposal was executed'
    case states.RejectedMinQuorum:
      return 'Failed to reach minimum quorum'
    case states.Rejected:
      return 'Proposal was rejected'
    case states.FailedThreshold:
      return `Over ${getPercent(new BigNumber(tallyParams?.threshold || 1.5).minus(1).abs())} voted for No or No with Veto`
    case states.RejectedVeto:
      return `Over ${getPercent(new BigNumber(tallyParams?.veto || 0.334))} voted for No with Veto`
    default:
      return ''
  }
}

export default ProposalStatus
