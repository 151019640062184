import {
  Button,
  ButtonGroup as MuiButtonGroup,
  withStyles,
  makeStyles,
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { switcheo } from 'js/theme/palettes/colors'
import clsx from 'clsx'
// tslint:disable:jsx-no-lambda

interface Props {
  handleButtonClick: any
  disableFoward: boolean
  disableBack: boolean
}

const PaginationButtons: React.FunctionComponent<Props> = ({
  handleButtonClick,
  disableBack,
  disableFoward,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button
        className={classes.buttons}
        onClick={() => handleButtonClick('first')}
        name="first"
        disabled={disableBack}
      >
        First
      </Button>
      <Button
        onClick={() => handleButtonClick('previous')}
        name="previous"
        className={clsx(classes.buttons, disableBack ? classes.normal : classes.showRightBorder)}
        disabled={disableBack}
      >
        Previous
      </Button>
      <Button
        className={classes.buttons}
        onClick={() => handleButtonClick('next')}
        name="next"
        disabled={disableFoward}
      >
        Next
      </Button>
      <Button
        className={classes.buttons}
        onClick={() => handleButtonClick('last')}
        name="last"
        disabled={disableFoward}
      >
        Last
      </Button>
      x
    </ButtonGroup>
  )
}

const useStyles = makeStyles((theme) => ({
  showRightBorder: {
    borderRightColor: `${switcheo.paginationButtonBorder} !important`,
  },
  normal: {
    borderRightColor: 'inherit',
  },
  buttons: {
    [theme.breakpoints.down('xs')]: {
        fontSize: '0.9rem',
        minWidth: 0,
        padding: '0.3rem',
        justifyContent: 'center',
        margin: 0
    },
  }
}))

const ButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto'
    },
  },
}))(MuiButtonGroup)

export default PaginationButtons
