import { TableCellInfo } from 'js/components'

export const MarketsHeader: TableCellInfo[] = [
  {
    align: 'inherit',
    content: 'Display Name',
  },
  {
    align: 'inherit',
    content: 'Name',
  },
  {
    align: 'inherit',
    content: 'Type',
  },
  {
    content: 'Lot Size',
  },
  {
    content: 'Tick Size',
  },
  {
    content: 'Min. Quantity',
  },
  {
    content: 'Maker Fee',
  },
  {
    content: 'Taker Fee',
  },
  {
    align: 'inherit',
    content: 'Index Oracle ID',
  },
  {
    align: 'inherit',
    content: 'Is Active',
  },
  {
    align: 'inherit',
    content: 'Is Settled',
  },
  {
    content: 'Expiry Time',
  },
  {
    content: 'Created At',
  }
]
