import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiSelect']>> => ({
  filled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    padding: theme.spacing(1.5),
  },
})
