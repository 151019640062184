import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'

type Props = BoxProps & {
  success?: boolean
  warning?: boolean
  error?: boolean
  disabled?: boolean
  label?: string
}

const VoteStatistic: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    className,
    children,
    success,
    warning,
    error,
    disabled,
    label,
    ...rest
  } = props
  const classes = useStyles(props)

  const colorClass = {
    [classes.success]: success,
    [classes.error]: error,
    [classes.warning]: warning,
    [classes.disabled]: disabled,
  }

  return (
    <Box {...rest} className={clsx(classes.root, className, colorClass)}>
      <Typography variant="body1" className={classes.statistic}>
        {children}
      </Typography>
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    textAlign: 'center',
    minWidth: '5rem',
  },
  statistic: {
    fontWeight: 'bold',
  },
  label: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
  },
  warning: {
    color: switcheo.warning,
  },
  error: {
    color: switcheo.danger,
  },
  success: {
    color: switcheo.success,
  },
  disabled: {
    color: switcheo.mono[400],
  },
}))

export default VoteStatistic
