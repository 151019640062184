import { TableBody, TableCell, TableHead, TableRow, Theme, useMediaQuery } from '@material-ui/core'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState, TxTypeLabel } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props {
  transactions: Array<RestModels.Txn>
}

const TransactionMsgs: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { transactions } = props
  const smallScreen: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )
  const [loading] = useTaskSubscriber(TaskNames.Block.Transaction)

  return (
    <Section title="Transaction Messages">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Hash</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((tx: RestModels.Txn, index: number) =>
              renderTransaction(tx, smallScreen, index)
            )}
          </TableBody>
        </ListTable>
        {(!transactions || transactions?.length <= 0) && (
          <TableEmptyState itemName="transactions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransaction(
    transaction: RestModels.Txn,
    isScreenSmall: boolean,
    index: number,
  ): ReactNode {
    const { hash, msg_type, code } = transaction
    const shortenedHash = isScreenSmall
      ? hash
        .substring(0, 12)
        .concat('... ')
        .concat(hash.slice(-12))
      : hash

    return (
      <TableRow key={hash + index} hover>
        <TableCell>
          <CellLink to={`/transaction/${hash}`}>{shortenedHash}</CellLink>
        </TableCell>
        <TableCell>
          <TxTypeLabel>{msg_type}</TxTypeLabel>
        </TableCell>
        <TableCell>{code === '0' ? 'Success' : 'Fail'}</TableCell>
      </TableRow>
    )
  }
}

export default TransactionMsgs
