import { AppActionType } from 'js/actions/app'
import { clearSubmitProposalFormState, GovernanceActionTypes, setDelegations, setDepositParams, setIsSubmittingProposal, setProposal, setProposalDeposits, setProposals, setProposalVotes, setSubmitProposalDepositAmount, setSubmitProposalError, setSubmitProposalId, setTallyParams, updateLiveVoteTally } from 'js/actions/governance'
import { setLiquidityPools } from 'js/actions/liquidityPools'
import { clear } from 'js/actions/validators'
import customToast from 'js/components/Toast/Toast'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { all, call, delay, Effect, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes, TradeHubWallet } from 'tradehub-api-js'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class Governance extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchTallyParameters]),
      call([this, this.fetchDepositParameters]),
      spawn([this, this.pollProposals]),
      spawn([this, this.pollDelegations]),
      spawn([this, this.pollDeposits]),
      spawn([this, this.pollVotes]),
      spawn([this, this.watchSubmitProposal]),
      spawn([this, this.fetchPools]),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *pollProposals(): any {
    while (true) {
      yield runSagaTask(TaskNames.Governance.Proposals, function* () {
        const sdk = yield* getInitializedSDK()
        const listProposalsResponse = (yield sdk.api.listGovProposals()) as RestTypes.GovListProposalResponse
        const proposals = listProposalsResponse.result

        proposals.sort((lhs, rhs) => {
          return rhs.submit_time.diff(lhs.submit_time) || parseInt(lhs.id) - parseInt(rhs.id)
        })

        const finalizedProposals: RestModels.GovProposal[] = []
        const liveProposals: RestModels.GovProposal[] = []

        proposals.forEach((proposal) => {
          if (["Passed", "Rejected"].includes(proposal.proposal_status)) {
            finalizedProposals.push(proposal)
          } else {
            liveProposals.push(proposal)
          }
        })

        const liveTalliesResponses = (yield all(liveProposals.map((proposal: RestModels.GovProposal) => {
          return call([
            sdk.api,
            sdk.api.getGovLiveTally,
          ], { proposalId: parseInt(proposal.id) })
        }))) as RestTypes.GovLiveTallyResponse[]
        const liveTallies = liveTalliesResponses.map((response) => response.result)

        yield all(finalizedProposals.map((proposal: RestModels.GovProposal) => {
          return put(updateLiveVoteTally(proposal.id, proposal.final_tally_result))
        }))
        yield all(liveTallies.map((tally: RestModels.GovProposalTally, index: number) => {
          return put(updateLiveVoteTally(liveProposals[index].id, tally))
        }))

        yield put(setProposals(proposals))
      })
      yield delay(10000)
    }
  }

  private *fetchTallyParameters(): any {
    yield runSagaTask(TaskNames.Governance.TallyParams, function* () {
      const sdk = yield* getInitializedSDK()
      const tallyParams = (yield sdk.api.getGovParamsTally()) as RestTypes.GovTallyParamsResponse
      yield put(setTallyParams(tallyParams.result))
    })
  }

  private *fetchDepositParameters(): any {
    yield runSagaTask(TaskNames.Governance.DepositParams, function* () {
      const sdk = yield* getInitializedSDK()
      const depositParams = (yield sdk.api.getGovParamsDeposit()) as RestTypes.GovDepositParamsResponse
      yield put(setDepositParams(depositParams.result))
    })
  }

  private *pollDelegations(): any {
    while (true) {
      yield runSagaTask(TaskNames.Governance.Delegations, function* () {
        const wallet = (yield select((state) => state.core.sdk?.wallet)) as TradeHubWallet | undefined
        if (!wallet) return

        const sdk = yield* getInitializedSDK()
        const delegations = (yield sdk.api.getDelegatorDelegations({
          address: wallet.bech32Address,
        })) as RestTypes.GetDelegatorDelegationsResponse

        yield put(setDelegations(delegations.result))
      })
      yield delay(10000)
    }
  }

  private *pollDeposits(): any {
    while (true) {
      yield runSagaTask(TaskNames.Governance.DepositHistory, function* () {
        const sdk = yield* getInitializedSDK()
        const txs = (yield sdk.api.getTxs({
          msg_type: 'deposit',
        })) as RestTypes.GetTxsResponse
        yield put(setProposalDeposits(txs))
      })
      yield delay(10000)
    }
  }

  private *pollVotes(): any {
    while (true) {
      yield runSagaTask(TaskNames.Governance.VoteHistory, function* () {
        const sdk = yield* getInitializedSDK()
        const txs = (yield sdk.api.getTxs({
          msg_type: 'vote',
        })) as RestTypes.GetTxsResponse
        yield put(setProposalVotes(txs))
      })
      yield delay(10000)
    }
  }

  private *watchSubmitProposal(): any {
    yield takeLatest(GovernanceActionTypes.SUBMIT_PROPOSAL, this.handleExecuteSubmitProposal)
  }

  private *handleExecuteSubmitProposal(action: any): any {
    try {
      yield put(setIsSubmittingProposal(true))
      const sdk = yield* getInitializedSDK()
      const proposalType = action.proposalType
      const value = action.value
      const initialDeposit = action.deposit

      // Submit proposal
      const result = yield call(
        [sdk.governance, sdk.governance.submitProposal],
        proposalType,
        value,
        initialDeposit,
      )

      if (result.logs) {
        const events = result.logs[0].events
        yield put(clearSubmitProposalFormState())
        yield put(setSubmitProposalDepositAmount(''))
        yield put(setSubmitProposalError(null))

        const proposalId = events[1].attributes[1].value

        // Delay page load and notification until new proposal is found from rest call
        let newProposal = null
        while (!newProposal) {
          const getProposalsResponse = (yield sdk.api.getGovProposal({ proposalId })) as RestTypes.GovGetProposalResponse
          newProposal = getProposalsResponse.result
          if (newProposal) yield put(setProposal(newProposal))
          yield delay(2000)
        }
        yield put(setSubmitProposalId(proposalId))
        customToast('Notification', 'Proposal sucessfully placed')
      }
    } catch (err) {
      yield put(setSubmitProposalError(err))
    } finally {
      yield put(setIsSubmittingProposal(false))
    }
  }

  private * fetchPools(): any {
    yield runSagaTask(TaskNames.Pools.List, function* () {
      const sdk = yield* getInitializedSDK()
      const pools = (yield sdk.api.getLiquidityPools()) as RestModels.LiquidityPool[]
      yield put(setLiquidityPools(pools))
    })
  }
}
