import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { ListTable, Section } from 'js/components'
import { SignedBlocksCount, TooltipsMsgs } from 'js/constants'
import React, { ReactElement } from 'react'
import { ProfileDetailRow } from '../components'

interface Props {
  validator: any
}

type RowInfo = {
  keyClass?: string
  title: string
  tooltip?: string
  value: any
  valueClass?: string
}

type RowsData = RowInfo[]

const CurrentPool: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { validator } = props
  const classes = useStyles()

  const rows: RowsData = [
    {
      title: 'Total Stake',
      tooltip: TooltipsMsgs.total_stake,
      value: cellFormat('', validator?.tokens),
      valueClass: classes.rowValue,
    },
    {
      title: 'Self Stake',
      tooltip: TooltipsMsgs.self_stake,
      value: cellFormat('', validator?.self_bond),
      valueClass: classes.rowValue,
    },
    {
      title: 'Delegator shares',
      value: cellFormat('', validator?.delegator_shares),
      valueClass: classes.rowValue,
    },
    {
      title: 'Delegators',
      value: cellFormat('', validator?.delegators),
      valueClass: classes.rowValue,
    },
    {
      title: 'Bond status',
      value: cellFormat('', validator?.bond_status),
      valueClass: clsx(classes.bondStatus, classes.rowValue),
    },
    {
      title: 'Commission',
      value: cellFormat('percentage', validator?.commission_rate),
      tooltip: TooltipsMsgs.commission_rate,
      valueClass: classes.rowValue,
    },
    {
      title: 'Max rate',
      value: cellFormat('percentage', validator?.max_rate),
      valueClass: classes.rowValue,
    },
    {
      title: 'Max change rate',
      value: cellFormat('percentage', validator?.max_change_rate),
      valueClass: classes.rowValue,
    },
    {
      title: 'Jailed',
      value: cellFormat('status', validator?.jailed),
      valueClass: classes.rowValue,
    },
    {
      keyClass: classes.endRow,
      title: 'Uptime',
      tooltip: TooltipsMsgs.uptime,
      value: cellFormat('uptime', validator?.missed_blocks_count),
      valueClass: classes.endRowValue,
    },
  ]

  return (
    <Section title="Current Pool" titleClass={classes.title}>
      <ListTable>
        <TableBody>
          {
            rows.map((row, id) =>
              <ProfileDetailRow key={id} {...row} />,
            )
          }
        </TableBody>
      </ListTable>
    </Section>
  )
}

const cellFormat = (name: string, value: any) => {
  switch (name) {
    case 'percentage':
      if (value) {
        return `${(parseFloat(value) * 100).toFixed(2)} %`
      }
      break
    case 'status':
      if (value) {
        return 'Yes'
      }
      return 'No'

    case 'uptime':
      if (typeof value === 'undefined') {
        return '0%'
      }
      const missedBlocks = new BigNumber(value)
      if (missedBlocks.comparedTo(SignedBlocksCount) === 1) {
        return `100% (0)`
      }
      return `${SignedBlocksCount.minus(missedBlocks).div(SignedBlocksCount).times(100).toFormat(2)}% (${value})`
    default:
      if (value) {
        return value
      }
      break
  }
  return "-"
}

const useStyles = makeStyles((theme: Theme) => ({
  bondStatus: {
    textTransform: 'capitalize',
  },
  rowValue: {
    '&.MuiTableCell-root:last-child': {
      textAlign: 'right !important',
    },
  },
  endRow: {
    "&.MuiTableCell-root": {
      borderBottom: '1px solid transparent',
    },
  },
  endRowValue: {
    "&.MuiTableCell-root": {
      borderBottom: '1px solid transparent',
      '&:last-child': {
        textAlign: 'right !important',
      },
    },
  },
  title: {
    textAlign: 'left',
  },
}))

export default CurrentPool
