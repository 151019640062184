import {
    makeStyles,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
  } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import { SimpleMap } from 'js/utils'
import React, { ReactElement } from 'react'

  // tslint:disable:jsx-no-lambda
interface Props  {
    fees: SimpleMap<BigNumber>
    loadName: string[]
    title: string
  }

const GasFees: React.FunctionComponent<Props> = (
    props: Props,
  ): ReactElement<Props> => {
    const { fees, loadName, title } = props
    const classes = useStyles()
    const [loading] = useTaskSubscriber(...loadName)

    return (
      <Section className={classes.root} title={title}>
        <DataLoadSegment loading={loading}>
          <ListTable>
            <TableHead>
              <TableRow>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(fees).map((key) => {
                const fee = new BigNumber(fees[key]).shiftedBy(-8).toFormat(2)
                return (
                  <TableRow key={key}>
                    <TableCell>
                      {key.replaceAll('_', ' ').toUpperCase()}
                    </TableCell>
                    <TableCell>
                      {fee} SWTH
                    </TableCell>
                  </TableRow>
                )
              },
              )}
            </TableBody>
          </ListTable>
        </DataLoadSegment>
      </Section>
    )
  }

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(1, 3, 3),
    },
  }))

export default GasFees

