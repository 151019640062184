import { Action } from 'redux'
import { RestModels, RestResponse, RestTypes } from 'tradehub-api-js'

export enum MarketActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_STATS = 'SET_STATS',
  SET_MARKET = 'SET_MARKET',
  SET_ORDERS = 'SET_ORDERS',
  SET_TRADES = 'SET_TRADES',
  UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS',
  UPDATE_TRADES_FILTERS = 'UPDATE_TRADES_FILTERS',
  SET_LARGE_POSITIONS = 'SET_LARGE_POSITIONS',
  UPDATE_LARGE_POSITIONS = 'UPDATE_LARGE_POSITIONS',
  SET_RISKY_LONG_POSITIONS = 'SET_RISKY_LONG_POSITIONS',
  UPDATE_RISKY_LONG_POSITIONS = 'UPDATE_RISKY_LONG_POSITIONS',
  SET_RISKY_SHORT_POSITIONS = 'SET_RISKY_SHORT_POSITIONS',
  UPDATE_RISKY_SHORT_POSITIONS = 'UPDATE_RISKY_SHORT_POSITIONS',
  SET_PROFITABLE_POSITIONS = 'SET_PROFITABLE_POSITIONS',
  UPDATE_PROFITABLE_POSITIONS = 'UPDATE_PROFITABLE_POSITIONS',
}

export type MarketAction =
  | ClearAction
  | SetMarketAction
  | SetMarketStatsAction
  | SetOrdersAction
  | SetTradesAction
  | UpdateOrdersFiltersAction
  | UpdateTradesFiltersAction
  | SetLargePositionsAction
  | UpdateLargePositionsAction
  | SetRiskyLongPositionsAction
  | UpdateRiskyLongPositionsAction
  | SetRiskyShortPositionsAction
  | UpdateRiskyShortPositionsAction
  | SetProfitablePositionsAction
  | UpdateProfitablePositionsAction

export interface ClearAction extends Action<MarketActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: MarketActionType.CLEAR,
  }
}

export interface SetMarketAction extends Action<MarketActionType.SET_MARKET> {
  market: RestResponse.Market
}

export function setMarket(market: RestResponse.Market): SetMarketAction {
  return {
    type: MarketActionType.SET_MARKET,
    market,
  }
}

export interface SetMarketStatsAction
  extends Action<MarketActionType.SET_STATS> {
  stats: RestResponse.MarketStat
}

export function setMarketStats(stats: RestResponse.MarketStat): SetMarketStatsAction {
  return {
    type: MarketActionType.SET_STATS,
    stats,
  }
}

export interface SetOrdersAction extends Action<MarketActionType.SET_ORDERS> {
  orders: RestTypes.ResultsMinMax<RestModels.Order>
}

export function setOrders(orders: RestTypes.ResultsMinMax<RestModels.Order>): SetOrdersAction {
  return {
    type: MarketActionType.SET_ORDERS,
    orders,
  }
}
export interface SetTradesAction extends Action<MarketActionType.SET_TRADES> {
  trades: RestTypes.ResultsMinMax<RestModels.Trade>
}

export function setTrades(trades: RestTypes.ResultsMinMax<RestModels.Trade>): SetTradesAction {
  return {
    type: MarketActionType.SET_TRADES,
    trades,
  }
}

export interface UpdateOrdersFiltersAction
  extends Action<MarketActionType.UPDATE_ORDERS_FILTERS> {
  options: any
}

export function updateOrdersFilters(options: any): UpdateOrdersFiltersAction {
  return {
    type: MarketActionType.UPDATE_ORDERS_FILTERS,
    options,
  }
}

export interface UpdateTradesFiltersAction
  extends Action<MarketActionType.UPDATE_TRADES_FILTERS> {
  options: any
}

export function updateTradesFilters(options: any): UpdateTradesFiltersAction {
  return {
    type: MarketActionType.UPDATE_TRADES_FILTERS,
    options,
  }
}

export interface SetLargePositionsAction
  extends Action<MarketActionType.SET_LARGE_POSITIONS> {
  largePositions: RestTypes.ResultsPaged<RestResponse.Position>
}

export function setLargePositions(
  largePositions: RestTypes.ResultsPaged<RestResponse.Position>,
): SetLargePositionsAction {
  return {
    type: MarketActionType.SET_LARGE_POSITIONS,
    largePositions,
  }
}

export interface UpdateLargePositionsAction
  extends Action<MarketActionType.UPDATE_LARGE_POSITIONS> {
  options: any
}

export function updateLargePositions(options: any): UpdateLargePositionsAction {
  return {
    type: MarketActionType.UPDATE_LARGE_POSITIONS,
    options,
  }
}

export interface SetRiskyLongPositionsAction
  extends Action<MarketActionType.SET_RISKY_LONG_POSITIONS> {
  riskyPositions: RestTypes.ResultsPaged<RestResponse.Position>
}

export function setRiskyLongPositions(
  riskyPositions: RestTypes.ResultsPaged<RestResponse.Position>,
): SetRiskyLongPositionsAction {
  return {
    type: MarketActionType.SET_RISKY_LONG_POSITIONS,
    riskyPositions,
  }
}

export interface UpdateRiskyLongPositionsAction
  extends Action<MarketActionType.UPDATE_RISKY_LONG_POSITIONS> {
  options: any
}

export function updateRiskyLongPositions(
  options: any,
): UpdateRiskyLongPositionsAction {
  return {
    type: MarketActionType.UPDATE_RISKY_LONG_POSITIONS,
    options,
  }
}

export interface SetRiskyShortPositionsAction
  extends Action<MarketActionType.SET_RISKY_SHORT_POSITIONS> {
  riskyPositions: RestTypes.ResultsPaged<RestResponse.Position>
}

export function setRiskyShortPositions(
  riskyPositions: RestTypes.ResultsPaged<RestResponse.Position>,
): SetRiskyShortPositionsAction {
  return {
    type: MarketActionType.SET_RISKY_SHORT_POSITIONS,
    riskyPositions,
  }
}

export interface UpdateRiskyShortPositionsAction
  extends Action<MarketActionType.UPDATE_RISKY_SHORT_POSITIONS> {
  options: any
}

export function updateRiskyShortPositions(
  options: any,
): UpdateRiskyShortPositionsAction {
  return {
    type: MarketActionType.UPDATE_RISKY_SHORT_POSITIONS,
    options,
  }
}

export interface SetProfitablePositionsAction
  extends Action<MarketActionType.SET_PROFITABLE_POSITIONS> {
  profitablePositions: RestTypes.ResultsPaged<RestResponse.Position>
}

export function setProfitablePositions(
  profitablePositions: RestTypes.ResultsPaged<RestResponse.Position>,
): SetProfitablePositionsAction {
  return {
    type: MarketActionType.SET_PROFITABLE_POSITIONS,
    profitablePositions,
  }
}

export interface UpdateProfitablePositionsAction
  extends Action<MarketActionType.UPDATE_PROFITABLE_POSITIONS> {
  options: any
}

export function updateProfitablePositions(
  options: any,
): UpdateProfitablePositionsAction {
  return {
    type: MarketActionType.UPDATE_PROFITABLE_POSITIONS,
    options,
  }
}
