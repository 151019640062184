export enum Network {
  Main = 'MAINNET',
  Test = 'TESTNET',
  Dev = 'DEVNET',
  Local = 'LOCALHOST',
}

export function parseNetwork(network?: string | null) {
  switch (network) {
    case 'MainNet':
    case 'MAINNET':
    case 'main':
      return Network.Main
    case 'TestNet':
    case 'TESTNET':
    case 'test':
      return Network.Test
    case 'DevNet':
    case 'DEVNET':
    case 'dev':
      return Network.Dev
    case 'LocalNet':
    case 'LOCALNET':
    case 'LOCALHOST':
    case 'local':
      return Network.Local
    default:
      const isProduction = process.env.NODE_ENV === 'production'
      return isProduction ? Network.Main : Network.Test
  }
}

export function returnUrlNet(network?: string | null) {
  switch (network) {
    case 'MainNet':
    case 'MAINNET':
    case Network.Main:
      return 'main'
    case 'TestNet':
    case 'TESTNET':
    case Network.Test:
      return 'test'
    case 'DevNet':
    case 'DEVNET':
    case Network.Dev:
      return 'dev'
    case 'LocalNet':
    case 'LOCALNET':
    case 'LOCALHOST':
    case Network.Local:
      return 'local'
    default:
      const isProduction = process.env.NODE_ENV === 'production'
      return isProduction ? 'main' : 'test'
  }
}

export function parseNet(network: Network) {
  switch (network) {
    case Network.Main:
      return 'main'
    case Network.Test:
      return 'test'
    case Network.Dev:
      return 'dev'
    case Network.Local:
      return 'local'
    default:
      throw new Error(`Failed to parse net: ${network}`)
  }
}

export class UnknownNetworkError extends Error {
  constructor(network: Network) {
    super(`Unknown network error: ${network}`)
  }
}
