import { makeStyles, TableCell } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'

interface Props {
  denom?: string
  amount?: string
}

const AmountDenomCell: React.FunctionComponent<Props> = ({
  denom,
  amount,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux(state => state.core.sdk)

  const tokenName = useMemo(() => sdk?.token.getTokenName(denom ?? ''), [sdk, denom])
  return (
    <TableCell align="right">
      <div className={classes.fees}>
        {amount} <div className={classes.denom}>{tokenName}</div>
      </div>
    </TableCell>
  )
}

const useStyles = makeStyles({
  fees: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  denom: {
    color: grey[500],
    marginLeft: 6,
    minWidth: 42,
    textAlign: 'left',
  },
})

export default AmountDenomCell
