import BigNumber from 'bignumber.js'
import { clear, setCommitmentCurve, setLiquidityPools, setRewardCurve, setWeeklyPoolRewards } from 'js/actions/liquidityPools'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

export default class LiquidityPools extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchPools],
      [this, this.fetchWeeklyPoolRewards],
      [this, this.fetchRewardCurve],
      [this, this.fetchCommitmentCurve],
      [this, this.watchSetNetwork],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private * fetchPools(): any {
    yield runSagaTask(TaskNames.Pools.List, function* () {
      const sdk = yield* getInitializedSDK()
      const pools = (yield sdk.api.getLiquidityPools()) as RestModels.LiquidityPool[]
      yield put(setLiquidityPools(pools))
    })
  }

  private *fetchWeeklyPoolRewards(): any {
    yield runSagaTask(TaskNames.Pools.List, function* () {
      const sdk = yield* getInitializedSDK()
      const rewards = (yield sdk.api.getWeeklyPoolRewards()) as BigNumber
      yield put(setWeeklyPoolRewards(rewards))
    })
  }

  private *fetchCommitmentCurve(): any {
    yield runSagaTask(TaskNames.Pools.CommitmentCurve, function* () {
      const sdk = yield* getInitializedSDK()
      const commitmentCurve = (yield sdk.api.getCommitmentCurve()) as RestTypes.GetCommitmentCurveResponse
      yield put(setCommitmentCurve(commitmentCurve.result))
    })
  }

  private *fetchRewardCurve(): any {
    yield runSagaTask(TaskNames.Pools.RewardCurve, function* () {
      const sdk = yield* getInitializedSDK()
      const rewardCurve = (yield sdk.api.getRewardCurve()) as RestTypes.GetRewardCurveResponse
      yield put(setRewardCurve(rewardCurve.result))
    })
  }

}
