import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useRedux } from "js/hooks";
import React, { useMemo } from "react";
import { RestModels } from "tradehub-api-js";

interface Props extends SelectProps {
  defaultText?: string
}

const tokenBlackList = [
  "nneo1", // error token
]

const TokenSelectInput: React.FC<Props> = (props: Props) => {
  const { children, label, className, onChange, defaultText, ...rest } = props;
  const classes = useStyles();
  const allTokens = useRedux((state) => state.tokens.info)

  const _defaultText = defaultText ?? "Select Token"

  const _onChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>, child: React.ReactNode) => {
    onChange?.(event, child)
  }

  const tokens = useMemo(() => {
    return allTokens.filter((token) => !tokenBlackList.includes(token.denom))
  }, [allTokens])

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Select
          displayEmpty
          {...rest}
          className={clsx(classes.select, className)}
          onChange={_onChange}
        >
          <MenuItem value="" disabled>{_defaultText}</MenuItem>
          {
            tokens.map((token: RestModels.Token) =>
              <MenuItem key={`${token.denom}-${token.asset_id}`} value={token.denom}>
                [{token.denom?.toUpperCase()}] {token.name} ({token.blockchain})
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  select: {

  },
}))

export default TokenSelectInput;
