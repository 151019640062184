import { Box, makeStyles, TableCell, TableRow, TableRowProps, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Paths } from 'js/constants'
import { Constants } from 'js/constants/generic'
import { unescapeHtmlGo } from 'js/utils'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'
import CellLink from '../CellLink'
import { ProposalTopicLabel } from '../ParsedLabel'
import { PeriodTag, ProposalStatus, VoteStatisticGroup } from './components'

type Props = TableRowProps & {
  proposal: RestModels.GovProposal
}

const ProposalRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, proposal, ...rest } = props
  const classes = useStyles(props)

  const topic = proposal.content.value?.changes?.[0]?.subspace
  const title = proposal.content.value?.title
  const isDepositPeriod =
    proposal.proposal_status === Constants.Proposal.Status.DepositPeriod

  return (
    <TableRow {...rest} className={clsx(classes.root, className)}>
      <TableCell className={classes.titleCell}>
        <Box marginBottom={1}>
          <CellLink
            color="inherit"
            variant="h3"
            to={Paths.Proposal.replace(':proposalId', proposal.id)}
          >
            {!!topic && (
              <React.Fragment>
                [<ProposalTopicLabel>{topic}</ProposalTopicLabel>]
                {' '}
              </React.Fragment>
            )}
            {unescapeHtmlGo(title)}
          </CellLink>
        </Box>
        <Typography variant="body2">
          <ProposalStatus proposal={proposal} />
        </Typography>
      </TableCell>
      <TableCell>
        {!isDepositPeriod && (
          <VoteStatisticGroup proposal={proposal} />
        )}
      </TableCell>
      <TableCell>
        <PeriodTag proposal={proposal} width={1} />
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  titleCell: {
    width: '100%',
    minWidth: "250px",
  },
}))

export default ProposalRow
