import { Box, BoxProps, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip } from '@material-ui/core'
import StarIcon from '@material-ui/icons/StarOutlined'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, ListTable, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber, useWallet } from 'js/hooks'
import React from 'react'
import { RestModels } from 'tradehub-api-js'

interface Props extends BoxProps {
  proposal: RestModels.GovProposal
}

const VoteHistory: React.FC<Props> = (props: Props) => {
  const { className, proposal, ...rest } = props
  const classes = useStyles(props)
  const transactions = useRedux((state) => state.governance.voteTxs)
  const wallet = useWallet()
  const [loading] = useTaskSubscriber(TaskNames.Governance.Proposals, TaskNames.Governance.VoteHistory)
  const filteredTxns = transactions.filter((txn) => {
    const msg = JSON.parse(txn.msg)
    return parseInt(msg.proposal_id) === parseInt(proposal.id)
  })

  const getVoter = (txn: RestModels.Txn) => JSON.parse(txn.msg).voter
  const getOption = (txn: RestModels.Txn) => JSON.parse(txn.msg).option

  return (
    <Box
      className={clsx(classes.root, className)}
      {...rest}
    >
      <DataLoadSegment loading={loading && !transactions}>
        {!!transactions && (
          <>
            <ListTable>
              <TableHead>
                <TableRow>
                  <TableCell>Height</TableCell>
                  <TableCell>Option</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>Hash</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTxns.map((txn: RestModels.Txn, index: number) =>
                  <TableRow key={txn.hash + index} hover>
                    <TableCell>
                      <CellLink to={`/block/${txn.height}`}>{txn.height}</CellLink>
                    </TableCell>
                    <TableCell>
                      {getOption(txn)}
                    </TableCell>
                    <TableCell>
                      <CellLink to={`/account/${getVoter(txn)}`}>
                        {getVoter(txn)}
                      </CellLink>
                      {
                        wallet?.bech32Address === getVoter(txn) && (
                          <Tooltip arrow placement="top" title="Your deposit">
                            <StarIcon className={classes.meIcon} />
                          </Tooltip>
                        )
                      }
                    </TableCell>
                    <TableCell>
                      <CellLink to={`/transaction/${txn.hash}`}>
                        {txn.hash}
                      </CellLink>
                    </TableCell>
                  </TableRow>,
                )}
              </TableBody>
            </ListTable>
          </>
        )}
        {filteredTxns.length === 0 && (
          <TableEmptyState itemName="votes" />
        )}
      </DataLoadSegment>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  meIcon: {
    width: '0.75rem',
    height: '0.75rem',
    verticalAlign: 'sub',
    marginLeft: theme.spacing(2),
  },
}))

export default VoteHistory
