import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = BoxProps & {
  title?: string
  titleClass?: string
  variant?: 'secondary'
}

const Section: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, titleClass, variant, className, children, ...rest } = props
  const classes = useStyles(props)

  const secondaryVariant = variant === 'secondary'

  return (
    <Box
      component="section"
      {...rest}
      className={clsx(classes.root, className, {
        [classes.secondary]: secondaryVariant,
      })}
    >
      {!!title && (
        <Typography className={clsx(classes.title, titleClass)} variant="h2">
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}))

export default Section
