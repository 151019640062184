import { bnOrZero } from 'js/utils'
import React from 'react'
import { useDispatch } from 'react-redux'
import { RestModels, RestTypes } from 'tradehub-api-js'
import PaginationButtons from './PaginationButtons'

type PaginatedModel =
  | RestModels.Trade
  | RestModels.AccountTrade
  | RestModels.Order

interface Props {
  response: RestTypes.ResultsMinMax<PaginatedModel> | any
  action: any
  filters?: any
  useBlockHeight: boolean
}

const PaginationByIndex: React.FC<Props> = ({
  response,
  action,
  filters = {},
  useBlockHeight,
}: Props) => {
  const dispatch = useDispatch()
  const data = response?.data
  const max = data[0]
  const min = data.slice(-1)[0]

  if (!data?.length)
    return null

  const minRef = bnOrZero(useBlockHeight ? (min.block_height ?? (min as any).blockHeight) : min.id).dp(0).toNumber()
  const maxRef = bnOrZero(useBlockHeight ? (max.block_height ?? (max as any).blockHeight) : max.id).dp(0).toNumber()

  const handleButtonClick = (event: any) => {
    if (data.length > 0) {
      const state: any = {}
      switch (event) {
        case 'next':
          state.before_id = minRef
          break
        case 'last':
          state.order_by = 'asc'
          break
        case 'previous':
          state.after_id = maxRef
          state.order_by = 'asc'
          break
        case 'first':
          break
        default:
          break
      }
      dispatch(
        action({
          filters,
          pagination: state,
        }),
      )
    }
  }

  let disableBack: boolean = true
  if (
    data.length > 0 &&
    maxRef < response.max
  ) {
    disableBack = false
  }

  let disableFoward: boolean = true
  if (
    data.length > 0 &&
    minRef > response.min
  ) {
    disableFoward = false
  }
  return (
    <PaginationButtons
      handleButtonClick={handleButtonClick}
      disableBack={disableBack}
      disableFoward={disableFoward}
    />
  )
}

export default PaginationByIndex
