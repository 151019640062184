
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { delay, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setNodes } from '../actions/nodes'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

function comparator(a: RestModels.NodeData, b: RestModels.NodeData) {
  // Use toUpperCase() to ignore character casing
  return a.moniker.toUpperCase().localeCompare(b.moniker.toUpperCase())
}

export default class Nodes extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.pollNodes], [this, this.watchSetNetwork]].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *pollNodes(): any {
    while (true) {
      yield runSagaTask(TaskNames.Nodes.List, function* () {
        const sdk = yield* getInitializedSDK()
  
        const nodes = (yield sdk.api.getNodes()) as RestModels.NodeData[]
        yield put(setNodes(nodes.sort(comparator)))
      });
      yield delay(6001)
    }
  }
}
