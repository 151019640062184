import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum PositionActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_POSITION = 'SET_POSITION',
}

export type PositionActon = ClearAction | SetPositionAction

export interface ClearAction extends Action<PositionActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: PositionActionType.CLEAR,
  }
}

export interface SetPositionAction extends Action<PositionActionType.SET_POSITION> {
  position: RestModels.Position
}

export function setPosition(position: RestModels.Position): SetPositionAction {
  return {
    type: PositionActionType.SET_POSITION,
    position,
  }
}
