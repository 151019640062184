import React, { ReactElement } from 'react'
import PaginationButtons from './PaginationButtons'

// tslint:disable:jsx-no-lambda

interface Props {
  data: any[]
  setPage: any
  page: number
  itemsPerPage: number

}

const PaginationByData: React.FunctionComponent<Props> = ({
  data,
  setPage,
  page,
  itemsPerPage
}: Props): ReactElement<Props> => {

  const handleButtonClick = (event: any) => {
    if (data.length > 0) {
      switch (event) {
        case 'next':
          setPage((prev: number) => prev + 1)
          break
        case 'last':
          setPage(Math.ceil(data.length/itemsPerPage))
          break
        case 'previous':
          setPage((prev: number) => prev - 1)
          break
        case 'first':
          setPage(1)
          break
        default:
          break
      }
    }
  }

  let disableBack: boolean = true
  if (page > 1) {
    disableBack = false
  }

  let disableFoward: boolean = true
  if (page*itemsPerPage < data.length) {
    disableFoward = false
  }

  return (
    <PaginationButtons
      handleButtonClick={handleButtonClick}
      disableBack={disableBack}
      disableFoward={disableFoward}
    />
  )
}

export default PaginationByData
