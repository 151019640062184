import { Record, RecordOf } from 'immutable'
import { TutorialKeys } from 'js/constants'
import { TutorialProgress } from 'js/models'
import { ValueOf } from 'js/utils'
import {
  ActionType,
  TriggerTutorialAction,
  TutorialAction,
  UpdateProgressAction,
} from './action'

export type TutorialKey = ValueOf<typeof TutorialKeys>

export type TutorialStateProps = {
  [key in TutorialKey]?: TutorialProgress
}

const defaultStateProps: {
  [key in TutorialKey]?: undefined
} = {}

Object.values(TutorialKeys).forEach((key: string) => {
  // required for initial loading of
  // tutorial state from localStorage
  // immutable Record only populate
  // declared members
  defaultStateProps[key as TutorialKey] = undefined
})

export const makeState: Record.Factory<TutorialStateProps> = Record<TutorialStateProps>({
  ...defaultStateProps,
})

export type TutorialState = RecordOf<TutorialStateProps>

export default (
  state: TutorialState = makeState(),
  action: TutorialAction,
): TutorialState => {
  switch (action.type) {
    case ActionType.CLEAR:
      return makeState()

    case ActionType.UPDATE_PROGRESS:
      const updateAction = action as UpdateProgressAction
      return state.setIn([updateAction.tutorialKey], updateAction.progress)

    case ActionType.TRIGGER:
      const triggerAction = action as TriggerTutorialAction
      if (state[triggerAction.tutorialKey]) {
        return state
      }

      return state.setIn([triggerAction.tutorialKey], {
        showing: true,
        step: 1,
      })
    default:
      return state
  }
}
