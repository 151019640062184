import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiMenu']>> => ({
  list: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
  },
  paper: {
    borderRadius: 0,
  },
})
