import { Divider, Grid, makeStyles, Theme, ListItem, Typography, Box, SvgIcon } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { DemexLogoSVG } from 'assets'
import { BigNumber } from 'bignumber.js'
import clsx from 'clsx'
import { CellLink, Section, TooltipHint } from 'js/components'
import { capitalizeFirstLetter } from 'js/helpers'
import { useAssetSymbol } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import { RestModels } from 'tradehub-api-js'

interface CollectiveTrades {
  avgPrice: BigNumber,
  fees: BigNumber,
  denom: string
}

interface Props {
  market: string,
  side: string,
  orderType: string,
  quantity: string,
  price: string,
  filled: string,
  timeInForce: string,
  allocatedMarginDenom: string,
  venue: string,
  stopPrice: string,
  currentPrice: number,
  dollarValue: number,
  demexPrice: string,
  collectiveTrades: CollectiveTrades,
  marketDetails: RestModels.Market,
  orderStatus: string,
  isFutures: boolean,
}

enum limitMarket {
  stopLimit = "stop-limit",
  stopMarket = "stop-market"
}

const isValidMarketType = (orderType: string) => Object.values(limitMarket).includes(orderType as limitMarket)

const OrderDetails: React.FunctionComponent<Props> = (props): ReactElement<Props> => {
  const classes = useStyles()
  const assetSymbol = useAssetSymbol()
  const {
    orderStatus,
    market,
    marketDetails,
    side,
    orderType,
    quantity,
    price,
    dollarValue,
    filled,
    timeInForce,
    allocatedMarginDenom,
    venue,
    stopPrice,
    demexPrice,
    currentPrice,
    collectiveTrades,
    isFutures,
  } = props

  let timeInForceDisplay = timeInForce
  switch (timeInForce) {
    case "gtc":
      timeInForceDisplay = "(Good Till Cancel)"
      break
    case "fok":
      timeInForceDisplay = "(Fill or Kill)"
      break
    case "ioc":
      timeInForceDisplay = "(Immeditae or Cancel)"
      break
    default:
      timeInForceDisplay = timeInForce
  }

  const demexPriceBN = new BigNumber(demexPrice || currentPrice)
  const filledBN = new BigNumber(filled)
  const tickSizeBN = new BigNumber(marketDetails.tick_size)
  const decimal = tickSizeBN.dp()
  const priceBN = new BigNumber(collectiveTrades.avgPrice.isZero() ? price : collectiveTrades.avgPrice)

  const currentValue = demexPriceBN.times(filledBN)
  const currentDollarValue = (currentValue.times(dollarValue))
  const previousValue = priceBN.times(filledBN)
  const previousDollarValue = (previousValue.times(dollarValue))
  const differenceValue = side === "sell" ? previousDollarValue.minus(currentDollarValue) : currentDollarValue.minus(previousDollarValue)
  const differenceValueBN = new BigNumber(differenceValue.toFixed(2))
  const differencePercentage = side === "sell" ? previousDollarValue.times(100).div(currentDollarValue).minus(100) : currentDollarValue.times(100).div(previousDollarValue).minus(100)
  const differencePercentageBN = new BigNumber(differencePercentage.toFixed(2))

  const displayNumbers = !((orderStatus === "cancelled" || demexPriceBN.isZero() || priceBN.isZero()) && filledBN.isZero() && collectiveTrades.avgPrice.isZero())

  const changeToDollar = (num: BigNumber) => {
    let numString = num.toFixed(2).toLocaleString()
    if (numString[0] === "-") {
      numString = numString.replace("-", "-$")
    }
    else {
      numString = "$" + numString
    }
    return numString
  }

  return (
    <Section variant="secondary" className={classes.section}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant={"h2"} className={classes.title}>
            Potential PNL
              <TooltipHint title="Potential PNL is calculated by current price compared to order price.">
              <InfoIcon className={classes.infoIcon} />
            </TooltipHint>
          </Typography>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <div className={classes.pnlContainer}>
            <Typography variant={"h1"} display={"inline"} className={clsx(classes.pnlH1, { [classes.loss]: differenceValueBN.isLessThan(0), [classes.profit]: differenceValueBN.isGreaterThanOrEqualTo(0) || !displayNumbers })}>
              {displayNumbers && filledBN.isGreaterThan(0) ? changeToDollar(differenceValueBN) : "$0.00"}
            </Typography>
            <Typography variant={"h3"} display={"inline"} className={clsx(classes.pnlH3, { [classes.loss]: differenceValueBN.isLessThan(0), [classes.profit]: differenceValueBN.isGreaterThanOrEqualTo(0) || !displayNumbers })}>
              {differencePercentageBN.isNaN() || !displayNumbers || filledBN.isZero() ? "0.00" : differencePercentageBN.toFixed(2)}%
            </Typography>
          </div>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          Current Value: {displayNumbers ? changeToDollar(currentDollarValue) : "-"}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Market</div>
            <div className={classes.string}>
              <CellLink to={`/market/${market}`}>{market.toUpperCase()}</CellLink>
            </div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Side</div>
            <div className={classes.string}>{capitalizeFirstLetter(side)}</div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Type</div>
            <div className={classes.string}>{capitalizeFirstLetter(orderType)} {timeInForceDisplay}</div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Filled / Quantity</div>
            <div className={classes.string}>{filled} / {quantity}</div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Avg Price</div>
            {
              displayNumbers ? (
                <>
                  <div className={classes.string}>{priceBN.toFixed(decimal)} (Current Price: {demexPriceBN.toFixed(decimal)})</div>
                </>
              )
                : "-"
            }
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Total Value</div>
            <div className={classes.string}>
              <Typography className={classes.longString}>
                <Box fontWeight={displayNumbers && filledBN.isGreaterThan(0) ? 700 : 0} component={"span"}>
                  {
                    displayNumbers && filledBN.isGreaterThan(0) ? (
                      <>
                        {side === "sell" ? filled : previousValue.toFixed(2)} {isFutures && side === "sell" ? assetSymbol(market.split("_")[0].toUpperCase()) : assetSymbol(allocatedMarginDenom).toUpperCase()} ({changeToDollar(previousDollarValue)})
                      </>
                    )
                      : "-"
                  }
                </Box>
              </Typography>
            </div>
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Fee Paid</div>
            {
              displayNumbers ? (
                <>
                  <div className={classes.string}>
                    {collectiveTrades.fees.isZero() && !collectiveTrades.denom ? "-" : collectiveTrades.fees.toString()} {assetSymbol(collectiveTrades.denom).toUpperCase()}
                  </div>
                </>
              )
                : "-"
            }
          </ListItem>
          <Divider
            className={classes.divider}
            variant="middle"
          />
          {isValidMarketType(orderType) &&
            (
              <>
                <ListItem className={classes.listItem} disableGutters>
                  <div className={classes.header}>Trigger At</div>
                  <div className={classes.string}>Last Price ≤ {stopPrice} {assetSymbol(allocatedMarginDenom).toUpperCase()} </div>
                </ListItem>
                <Divider
                  className={classes.divider}
                  variant="middle"
                />
              </>
            )
          }
          <ListItem className={classes.listItem} disableGutters>
            <div className={classes.header}>Trade Venue</div>
            <div className={classes.string}>
              <CellLink href="https://app.dem.exchange">
                <SvgIcon
                  component={DemexLogoSVG}
                  className={classes.demexIcon}
                />
              </CellLink>
              <Typography display="inline">
                <Box fontWeight={700} display="inline" component={"span"}>
                  {venue}
                </Box>
              </Typography>
            </div>
          </ListItem>
          {/* hide: implementation for sharing not done */}
          {/* <Box display={"flex"} justifyContent={"flex-end"} className={classes.buttonBox}>
                <Button
                  color="primary"
                  className={classes.copyButton}
                  startIcon={<CopyTradeSVG fontSize={'small'} className={classes.buttonIcon}/>}
                >
                  Copy Trade
                </Button>
                <Button
                  color="primary"
                  className={classes.counterButton}
                  startIcon={<CounterTradeSVG fontSize={'small'} className={classes.buttonIcon}/>}
                >
                  Counter Trade
                </Button>
              </Box> */}
        </Grid>
      </Grid>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    alignSelf: 'center',
    width: '100%',
    margin: theme.spacing(0.75, 0),
  },
  section: {
    height: '100%'
  },
  buttonBox: {
    margin: theme.spacing(2.5, 0, 0, 0)
  },
  string: {
    wordBreak: 'break-word',
  },
  header: {
    // flexBasis: '33%',
    paddingRight: '1rem',
  },
  infoIcon: {
    verticalAlign: 'text-top',
    margin: theme.spacing(0, 1.5)
  },
  pnlContainer: {
    wordBreak: "break-word"
  },
  pnlH1: {
    margin: theme.spacing(0, 1.2, 0, 0)
  },
  pnlH3: {
    whiteSpace: "pre"
  },
  loss: {
    color: switcheo.loss,
  },
  profit: {
    color: switcheo.green[400],
  },
  title: {
    marginBottom: theme.spacing(2.5)
  },
  copyButton: {
    borderRadius: 0,
    backgroundColor: switcheo.copyTrade,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.8, 1.25),
    fontSize: 'x-small',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: switcheo.default.white,
    wordBreak: 'normal',
    "&:hover": {
      backgroundColor: switcheo.copyTradeHover
    }
  },
  counterButton: {
    borderRadius: 0,
    backgroundColor: switcheo.counterTrade,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.8, 1.25),
    fontSize: 'x-small',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: 'white',
    wordBreak: 'normal',
    "&:hover": {
      backgroundColor: switcheo.counterTradeHover
    }
  },
  demexIcon: {
    margin: theme.spacing(0, 0.5, 0, 0),
    fontSize: '1rem',
    verticalAlign: 'text-bottom'
  },
  buttonIcon: {
    margin: theme.spacing(0, -0.3, 0, 0.5),
    fontSize: '1rem !important'
  },
  longString: {
    wordBreak: "break-word"
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '33% auto'
  }
}))

export default OrderDetails
