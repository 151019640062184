import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByPage, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestResponse, RestTypes } from 'tradehub-api-js'
import { bnOrZero, SimpleMap } from 'js/utils'

interface Props {
  positions?: RestTypes.ResultsPaged<RestResponse.Position>
  title: string
  action: any
  markets?: SimpleMap<RestResponse.Market>
  loadName: string[]
}

const RiskyPositions: React.FunctionComponent<Props> = ({
  positions,
  title,
  action,
  markets,
  loadName,
}: Props): ReactElement<Props> => {
  const [page, setPage] = React.useState(1)
  const [loading] = useTaskSubscriber(TaskNames.App.Markets, ...loadName)

  return (
    <Section title={title}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell align="right">Position Size</TableCell>
              <TableCell align="right">Avg Price</TableCell>
              <TableCell align="right">Est. Liquidation Price</TableCell>
              <TableCell align="right">Realized PnL</TableCell>
              <TableCell align="right">Margin Allocated</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{positions?.data.map(renderRiskyPosition)}</TableBody>
        </ListTable>
        {!!positions?.data?.length && (
          <Box marginTop={3}>
            <PaginationByPage
              action={action}
              setPage={setPage}
              page={page}
              response={positions}
            />
          </Box>
        )}
        {!positions?.data?.length && (
          <TableEmptyState itemName="positions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderRiskyPosition(
    position: RestResponse.Position,
    index: number,
  ): ReactNode {
    const entryPriceBN = bnOrZero(position.entry_price)
    const realizedPnlBN = bnOrZero(position.realized_pnl)
    const estLiquidationPriceBN = bnOrZero(position.est_liquidation_price)
    const allocatedMarginBN = bnOrZero(position.allocated_margin_amount)
    const lotsBN = bnOrZero(position.lots)
    const denom = position.market.split('_')[0].toUpperCase()
    const lotDp: number = bnOrZero(markets?.[position.market]?.lot_size).dp()

    const { address, username } = position
    return (
      <TableRow key={address + index} hover>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {username === '' ? address : username}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={lotsBN.toFormat(lotDp)} denom={denom} />
        <TableCell align="right">{entryPriceBN.toFormat(2)}</TableCell>
        <TableCell align="right">{estLiquidationPriceBN.toFormat(2)}</TableCell>
        <TableCell align="right">{realizedPnlBN.toFormat(2)}</TableCell>
        <AmountDenomCell denom={position?.allocated_margin_amount} amount={allocatedMarginBN.toFormat(2)} />
        <TableCell align="right">
          <CellLink to={`/block/${position?.created_block_height}`}>
            {position?.created_block_height}
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default RiskyPositions
