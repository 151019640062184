import { ListItem, makeStyles } from '@material-ui/core'
import { CellLink, Section } from 'js/components'
import { BlockChainMap, BlockChainPaths, getBlockChain } from 'js/constants'
import { useRedux } from 'js/hooks'
import { reverseStr } from 'js/utils'
import React, { ReactElement, useMemo } from 'react'
import { RestModels, Blockchain, TradeHubSDK } from 'tradehub-api-js'
import { toBech32Address } from '@zilliqa-js/crypto'

interface Props {
  token: RestModels.Token
}

function isTrue(params: boolean) {
  return params ? 'true' : 'false'
}

const Information: React.FunctionComponent<Props> = ({
  token,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux(state => state.core.sdk)
  const { network } = useRedux(state => state.app)
  let blockChain = useMemo(() => getBlockChain(network, token.chain_id), [network, token.chain_id])

  const getAssetIDLink = () => {
    if (!blockChain) blockChain = token.blockchain
    const paths = sdk?.network === TradeHubSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet;
    switch (blockChain?.toLowerCase()) {
      case Blockchain.Ethereum.toLowerCase(): {
        if (token.denom === 'eth1') {
          return paths.eth.replace(':path', '/')
        }
        return paths.eth.replace(':path', `/token/0x${token.asset_id}`)
      }
      case Blockchain.BinanceSmartChain.toLowerCase(): {
        if (token.denom === 'bnb1') {
          return paths.bsc.replace(':path', '/')
        }
        return paths.bsc.replace(':path', `/token/0x${token.asset_id}`)
      }
      case Blockchain.Zilliqa.toLowerCase(): {
        if (token.denom === 'zil1' || token.asset_id === '0000000000000000000000000000000000000000') {
          return paths.zil.replace(':path', '/')
        }
        return paths.zil.replace(':path', `/address/${toBech32Address(token.asset_id)}`)
      }
      case Blockchain.Neo.toLowerCase(): {
        if (token.denom === 'nneo1') {
          return paths.neo.replace(':path', `/nep5/${token.asset_id}/page/1`)
        }
        return paths.neo.replace(':path', `/nep5/${reverseStr(token.asset_id, 2)}/page/1`)
      }
      default:
        return ''
    }
  }

  const getLockProxyLink = () => {
    if (!blockChain) blockChain = token.blockchain
    const paths = sdk?.network === TradeHubSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet;
    switch (blockChain?.toLowerCase()) {
      case Blockchain.Ethereum.toLowerCase(): {
        return paths.eth.replace(':path', `/address/0x${token.lock_proxy_hash}`)
      }
      case Blockchain.BinanceSmartChain.toLowerCase(): {
        return paths.bsc.replace(':path', `/address/0x${token.lock_proxy_hash}`)
      }
      case Blockchain.Zilliqa.toLowerCase(): {
        return paths.zil.replace(':path', `/address/${toBech32Address(token.lock_proxy_hash)}`)
      }
      case Blockchain.Neo.toLowerCase(): {
        return paths.neo.replace(':path', `/address/${reverseStr(token.lock_proxy_hash, 2)}/page/1`)
      }
      default:
        return ''
    }
  }
  const assetIDLink = getAssetIDLink();
  const lockProxyLink = getLockProxyLink();
  return (
    <Section title="Details">
      <ListItem disableGutters>
        <div className={classes.header}>Name</div>
        <div className={classes.string}>{token.name}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Symbol</div>
        <div className={classes.string}>{token.symbol}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Decimals</div>
        <div className={classes.string}>{token.decimals}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Originator</div>
        <CellLink href={`https://switcheo.org/account/${token.originator}`}>
          <div className={classes.string}>{token.originator}</div>
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Blockchain</div>
        <div className={classes.string}>{BlockChainMap[token.blockchain]}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Chain ID</div>
        <div className={classes.string}>{token.chain_id}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Lock Proxy Address</div>
        {
          lockProxyLink
            ?
            <CellLink href={lockProxyLink}>
              <div className={classes.string}>{token.lock_proxy_hash}</div>
            </CellLink>
            : <div className={classes.string}>{token.lock_proxy_hash}</div>
        }
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Asset ID</div>
        {
          assetIDLink
            ?
            <CellLink href={assetIDLink}>
              <div className={classes.string}>{token.asset_id}</div>
            </CellLink>
            : <div className={classes.string}>{token.asset_id}</div>
        }
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Is Active</div>
        <div className={classes.string}>{isTrue(token.is_active)}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Is Collateral</div>
        <div className={classes.string}>{isTrue(token.is_collateral)}</div>
      </ListItem>
    </Section>
  )
}

const useStyles = makeStyles({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
})

export default Information
