import { Divider, Grid, makeStyles, Theme } from '@material-ui/core'
import { AccountSVG, BlockTimeSVG, TotalBlocksSVG, TransactionSVG } from 'assets'
import { BigNumber } from 'bignumber.js'
import { Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { BIG_ZERO, bnOrZero } from 'js/utils'
import { Blocks, HomeStatsBox, StatsBox, StatsNumber, Transactions } from './components'
import RewardsDistributed from './components/RewardsDistributed'

interface Props { }

const secondsInAYear = new BigNumber(31536000)

const Dashboard: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const [swthPrice, setSwthPrice] = useState(new BigNumber(0))
  useEffect(() => {
    setTimeout(() => {
      setSwthPrice(new BigNumber(1))
      // tslint:disable:align
    }, 900)
  }, [])
  const classes = useStyles()
  const {
    blocks,
    transactions,
    blockTime,
    insurance,
    volume,
    volumeIsLoaded,
    avgReward,
    activeWallets,
    rewardsDistributed,
  } = useRedux((state) => state.dashboard)
  const { consensusMap,
    totalBonded,
    totalStaked,
    validatorsMap,
    geckoPrices,
    neoPrice,
    swthSupply,
    total: totalSupply,
    oracles,
  } = useRedux((state) => state.app)
  const insuranceAmount = bnOrZero(insurance?.amount)
  const timeArray: any = blockTime.split(':')
  const hours = new BigNumber(timeArray[0]).times(60 * 60)
  const minutes = new BigNumber(timeArray[1]).times(60)
  const seconds = new BigNumber(timeArray[2])
  const blockTimeBN = hours.plus(minutes).plus(seconds)
  const blocksInYear = secondsInAYear.div(blockTimeBN)
  const rewardsInYear = blocksInYear.times(avgReward)
  const apr = rewardsInYear.div(new BigNumber(totalBonded))
  const { switcheo } = geckoPrices

  const usdPriceBN = new BigNumber(switcheo.usd)
  const btcPriceBN = new BigNumber(switcheo.btc)
  const ethPriceBN = new BigNumber(switcheo.eth)
  const neoUSDPriceBN = new BigNumber(neoPrice.usd)
  const mcap = usdPriceBN.times(totalSupply)
  const DXBTBN = Object.values(oracles ?? {}).find(o => o.oracle_id === 'SIDXBTC')?.data ?? BIG_ZERO
  const bondedPercent: any = totalStaked.div(totalSupply).isFinite()
    ? totalStaked.div(totalSupply).times(100)
    : new BigNumber(0)
  let activeValidators = new BigNumber(0)
  let totalValidators = new BigNumber(0)
  // tslint:disable-next-line:forin
  for (const k in validatorsMap) {
    if (validatorsMap[k].BondStatus === 'bonded') {
      // tslint:disable:no-increment-decrement
      activeValidators = activeValidators.plus(1)
    }
    totalValidators = totalValidators.plus(1)
  }
  // let oiBN = new BigNumber(0)
  // let volumeBN = new BigNumber(0)

  const HomeStatsBoxes: StatsBox[] = [
    {
      asset: BlockTimeSVG,
      content: blockTimeBN.toFixed(4),
      footer: 'seconds',
      loadname: [
        TaskNames.Dashboard.BlockTime,
      ],
      isLoaded: !blockTimeBN.isZero(),
      title: 'Block Time (Last 100)',
    },
    {
      asset: TotalBlocksSVG,
      content: `${apr.isNaN() ? '0.00' : apr.times(100).toFormat(2)}%`,
      footer: 'est.',
      loadname: [
        TaskNames.Dashboard.Apr,
        TaskNames.Dashboard.BlockTime,
      ],
      isLoaded: !apr.isNaN() && apr.isFinite(),
      title: 'Staking APR',
    },
    {
      asset: AccountSVG,
      content: `${bondedPercent.toFormat(2)}%`,
      footer: `${totalStaked.toFormat(0)} SWTH`,
      loadname: [
        TaskNames.App.StakingPool,
        TaskNames.App.TotalSupply,
      ],
      isLoaded: !bondedPercent.isZero(),
      title: 'Bonded',
    },
    {
      asset: TransactionSVG,
      content: activeValidators.toFormat(0),
      footer: `${activeValidators.toFormat(0)} / ${totalValidators.toFormat(0)}`,
      loadname: [
        TaskNames.App.Validators,
      ],
      isLoaded: !activeValidators.isZero(),
      title: 'Active Validators',
    },
  ]

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      spacing={3}
    >
      {HomeStatsBoxes.map((homeBox: StatsBox, index: number) => (
        <HomeStatsBox {...homeBox} key={index} />
      ))}
      <Grid item sm={12}>
        <Section className={classes.sectionWrap}>
          <Grid container>
            <Grid item xs={6} sm={4} md={2} className={classes.section}>
              <StatsNumber
                label="Price"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!usdPriceBN.isZero()}
                title={`$${usdPriceBN.toFormat(4)}`}
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="Market Cap"
                loadname={[
                  TaskNames.App.GeckoCoin,
                  TaskNames.App.TotalSupply,
                ]}
                isLoaded={!mcap.isZero()}
                title={`${mcap.toFormat(0)}`}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2} className={classes.section}>
              <StatsNumber
                label="Total Supply"
                loadname={[
                  TaskNames.App.TotalSupply,
                ]}
                isLoaded={totalSupply.gt(1000)}
                title={totalSupply.toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="TradeHub Supply"
                loadname={[
                  TaskNames.App.SwthSupply,
                ]}
                isLoaded={swthSupply.gt(100000)}
                title={swthSupply.toFormat(0)}
                subtitle="SWTH"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2} className={classes.section}>
              <StatsNumber
                label="1 USD"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!usdPriceBN.isZero()}
                title={new BigNumber(1).div(usdPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="1 BTC"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!btcPriceBN.isZero()}
                title={new BigNumber(1).div(btcPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2} className={classes.section}>
              <StatsNumber
                label="1 ETH"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!ethPriceBN.isZero()}
                title={new BigNumber(1).div(ethPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="1 NEO"
                loadname={[
                  TaskNames.App.GeckoCoin,
                  TaskNames.App.NeoPrice,
                ]}
                isLoaded={!neoUSDPriceBN.isZero()}
                title={neoUSDPriceBN.div(usdPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2} className={classes.section}>
              <StatsNumber
                label="Volume 24H"
                loadname={[
                  TaskNames.Dashboard.MarketStats,
                ]}
                isLoaded={volumeIsLoaded}
                title={`$${volume.toFormat(2)}`}
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="Insurance"
                loadname={[
                  TaskNames.App.InsuranceSupply,
                ]}
                isLoaded={!swthPrice.isZero()}
                title={`$${insuranceAmount.toFormat(2)}`}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2} className={classes.section}>
              <StatsNumber
                label="DXBT"
                loadname={[
                  TaskNames.App.NeoPrice,
                  TaskNames.App.Oracles,
                ]}
                isLoaded={!neoUSDPriceBN.isZero()}
                title={`$${DXBTBN.toFormat(2)}`}
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="Active Accounts"
                loadname={[
                  TaskNames.Dashboard.Wallets,
                ]}
                isLoaded={!activeWallets.isZero()}
                title={activeWallets.toFormat(0)}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item sm={12} md={6}>
        <Blocks blocks={blocks} consensusMap={consensusMap} />
      </Grid>
      <Grid item sm={12} md={6}>
        <Transactions transactions={transactions} />
      </Grid>
      <Grid item sm={12} md={12}>
        <RewardsDistributed params={rewardsDistributed ?? []} />
      </Grid>
    </Grid>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  section: {
    padding: '12px 6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  equal: {
    textAlign: 'center',
  },
  divider: {
    alignSelf: 'center',
    width: '78%',
    margin: theme.spacing(1.25, 0),
  },
  sectionWrap: {
    marginBottom: 0,
  },
}))

export default Dashboard
