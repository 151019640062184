import { Record, RecordOf } from 'immutable'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AccountAction, AccountActionType } from '../actions/account'

export interface AccountProps {
  balance: RestModels.Balances
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>
  tokens: RestModels.Token[]
  types: string[]
  profile?: RestModels.Profile
  positions: RestModels.Position[]
  positionsHistory: RestModels.Position[]
  openOrders?: RestTypes.ResultsMinMax<RestModels.Order>
  orders?: RestTypes.ResultsMinMax<RestModels.Order>
  tradesByAccount?: RestTypes.ResultsMinMax<RestModels.AccountTrade>
  delegations: RestModels.ValidatorDelegation[]
  rewards?: RestModels.DelegationRewards
  address: string
  externalTransfers: RestModels.Transfer[]
}

const makeState: Record.Factory<AccountProps> = Record<AccountProps>({
  balance: {},
  transactions: {
    data: [],
    min: 0,
    max: 0,
  },
  tokens: [],
  types: [],
  profile: undefined,
  positions: [],
  positionsHistory: [],
  openOrders: undefined,
  orders: undefined,
  tradesByAccount: undefined,
  delegations: [],
  rewards: undefined,
  address: '',
  externalTransfers: [],
})
export type AccountState = RecordOf<AccountProps>

function accountReducer(
  state: AccountState = makeState(),
  action: AccountAction,
): AccountState {
  switch (action.type) {
    case AccountActionType.CLEAR:
      return makeState()
    case AccountActionType.SET_BALANCE:
      return state.updateIn(['balance'], () => action.balance)
    case AccountActionType.SET_TRANSACTIONS:
      return state.updateIn(
        ['transactions'],
        (): RestTypes.ResultsMinMax<RestModels.Txn> => {
          return action.transactions
        },
      )
    case AccountActionType.SET_TYPES:
      return state.updateIn(
        ['types'],
        (): string[] => {
          return action.types
        },
      )
    case AccountActionType.SET_PROFILE:
      return state.updateIn(['profile'], () => action.profile)
    case AccountActionType.SET_POSITIONS:
      return state.updateIn(['positions'], () => action.positions)
    case AccountActionType.SET_POSITIONS_HISTORY:
      return state.updateIn(['positionsHistory'], () => action.positionsHistory)
    case AccountActionType.SET_OPEN_ORDERS:
      return state.updateIn(['openOrders'], () => action.openOrders)
    case AccountActionType.SET_ORDERS:
      return state.updateIn(['orders'], () => action.orders)
    case AccountActionType.SET_ACCOUNT_TRADES:
      return state.updateIn(['tradesByAccount'], () => action.trades)
    case AccountActionType.SET_DELEGATIONS:
      return state.updateIn(['delegations'], () => action.delegations)
    case AccountActionType.SET_REWARDS:
      return state.updateIn(['rewards'], () => action.rewards)
    case AccountActionType.SET_ADDRESS:
      return state.updateIn(['address'], () => action.address)
    case AccountActionType.SET_EXTERNAL_TRANSFERS:
      return state.updateIn(['externalTransfers'], () => action.externalTransfers)
    default:
      return state
  }
}

export default accountReducer
