import { Typography } from '@material-ui/core'
import { updateOrdersFilters, updateRiskyLongPositions, updateRiskyShortPositions, updateTradesFilters } from 'js/actions/market'
import { CellLink, DataLoadSegment, NotFoundState, Orders, Page, Trades } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import { LargePositions, MarketInfo, ProfitablePositions, RiskyPositions, StatsSection } from './components'

interface Props { }

const M: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const {
    market,
    stats,
    orders,
    trades,
    largePositions,
    riskyLongs,
    riskyShorts,
    profitablePositions,
  } = useRedux((state) => state.market)
  const { markets } = useRedux((state) => state.app)
  const [loading] = useTaskSubscriber(TaskNames.Market.Detail, TaskNames.Market.Stats)

  return (
    <Page title={market?.description} >
      <DataLoadSegment loading={loading}>
        {market?.name && (
          <Fragment>
            <StatsSection market={market} stats={stats} />
            <MarketInfo market={market} />
            <ProfitablePositions
              profitablePositions={profitablePositions}
              markets={markets}
            />
            <LargePositions largePositions={largePositions} markets={markets} />
            <RiskyPositions
              positions={riskyLongs}
              title="Risky Long Positions"
              action={updateRiskyLongPositions}
              markets={markets}
              loadName={[
                TaskNames.Market.LongRisky,
                TaskNames.Market.LongRiskyFilter,
              ]}
            />
            <RiskyPositions
              positions={riskyShorts}
              title="Risky Short Positions"
              action={updateRiskyShortPositions}
              markets={markets}
              loadName={[
                TaskNames.Market.ShortRisky,
                TaskNames.Market.ShortRiskyFilter,
              ]}
            />
            <Orders
              filterStatus
              orders={orders}
              title="Recent Orders"
              action={updateOrdersFilters}
              markets={markets}
              loadName={[
                TaskNames.Market.OrderList,
                TaskNames.Market.OrderFilter,
              ]}
            />
            <Trades
              filterStatus
              trades={trades}
              title="Recent Trades"
              action={updateTradesFilters}
              markets={markets}
              loadName={[
                TaskNames.Market.TradeList,
                TaskNames.Market.TradeFilter,
              ]}
            />
          </Fragment>
        )}
        {!market?.name && (
          <NotFoundState title="No Market Found">
            <Typography variant="body1">
              We can’t find any market with this name. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Markets}>
                Markets
              </CellLink>
              &nbsp;page to view existing markets.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

export default M
