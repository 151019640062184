import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { User } from '../assets'
import YourWalletDropdown from './YourWalletDropdown'

type Props = {
}

const Login: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles(props)
  const wallet = useRedux((state) => state.core.sdk?.wallet)

  return (
    <div className={classes.root}>
      {wallet ? (
        <YourWalletDropdown />
      ) : (
        <Button
          component={RouterLink}
          to={'/login'}
          variant="text"
          startIcon={<User className={classes.userIcon} />}
        >
          <Typography className={classes.loginText} variant="h3">
            Login
          </Typography>
        </Button>
      )}
    </div>

  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '4rem',
    position: 'relative',
    '& .MuiButton-root': {
      minWidth: 'unset',
    },
  },
  loginText: {
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    textTransform: 'initial',
  },
  userIcon: {
    height: '1.125rem',
    width: '1.125rem',
  },
}))

export default Login
