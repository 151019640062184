import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, makeStyles, TableBody, TableCell, TableRow, Theme, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { ListTable, StatefulButton } from 'js/components'
import { SubmitProposalFormState } from 'js/models'
import { makeMoneyLabel } from 'js/utils'
import React, { ReactElement } from 'react'

interface Props extends Omit<DialogProps, 'open'> {
  onConfirm: () => void
  formState: SubmitProposalFormState | null
  depositAmount: string
  showError?: Error | null
  showLoading?: boolean
  isOpen: boolean
}

const ConfirmSubmitDialog: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, showError, showLoading, formState, depositAmount, onClose, onConfirm, isOpen, ...rest } = props
  const classes = useStyles(props)

  const handleClose = (event: React.MouseEvent<any>) => {
    if (showLoading) return
    if (typeof onClose === 'function') {
      onClose(event, 'escapeKeyDown')
    }
  }

  return (
    <Dialog
      keepMounted
      {...rest}
      open={isOpen}
      className={clsx(classes.root, className)}
    >
      <DialogTitle>
        Confirm Proposal
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ListTable>
          <TableBody>
            {/* <TableRow>
              <TableCell>Module</TableCell>
              <TableCell>{formState?.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Parameter</TableCell>
              <TableCell>{formState?.parameter}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Value</TableCell>
              <TableCell>{formState?.value}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>Proposal Title</TableCell>
              <TableCell>{formState?.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Proposal Description</TableCell>
              <TableCell>{formState?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deposit</TableCell>
              <TableCell>
                {makeMoneyLabel(new BigNumber(depositAmount || '0'))}
              </TableCell>
            </TableRow>
          </TableBody>
        </ListTable>

        {!!showError && (
          <Box marginTop={4}>
            <Typography color='error'>{showError.message}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        className={classes.actions}
        classes={{ spacing: classes.actionRes }}
      >
        <StatefulButton
          className={classes.actionButton}
          onClick={onConfirm}
          loading={showLoading}
          variant='contained'
          color='primary'
        >
          Confirm
        </StatefulButton>
        <Button
          className={clsx(classes.actionButton, classes.skipButton)}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Box flex={1} />
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      '&>*:first-child': {
        paddingTop: theme.spacing(3),
      },
      '&>*:last-child': {
        paddingBottom: theme.spacing(3),
      },
    },
  },
  table: {
    '& .MuiTableCell-root:last-child': {
      textAlign: 'left',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  actionButton: {
    minWidth: 'unset',
    marginRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  actionRes: {
    [theme.breakpoints.only('xs')]: {
      '&> :not(:first-child)': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      },
    },
  },
  skipButton: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    textTransform: 'none',
    padding: '0.7rem 1.2rem',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(0.5),
      justifyContent: 'center',
    },
  },
}))

export default ConfirmSubmitDialog
