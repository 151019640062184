import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import BigNumber from 'bignumber.js'
import customToast from 'js/components/Toast/Toast'
import { TaskName } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { BIG_ZERO, divideSafely, makeMoneyLabel } from 'js/utils'
import React, { useState } from 'react'
import { RestModels } from 'tradehub-api-js'
import { CustomDialog, CustomTable } from '../components'

interface Props {
  isOpen: boolean
  proposalID: string
  proposalTitle: string
  proposalTopic: string
  onClose: () => void
}

const VoteDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const {
    isOpen,
    proposalID,
    proposalTitle,
    proposalTopic,
    onClose,
  } = props
  const [voteChoice, setVoteChoice] = useState('')

  const voteTally = useRedux((state) => state.governance.liveVoteTallies)[proposalID]
  const validatorsMap = useRedux((state) => state.app.validatorsMap)
  const sdk = useRedux((state) => state.core.sdk)
  const [runVote, loading, error] = useAsyncTask(TaskName.VoteProposal)

  const resultYes = (voteTally?.yes || BIG_ZERO).shiftedBy(-8)
  const resultNo = (voteTally?.no || BIG_ZERO).shiftedBy(-8)
  const resultVeto = (voteTally?.no_with_veto || BIG_ZERO).shiftedBy(-8)
  const resultAbstain = (voteTally?.abstain || BIG_ZERO).shiftedBy(-8)
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  const totalStakingPower = Object.values(validatorsMap).reduce((sumShares: BigNumber, validator) => {
    return sumShares = sumShares.plus(validator.DelegatorShares)
  }, BIG_ZERO)

  const handleClose = () => {
    setVoteChoice('')
    onClose()
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVoteChoice(event.target.value as string)
  }

  function onVote(): void {
    runVote(async () => {
      if (!sdk?.wallet) return
      const result = await sdk.governance.voteProposal(proposalID, voteChoice)
      if (result && !error) {
        customToast('Notification', 'Vote sucessfully placed')
        handleClose()
      }
    })
  }

  const voteMeter = (
    child: React.ReactElement,
    numVotes: BigNumber,
    background: string,
  ) => {
    const proportionOfTotal = divideSafely(numVotes, totalStakingPower).shiftedBy(2)
    const proportionOfVoted = divideSafely(numVotes, totalVoted).shiftedBy(2)

    return (
      <div
        className={classes.voteMeterContainer}
        style={{
          background: `linear-gradient(to right, ${background} ${proportionOfTotal}%, #F7F7F7 0%)`,
        }}
      >
        {child}
        <div>
          {`${makeMoneyLabel(numVotes, { decimals: 0 })} (${proportionOfVoted.toFormat(3)}%)`}
        </div>
      </div>
    )
  }

  const delegations = useRedux((state) => state.governance.delegations)
  const votingPower: BigNumber = delegations.map((delgation: RestModels.ValidatorDelegation) => delgation.shares)
    .reduce((a, b) => a.plus(new BigNumber(b)), BIG_ZERO)

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={onVote}
      submitLabel={'Submit Vote'}
      miniTitle={'Vote for'}
      submitLoading={loading}
      bigTitle={`[${proposalTopic}] ${proposalTitle}`}
      intro={''}
      content={
        <>
          <FormControl
            component="fieldset"
            className={classes.formContainer}
            fullWidth
          >
            <RadioGroup
              value={voteChoice}
              onChange={handleChange}
              className={classes.radioContainer}
            >
              {voteMeter(
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" color='primary' />}
                  label="Yes"
                />,
                resultYes,
                '#ECF5E7',
              )}
              {voteMeter(
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" color='primary' />}
                  label="No"
                />,
                resultNo,
                '#F8D8D7',
              )}
              {voteMeter(
                <FormControlLabel
                  value="NoWithVeto"
                  control={<Radio size="small" color='primary' />}
                  label="No With Veto"
                />,
                resultVeto,
                '#F8D8D7',
              )}
              {voteMeter(
                <FormControlLabel
                  value="Abstain"
                  control={<Radio size="small" color='primary' />}
                  label="Abstain"
                />,
                resultAbstain,
                '#E8E8E8',
              )}
            </RadioGroup>
          </FormControl>
          <CustomTable
            headerAndCells={[
              { header: 'Your voting power', cell: `${makeMoneyLabel(votingPower)} SWTH` },
              { header: 'Network Fee', cell: '1 SWTH' },
            ]}
          />
          <Typography variant='body1' color='error'>{error?.message}</Typography>
        </>
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    margin: theme.spacing(2, 0),
  },
  radioContainer: {
    marginTop: theme.spacing(2),
  },
  voteMeterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    marginBottom: '.5rem',
    background: '#F7F7F7',
    '& .MuiFormControlLabel-root': {
      flex: 1,
      fontWeight: 'bold',
    },
  },
}))

export default VoteDialog
