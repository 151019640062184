import { List } from 'immutable'
import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum TokensActionType {
  CLEAR = 'CLEAR_TOKENS',
  SET_TOKENS_BALANCES = 'SET_TOKENS_BALANCES',
  SET_TOKENS_INFO = 'SET_TOKENS_INFO',
}

export type TokensAction =
  | ClearAction
  | SetTotalBalancesAction
  | SetTokensInfoAction

export interface ClearAction extends Action<TokensActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: TokensActionType.CLEAR,
  }
}

export interface SetTotalBalancesAction
  extends Action<TokensActionType.SET_TOKENS_BALANCES> {
  total: RestModels.Balance[]
}

export function setTotalBalances(
  total: RestModels.Balance[],
): SetTotalBalancesAction {
  return {
    type: TokensActionType.SET_TOKENS_BALANCES,
    total,
  }
}

export interface SetTokensInfoAction
  extends Action<TokensActionType.SET_TOKENS_INFO> {
  info: List<RestModels.Token>
}

export function setTokensInfo(info: List<RestModels.Token>): SetTokensInfoAction {
  return {
    type: TokensActionType.SET_TOKENS_INFO,
    info,
  }
}
