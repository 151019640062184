import { Paths } from 'js/constants'
import React from 'react'
import { SvgDiscord, SvgFacebook, SvgLinkedin, SvgMedium, SvgReddit, SvgTelegram, SvgTwitter, SvgYoutube } from './icons'

interface FooterLink {
  label: string
  href: string
}

interface SocialBtn {
  label: string
  href: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const foundationLinks: FooterLink[] = [
  {
    label: 'About Switcheo',
    href: Paths.Foundation.About,
  },
  {
    label: 'Press',
    href: Paths.Foundation.Press,
  },
  {
    label: 'Blog',
    href: Paths.Foundation.Blog,
  },
  {
    label: 'Support',
    href: Paths.Foundation.Support,
  },
]

export const ecosystemLinks: FooterLink[] = [
  {
    label: 'Spot Exchange',
    href: Paths.EcoSystem.Spot,
  },
  {
    label: 'Derivatives Exchange',
    href: Paths.EcoSystem.Derivatives,
  },
  {
    label: "Switcheo TradeHub Staking",
    href: Paths.EcoSystem.Stake,
  },
  {
    label: 'Switcheo TradeHub Pool',
    href: Paths.EcoSystem.Pool,
  },
  {
    label: "Governance",
    href: Paths.Governance,
  },
]

export const resourcesLinks: FooterLink[] = [
  {
    label: 'Developer APIs',
    href: Paths.Resources.API,
  },
  {
    label: 'GitHub',
    href: Paths.Resources.Github,
  },
  {
    label: 'Media Kit',
    href: Paths.Resources.MediaKit,
  },
  {
    label: 'Privacy Policy',
    href: Paths.Resources.PrivacyPolicy,
  },
]

export const socialBtns: SocialBtn[] = [
  {
    label: 'Discord',
    href: Paths.Social.Discord,
    icon: SvgDiscord,
  },
  {
    label: 'Telegram',
    href: Paths.Social.Telegram,
    icon: SvgTelegram,
  },
  {
    label: 'Twitter',
    href: Paths.Social.Twitter,
    icon: SvgTwitter,
  },
  {
    label: 'Medium',
    href: Paths.Social.Medium,
    icon: SvgMedium,
  },
  {
    label: 'Reddit',
    href: Paths.Social.Reddit,
    icon: SvgReddit,
  },
  {
    label: 'LinkedIn',
    href: Paths.Social.LinkedIn,
    icon: SvgLinkedin,
  },
  {
    label: 'Facebook',
    href: Paths.Social.Facebook,
    icon: SvgFacebook,
  },
  {
    label: 'Youtube',
    href: Paths.Social.Youtube,
    icon: SvgYoutube,
  },
]
