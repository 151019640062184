import { SetSettlementPriceProposalFormState } from "js/models"
import store from "js/store"
import { BIG_ZERO, escapeHtmlGo, parseNumber } from "js/utils"
import { RPCParams } from "tradehub-api-js"
import { fieldTypes } from "../Helpers/types"

export const settlementPriceFormRequiredFields = ['market', 'settlementPrice']

export function settlementPriceConfig() {
  const markets = store.getState().app.markets
  return {
    market: {
      id: 'market',
      label: 'Market',
      type: fieldTypes.select,
      options: Object.values(markets).map((market: any) => market?.name),
      placeholder: 'Select a market',
    },
    settlementPrice: {
      id: 'settlementPrice',
      label: 'Settlement Price',
      tooltip: 'Settlement price for the expired futures market',
      type: fieldTypes.number,
      constraints: {
        greaterThanOrEqualTo: 0,
      },
    },
  }
}

export function setSettlementPriceProposalValue(): RPCParams.SetSettlementPriceProposal {
  const formState = store.getState().governance.submitProposalFormState as SetSettlementPriceProposalFormState

  const { title, description, market, settlementPrice } = formState!

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    settlement_price: parseNumber(settlementPrice, BIG_ZERO)!.toString(),
    market,
  }

  return value
}
