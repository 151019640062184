import { DataLoadSegment, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'
import { RestModels } from 'tradehub-api-js'
import LogMessage from './LogMessage'

interface Props {
  msgs: RestModels.TxnMessage[],
  log: RestModels.TxLog
}

const TransactMessages: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { msgs, log } = props
  const [loading] = useTaskSubscriber(TaskNames.Transaction.Log)

  const parsedLog = useMemo(() => {
    const rawLog = log.raw_log ?? ''
    let parsedLog: { [key in number | 'error']?: any } = {}
    try {
      const json = JSON.parse(rawLog)
      parsedLog = json.reduce((acc: typeof parsedLog, cur: any) => {
        const msgIndex = cur.msg_index ?? 0
        acc[msgIndex] = acc[msgIndex]?.concat([cur]) ?? [cur]
        return acc
      }, {})
    } catch (err) {
      console.error(err)
      parsedLog = [{ log: log.raw_log }]
    }
    return parsedLog
  }, [log])

  return (
    <Section title="Messages">
      <DataLoadSegment loading={loading}>
        {msgs.map((msg, index) => (
          <LogMessage msgLog={msg} key={index} logs={parsedLog[index]} />
        ))}
        {(!msgs || msgs.length <= 0) && (
          <TableEmptyState itemName="messages" />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default TransactMessages
