import { TaskNames, TooltipsMsgs } from 'js/constants'

export type IndivCell = {
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify',
  label: string,
  tooltip?: string,
  underline: boolean
}

export const HeaderCells: IndivCell[] = [
  {
    label: '#',
    underline: false,
  },
  {
    label: 'Validators',
    underline: false,
  },
  {
    align: 'right',
    label: 'Total Stake (%)',
    tooltip: TooltipsMsgs.total_stake_percent,
    underline: true,
  },
  {
    align: 'right',
    label: 'Self Stake',
    tooltip: TooltipsMsgs.self_stake,
    underline: true,
  },
  {
    align: 'right',
    label: 'Delegators',
    underline: false,
  },
  {
    align: 'right',
    label: 'Commission',
    tooltip: TooltipsMsgs.commission_rate,
    underline: true,
  },
  {
    align: 'right',
    label: 'Uptime',
    tooltip: TooltipsMsgs.uptime,
    underline: true,
  },
  {
    align: 'right',
    label: 'Bond Status',
    underline: false,
  },
]

export const LoadNames = [
  TaskNames.Validators.Distribution,
  TaskNames.App.Validators,
  TaskNames.Validators.Slashing,
]
