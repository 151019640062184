import { camelCase, forEach, isArray, isPlainObject } from 'lodash'

export function camelizeKeys(o: object): object {
  const newO: any = {}
  forEach(o, (value: any, key: string): void => {
    if (isPlainObject(value) || isArray(value)) {
      // tslint:disable:no-parameter-reassignment
      value = camelizeKeys(value)
    }
    newO[camelCase(key)] = value
  })
  return newO
}

export function shorten(s?: string): string {
  if (!s) return ''
  return s.substring(0, 10).concat(' ... ')
}

export function capitalizeFirstLetter(s?: string) {
  if (!s) return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function copyTextToClipBoard(s: string) {
  let dummy = document.createElement("textarea")
  document.body.appendChild(dummy)
  dummy.value = s
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}

export const actionNameGenFactory = (prefix: string) => {
  return (actionName: string) => {
    if (!prefix.length) return actionName
    return `${prefix}_${actionName}`
  }
}

export const GECKO_SYMBOLS: any = {
  eth1: "ethereum",
  eth: "ethereum",
  swth1: "switcheo",
  swth: "switcheo",
  wbtc: "wrapped-bitcoin",
  btc: "bitcoin",
  usdc: "usd-coin",
  usdc1: "usd-coin",
  cel: "celsius-degree-token",
  cel1: "celsius-degree-token",
  nex1: "neon-exchange",
  nex: "neon-exchange",
  nneo2: "neo",
  busd1: "binance-usd",
  wbtc1: "wrapped-bitcoin",
  btcb1: "bitcoin-bep2",
  bnb1: "binancecoin",
  bnb: "binancecoin",
  yam1: "yam-2",
  asa1: "asura",
  dbc1: "deepbrain-chain",
  flm: "flamingo-finance",
  dbc2: "deepbrain-chain",
  swthn: "switcheo", 
  cgas: "gas",
  nneo: "neo",
  swthb: "switcheo", 
  busd: "binance-usd",
  btcb: "binance-bitcoin",
  busdt: "tether",
  helmet: "helmet-insure",
  belt: "belt",
  link: "chainlink",
  uni: "uniswap",
  trb: "tellor",
  trb1: "tellor",
  usdt: "tether",
  tru: "truebit-protocol",
  grt: "the-graph",
  "1inch": "1inch",
  okb: "okb",
  aave: "aave",
  mkr: "maker",
  cro: "crypto-com-chain",
  dai: "dai",
  comp: "compound-governance-token",
  tel: "telcoin",
  snx: "havven",
  sushi: "sushi",
  chz: "chiliz",
  hot: "holotoken",
  enj: "enjincoin",
  nexo: "nexo",
  bat: "basic-attention-token",
  yfi: "yearn-finance",
  omg: "omisego",
  bnt: "bancor",
  zrx: "0x",
  one: "harmony",
  chsb: "swissborg",
  crv: "curve-dao-token",
  nxm: "nxm",
  snt: "status",
  bal: "balancer",
  rlc: "iexec-rlc",
  lrc: "loopring",
  ocean: "ocean-protocol",
  btmx: "asd", //btmx is outdated name
  band: "band-protocol",
  knc: "kyber-network",
  inj: "injective-protocol",
  gno: "gnosis",
  fun: "funfair",
  sxp: "swipe",
  iotx: "iotex", //only have bep20
  nkn: "nkn",
  stmx: "storm",
  agi: "singularitynet",
  oxt: "orchid-protocol",
  nmr: "numeraire",
  sand: "the-sandbox",
  poly: "polymath-network",
  ant: "aragon",
  ogn: "origin-protocol",
  storj: "storj",
  "eps.b.1": "ellipsis",
  "eth.b.1": "ethereum",
  "usdt.b.1": "tether",
  "ada.b.1": "binance-peg-cardano",
  "wbnb.b.1": "wbnb",
  "doge.b.1": "binance-peg-dogecoin",
  "xrp.b.1": "binance-peg-xrp",
  "usdc.b.1": "usd-coin",
  "dot.b.1": "binance-peg-polkadot",
  "uni.b.1": "uniswap",
  "link.b.1": "chainlink",
  "bch.b.1": "binance-peg-bitcoin-cash",
  "ltc.b.1": "binance-peg-litecoin",
  "busd.b.1": "binance-usd",
  "etc.b.1": "ethereum-classic", //cant find binance peg
  "eos.b.1": "binance-peg-eos",
  "trx.b.1": "tron-bsc",
  "dai.b.1": "dai",
  "mkr.b.1": "maker", //cant find binance peg
  "xtz.b.1": "tezos", //cant find binance peg
  "cake.b.1": "pancakeswap-token",
  "atom.b.1": "cosmos", //cant find binance peg
  "safemoon.b.1": "safemoon",
  "comp.b.1": "compound-governance-token", //cant find binance peg
  "btcb.b.1": "binance-bitcoin",
  "ust.b.1": "wrapped-ust-bsc",
  "zec.b.1": "zcash", //cant find binance peg
  "yfi.b.1": "yearn-finance", //cant find binance peg
  "zil.b.1": "zilliqa",
  "snx.b.1": "havven", //cant find binance peg
  "pax.b.1": "paxos-standard", //cant find binance peg
  "near.b.1": "near", //cant find binance peg
  "bat.b.1": "basic-attention-token", //cant find binance peg
  "ont.b.1": "ontology", //cant find binance peg
  "wrx.b.1": "wazirx",
  "bake.b.1": "bakerytoken",
  "ankr.b.1": "ankr", //cant find binance peg
  "bcha.b.1": "bitcoin-cash-abc-2", //cant find binance peg
  "bcfx.b.1": "conflux-token", //cant find binance peg
  "mir.b.1": "mirror-protocol",
  "reef.b.1": "reef-finance",
  "xvs.b.1": "venus",
  "btcst.b.1": "btc-standard-hashrate-token",
  "iotx.b.1": "iotex",
  "tlm.b.1": "alien-worlds-bsc",
  "prom.b.1": "prometeus", //cant find binance peg
  "alpha.b.1": "alpha-finance",
  "dodo.b.1": "dodo",
  "math.b.1": "math",//cant find binance peg
  "sxp.b.1": "swipe",
  "band.b.1": "band-protocol", //cant find binance peg
}

export const MARKETS_PAIR: any = [
  "swth_eth1",
  "swth_eth",
  "swth_usdc1",
  "eth1_usdc1",
  "cel1_usdc1",
  "nex1_usdc1",
  "nneo2_usdc1",
  "wbtc1_usdc1",
  "eth1_wbtc1",
  "nneo2_eth1",
  "swth_busd1",
  "busd1_usdc1",
  "nneo2_busd1",
  "cel1_busd1",
  "wbtc1_btcb1",
  "bnb1_eth1",
  "btc_m21",
  "eth_m21",
  "swth_usdc1",
  "eth_h21",
  "btc_h21",
  "cel_eth",
  "bnb1_busd1",
  "btcb1_busd1",
  "trb1_busd1",
]
