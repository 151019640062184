import { Action } from 'redux'
import { RestModels, RestTypes } from 'tradehub-api-js'

export enum MarketsActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_ORDERS = 'SET_ORDERS',
  SET_TRADES = 'SET_TRADES',
  UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS',
  UPDATE_TRADES_FILTERS = 'UPDATE_TRADES_FILTERS',
}

export type MarketsAction =
  | ClearAction
  | SetOrdersAction
  | SetTradesAction
  | UpdateOrdersFiltersAction
  | UpdateTradesFiltersAction

export interface ClearAction extends Action<MarketsActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: MarketsActionType.CLEAR,
  }
}

export interface SetOrdersAction extends Action<MarketsActionType.SET_ORDERS> {
  orders: RestTypes.ResultsMinMax<RestModels.Order>
}

export function setOrders(orders: RestTypes.ResultsMinMax<RestModels.Order>): SetOrdersAction {
  return {
    type: MarketsActionType.SET_ORDERS,
    orders,
  }
}
export interface SetTradesAction extends Action<MarketsActionType.SET_TRADES> {
  trades: RestTypes.ResultsMinMax<RestModels.Trade>
}

export function setTrades(trades: RestTypes.ResultsMinMax<RestModels.Trade>): SetTradesAction {
  return {
    type: MarketsActionType.SET_TRADES,
    trades,
  }
}

export interface UpdateOrdersFiltersAction
  extends Action<MarketsActionType.UPDATE_ORDERS_FILTERS> {
  options: any
}

export function updateOrdersFilters(options: any): UpdateOrdersFiltersAction {
  return {
    type: MarketsActionType.UPDATE_ORDERS_FILTERS,
    options,
  }
}

export interface UpdateTradesFiltersAction
  extends Action<MarketsActionType.UPDATE_TRADES_FILTERS> {
  options: any
}

export function updateTradesFilters(options: any): UpdateTradesFiltersAction {
  return {
    type: MarketsActionType.UPDATE_TRADES_FILTERS,
    options,
  }
}
