import { Action } from 'redux'
import { RestModels, RestTypes } from 'tradehub-api-js'

export enum AccountActionType {
  CLEAR = 'CLEAR_ACCOUNT',
  SET_BALANCE = 'SET_BALANCE',
  SET_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS',
  SET_TYPES = 'SET_TYPES',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  SET_PROFILE = 'SET_PROFILE',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_POSITIONS = 'SET_POSITIONS',
  UPDATE_POSITIONS = 'UPDATE_POSITIONS',
  SET_POSITIONS_HISTORY = 'SET_POSITION_HISTORY',
  UPDATE_POSITIONS_HISTORY = 'UPDATE_POSITIONS_HISTORY',
  SET_OPEN_ORDERS = 'SET_OPEN_ORDERS',
  UPDATE_OPEN_ORDERS = 'UPDATE_OPEN_ORDERS',
  SET_ORDERS = 'SET_ORDERS',
  UPDATE_ORDERS = 'UPDATE_ORDERS',
  SET_ACCOUNT_TRADES = 'SET_ACCOUNT_TRADES',
  UPDATE_TRADES = 'UPDATE_TRADES',
  SET_DELEGATIONS = 'SET_DELEGATIONS',
  SET_REWARDS = 'SET_REWARDS',
  SET_EXTERNAL_TRANSFERS = 'SET_EXTERNAL_TRANSFERS',
}

export type AccountAction =
  | ClearAction
  | SetBalanceAction
  | SetTransactionsAction
  | SetTransactionTypesAction
  | UpdateFiltersAction
  | SetProfileAction
  | SetAddressAction
  | SetPositionsAction
  | UpdatePositionsAction
  | SetPositionsHistoryAction
  | UpdatePositionsHistoryAction
  | SetOpenOrdersAction
  | UpdateOpenOrdersAction
  | SetOrdersAction
  | UpdateOrdersAction
  | SetTradesAction
  | SetDelegationsAction
  | SetRewardsAction
  | SetExternalTransfersAction

export interface ClearAction extends Action<AccountActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: AccountActionType.CLEAR,
  }
}

export interface SetBalanceAction
  extends Action<AccountActionType.SET_BALANCE> {
  balance: RestModels.Balances
}

export function setBalance(balance: RestModels.Balances): SetBalanceAction {
  return {
    type: AccountActionType.SET_BALANCE,
    balance,
  }
}

export interface SetTransactionsAction
  extends Action<AccountActionType.SET_TRANSACTIONS> {
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>
}

export function setTransactions(
  transactions: RestTypes.ResultsMinMax<RestModels.Txn>,
): SetTransactionsAction {
  return {
    type: AccountActionType.SET_TRANSACTIONS,
    transactions,
  }
}

export interface SetProfileAction
  extends Action<AccountActionType.SET_PROFILE> {
  profile: RestModels.Profile
}

export function setProfile(profile: RestModels.Profile): SetProfileAction {
  return {
    type: AccountActionType.SET_PROFILE,
    profile,
  }
}

export interface UpdatePositionsAction
  extends Action<AccountActionType.UPDATE_POSITIONS> {
  options: any
}

export function updatePositions(options: any): UpdatePositionsAction {
  return {
    type: AccountActionType.UPDATE_POSITIONS,
    options,
  }
}

export interface SetPositionsAction
  extends Action<AccountActionType.SET_POSITIONS> {
  positions: RestTypes.ResultsMinMax<RestModels.Position>
}

export function setPositions(positions: RestTypes.ResultsMinMax<RestModels.Position>): SetPositionsAction {
  return {
    type: AccountActionType.SET_POSITIONS,
    positions,
  }
}

export interface UpdatePositionsHistoryAction
  extends Action<AccountActionType.UPDATE_POSITIONS_HISTORY> {
  options: any
}

export function updatePositionsHistory(options: any): UpdatePositionsHistoryAction {
  return {
    type: AccountActionType.UPDATE_POSITIONS_HISTORY,
    options,
  }
}

export interface SetPositionsHistoryAction
  extends Action<AccountActionType.SET_POSITIONS_HISTORY> {
  positionsHistory: any
}

export function setPositionsHistory(positionsHistory: any): SetPositionsHistoryAction {
  return {
    type: AccountActionType.SET_POSITIONS_HISTORY,
    positionsHistory,
  }
}

export interface SetAddressAction
  extends Action<AccountActionType.SET_ADDRESS> {
  address: string
}

export function setAddress(address: string): SetAddressAction {
  return {
    type: AccountActionType.SET_ADDRESS,
    address,
  }
}

export interface SetTransactionTypesAction
  extends Action<AccountActionType.SET_TYPES> {
  types: string[]
}
export function setTypes(types: string[]): SetTransactionTypesAction {
  return {
    type: AccountActionType.SET_TYPES,
    types,
  }
}
export interface SetOpenOrdersAction
  extends Action<AccountActionType.SET_OPEN_ORDERS> {
  openOrders: RestTypes.ResultsMinMax<RestModels.Order>
}
export function setOpenOrders(openOrders: RestTypes.ResultsMinMax<RestModels.Order>): SetOpenOrdersAction {
  return {
    type: AccountActionType.SET_OPEN_ORDERS,
    openOrders,
  }
}

export interface UpdateOpenOrdersAction
  extends Action<AccountActionType.UPDATE_OPEN_ORDERS> {
  options: any
}

export function updateOpenOrders(options: any): UpdateOpenOrdersAction {
  return {
    type: AccountActionType.UPDATE_OPEN_ORDERS,
    options,
  }
}

export interface SetOrdersAction extends Action<AccountActionType.SET_ORDERS> {
  orders: RestTypes.ResultsMinMax<RestModels.Order>
}
export function setOrders(orders: RestTypes.ResultsMinMax<RestModels.Order>): SetOrdersAction {
  return {
    type: AccountActionType.SET_ORDERS,
    orders,
  }
}

export interface UpdateOrdersAction
  extends Action<AccountActionType.UPDATE_ORDERS> {
  options: any
}

export function updateOrders(options: any): UpdateOrdersAction {
  return {
    type: AccountActionType.UPDATE_ORDERS,
    options,
  }
}

export interface SetTradesAction
  extends Action<AccountActionType.SET_ACCOUNT_TRADES> {
  trades: RestTypes.ResultsMinMax<RestModels.AccountTrade>
}
export function setTrades(trades: RestTypes.ResultsMinMax<RestModels.AccountTrade>): SetTradesAction {
  return {
    type: AccountActionType.SET_ACCOUNT_TRADES,
    trades,
  }
}

export interface UpdateTradesAction
  extends Action<AccountActionType.UPDATE_TRADES> {
  options: any
}

export function updateTrades(options: any): UpdateTradesAction {
  return {
    type: AccountActionType.UPDATE_TRADES,
    options,
  }
}

export interface SetDelegationsAction
  extends Action<AccountActionType.SET_DELEGATIONS> {
  delegations: RestModels.ValidatorDelegation[]
}
export function setDelegations(delegations: RestModels.ValidatorDelegation[]): SetDelegationsAction {
  return {
    type: AccountActionType.SET_DELEGATIONS,
    delegations,
  }
}
export interface SetRewardsAction
  extends Action<AccountActionType.SET_REWARDS> {
  rewards: RestModels.DelegationRewards
}
export function setRewards(rewards: RestModels.DelegationRewards): SetRewardsAction {
  return {
    type: AccountActionType.SET_REWARDS,
    rewards,
  }
}

export interface UpdateFiltersAction
  extends Action<AccountActionType.UPDATE_FILTERS> {
  options: any
}

export function updateFilters(options: any): UpdateFiltersAction {
  return {
    type: AccountActionType.UPDATE_FILTERS,
    options,
  }
}

export interface SetExternalTransfersAction
  extends Action<AccountActionType.SET_EXTERNAL_TRANSFERS> {
  externalTransfers: RestModels.Transfer[]
}

export function setExternalTransfers(
  externalTransfers: RestModels.Transfer[],
): SetExternalTransfersAction {
  return {
    type: AccountActionType.SET_EXTERNAL_TRANSFERS,
    externalTransfers,
  }
}
