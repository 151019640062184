import { Box, Divider, FormControl, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import { clearSubmitProposalFormState, setSubmitProposalDepositAmount, setSubmitProposalError, submitProposal } from 'js/actions/governance'
import { useRedux, useWallet } from 'js/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { RPCParams } from 'tradehub-api-js'
import { Wizard } from '../components'
import { CommunityPoolSpendProposalInitialFormState, CreateOracleProposalInitialFormState, SetRewardCurveProposalInitialFormState, SetSettlementPriceProposalInitialFormState, SoftwareUpgradeProposalInitialFormState } from './constants'
import ParameterChangeProposalMainForm from './ParameterChangeProposal/ParameterChangeProposalMainForm'
import { parameterChangeValidator } from './ParameterChangeProposal/Helpers/FormConstraints'
import { parameterChangeProposalValue } from './ParameterChangeProposal/Helpers/ProposalValue'
import CreateMarketProposalMainForm from './CreateMarketProposal/CreateMarketProposalMainForm'
import { createMarketValidator } from './CreateMarketProposal/Helpers/FormConstraints'
import { createMarketProposalValue } from './CreateMarketProposal/Helpers/ProposalValue'
import CreateTokenProposalMainForm from './CreateTokenProposal/CreateTokenProposalMainForm'
import { createTokenValidator } from './CreateTokenProposal/Helpers/FormConstraints'
import { createTokenProposalValue } from './CreateTokenProposal/Helpers/ProposalValue'
import DepositStep from './DepositStep'
import DetailsStep from './DetailsStep'
import { communityPoolSpendConfig, communityPoolSpendFormRequiredFields, communityPoolSpendProposalValue } from './GenericProposal/Config/CommunityPoolSpend'
import { createOracleConfig, createOracleFormRequiredFields, createOracleProposalValue } from './GenericProposal/Config/CreateOracle'
import { setRewardCurveConfig, setRewardCurveFormRequiredFields, setRewardCurveProposalValue } from './GenericProposal/Config/SetRewardCurve'
import { setSettlementPriceProposalValue, settlementPriceConfig, settlementPriceFormRequiredFields } from './GenericProposal/Config/SetSettlementPrice'
import { softwareUpgradeConfig, softwareUpgradeFormRequiredFields, softwareUpgradeProposalValue, softwareUpgradeValidator } from './GenericProposal/Config/SoftwareUpgrade'
import { textProposalValue } from './GenericProposal/Config/Text'
import GenericProposalMainForm from './GenericProposal/GenericProposalMainForm'
import formatDepositAmount from './Helpers/FormatDeposit'
import { linkPoolProposalValue } from './LinkPoolProposal/Helpers/ProposalValue'
import LinkPoolMainForm from './LinkPoolProposal/LinkPoolMainForm'
import { setCommitmentCurveConfig } from './SetCommitmentCurveProposal/Helpers/FormConstraints'
import { setCommitmentCurveProposalValue } from './SetCommitmentCurveProposal/Helpers/ProposalValue'
import SetCommitmentCurveProposalMainForm from './SetCommitmentCurveProposal/SetCommitmentCurveProposalMainForm'
import { setPoolRewardWeightsValidator } from './SetPoolRewardWeightsProposal/Helpers/FormConstraints'
import { setPoolRewardWeightsProposalValue } from './SetPoolRewardWeightsProposal/Helpers/ProposalValue'
import SetPoolRewardWeightsProposalMainForm from './SetPoolRewardWeightsProposal/SetPoolRewardWeightsProposalMainForm'
import { unlinkPoolProposalValue } from './UnlinkPoolProposal/Helpers/ProposalValue'
import UnlinkPoolMainForm from './UnlinkPoolProposal/UnlinkPoolMainForm'
import { updateMarketValidator } from './UpdateMarketProposal/Helpers/FormConstraints'
import { updateMarketProposalValue } from './UpdateMarketProposal/Helpers/ProposalValue'
import UpdateMarketProposalMainForm from './UpdateMarketProposal/UpdateMarketProposalMainForm'

const MainForm: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [proposal, setProposal] = React.useState('')
  const wallet = useWallet()
  const deposit = useRedux((state) => state.governance.submitProposalDepositAmount)
  const network = useRedux((state) => state.app.network)
  const tokens = useRedux((state) => state.tokens.info)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProposal(event.target.value as string)
    dispatch(clearSubmitProposalFormState())
    dispatch(setSubmitProposalDepositAmount(''))
    dispatch(setSubmitProposalError(null))
  }

  const executeSubmit = (proposalValue: RPCParams.Proposal) => {
    if (!wallet || !network || !proposalValue || proposal === '') return
    dispatch(submitProposal(proposal, proposalValue, formatDepositAmount(deposit)))
  }

  const proposalDropdown: React.ReactNode = (
    <Box className={classes.proposalDropdown}>
      <FormControl fullWidth>
        <Select
          displayEmpty
          value={proposal}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>Select a proposal type</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.CreateToken}>Create Token</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.CreateOracle}>Create Oracle</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.CreateMarket}>Create Market</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.UpdateMarket}>Update Market</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.LinkPool}>Link Pool</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.UnlinkPool}>Unlink Pool</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.SetPoolRewardWeights}>Set Pool Reward Weight</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.SetRewardCurve}>Set Reward Curve</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.SetCommitmentCurve}>Set Commitment Curve</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.SetSettlementPrice}>Set Settlement Price</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.CommunityPoolSpend}>Community Pool Spend</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.ParameterChange}>Parameter Change</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.SoftwareUpgrade}>Software Upgrade</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.CancelSoftwareUpgrade}>Cancel Software Upgrade</MenuItem>
          <MenuItem value={RPCParams.Proposal.Type.Text}>Text</MenuItem>
        </Select>
      </FormControl>
      <Divider variant="fullWidth" light />
    </Box>
  )

  const withDetailsStepTitles = ['Select Proposal', 'Proposal Details', 'Proposal Description', 'Deposit']
  const noDetailsStepTitles = ['Select Proposal', 'Proposal Description', 'Deposit']

  return (
    <Box className={classes.root}>
      {
        proposal === '' &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit}
          steps={[proposalDropdown]}
          noSubmit
          titles={withDetailsStepTitles}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.CreateToken &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(createTokenProposalValue())}
          steps={[
            proposalDropdown,
            <CreateTokenProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to create a token. Token denom must be unique"]}
          titles={withDetailsStepTitles}
          customValidator={createTokenValidator}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.CreateOracle &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(createOracleProposalValue())}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={createOracleConfig()}
              initialFormState={CreateOracleProposalInitialFormState}
              requiredFields={createOracleFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to create a new Oracle. A use case is to determine the index price for a market"]}
          titles={withDetailsStepTitles}
          config={createOracleConfig()}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.CreateMarket &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(createMarketProposalValue())}
          steps={[
            proposalDropdown,
            <CreateMarketProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to create a market. Market name must be unique"]}
          titles={withDetailsStepTitles}
          customValidator={createMarketValidator(tokens)}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.UpdateMarket &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(updateMarketProposalValue())}
          steps={[
            proposalDropdown,
            <UpdateMarketProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to update market parameters. All of the fields are compulsory and will override existing parameters even if left blank"]}
          titles={withDetailsStepTitles}
          customValidator={updateMarketValidator}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.LinkPool &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(linkPoolProposalValue())}
          steps={[
            proposalDropdown,
            <LinkPoolMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to link pool to a market. This will generate AMM orders for the market by using this pool's liquidity"]}
          titles={withDetailsStepTitles}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.UnlinkPool &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(unlinkPoolProposalValue())}
          steps={[
            proposalDropdown,
            <UnlinkPoolMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to unlink pool from a market. This will remove all AMM orders from the market that is using this pool's liquidity"]}
          titles={withDetailsStepTitles}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.SetPoolRewardWeights &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setPoolRewardWeightsProposalValue())}
          steps={[
            proposalDropdown,
            <SetPoolRewardWeightsProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose the respective pools weights to determine the distribution of rewards/fees among pools. Distribution ratio for a given pool is calculated by taking the weight of the pool divided by the total weight of all pools. Pools that are not included in the proposal will remain unchanged"]}
          titles={withDetailsStepTitles}
          customValidator={setPoolRewardWeightsValidator}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.SetRewardCurve &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setRewardCurveProposalValue())}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={setRewardCurveConfig()}
              initialFormState={SetRewardCurveProposalInitialFormState}
              requiredFields={setRewardCurveFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a reward curve that determines the rewards & fees allocation to staked liquidity pool tokens"]}
          titles={withDetailsStepTitles}
          config={setRewardCurveConfig()}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.SetCommitmentCurve &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setCommitmentCurveProposalValue())}
          steps={[
            proposalDropdown,
            <SetCommitmentCurveProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a commitment curve that determines a linear yield multiplier based on the duration of the locked duration"]}
          titles={withDetailsStepTitles}
          config={setCommitmentCurveConfig}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.SetSettlementPrice &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setSettlementPriceProposalValue())}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={settlementPriceConfig()}
              initialFormState={SetSettlementPriceProposalInitialFormState}
              requiredFields={settlementPriceFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a settlement price in the event that there is no settlement price for an expired futures market"]}
          titles={withDetailsStepTitles}
          config={settlementPriceConfig()}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.ParameterChange &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(parameterChangeProposalValue())}
          steps={[
            proposalDropdown,
            <ParameterChangeProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', 'Propose a parameter change in the chain']}
          titles={withDetailsStepTitles}
          customValidator={parameterChangeValidator}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.CommunityPoolSpend &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(communityPoolSpendProposalValue())}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={communityPoolSpendConfig(tokens)}
              initialFormState={CommunityPoolSpendProposalInitialFormState}
              requiredFields={communityPoolSpendFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to spend the community pool fund by sending amount to recipient address"]}
          titles={withDetailsStepTitles}
          config={communityPoolSpendConfig(tokens)}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.SoftwareUpgrade &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(softwareUpgradeProposalValue())}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={softwareUpgradeConfig()}
              initialFormState={SoftwareUpgradeProposalInitialFormState}
              requiredFields={softwareUpgradeFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a software upgrade to happen at a specific block height or time. If there is another scheduled plan, it will override it"]}
          titles={withDetailsStepTitles}
          customValidator={softwareUpgradeValidator}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.CancelSoftwareUpgrade &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(textProposalValue())}
          steps={[
            proposalDropdown,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to abort a previously voted upgrade"]}
          titles={noDetailsStepTitles}
        />
      }
      {
        proposal === RPCParams.Proposal.Type.Text &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(textProposalValue())}
          steps={[
            proposalDropdown,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Proposals that do not involve a modification of the source code go under this type. For example, an opinion poll would use a proposal of type PlainTextProposal"]}
          titles={noDetailsStepTitles}
        />
      }
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  proposalDropdown: {
    padding: theme.spacing(3, 3, 6, 3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
}))

export default MainForm
