import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { setSubmitProposalId } from 'js/actions/governance'
import { CellLink, DataLoadSegment, JSONBlock, NotFoundState, Page, ProposalTopicLabel, Section, TabsButtons } from 'js/components'
import { DepositStatus, PeriodTag, ProposalStatus } from 'js/components/ProposalRow/components'
import { Constants, Paths, TutorialKeys } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { actions } from 'js/store'
import { BIG_ZERO, unescapeHtmlGo, parseNumber } from 'js/utils'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RestModels } from 'tradehub-api-js'
import { CustomTable } from '../components'
import { DepositDialog, VoteDialog } from '../Dialogs'
import { DepositDetails, DepositHistory, ProposalHistory, ValidatorVotes, VoteDetails, VoteHistory } from './components'
import ProposalProperties from './components/ProposalProperties/ProposalProperties'

interface Props {
  match?: {
    params?: {
      proposalId?: string
    }
  }
}

const TAB_ITEMS = [{
  label: 'Deposits',
  key: 'deposits',
}, {
  label: 'Votes',
  key: 'votes',
}, {
  label: 'Validators',
  key: 'validators',
}]

const Proposal: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = props?.match?.params
  const [getProposal, getProposalLoading] = useAsyncTask('getProposal')
  const [proposer, setProposer] = useState('')
  const [selectedTab, setSelectedTab] = useState(TAB_ITEMS[0].key)
  const api = useRedux((state) => state.core.sdk?.api)
  const [openDepositDialog, setOpenDepositDialog] = useState(false)
  const [openVoteDialog, setOpenVoteDialog] = useState(false)
  const [proposal, setProposal] = useState<RestModels.GovProposal | null>(null)
  const [runQueryProposer, getProposerLoading] = useAsyncTask('queryProposer')

  const isDepositPeriod = proposal?.proposal_status === Constants.Proposal.Status.DepositPeriod

  dispatch(setSubmitProposalId(null))
  useEffect(() => {
    if (!params?.proposalId || !api) return
    getProposal(async () => {
      const response = await api.getGovProposal({ proposalId: parseInt(params.proposalId!) })
      setProposal(response?.result || null)
    })
    // eslint-disable-next-line
  }, [params?.proposalId, api])

  useEffect(() => {
    if (!params?.proposalId || !api) return
    runQueryProposer(async () => {
      const response = await api.getGovProposer({ proposalId: parseInt(params.proposalId!) })
      setProposer(response?.result.proposer || '')
    })
    // eslint-disable-next-line
  }, [params?.proposalId, api])

  useEffect(() => {
    if (isDepositPeriod) {
      dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.DepositProposal))
    }
    // eslint-disable-next-line
  }, [isDepositPeriod])

  function onClickDeposit(): void {
    setOpenDepositDialog(true)
  }

  function onCloseDeposit(): void {
    setOpenDepositDialog(false)
  }

  function onClickVote(): void {
    setOpenVoteDialog(true)
  }

  function onCloseVote(): void {
    setOpenVoteDialog(false)
  }

  function onTabChange(event: any, key: string): void {
    setSelectedTab(key)
  }

  const topic = proposal?.content.value?.changes?.[0]?.subspace
  const title = proposal && (
    <span>
      {!!topic && (
        <React.Fragment>
          [<ProposalTopicLabel>{topic}</ProposalTopicLabel>]
          {' '}
        </React.Fragment>
      )}
      {unescapeHtmlGo(proposal?.content.value?.title)}
    </span>
  )

  let proposalJson: any

  if (!!proposal?.content.value && !proposal.content.value.changes) {
    const { title, description, ...rest } = proposal.content.value
    proposalJson = rest
  }

  // Show details in proposal description
  const proposalDescriptionList = []
  if (proposal?.content?.type) {
    proposalDescriptionList.push(
      { header: 'Proposal Type', cell: proposal?.content?.type }
    )
  }
  if (proposal?.content?.value?.changes) {
    proposalDescriptionList.push(
      { header: 'Topic', cell: proposal?.content?.value?.changes?.[0]?.subspace },
      { header: 'Parameter', cell: proposal?.content?.value?.changes?.[0]?.key },
      { header: 'Value', cell: proposal?.content?.value?.changes?.[0]?.value },
    )
  }

  return (
    <Page
      title={title}
      backLink={Paths.Governance}
      backLabel="Governance Proposals"
    >
      <DataLoadSegment loading={getProposalLoading || getProposerLoading}>
        {proposal && (
          <Fragment>
            <Box marginBottom={2} marginTop={2}>
              <PeriodTag proposal={proposal} />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7} lg={8} className={classes.flexGrid}>
                <Section flexGrow={0}>
                  <CustomTable
                    className={classes.dataTable}
                    headerAndCells={[
                      { header: 'Proposal ID', cell: proposal?.id },
                      {
                        header: 'Proposed By',
                        cell: <CellLink to={Paths.Account.replace(':address', proposer)}>{proposer}</CellLink>,
                      },
                      { header: 'Current Status', cell: <ProposalStatus proposal={proposal} /> },
                      { header: 'Deposit Status', cell: <DepositStatus proposal={proposal} /> },
                    ]}
                  />
                </Section>

                <Section flex={1}>
                  <Box className={classes.descContainer}>
                    <div className={classes.descTitleContainer}>
                      <Typography variant="h2" className={classes.title}>
                        Proposal Description
                      </Typography>
                    </div>
                    {proposalDescriptionList.length > 0 && (
                      <CustomTable headerAndCells={proposalDescriptionList} />
                    )}
                    {!!proposalJson && (
                      <Box marginBottom={4}>
                        <ProposalProperties
                          propertyName="Root Property"
                          rootProperty={true}
                          property={proposalJson}
                          excludeBottomBorder={false}
                        />
                        <JSONBlock header="Content" content={proposalJson} />
                      </Box>
                    )}
                    {unescapeHtmlGo(proposal?.content?.value?.description)}
                  </Box>
                </Section>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                {
                  isDepositPeriod
                    ? <DepositDetails proposal={proposal} onClickDeposit={onClickDeposit} />
                    : <VoteDetails proposal={proposal} onClickVote={onClickVote} />
                }
                <ProposalHistory proposal={proposal} />
              </Grid>
              <Grid item xs={12}>
                <Section title="Activity">
                  <TabsButtons
                    handleChange={onTabChange}
                    items={TAB_ITEMS}
                    value={selectedTab}
                  />
                  {selectedTab === 'deposits' && (
                    <DepositHistory proposal={proposal} />
                  )}
                  {selectedTab === 'votes' && (
                    <VoteHistory proposal={proposal} />
                  )}
                  {selectedTab === 'validators' && (
                    <ValidatorVotes proposal={proposal} />
                  )}
                </Section>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {!proposal && (
          <NotFoundState title="No Proposal Found">
            <Typography variant="body1">
              We can’t find any proposal with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Governance}>
                Governance
              </CellLink>
              &nbsp;page to view existing proposals.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>

      {proposal && (
        <DepositDialog
          isOpen={openDepositDialog}
          proposalID={proposal.id}
          proposalTitle={proposal.content.value.title}
          proposalTopic={proposal.content.value.changes?.[0]?.subspace}
          currentDeposit={proposal.total_deposit
            .reduce(
              (a, deposit: RestModels.TokenAmount) =>
                a.plus(parseNumber(deposit.amount, BIG_ZERO)!),
              BIG_ZERO,
            )
            .toString()}
          onClose={onCloseDeposit}
        />
      )}
      {proposal && (
        <VoteDialog
          isOpen={openVoteDialog}
          proposalID={proposal.id}
          proposalTitle={proposal.content.value.title}
          proposalTopic={proposal.content.value.changes?.[0]?.subspace}
          onClose={onCloseVote}
        />
      )}
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  descContainer: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  descTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  dataTable: {
    marginTop: 0,
    marginBottom: 0,
  },
}))

export default Proposal
