import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { DataLoadSegment, ListTable, PaginationByIndex, Section, TableEmptyState } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import { SimpleMap } from 'js/utils'
import React, { ReactElement } from 'react'
import { RestModels, RestTypes } from 'tradehub-api-js'
import LiquidationADLRow from './LiquidationADLRow'

export interface LiquidationADLsProps {
  filterStatus: boolean
  orders?: RestTypes.ResultsMinMax<RestModels.Order>
  title: string
  action: any
  markets: SimpleMap<RestModels.Market>
  loadName: string[]
  loadCondition?: boolean
  sectionClass?: string
}

const LiquidationADLs: React.FunctionComponent<LiquidationADLsProps> = ({
  filterStatus = false,
  orders,
  title,
  action,
  markets,
  loadName,
  loadCondition = true,
  sectionClass,
}: LiquidationADLsProps): ReactElement<LiquidationADLsProps> => {
  const [loading] = useTaskSubscriber(...loadName)

  return (
    <Section className={sectionClass} title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={loading && loadCondition}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Event</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Created At</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.data.map((order) => (
              <LiquidationADLRow key={order.id} order={order} markets={markets} />
            ))}
          </TableBody>
        </ListTable>
        {!!orders?.data?.length && (
          <Box marginTop={3}>
            <PaginationByIndex
              response={orders}
              action={action}
              filters={{}}
              useBlockHeight={false}
            />
          </Box>
        )}
        {!orders?.data?.length && (
          <TableEmptyState itemName={title.toLowerCase()} />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default LiquidationADLs
