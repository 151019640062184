import { createMuiTheme, StyleRules, Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import { MuiTheme } from '../constants'
import themeOverrides from './overrides'
import { darkPalette, lightPalette } from './palettes'
import typography from './typography'

const darkFlavouringTheme = createMuiTheme({ palette: darkPalette })
const lightFlavouringTheme = createMuiTheme({ palette: lightPalette })

interface ThemeableOverrides {
  [index: string]: (theme: Theme) => Partial<StyleRules>
}

const applyFlavour = (
  _overrides: ThemeableOverrides,
  theme: Theme,
): Overrides => {
  const result: Overrides = {}
  for (const key in _overrides) {
    if (typeof key !== 'string') {
      throw new Error('invalid key')
    }
    result[key as (keyof Overrides)] = _overrides[key](theme)
  }
  return result
}

type ValueOf<T> = T[keyof T]
export type ThemeKey = ValueOf<typeof MuiTheme.Mode>

const getFlavourForMode = (key: ThemeKey) => {
  if (key === 'dark') return darkFlavouringTheme
  return lightFlavouringTheme
}
const getPaletteForMode = (key: ThemeKey) => {
  if (key === 'dark') return darkPalette
  return lightPalette
}

export const createTheme = (mode: ThemeKey) => {
  mode = MuiTheme.Mode.Bright // force light mode, dark mode palette not done
  const flavouringTheme = getFlavourForMode(mode)
  const overrides = applyFlavour(themeOverrides, flavouringTheme)
  const palette = getPaletteForMode(mode)
  return createMuiTheme({ overrides, typography, palette })
}

export default createTheme
