import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode } from 'react'
import { RestModels } from 'tradehub-api-js'
import { bnOrZero } from 'js/utils'

interface Props {
  rich: RestModels.UserToken[]
}

const RichList: React.FunctionComponent<Props> = ({
  rich,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.Token.RichList)
  const sdk = useRedux(state => state.core.sdk)

  return (
    <Section title="Rich List">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rich.map(renderRichAccount)}</TableBody>
        </ListTable>
        {!rich?.length && (
          <TableEmptyState itemName="accounts" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderRichAccount(balance: RestModels.UserToken): ReactNode {
    const { address, amount, username } = balance
    const amountBN = bnOrZero(amount)
    const decimals = sdk?.token.getDecimals(balance.denom) ?? 2
    return (
      <TableRow key={address} hover>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {username === '' ? address : username}
          </CellLink>
        </TableCell>
        <TableCell align="right">{amountBN.toFormat(decimals)}</TableCell>
      </TableRow>
    )
  }
}

export default RichList
