import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import CellLink from 'js/components/CellLink'
import { Paths } from 'js/constants'
import React, { ReactElement } from 'react'
import { ReactComponent as ArtworkSvg } from './art-vote.svg'

type Props = BoxProps

const Step4: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ArtworkSvg className={classes.artwork} />
      <Typography variant='body1'>
        There are 4 types of criteria.
      </Typography>
      <Box marginBottom={2} />
      <Typography variant='body1'>
        <strong>Deposit</strong>: A minimum deposit of 10,000
        SWTH is required for the proposal.
      </Typography>
      <Typography variant='body1'>
        <strong>Quorum</strong>: More than 33.4% of total
        staking power voted on a proposal.
      </Typography>
      <Typography variant='body1'>
        <strong>Pass Threshold</strong>: At least 50% of
        voters voted “Yes” on a proposal.
      </Typography>
      <Typography variant='body1'>
        <strong>Veto Threshold</strong>: Less than 33.4% of
        voters voted “NowithVeto”.
      </Typography>
      <Box marginBottom={2} />
      <Typography variant='body1'>
        Find out examples of how a proposal is
        rejected <CellLink
          underline='hover'
          href={Paths.Resources.HowToVote}
        >
          here
        </CellLink>.
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  artwork: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '15rem',
    width: '100%',
  },
}))

export default Step4
