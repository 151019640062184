import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { NoSearchResultsSvg } from 'assets'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

interface Props {
  title: string
  className?: string
  children: any
  boxClass?: string
  svgClass?: string
  titleClass?: string
}

const NotFoundState: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, children, className, boxClass, svgClass, titleClass } = props
  const classes = useStyles()

  return (
    <Grid container className={clsx(classes.root, className)}>
      <Grid item xs={12} className={clsx(classes.box, boxClass)}>
        <NoSearchResultsSvg className={clsx(classes.graphic, svgClass)} />
        <Typography
          className={clsx(classes.title, titleClass)}
          variant="h2"
        >
          {title}
        </Typography>
        {children}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  box: {
    margin: theme.spacing(5, 0),
  },
  graphic: {
    margin: theme.spacing(5, 'auto', 5),
    maxWidth: '25rem',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

export default NotFoundState
