import { List } from "immutable"
import { CommunityPoolSpendProposalFormState } from "js/models"
import store from "js/store"
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { RestModels, RPCParams } from "tradehub-api-js"
import { fieldTypes } from "../Helpers/types"

export const communityPoolSpendFormRequiredFields = ['denom', 'amount', 'recipient']

export function communityPoolSpendConfig(tokens: List<RestModels.Token>) {
  return {
    denom: {
      id: 'denom',
      label: 'Denom',
      type: fieldTypes.select,
      options: tokens.map((token) => token.denom),
      placeholder: 'Select a denom',
    },
    recipient: {
      id: 'recipient',
      label: 'Recipient',
      type: fieldTypes.text,
      tooltip: 'Account to receive funds from community pool',
      constraints: {
        validAddress: true,
      },
    },
    amount: {
      id: 'amount',
      label: 'Amount',
      type: fieldTypes.number,
      tooltip: 'Amount to receive (in human form)',
      constraints: {
        greaterThan: 0,
      },
    },
  }
}

export function communityPoolSpendProposalValue(): RPCParams.CommunityPoolSpendProposal {
  const formState = store.getState().governance.submitProposalFormState as CommunityPoolSpendProposalFormState
  const sdk = store.getState().core.sdk;
  const { title, description, denom, amount, recipient } = formState
  const tokenDecimals = sdk?.token.getDecimals(denom) ?? 0

  const formattedAmount = parseNumber(amount, BIG_ZERO)!.shiftedBy(tokenDecimals).toString()

  const value = {
    title: escapeHtmlGo(title),
    description: escapeHtmlGo(description),
    recipient,
    amount: [{ denom, amount: formattedAmount }],
  }

  return value
}
