import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum OrderActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_ORDER = 'SET_ORDER',
}

export type OrderActon = ClearAction | SetOrderAction

export interface ClearAction extends Action<OrderActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: OrderActionType.CLEAR,
  }
}

export interface SetOrderAction extends Action<OrderActionType.SET_ORDER> {
  order: RestModels.Order
}

export function setOrder(order: RestModels.Order): SetOrderAction {
  return {
    type: OrderActionType.SET_ORDER,
    order,
  }
}
