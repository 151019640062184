import { Button, makeStyles, Theme } from '@material-ui/core'
import { ReactComponent as RightArrowSVG } from 'assets/ButtonLabel_Light.svg'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  ref?: any
  children: any
  className?: string
  color: 'inherit' | 'default' | 'primary' | 'secondary'
  disabled?: boolean
  onClick?: any
  to?: string
  variant: 'text' | 'contained' | 'outlined'
}

const StandardBtn: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { children, className, to, ref, ...rest } = props
  const classes = useStyles()

  return (!!to && to.length > 0 ?
    <Button
      ref={ref}
      component={RouterLink}
      className={clsx(classes.button, className)}
      to={to}
      {...rest}
    >
      {children}
      <RightArrowSVG className={classes.icon} />
    </Button>
    :
    <Button
      ref={ref}
      className={clsx(classes.button, className)}
      {...rest}
    >
      {children}
      <RightArrowSVG className={classes.icon} />
    </Button>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: '12.5rem',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    textAlign: 'left',
    "&:hover $icon, &:focus $icon": {
      transform: "rotate(-45deg)",
    },
  },
  icon: {
    marginLeft: theme.spacing(2),
    transition: "transform .05s ease-in-out",
    transform: "rotate(0deg)",
    height: "1.375rem",
    width: "1.375rem",
    [theme.breakpoints.down("md")]: {
      height: "1rem",
      width: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(0)",
    },
  },
}))

export default StandardBtn
