import { Link, LinkProps, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = LinkProps & {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const FooterNavLink: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { className, children, Icon, ...rest } = props

  const classes = useStyles(props)

  return (
    <Link variant="body1" {...rest} className={clsx(classes.root, className)}>
      {Icon && (
        <Icon className={classes.icon} />
      )}
      {children}
    </Link>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5, 0),
    borderTop: `1px solid #9DADB2`,
    '&:last-child': {
      borderBottom: `1px solid #9DADB2`,
    },
    '&.MuiLink-root': {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    height: '1.25rem',
    width: '1.25rem',
    '& path,rect,polygon': {
      fill: 'currentColor',
    },
  },
}))

export default FooterNavLink
